import React, { useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import useQuery from '@/hooks/useQuery'
import { debounce } from 'throttle-debounce'
import { ExclamationCircleIcon } from '@heroicons/react/solid'
import { useGlobalState } from '@/state'
import { randomToastSuccess } from '@/shared/Toast'
import Tippy from '@tippyjs/react'
import ToolTip from '@/shared/ToolTip'

import Card from '@/shared/Card'
import PrimaryButton from '@/shared/Buttons/Primary'
import OmniAuthSettings from '@/pages/Settings/OmniAuthSettings'
import DeleteAccount from '@/pages/Settings/DeleteAccount'

const SettingsGeneral = () => {
  const [user, setUser] = useGlobalState('settingsContainerUser')
  const [currentUser, setCurrentUser] = useGlobalState('currentUser')

  const [, setToast] = useGlobalState('toast')
  let history = useHistory()
  const [timezones, setTimezones] = useState([])
  const { putpostRequest, getRequest } = useQuery()
  const { formState, reset, register, unregister, handleSubmit, setValue, getValues, setError } = useForm({
   defaultValues: {
      firstName: user.firstName,
      timezone: user.timezone,
      email: user.email,
      lastName: user.lastName
    }
  })

  const { isDirty, errors } = formState
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    getRequest('/api/v1/static/timezones', {}, (err, jsonData) => {
      if (err) { /* handled in hook */ return }

      if (jsonData.timezones) {
        setTimezones(jsonData.timezones)
        setValue('timezone', user.timezone, { shouldDirty: false, shouldValidate: false })
      }
    })
  }, [])

  const onSubmit = debounce(300, data => {
    setLoading(true)

    putpostRequest(`/api/v1/users/${user.id}`, 'PATCH', { user: data }, (err, jsonData) => {
      setLoading(false)
      if (err) { // 422 code
        Object.entries(err).forEach(([key, value]) => {
          setError(key, value)
        })
        return
      }

      setCurrentUser({
        ...currentUser,
        displayName: jsonData.user.displayName,
        timezone: getValues().timezone,
        email: getValues().email,
        firstName: getValues().first_name
      })

      setToast(randomToastSuccess())
    })
  })


  return (
    <div className='mt-10'>
      <div className="md:grid md:grid-cols-3 md:gap-6">
        <div className="md:col-span-1">
          <div className="px-4 sm:px-0">
            <h3 className="text-lg font-medium leading-6 text-gray-900 dark:text-gray-100">General</h3>
            <p className="mt-1 text-sm text-gray-600 dark:text-gray-300">
              The basics of the basics.
            </p>
          </div>
        </div>
        <Card footer={<PrimaryButton onClick={handleSubmit(onSubmit)} loading={loading} disabled={!isDirty} text="Save" />}>
          <form>
            <div className="grid grid-cols-3 gap-6">
              <div className="col-span-3 sm:col-span-1">
                <label htmlFor="display_name" className="block text-sm font-medium text-gray-700 dark:text-gray-300 flex justify-between">
                  <span>First Name</span>

                  <Tippy interactive={true} interactiveBorder={20} delay={100} content={<ToolTip>Required Field</ToolTip>}>
                    <span onClick={(e) => e.preventDefault() }><ExclamationCircleIcon className="h-5 w-5 text-gray-400" aria-hidden="true" /></span>
                  </Tippy>
                </label>
                <div className="mt-1 flex flex-col rounded-md shadow-sm">
                  <div className='relative'>
                    <input type="text" className={errors.first_name ? 'errors' : ''} {...register('first_name', { required: true }) } defaultValue={user.firstName} placeholder="Happy" />
                    { errors.first_name && <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                      <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
                    </div> }
                  </div>
                </div>
                { errors.first_name && <div className='mt-2 text-sm text-red-600'>This field is required.</div> }
              </div>
              <div className="col-span-3 sm:col-span-1">
                <label htmlFor="location" className="block text-sm font-medium text-gray-700 dark:text-gray-300 flex justify-between">
                  <span>Last Name</span>
                  <Tippy interactive={true} interactiveBorder={20} delay={100} content={<ToolTip>Required Field</ToolTip>}>
                    <span onClick={(e) => e.preventDefault() }><ExclamationCircleIcon className="h-5 w-5 text-gray-400" aria-hidden="true" /></span>
                  </Tippy>
                </label>
                <div className="mt-1 flex rounded-md shadow-sm flex flex-col">
                  <div className='relative'>
                    <input {...register('last_name', { required: true }) } className={errors.last_name ? 'errors' : ''} type="text" placeholder="Hedgehog" defaultValue={user.lastName}/>
                    { errors.last_name && <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                      <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
                    </div> }
                  </div>
                </div>
                { errors.last_name && <div className='mt-2 text-sm text-red-600'>This field is required.</div> }
              </div>
              <div className="col-span-3 sm:col-span-1">
                <label htmlFor="location" className="block text-sm font-medium text-gray-700 dark:text-gray-300 flex justify-between">
                  <span>Email</span>
                  <Tippy interactive={true} interactiveBorder={20} delay={100} content={<ToolTip>Required Field</ToolTip>}>
                    <span onClick={(e) => e.preventDefault() }><ExclamationCircleIcon className="h-5 w-5 text-gray-400" aria-hidden="true" /></span>
                  </Tippy>
                </label>
                <div className="mt-1 flex rounded-md shadow-sm flex flex-col">
                  <div className='relative'>
                    <input {...register('email', { required: true }) } className={errors.email ? 'errors' : ''} type="text" placeholder="Hedgehog" defaultValue={user.email}/>
                    { errors.email && <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                      <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
                    </div> }
                  </div>
                </div>
                { errors.email && <div className='mt-2 text-sm text-red-600'>This field is required.</div> }
              </div>
              <div className='col-span-2'>
                <div className="col-span-6 sm:col-span-3">
                  <label htmlFor="country" className="block text-sm font-medium text-gray-700 dark:text-gray-200">
                    Preferred Timezone
                  </label>
                  <select
                    {...register('timezone')}
                    className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white dark:bg-gray-900 dark:text-gray-100 rounded-md shadow-sm focus:outline-none focus:ring-cccpurple focus:border-cccpurple sm:text-sm"
                  >
                    { timezones.map((tz) => (
                      <option key={tz.computer} value={tz.computer}>{tz.human.replace(/_/g, ' ')}</option>
                    ))}
                  </select>
                </div>
              </div>
            </div>

          </form>
        </Card>
      </div>
    </div>
  )
}


const SettingsPassword = () => {
  const [user, setUser] = useGlobalState('settingsContainerUser')

  const [, setToast] = useGlobalState('toast')
  const { putpostRequest, getRequest } = useQuery()
  const { formState, reset, register, unregister, handleSubmit, setValue, getValues, setError } = useForm({
   defaultValues: { }
  })

  const { isDirty, errors } = formState
  const [loading, setLoading] = useState(false)

  const onSubmit = debounce(300, data => {
    setLoading(true)

    putpostRequest(`/api/v1/users/${user.id}/change_password`, 'POST', { user: data }, (err, jsonData) => {
      setLoading(false)
      if (err) { // 422 code
        Object.entries(err).forEach(([key, value]) => {
          setError(key, value)
        })
        return
      }

      setToast(randomToastSuccess())
    })
  })


  return (
    <div className='mt-10'>
      <div className="md:grid md:grid-cols-3 md:gap-6">
        <div className="md:col-span-1">
          <div className="px-4 sm:px-0">
            <h3 className="text-lg font-medium leading-6 text-gray-900 dark:text-gray-100">Password</h3>
            <p className="mt-1 text-sm text-gray-600 dark:text-gray-300">
              Feeling like your security is a little lax? Change your password here.
            </p>
          </div>
        </div>
        <Card footer={<PrimaryButton onClick={handleSubmit(onSubmit)} loading={loading} disabled={!isDirty} text="Save" />}>
          <form>
            <div className="md:grid md:grid-cols-3 md:gap-6">
              <div className="md:col-span-1 col-span-3">
                <label htmlFor="location" className="block text-sm font-medium text-gray-700 dark:text-gray-300 flex justify-between">
                  <span>Old Password</span>
                  <Tippy interactive={true} interactiveBorder={20} delay={100} content={<ToolTip>Minimum 8 characters</ToolTip>}>
                    <span onClick={(e) => e.preventDefault() }><ExclamationCircleIcon className="h-5 w-5 text-gray-400" aria-hidden="true" /></span>
                  </Tippy>
                </label>
                <div className="mt-1 flex rounded-md shadow-sm flex flex-col">
                  <div className='relative'>
                    <input type='password' {...register('old_password', { required: true }) } className={errors.old_password ? 'errors' : ''} />
                    { errors.old_password && <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                      <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
                    </div> }
                  </div>
                </div>
                { errors.old_password && <div className='mt-2 text-sm text-red-600'>This field is required. Minimum 8 characters</div> }
              </div>
              <div className="md:col-span-1 col-span-3">
                <label htmlFor="location" className="block text-sm font-medium text-gray-700 dark:text-gray-300 flex justify-between">
                  <span>New Password</span>
                  <Tippy interactive={true} interactiveBorder={20} delay={100} content={<ToolTip>Minimum 8 characters</ToolTip>}>
                    <span onClick={(e) => e.preventDefault() }><ExclamationCircleIcon className="h-5 w-5 text-gray-400" aria-hidden="true" /></span>
                  </Tippy>
                </label>
                <div className="mt-1 flex rounded-md shadow-sm flex flex-col">
                  <div className='relative'>
                    <input type='password' {...register('password', { required: true }) } className={errors.password ? 'errors' : ''} />
                    { errors.password && <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                      <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
                    </div> }
                  </div>
                </div>
                { errors.password && <div className='mt-2 text-sm text-red-600'>This field is required. Minimum 8 characters</div> }
              </div>
            </div>

          </form>
        </Card>
      </div>
    </div>
  )
}

const Settings = ({school}) => {
  return <>
    <SettingsGeneral />
    <SettingsPassword />
    <OmniAuthSettings />
    <DeleteAccount />
  </>
}

export default Settings

import React, { useState, useEffect, useRef, useReducer } from 'react'
import { Link } from 'react-router-dom'

import { useGlobalState } from '@/state'
import useQuery from '@/hooks/useQuery'
import { ExclamationIcon } from '@heroicons/react/solid'

import Loading from '@/shared/Loading'
import EventRow from '@/shared/EventRow'

export default function PastEventsIndex(props) {
  const [ currentUser ] = useGlobalState('currentUser')
  const { getRequest } = useQuery()
  const isComponentMounted = useRef(false)
  const [ loading, setLoading ] = useState(false)

  const [state, setState] = useReducer(
    (state, newState) => (
      {...state, ...newState}), {
        events: [],
        attendingEventIds: []
      }
    )
  const { events, attendingEventIds, pastEvents } = state

  useEffect(() => {
    getRequest(`/api/v1/events?past=true`, {}, (err, jsonData) => {
      if (err) { /* handled in hook */ return }

      setState( {
        events: jsonData.events,
        attendingEventIds: jsonData.attendingEventIds
      })
    })
    isComponentMounted.current = true
  }, [])

  if (!isComponentMounted.current) {
    return <Loading />
  }

  return (
    <div className="relative bg-gray-100 dark:bg-gray-700 pb-20 px-4 sm:px-6 pt-6">
      <div className="relative max-w-7xl mx-auto">
        { !['staff', 'member'].includes(currentUser.access) && <>
          <div className="rounded-md bg-yellow-50 p-4 mt-10">
            <div className="flex">
              <div className="flex-shrink-0">
                <ExclamationIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
              </div>
              <div className="ml-3">
                <div className='pb-3 text-sm'>
                  Past events are only available to members.
                </div>
                <h3 className="text-sm font-medium text-yellow-800">
                  If you'd like to view past event recordings, notes, and summaries, please <Link to='/subscriptions'>upgrade to the membership</Link>.
                </h3>
              </div>
            </div>
          </div>
        </> }

        { ['staff', 'member'].includes(currentUser.access) && <>
          <div className="relative my-6">
            <div className="absolute inset-0 flex items-center" aria-hidden="true">
              <div className="w-full border-t border-gray-300" />
            </div>
            <div className="relative flex justify-start">
              <span className="pr-3 bg-gray-100 text-lg font-medium text-gray-900 dark:text-gray-300 dark:bg-gray-700">Past Events</span>
            </div>
          </div>

          <div className="mt-12 max-w-lg mx-auto grid gap-5 lg:grid-cols-3 lg:max-w-none">
            {events.map((event) => (
              <EventRow key={`event${event.id}`} event={event} user={currentUser} attendingEventIds={attendingEventIds} />
            ))}
          </div>
        </> }
      </div>
    </div>
  )
}

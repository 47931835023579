import React, { useEffect, useRef, useReducer } from 'react'
import { Switch, Route, useLocation, Link } from 'react-router-dom'

import { useGlobalState } from '@/state'
import Breadcrumbs from '@/Layout/Breadcrumbs'
import PageTitle from '@/Layout/PageTitle'

import DefaultButton from '@/shared/Buttons/Default'
import EventIndex from '@/pages/EventIndex'
import PastEventsIndex from '@/pages/PastEventsIndex'
import EventShow from '@/pages/EventShow'
import MyEvents from '@/pages/MyEvents'

function breadCrumbs() {
  const location = useLocation()
  const activePath = location.pathname
  const crumbs = []
  if (activePath === '/events') {
    crumbs.push({ to: '/events', name: 'Events' })
  }
  if (activePath === '/past-events') {
    crumbs.push({ to: '/past-events', name: 'Past Events' })
  }
  if (activePath === '/myevents') {
    crumbs.push({ to: '/myevents', name: 'My Events' })
  }
  if (activePath.includes('/events/')) {
    const [ currentEvent ] = useGlobalState('currentEvent')
    let crumb = currentEvent?.name || 'Event'
    crumbs.push({ to: activePath, name: crumb })
  }
  return crumbs
}

function pageTitle() {
  const location = useLocation()
  const activePath = location.pathname
  const titleInfo = {
     title: '',
    buttons: [ ]
  }
  if (activePath === '/events') { titleInfo.title = 'Events' }
  if (activePath === '/past-events') { titleInfo.title = 'Past Events' }
  if (activePath === '/myevents') { titleInfo.title = 'My Events' }
  if (activePath.includes('/events/')) {
    const [ currentEvent ] = useGlobalState('currentEvent')
    let title = currentEvent?.name || 'Event'
    titleInfo.title = title
  }
  return titleInfo
}

export default function EventsContainer(props) {
  const crumbs = breadCrumbs()
  const titleInfo = pageTitle()

  return <>
    <Breadcrumbs>
      { crumbs.map((crumb, index) => (
        <Link key={crumb.name} to={crumb.to} className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700 dark:text-gray-100">
          {crumb.name}
        </Link>
      ))}
    </Breadcrumbs>
    <PageTitle pagetitle={titleInfo.title}>
      {titleInfo.buttons}
    </PageTitle>
    <Switch>
      <Route {...props} exact path='/events'>
        <EventIndex />
      </Route>
      <Route {...props} exact path='/past-events'>
        <PastEventsIndex />
      </Route>
      <Route {...props} exact path={[`/events/:id`]}>
        <EventShow />
      </Route>
      <Route {...props} exact path={['/myevents']}>
        <MyEvents />
      </Route>
    </Switch>
  </>
}

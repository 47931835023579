import React, {useState, useEffect} from 'react'
import useQuery from '@/hooks/useQuery'
import { Switch } from '@headlessui/react'
import { useForm } from 'react-hook-form'
import FroalaMinimalWrapper from '@/shared/FroalaMinimalWrapper'
import { ExclamationCircleIcon, EmojiHappyIcon, LinkIcon, DuplicateIcon, ClipboardCopyIcon } from '@heroicons/react/solid'

import { PrimaryButton, DefaultButton } from '@/shared/Buttons'
import { Modal, useModal } from '@/shared/Modal'
import classNames from '@/utils/classNamesLocal'

import TestimonialImage from '@/pages/teachers/LandingPage/TestimonialImage'
import { showNonSpaToast } from '@/utils/nonSpaToast'
import { BulletItem, CaretItem } from '@/pages/teachers/Launch/Items'

const TestimonialForm = ({testimonial, refreshData}) => {
  const [editing, setEditing] = useState(false)
  const [confirmDelete, setConfirmDelete] = useState(false)
  const { putpostRequest } = useQuery()
  const { formState, reset, register, unregister, handleSubmit, setValue, watch, getValues, setError } = useForm({
   defaultValues: {
      author_title: testimonial.authorTitle,
      author_name: testimonial.authorName,
      quote: testimonial.quote,
    }
  })
  const { isDirty, errors } = formState
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    register('quote', { required: true })
    return () => {
      unregister('quote')
    }
  }, [register])

  const handleUpdateFroala = (key, val) => {
    setValue(key, val, { shouldDirty: true, shouldValidate: true })
  }

  const destroy = () => {
    setLoading(true)
    putpostRequest(`/api/v1/teachers/testimonials/${testimonial.id}`, 'DELETE', {}, (err, jsonData) => {
      setLoading(false)
      if (err) { // 422 code
        if (typeof err !== 'string') { // 500 code on API
          Object.entries(err).forEach(([key, value]) => {
            setError(key, value)
          })
        }
        return
      }

      refreshData()
      showNonSpaToast("Testimonial Deleted", true)
    })
  }

  const onSubmit = (data) => {
    const formData = {
      testimonial: data
    }
    setLoading(true)
    putpostRequest(`/api/v1/teachers/testimonials/${testimonial.id}`, 'PATCH', formData, (err, jsonData) => {
      setLoading(false)
      console.log(err)
      if (err) {
        showNonSpaToast("Error", false)
        if (err.slug) {
          if (err.slug[0] === 'has already been taken') { setError('slug', { type: 'custom', message: 'unique' }) }
          if (err.slug[0] !== 'has already been taken') { setError('slug', { type: 'custom', message: 'format' }) }
        }
        return
      }

      showNonSpaToast("Saved", true)
      setEditing(false)
      refreshData()
    })
  }

  const quote = getValues().quote

  return <>
    { !editing && <div className="hover:shadow-xl hover:opacity-50 cursor-pointer flex-col space-y-3" onClick={() => setEditing(true)}>
      <div className="mb-4 md:mb-6">
        <div className="flex space-x-4 items-start">
          <img src='https://clc-content.s3.amazonaws.com/static/icons/icon-quote.svg' />
          <span dangerouslySetInnerHTML={{ __html: testimonial.quote }}></span>
        </div>
      </div>
      <div className="flex">
        <div className="shrink-0">
          <div className="relative overflow-hidden rounded-2xl  w-14 h-14 md:w-20 md:h-20">
            <div className="aspect-w-1 aspect-h-1 sm:aspect-w-1 sm:aspect-h-1">
              <img className="object-cover shadow-lg rounded-lg" src={testimonial.imageUrl} alt="" />
            </div>
          </div>
        </div>
        <div className="pl-3 md:pl-6">
          <p className="p0 mb-1 font-semibold">{testimonial.authorName}</p>
          <div className="p2 text-gray-700">{testimonial.authorTitle}</div>
        </div>
      </div>
    </div> }
    { editing && <li className='p-8 border-4 border-clcpurple rounded-lg'>
      <form>
        <div className="p-8 pt-5">
          <div className="space-y-6">
            <TestimonialImage testimonial={testimonial} />
            <div>
              <div className="prose-product-lg">
                <h5 className="flex justify-between text-gray-800"><label className="mb-1">Author Name</label></h5>
              </div>
              <div className="prose-product-sm flex items-center sm:prose-product-lg">
                <input type="text" className={errors.author_name ? 'errors placeholder-shown:italic placeholder-gray-600 p2 py-3.5 px-4 w-full border rounded-lg' : 'placeholder-shown:italic placeholder-gray-600 p2 py-3.5 px-4 w-full border rounded-lg'} {...register('author_name', { required: true }) } defaultValue={testimonial.author_name} placeholder="Stuart Student" />
              </div>

              { errors.author_name && <div className='mt-2 text-sm text-red-600'>This field is required.</div> }
            </div>
            <div>
              <div className="prose-product-lg">
                <h5 className="flex justify-between text-gray-800"><label className="mb-1">Author Title</label></h5>
              </div>
              <div className="prose-product-sm flex items-center sm:prose-product-lg">
                <input type="text" className={errors.author_title ? 'errors placeholder-shown:italic placeholder-gray-600 p2 py-3.5 px-4 w-full border rounded-lg' : 'placeholder-shown:italic placeholder-gray-600 p2 py-3.5 px-4 w-full border rounded-lg'} {...register('author_title', { required: true }) } defaultValue={testimonial.author_title} placeholder="CEO of Pepsi, arch-nemisis of Coke, former student" />
              </div>

              { errors.author_title && <div className='mt-2 text-sm text-red-600'>This field is required.</div> }
            </div>
            <div>
              <div className="prose-product-sm flex justify-between sm:prose-product-lg">
                <div className="prose-product-lg">
                  <h5 className="flex justify-between text-gray-800"><label className="mb-1">Quote</label></h5>
                </div>
              </div>
              <div className="prose-product-sm flex items-center sm:prose-product-lg">
                { errors.quote && <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                  <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
                </div> }
                <FroalaMinimalWrapper
                  hideButtons={false}
                  showCharacterCounter={false}
                  model={quote}
                  updateModel={(val) => handleUpdateFroala('quote', val)}
                />
              </div>
              { errors.quote && <div className='mt-2 text-sm text-red-600'>This field is required.</div> }
            </div>
            <div className='flex gap-x-5'>
              <PrimaryButton onClick={handleSubmit(onSubmit)} loading={loading} text="Save" />
              <DefaultButton onClick={() => setEditing(false)} text="Cancel" />
              {!confirmDelete && <div onClick={() => setConfirmDelete(true)} className='text-red-500 text-sm cursor-pointer'>Delete</div> }
              {confirmDelete && <div onClick={destroy} className='text-red-500 text-sm cursor-pointer'>Really delete?</div> }

            </div>
          </div>
        </div>

      </form>
    </li> }
  </>

}

export default TestimonialForm

import React, { useEffect, useState } from 'react'
import { useGlobalState } from '@/state'
import { Link } from 'react-router-dom'
import { formatInTimezone } from '@/utils/date'
import { CheckCircleIcon, CalendarIcon, ClockIcon, ArrowRightIcon } from '@heroicons/react/solid'

import Loading from '@/shared/Loading'
import useQuery from '@/hooks/useQuery'
import EventRow from '@/shared/EventRow'
import CohortRow from '@/shared/CohortRow'
import VoiceActing101Index from '@/pages/content/voice-acting-101/Index'
import CourseIndex from '@/pages/content/CourseIndex'

const ManageCourses = (props) => {
  const [currentUser] = useGlobalState('currentUser')
  const { putpostRequest, getRequest } = useQuery()
  const { courseAdmins } = props
  const [ loading, setLoading ] = useState(false)

  return <div className='max-w-7xl mx-auto'>
    { loading && <Loading /> }
    { courseAdmins.length === 0 && <div className='mt-2 bg-white dark:bg-gray-900 rounded-lg p-2'>
      <div className="mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8">
        <div className="text-center">
          <h2 className="text-base font-semibold text-clcpurple tracking-wide uppercase">No Courses</h2>
          <p className="max-w-xl mt-5 mx-auto text-xl text-gray-500 dark:text-gray-300">
            You are currently not managing any courses.
          </p>
          <p className="mt-5 mx-auto text-xl">
            <a href='/teach_with_us'>Apply to teach with us!</a>
          </p>
        </div>
      </div>
    </div> }

    { courseAdmins.length > 0 && <div className='mt-2 bg-white dark:bg-gray-900 rounded-lg p-2'>
      <div className="relative">
        <div className="relative flex justify-start">
          <h2 className="text-gray-900 dark:text-gray-100 text-xl font-extrabold">Courses I Manage</h2>
        </div>
      </div>

      <section className="mt-6 p-2 space-y-12 sm:grid sm:grid-cols-3 sm:gap-x-2 sm:gap-y-6 sm:space-y-0 lg:gap-x-4">
        { courseAdmins.map((course, idx) => (

          <a href={`/teachers/courses/${course.id}`} key={`dasschourh${course.id}`} className={`p-2 rounded-lg bg-gray-200`}>
            <div className="flex flex-col sm:flex-row gap-x-5 items-center" data-cy="cy-calendar-event">

              <div className='w-full sm:w-36'>
                <div className="aspect-w-16 aspect-h-9 w-full">
                  <img className="object-cover shadow-lg rounded-lg" src={course.imageUrl} alt="" />
                </div>
              </div>

              <div className='flex-1 flex-col'>
                <div className="flex flex-col">
                  <span className="text-lg font-bold">{course.name}</span>
                  <button className="justify-center flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm font-medium text-gray-700 bg-white dark:bg-gray-900 dark:text-white dark:hover:bg-gray-800 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cccpurple" type="button">
                    View School
                    <ArrowRightIcon className="h-5 w-5 mr-2" aria-hidden="true" />
                  </button>
                </div>
              </div>
            </div>
          </a>
        ))}
      </section>
    </div> }

   
  </div>
}

export default ManageCourses

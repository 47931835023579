import React, {useState, useEffect} from 'react'
import useQuery from '@/hooks/useQuery'
import { Switch } from '@headlessui/react'
import { useForm } from 'react-hook-form'
import FroalaMinimalWrapper from '@/shared/FroalaMinimalWrapper'
import { ExclamationCircleIcon, EmojiHappyIcon, ExclamationIcon, LinkIcon, DuplicateIcon, ClipboardCopyIcon } from '@heroicons/react/solid'

import { PrimaryButton, DefaultButton } from '@/shared/Buttons'
import { Modal, useModal } from '@/shared/Modal'
import classNames from '@/utils/classNamesLocal'

import BenefitImage from '@/pages/teachers/LandingPage/BenefitImage'
import { showNonSpaToast } from '@/utils/nonSpaToast'
import { BulletItem, CaretItem } from '@/pages/teachers/Launch/Items'
import BenefitForm from '@/pages/teachers/LandingPage/BenefitForm'

const BenefitHeaderForm = ({course}) => {
  const {landing_page} = course
  const [landingPage, setLandingPage] = useState(landing_page)
  const [editing, setEditing] = useState(false)
  const [parentUrl, setParentUrl] = useState(landing_page.benefits_image_url)
  const [benefits, setBenefits] = useState([])
  const { putpostRequest, getRequest } = useQuery()
  const { formState, reset, register, unregister, handleSubmit, setValue, watch, getValues, setError } = useForm({
   defaultValues: {
      benefits_header: landing_page.benefits_header,
      benefits_section: landing_page.benefits_section === 'visible',
    }
  })
  const { isDirty, errors } = formState
  const [loading, setLoading] = useState(false)

  useEffect(() => fetchdata(), [])

  const handleUpdateSwitch = (key) => {
    setValue(key, !getValues()[key], { shouldDirty: true, shouldValidate: true })
  }

  const fetchdata = () => {
    getRequest(`/api/v1/teachers/courses/${course.id}/benefits`, {}, (err, jsonData) => {
      if (err) { /* handled in hook */ return }
      setBenefits(jsonData.benefits)
    })
  }

  const add = () => {
    setLoading(true)
    const data = {course_id: course.id}
    putpostRequest(`/api/v1/teachers/landing_page_benefits`, 'POST', data, (err, jsonData) => {
      setLoading(false)
      if (err) {
        showNonSpaToast(err, false)
        return
      }
      setBenefits([...benefits, jsonData.benefit ])
    })
  }


  const onSubmit = (data) => {
    const formData = {
      landing_page: {
        benefits_header: data.benefits_header,
        benefits_section: benefits_section ? 'visible' : 'hidden'
      }
    }
    setLoading(true)
    putpostRequest(`/api/v1/teachers/courses/${course.id}/landing_page`, 'POST', formData, (err, jsonData) => {
      setLoading(false)
      if (err) {
        showNonSpaToast("Error", false)
        if (err.slug) {
          if (err.slug[0] === 'has already been taken') { setError('slug', { type: 'custom', message: 'unique' }) }
        }
        return
      }

      showNonSpaToast("Saved", true)
      setLandingPage({ ...landingPage,
        benefits_header: jsonData.landing_page.benefits_header
      })
      setEditing(false)
    })
  }

  const benefits_section = watch('benefits_section')

  return <>
    { !editing && <div id="benefits" className="hover:shadow-xl hover:opacity-50 py-2 cursor-pointer" onClick={() => setEditing(true)}>
      <div className="text-center mb-4">
        <h1 className="mt-1 text-2xl font-extrabold text-gray-900 sm:text-4xl sm:tracking-tight">{landingPage.benefits_header}</h1>
        { !benefits_section && <div>
          <div className="mx-auto flex justify-center">
            <div className="flex items-end self-start rounded-full border py-2 px-5 sm:self-center sm:py-3 bg-opacity-5 bg-red-100 border-red-200">
               <h6 className="b1 ml-2 text-red-800 sm:ml-3">This section is hidden</h6>
            </div>
          </div>
        </div> }
        <div className="flex text-clcnavy justify-end">edit</div>
      </div>
      <div className="mt-6 space-y-3 flex sm:space-y-0 relative space-x-4">
        <div className="w-1/3">
          <div className="aspect-w-1 aspect-h-1">
            <img className="object-cover shadow-lg rounded-lg" src={parentUrl} alt="image"/>
          </div>
        </div>
        <div className="w-2/3">
          <ul className="mx-auto max-w-lg mt-4">
            { benefits.map((benefit, idx) => (
              <li className="flex flex-col px-4 sm:px-8 border-b-2 border-dotted py-4">
                <p className="text-200 mb-3 font-semibold text-clcnavy">{benefit.title}</p>
                <div className="text-gray-700"><span dangerouslySetInnerHTML={{ __html: benefit.desc }}></span></div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div> }
    { editing && <form className='p-8 border-4 border-clcpurple rounded-lg'>
      <div className="p-8 pt-5">
        <BenefitImage landingPage={landingPage} setParentUrl={setParentUrl} />
        <div className="space-y-6">
          <div className='mt-3 grid grid-cols-3'>
            <Switch.Group as="div" className="col-span-1 flex items-center justify-between mb-2">
              <Switch.Label as="span" className="flex-grow flex flex-col" >
                <div className="prose-product-lg">
                  <h5 className="flex justify-between text-gray-800"><label className="mb-1">Show Section</label></h5>
                </div>
              </Switch.Label>
              <Switch checked={benefits_section} onChange={() => handleUpdateSwitch('benefits_section')}
                className={classNames(benefits_section ? 'bg-clcpurple' : 'bg-gray-300 dark:bg-gray-500',
                  'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-clcpurple')} >
                <span className="sr-only" hidden>Section is shown</span>
                <span aria-hidden="true" className={classNames(benefits_section ? 'translate-x-5' : 'translate-x-0', 'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200')}
                />
              </Switch>
            </Switch.Group>
          </div>
          <div>
            <div className="prose-product-lg">
              <h5 className="flex justify-between text-gray-800"><label className="mb-1">Course Benefit Section Header</label></h5>
            </div>
            <div className="prose-product-sm flex items-center sm:prose-product-lg">
              <input type="text" className={errors.benefits_header ? 'errors placeholder-shown:italic placeholder-gray-600 p2 py-3.5 px-4 w-full border rounded-lg' : 'placeholder-shown:italic placeholder-gray-600 p2 py-3.5 px-4 w-full border rounded-lg'} {...register('benefits_header', { required: true }) } defaultValue={landingPage.benefits_header} placeholder="Course benefit" />
            </div>

            { errors.benefits_header && <div className='mt-2 text-sm text-red-600'>This field is required.</div> }
          </div>
          <div className='flex gap-x-5'>
            <PrimaryButton onClick={handleSubmit(onSubmit)} loading={loading} text="Save" />
            <DefaultButton onClick={() => setEditing(false)} text="Cancel" />
          </div>
          <div>
            <div className="prose-product-sm flex justify-between sm:prose-product-lg">
              <div className="prose-product-lg">
                <h5 className="flex justify-between text-gray-800"><label className="mb-1">Benefits List</label></h5>
              </div>
            </div>
          </div>
          <ul className="list-disc space-y-2 flex flex-col">
            { benefits.map((ben, index) => (
              <BenefitForm key={`benf${ben.id}`} benefit={ben} refreshData={fetchdata} />
            ))}
          </ul>
        </div>
      </div>
      <div className='flex gap-x-5'>
        <PrimaryButton onClick={add} loading={loading} text="Add Benefit" />
        <DefaultButton onClick={() => setEditing(false)} text="Done Editing" />
      </div>

    </form> }
  </>

}

export default BenefitHeaderForm

import React, { useEffect, useState } from 'react'
import { useGlobalState } from '@/state'
import { Link } from 'react-router-dom'
import { formatInTimezone } from '@/utils/date'
import { CheckCircleIcon, CalendarIcon, ClockIcon, ArrowRightIcon } from '@heroicons/react/solid'

import Loading from '@/shared/Loading'
import useQuery from '@/hooks/useQuery'
import EventRow from '@/shared/EventRow'
import CohortRow from '@/shared/CohortRow'
import VoiceActing101Index from '@/pages/content/voice-acting-101/Index'
import CourseIndex from '@/pages/content/CourseIndex'

const Dashboard = () => {
  const [currentUser] = useGlobalState('currentUser')
  const { putpostRequest, getRequest } = useQuery()
  const [dashboardContainer] = useGlobalState('dashboardContainer')
  const [ enrolledCohorts, setEnrolledCohorts ] = useState([])
  const [ pastCohorts, setPastCohorts ] = useState([])
  const [ loading, setLoading ] = useState(false)

  const urlParams = new window.URLSearchParams(window.location.search)
  const welcome = urlParams.get('welcome')

  const { events, classSessions, attendingEventIds } = dashboardContainer

  useEffect(() => {
    setLoading(true)
    getRequest(`/api/v1/users/dashboard`, {}, (err, jsonData) => {
      if (err) { /* handled in hook */ return }
      setEnrolledCohorts(jsonData.enrolledCohorts)
      setPastCohorts(jsonData.pastCohorts)
      setLoading(false)
    })
  }, [])

  return <div className='max-w-7xl mx-auto'>
    { loading && <Loading /> }

    { enrolledCohorts.length > 0 && <div className='mt-2 bg-white dark:bg-gray-900 rounded-lg p-2'>
      <div className="relative flex justify-start">
        <h2 className="mt-6 text-gray-900 dark:text-gray-100 text-xl font-extrabold">Courses I'm Currently Taking</h2>
      </div>

      <section className="mt-6 p-2 space-y-12 sm:grid sm:grid-cols-3 sm:gap-x-2 sm:gap-y-6 sm:space-y-0 lg:gap-x-4">
        { enrolledCohorts.map((cohort, idx) => (
          <Link to={`/c/${cohort.courseSlug}`} key={`dasco${cohort.id}`} className={`p-2 rounded-lg bg-gray-100 dark:bg-gray-800`}>
            <div>
              <div className="aspect-w-16 aspect-h-9">
                <img className="object-cover w-full rounded-lg" src={cohort.imageUrl} alt="" />
              </div>
              <div className='flex flex-col gap-y-3'>
                <div className="text-lg text-center leading-6 font-medium mt-2">{cohort.name}</div>
                <span className='text-gray-500 dark:text-gray-300 text-sm flex'><CalendarIcon className='w-5 h-5 mr-2' /><strong>{formatInTimezone(cohort.startsAtUtc, currentUser.timezone, 'EEE, MMM d, Y')} - {formatInTimezone(cohort.endsAtUtc, currentUser.timezone, 'EEE, MMM d, Y')}</strong></span>

                <button className="justify-center flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm font-medium text-gray-700 bg-white dark:bg-gray-900 dark:text-white dark:hover:bg-gray-800 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cccpurple" type="button">
                  View Course Dashboard
                  <ArrowRightIcon className="h-5 w-5 mr-2" aria-hidden="true" />
                </button>
              </div>
            </div>
          </Link>
        ))}
      </section>
    </div> }

    <div className='mt-2 bg-white dark:bg-gray-900 rounded-lg p-2'>
      <div className="relative flex justify-between">
        <h2 className="mt-6 text-gray-900 dark:text-gray-100 text-xl font-extrabold">Upcoming Class Sessions</h2>
      </div>

      <section aria-labelledby="events-this-week">
        <div className="relative">
          { classSessions.length === 0 && (
            <div>
              <div className="mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8">
                <div className="text-center">
                  <h2 className="text-base font-semibold text-clcpurple tracking-wide uppercase">No Class Sessions the next two weeks</h2>
                  <p className="max-w-xl mt-5 mx-auto text-xl text-gray-500 dark:text-gray-300">
                    There are no class sessions for you this week. You can <a href='/reserve_seat'> enroll in a course here</a>.
                  </p>
                </div>
              </div>
            </div>
          )}
          <div className="mt-12 max-w-lg mx-auto grid gap-5 lg:grid-cols-4 lg:max-w-none">
            { classSessions.map((event, idx) => <EventRow key={`dashboardevent${event.id}`} event={event} user={currentUser} attendingEventIds={attendingEventIds} /> )}
          </div>
        </div>
      </section>
    </div>

    <div className='mt-2 bg-white dark:bg-gray-900 rounded-lg p-2'>
      <div className="relative flex justify-between">
        <h2 className="mt-6 text-gray-900 dark:text-gray-100 text-xl font-extrabold">Events the Next Four Weeks</h2>
      </div>
      <Link to='/events'>See all upcoming events</Link>

      <section aria-labelledby="events-this-week">
        <div className="relative">
          { events.length === 0 && (
            <div>
              <div className="mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8">
                <div className="text-center">
                  <h2 className="text-base font-semibold text-clcpurple tracking-wide uppercase">No Events the next four weeks</h2>
                  <p className="max-w-xl mt-5 mx-auto text-xl text-gray-500 dark:text-gray-300">
                    There are no events this week. Whew. Time for a breather. In the meantime, you can <Link to='/chat'>chit chat</Link> with the other members or <Link to='/examples'>read up</Link> on stuff you've been meaning to for a while.
                  </p>
                  <p className="mt-5 mx-auto text-xl">
                    <Link to='/events'>See upcoming events</Link>
                  </p>
                </div>
              </div>
            </div>
          )}
          <div className="mt-12 max-w-lg mx-auto grid gap-5 lg:grid-cols-3 lg:max-w-none">
            { events.map((event, idx) => <EventRow key={`dashboardevent${event.id}`} event={event} user={currentUser} attendingEventIds={attendingEventIds} /> )}
          </div>
        </div>
      </section>
    </div>
    

  </div>
}

export default Dashboard

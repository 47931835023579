import React, { useState, useEffect} from 'react'
import ReactDOM from 'react-dom'
import { Link } from 'react-router-dom'
import useQuery from '@/hooks/useQuery'

import { useGlobalState } from '@/state'
import Loading from '@/shared/Loading'

const CohortInterestRow = ({cohort, showNonMemberPrice, showMemberPrice}) => {
  return <>
    <div className="flex-1mt-1  flex w-full items-center justify-between border border-gray-200 bg-white dark:bg-gray-800 rounded-md truncate">
      <img src={cohort.imageUrl} className='ml-4 h-10 w-10' alt='Voice Acting 101' />
      <div className="flex-1 px-4 py-2 text-sm truncate">
        <div className="text-gray-700 dark:text-gray-300 text-xs uppercase font-medium"> {cohort.topic} </div>
        <p className="text-gray-800 dark:text-gray-200">{cohort.name}</p>
      </div>
      { showNonMemberPrice && <div className="flex-1 px-4 py-2 text-sm truncate dark:text-gray-200"> ${cohort.nonMemberPrice} </div> }
      { showMemberPrice && <div className="flex-1 px-4 py-2 text-sm truncate dark:text-gray-200"> ${cohort.memberPrice} </div> }
    </div>
  </>
}

const CohortInterest = (props) => {
  const { oneTimeCheckout, creditCheckout, setCreditsNeeded } = props
  const [currentUser, setCurrentUser] = useGlobalState('currentUser')

  const { getRequest } = useQuery()
  const [ cohorts, setCohorts ] = useState([])
  const [ totalCost, setTotalCost ] = useState(0)
  const [ recommendedPlan, setRecommendedPlan ] = useState(null)
  const [ recommendedAction, setRecommendedAction ] = useState(null)
  const [ loading, setLoading ] = useState(true)

  useEffect(() => {
    getRequest(`/api/v1/cohort_interests`, {}, (err, jsonData) => {
      setLoading(false)
      if (err) { /* handled in hook */ return }

      setCohorts(jsonData.cohorts)
      setTotalCost(jsonData.interestedCohortCost)
      setRecommendedPlan(jsonData.recommendedPlan)
      setRecommendedAction(jsonData.recommendedAction)
CohortInterest
      if (typeof(setCreditsNeeded) !== 'undefined') {
        let creditsNeeded = jsonData.interestedCohortCost - currentUser.credits
        if (creditsNeeded < 0) { creditsNeeded = 0 }
        setCreditsNeeded(creditsNeeded)
      }
    })
  }, [])

  if (loading) {
    return <Loading />
  }

  if (cohorts.length === 0) {
    return null
  }

  const whatifmembership = document.getElementById('whatifmembership')
  const oneTimeTotalCost = cohorts.map(element => element.oneTimePrice).reduce((a, b) => a + b, 0)


  if (Boolean(oneTimeCheckout)) {
    return <>
      <div className="bg-white mt-3 dark:bg-gray-800 rounded-md  border-cccorange dark:border-cccpurple-alt">
        <div className="flex items-center px-6 py-3">
          <h4 className="flex-shrink-0 mr-3 bg-white dark:bg-gray-800 text-sm tracking-wider font-semibold uppercase text-clcpurple">
            Your Selected Courses
          </h4>
          <div className="flex-1 border-t-2 border-gray-200"></div>
        </div>
        <div className="flex flex-col items-center px-6">
          {cohorts.map((cohort, idx) => (
            <CohortInterestRow key={`coho${cohort.id}`} cohort={cohort} showNonMemberPrice />
          ))}
          <span className='text-gray-500 my-2 text-xs'>You will be enrolled in the selected courses.</span>
        </div>
        { recommendedPlan &&
          ReactDOM.createPortal(
            <div className="bg-white mt-1 dark:bg-gray-800 rounded-md  border-cccorange dark:border-cccpurple-alt">
              <div className='text-gray-500 px-6 py-3 flex flex-col'>
                <div className="flex items-center py-3">
                  <h4 className="w-full mr-3 bg-white dark:bg-gray-800 text-sm tracking-wider font-semibold uppercase text-clcpurple">
                    What if you were a member instead?
                  </h4>
                  <div className="flex-1 border-t-2 border-gray-200"></div>
                </div>
                <span>On the <b className='semibold'>{recommendedPlan.plan}</b> plan these courses would cost <b className='text-green-500'>${recommendedPlan.cost}</b>.</span>
                <span className='text-xs mt-2'>If you're nervous about a recurring charge, you can cancel your membership directly after your purchase and still have access to your courses. Also if you have any questions, we are happy to help anytime, just email us at hello@closingcredits.com</span>
              </div>
            </div>,
          whatifmembership)
        }
      </div>
      <div className="flex items-center p-6">
        <h4 className="flex-shrink-0 mr-3 bg-gray-100 dark:bg-gray-700 text-sm tracking-wider font-semibold uppercase text-clcpurple">
          Total
        </h4>
        <div className="flex-1 border-t-2 border-gray-200"></div>
        <div className='ml-5 text-2xl dark:text-gray-100 font-semibold text-red-500'>${oneTimeTotalCost}</div>
      </div>
    </>
  }


  if (Boolean(creditCheckout)) {
    return <>
      <div className="bg-white mt-3 dark:bg-gray-800 rounded-md  border-cccorange dark:border-cccpurple-alt">
        <div className="flex items-center px-6 py-3">
          <h4 className="flex-shrink-0 mr-3 bg-white dark:bg-gray-800 text-sm tracking-wider font-semibold uppercase text-clcpurple">
            Your Selected Courses
          </h4>
          <div className="flex-1 border-t-2 border-gray-200"></div>
        </div>
        <div className="flex flex-col items-center px-6">
          {cohorts.map((cohort, idx) => (
            <CohortInterestRow key={`coho${cohort.id}`} cohort={cohort} />
          ))}
          <span className='text-gray-500 my-2 text-xs'>Your enrollment is not final until you reserve your seat on the next screen.</span>
        </div>
        { recommendedAction?.upgradePlan &&
          ReactDOM.createPortal(
            <div className="bg-white my-3 dark:bg-gray-800 rounded-md  border-cccorange dark:border-cccpurple-alt">
              <div className='text-gray-500 px-6 py-3 flex flex-col'>
                <div className="flex items-center py-3">
                  <h4 className="w-full mr-3 bg-white dark:bg-gray-800 text-sm tracking-wider font-semibold uppercase text-clcpurple">
                    Money-saving tip
                  </h4>
                  <div className="flex-1 border-t-2 border-gray-200"></div>
                </div>
                <span>If you upgrade to the <b className='semibold'>{recommendedAction.upgradePlan}</b> plan these courses would cost <b className='text-green-500'>${recommendedAction.cost}</b> today.</span>
                <span>You can change your plan on the <Link to='/subscriptions'>Subscription Overview</Link> page.</span>
                <span className='text-xs mt-2'>Just remember to downgrade your plan if you don't want all those credits next year. We'll send you a reminder email before we renew your plan.</span>
              </div>
            </div>,
          whatifmembership)
        }
      </div>

      <div className="mt-5 border-t border-gray-200 content-center mx-auto">
        <dl className="sm:divide-y sm:divide-gray-200">
          <div className="py-4 sm:py-5 flex justify-between">
            <dt className="text-sm font-medium text-gray-500 dark:text-gray-300">Your Current Credits</dt>
            <dd className="mt-1 text-sm text-gray-900 dark:text-gray-100 sm:mt-0 sm:col-span-2">{currentUser.credits}</dd>
          </div>
          <div className="py-4 sm:py-5 flex justify-between">
            <dt className="text-sm font-medium text-gray-500 dark:text-gray-300">Credits Needed For Selected Courses</dt>
            <dd className="mt-1 text-sm text-gray-900 dark:text-gray-100 sm:mt-0 sm:col-span-2">-{totalCost}</dd>
          </div>
          <div className="py-4 sm:py-5 flex justify-between">
            <dt className="text-sm font-medium text-gray-500 dark:text-gray-300">Total Needed</dt>
            <dd className="mt-1 text-sm text-gray-900 dark:text-gray-100 sm:mt-0 sm:col-span-2 font-semibold">{totalCost - currentUser.credits} credits</dd>
          </div>
        </dl>
      </div>
    </>
  }

  return <>
    <div className="bg-white mt-3 dark:bg-gray-800 rounded-md  border-cccorange dark:border-cccpurple-alt">
      <div className="flex items-center px-6 py-3">
        <h4 className="flex-shrink-0 mr-3 bg-white dark:bg-gray-800 text-sm tracking-wider font-semibold uppercase text-clcpurple">
          Your Selected Courses
        </h4>
        <div className="flex-1 border-t-2 border-gray-200"></div>
      </div>
      <div className="flex flex-col items-center px-6">
        <span className='text-gray-500 my-2 text-xs'>{totalCost} credits needed for selected courses</span>
          {cohorts.map((cohort, idx) => (
            <CohortInterestRow key={`coho${cohort.id}`} cohort={cohort} showMemberPrice/>
          ))}
        <span className='text-gray-500 my-2 text-xs'>Your enrollment is not final until you reserve your seat on the next screen.</span>
      </div>
    </div>
  </>
}

export default CohortInterest

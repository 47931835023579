import React, {useState, useEffect} from 'react'
import useQuery from '@/hooks/useQuery'
import { Switch } from '@headlessui/react'
import { useForm } from 'react-hook-form'
import FroalaMinimalWrapper from '@/shared/FroalaMinimalWrapper'
import { ExclamationCircleIcon, EmojiHappyIcon, LinkIcon, DuplicateIcon, ClipboardCopyIcon } from '@heroicons/react/solid'

import { PrimaryButton, DefaultButton } from '@/shared/Buttons'
import { Modal, useModal } from '@/shared/Modal'
import classNames from '@/utils/classNamesLocal'

import { showNonSpaToast } from '@/utils/nonSpaToast'
import { BulletItem, CaretItem } from '@/pages/teachers/Launch/Items'
import ScheduleForm from '@/pages/teachers/LandingPage/ScheduleForm'

const ScheduleHeaderForm = ({course}) => {
  const {landing_page} = course
  const [landingPage, setLandingPage] = useState(landing_page)
  const [editing, setEditing] = useState(false)
  const [schedules, setSchedules] = useState([])
  const { putpostRequest, getRequest } = useQuery()
  const { formState, reset, register, unregister, handleSubmit, setValue, watch, getValues, setError } = useForm({
   defaultValues: {
      schedule_header: landing_page.schedule_header,
      time_commitment: landing_page.time_commitment
    }
  })
  const { isDirty, errors } = formState
  const [loading, setLoading] = useState(false)

  useEffect(() => fetchdata(), [])

  const fetchdata = () => {
    getRequest(`/api/v1/teachers/courses/${course.id}/schedules`, {}, (err, jsonData) => {
      if (err) { /* handled in hook */ return }
      setSchedules(jsonData.schedules)
    })
  }

  const add = () => {
    setLoading(true)
    const data = {course_id: course.id}
    putpostRequest(`/api/v1/teachers/landing_page_schedules`, 'POST', data, (err, jsonData) => {
      setLoading(false)
      if (err) {
        showNonSpaToast(err, false)
        return
      }
      setSchedules([...schedules, jsonData.schedule ])
    })
  }


  const onSubmit = (data) => {
    const formData = {
      landing_page: data
    }
    setLoading(true)
    putpostRequest(`/api/v1/teachers/courses/${course.id}/landing_page`, 'POST', formData, (err, jsonData) => {
      setLoading(false)
      if (err) {
        showNonSpaToast("Error", false)
        if (err.slug) {
          if (err.slug[0] === 'has already been taken') { setError('slug', { type: 'custom', message: 'unique' }) }
        }
        return
      }

      showNonSpaToast("Saved", true)
      setLandingPage({ ...landingPage,
        schedule_header: jsonData.landing_page.schedule_header,
        time_commitment: jsonData.landing_page.time_commitment
      })
      setEditing(false)
    })
  }

  return <>
    { !editing && <div id="schedules" className="hover:shadow-xl hover:opacity-50 py-2 cursor-pointer" onClick={() => setEditing(true)}>
      <div className="text-center mb-4">
        <h1 className="mt-1 text-2xl font-extrabold text-gray-900 sm:text-4xl sm:tracking-tight">{landingPage.schedule_header}</h1>
        <div className="flex text-clcnavy justify-end">edit</div>
      </div>
      <div className="mx-auto flex justify-center">
        <div className="flex items-end self-start rounded-full border py-2 px-5 sm:self-center sm:py-3 bg-gray-100 border-gray-200">
           <svg width="20" height="20" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M8 14.5C11.0376 14.5 13.5 12.0376 13.5 9C13.5 5.96243 11.0376 3.5 8 3.5C4.96243 3.5 2.5 5.96243 2.5 9C2.5 12.0376 4.96243 14.5 8 14.5Z" stroke="currentColor" strokeWidth="1.2" stroke-miterlimit="10"></path>
              <path d="M8 9L10.4749 6.52513" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
              <path d="M6.5 1.5H9.5" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
           </svg>
           <h6 className="b1 ml-2 text-gray-800 sm:ml-3">{landingPage.time_commitment}</h6>
        </div>
      </div>
      <ul className="border-l-2 mx-auto max-w-lg border-dashed border-gray-500 mt-4">
        { schedules.map((schedule, idx) => (
        <li className="flex flex-col px-4 pb-8 last:pb-0 sm:px-8">
          <h4 className="mb-1 text-gray-900 text-xl font-extrabold">{schedule.title}</h4>
          <p className="text-200 mb-3 font-semibold text-clcnavy">{schedule.time_slot}</p>
          <div className="text-gray-700">
            <span dangerouslySetInnerHTML={{ __html: schedule.desc }}></span>
          </div>
        </li>
        ))}
      </ul>
    </div> }
    { editing && <form className='p-8 border-4 border-clcpurple rounded-lg'>
      <div className="p-8 pt-5">
        <div className="space-y-6">
          <div>
            <div className="prose-product-lg">
              <h5 className="flex justify-between text-gray-800"><label className="mb-1">Course Schedule Section Header</label></h5>
            </div>
            <div className="prose-product-sm flex items-center sm:prose-product-lg">
              <input type="text" className={errors.schedule_header ? 'errors placeholder-shown:italic placeholder-gray-600 p2 py-3.5 px-4 w-full border rounded-lg' : 'placeholder-shown:italic placeholder-gray-600 p2 py-3.5 px-4 w-full border rounded-lg'} {...register('schedule_header', { required: true }) } defaultValue={landingPage.schedule_header} placeholder="Course Schedule" />
            </div>

            { errors.schedule_header && <div className='mt-2 text-sm text-red-600'>This field is required.</div> }
          </div>
          <div>
            <div className="prose-product-lg">
              <h5 className="flex justify-between text-gray-800"><label className="mb-1">Time committment</label></h5>
            </div>
            <div className="prose-product-sm flex items-center sm:prose-product-lg">
              <input type="text" className={errors.time_commitment ? 'errors placeholder-shown:italic placeholder-gray-600 p2 py-3.5 px-4 w-full border rounded-lg' : 'placeholder-shown:italic placeholder-gray-600 p2 py-3.5 px-4 w-full border rounded-lg'} {...register('time_commitment', { required: true }) } defaultValue={landingPage.time_commitment} placeholder="5 hours per week" />
            </div>

            { errors.time_commitment && <div className='mt-2 text-sm text-red-600'>This field is required.</div> }
          </div>
          <div className='flex gap-x-5'>
            <PrimaryButton onClick={handleSubmit(onSubmit)} loading={loading} text="Save" />
            <DefaultButton onClick={() => setEditing(false)} text="Cancel" />
          </div>
          <div>
            <div className="prose-product-sm flex justify-between sm:prose-product-lg">
              <div className="prose-product-lg">
                <h5 className="flex justify-between text-gray-800"><label className="mb-1">schedules List</label></h5>
              </div>
            </div>
          </div>
          <ul className="list-disc space-y-2 flex flex-col">
            { schedules.map((sch, index) => (
              <ScheduleForm key={`schf${sch.id}`} schedule={sch} refreshData={fetchdata} />
            ))}
          </ul>
        </div>
      </div>
      <div className='flex gap-x-5'>
        <PrimaryButton onClick={add} loading={loading} text="Add Schedule Seciton" />
        <DefaultButton onClick={() => setEditing(false)} text="Done Editing" />
      </div>

    </form> }
  </>

}

export default ScheduleHeaderForm

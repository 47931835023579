import React, {useState, useEffect} from 'react'
import useQuery from '@/hooks/useQuery'
import { useForm } from 'react-hook-form'
import { ExclamationCircleIcon, EmojiHappyIcon, LinkIcon, DuplicateIcon } from '@heroicons/react/solid'
import {PrimaryButton} from '@/shared/Buttons'
import { BulletItem, CaretItem } from '@/pages/teachers/Launch/Items'

import { showNonSpaToast } from '@/utils/nonSpaToast'

const SocialList = ({course}) => {
  const [show, setShow] = useState(false)
  const [done, setDone] = useState(course?.launch?.contactlist === 1)
  const { putpostRequest } = useQuery()

  const markAsDone = () => {
    putpostRequest(`/api/v1/teachers/courses/${course.id}/launch_step`, 'POST', {findniche: 'done'}, (err, jsonData) => {
      if (err) { /* handled in hook */ return }
      setDone(true)
    })
  }

  return <div className={`group ${show? 'rounded-xl border border-solid border-gray-900 shadow-lg' : ''}`}>
    <div onClick={() => setShow(!show)} className={done ? 'prose-product-sm relative flex min-h-16 items-center overflow-hidden p-3 sm:prose-product-lg border-b rounded-t-xl border-gray-200 hover:bg-gray-100 bg-gray-100 dark:bg-gray-800 cursor-pointer' : 'prose-product-sm relative flex min-h-16 items-center overflow-hidden p-3 sm:prose-product-lg  border-gray-200 hover:bg-gray-100  cursor-pointer'}>
      <div className="flex grow items-center justify-between">
        <div className="flex dark:text-gray-300">
          <BulletItem done={done} />
          <div className="ml-4">
            <div className="flex items-center space-x-3">
              <h4>Share on Social / Discord</h4>
            </div>
          </div>
        </div>
        <div className="mr-4 text-gray-400">
          <CaretItem show={show} />
        </div>
      </div>
    </div>
    { show && <div className="p-8 pt-5">
      <div className="flex space-x-6">
        <div className="space-y-3">
          <div className="p2 space-y-1 text-gray-900">
            <p>Ready to go public?</p>
            <ul className="ml-6 list-outside list-disc">
              <li className="pl-1">Back to the ABCs of Sales: Share valueable content and be present most of the time.</li>
              <li className="pl-1">Keep your topics focused on target students. Hang out in places they would hang out. (Discord, Twitter, Facebook, TikTok, Casting Call Club, etc).</li>
              <li className="pl-1">Soft nudges 25% of the time. e.g. These are course previews or success stories.</li>
              <li className="pl-1">Hard nudges 10% of the time. e.g. Deadline is in 1 week. There are only 3 seats left!</li>
            </ul>
            <p className="pt-2"></p>
          </div>
        </div>
      </div>
      <div className='mt-6'>
        <PrimaryButton onClick={markAsDone} text="Mark as Done" />
      </div>
    </div> }
  </div>
}

const EmailList = ({course}) => {
  const [show, setShow] = useState(false)
  const [done, setDone] = useState(course?.launch?.sharesurvey === 1)
  const { putpostRequest } = useQuery()

  const markAsDone = () => {
    putpostRequest(`/api/v1/teachers/courses/${course.id}/launch_step`, 'POST', {findniche: 'done'}, (err, jsonData) => {
      if (err) { /* handled in hook */ return }
      setDone(true)
    })
  }

  return <div className={`group ${show? 'rounded-xl border border-solid border-gray-900 shadow-lg' : ''}`}>
    <div onClick={() => setShow(!show)} className={done ? 'prose-product-sm relative flex min-h-16 items-center overflow-hidden p-3 sm:prose-product-lg border-b rounded-t-xl border-gray-200 hover:bg-gray-100 bg-gray-100 dark:bg-gray-800 cursor-pointer' : 'prose-product-sm relative flex min-h-16 items-center overflow-hidden p-3 sm:prose-product-lg  border-gray-200 hover:bg-gray-100  cursor-pointer'}>
      <div className="flex grow items-center justify-between">
        <div className="flex dark:text-gray-300">
          <BulletItem done={done} />
          <div className="ml-4">
            <div className="flex items-center space-x-3">
              <h4>It's time. Send Emails/DMs.</h4>
            </div>
          </div>
        </div>
        <div className="mr-4 text-gray-400">
          <CaretItem show={show} />
        </div>
      </div>
    </div>
    { show && <div className="p-8 pt-5">
      <div className="flex space-x-6">
        <div className="space-y-3">
          <div className="p2 space-y-1 text-gray-900">
            <p>Emailing / DMing is incredibly powerful. You can keep things casual and personal. The two big Email/DM campaigns before launch are:</p>
            <ul className="ml-6 list-outside list-disc">
              <li className="pl-1"><b>Ask your network to share your course</b>. "Hi, I created a course [course name] for [target student]. Would you happen to know anyone like that?" This is a casual way to build interest. Don't forget to follow-up in 3ish days if they haven't replied.</li>
              <li className="pl-1"><b>Ask your potential students to join your waitlist</b>. "Hi, [person] told me that you might be interested in [link to course]. If you want to jump on a quick call or chat, I'd love to answer any questions about it." When you're first starting out, this is the <b>single best way to convert</b> your students. </li>
            </ul>
            <p className="pt-2">After they're on your waitlist, you can use Closing Credits' automated system to keep them engaged and ready to buy your class.</p>
          </div>
          <a href={`/teachers/courses/${course.id}/emails`}>
            <div className="prose-product-sm sm:prose-product-lg">
              <button className="mt-2 justify-center items-center flex shadow-sm hover:shadow-md disabled:shadow-none ease-out duration-200 font-medium disabled:cursor-default h-10 px-4 b2 rounded-lg bg-clcpurple text-white hover:bg-clcnavy disabled:bg-gray-500" type="button">
                Go to emails editor
                <svg className="ml-2" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M2.5 8H13.5" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                  <path d="M9 3.5L13.5 8L9 12.5" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                </svg>
              </button>
            </div>
          </a>
        </div>
      </div>
      <div className='mt-6'>
        <PrimaryButton onClick={markAsDone} text="Mark as Done" />
      </div>
    </div> }
  </div>
}

const FreeWorkshop = ({course}) => {
  const [show, setShow] = useState(false)
  const [done, setDone] = useState(course?.launch?.organicgrowthmaterial === 1)
  const { putpostRequest } = useQuery()

  const markAsDone = () => {
    putpostRequest(`/api/v1/teachers/courses/${course.id}/launch_step`, 'POST', {findniche: 'done'}, (err, jsonData) => {
      if (err) { /* handled in hook */ return }
      setDone(true)
    })
  }

  return <div className={`group ${show? 'rounded-xl border border-solid border-gray-900 shadow-lg' : ''}`}>
    <div onClick={() => setShow(!show)} className={done ? 'prose-product-sm relative flex min-h-16 items-center overflow-hidden p-3 sm:prose-product-lg border-b rounded-t-xl border-gray-200 hover:bg-gray-100 bg-gray-100 dark:bg-gray-800 cursor-pointer' : 'prose-product-sm relative flex min-h-16 items-center overflow-hidden p-3 sm:prose-product-lg  border-gray-200 hover:bg-gray-100  cursor-pointer'}>
      <div className="flex grow items-center justify-between">
        <div className="flex dark:text-gray-300">
          <BulletItem done={done} />
          <div className="ml-4">
            <div className="flex items-center space-x-3">
              <h4>Consider a Free Workshop</h4>
            </div>
          </div>
        </div>
        <div className="mr-4 text-gray-400">
          <CaretItem show={show} />
        </div>
      </div>
    </div>
    { show && <div className="p-8 pt-5">
      <div className="flex space-x-6">
        <div className="flex space-x-6">
          <div className="space-y-3">
            <div className="p2 space-y-1 text-gray-900">
              <ul className="ml-6 list-outside list-disc">
                <li className="pl-1">Think of your free workshop like a movie trailer—showcase your best content and interactive exercises to hook prospective students in.</li>
                <li className="pl-1">Schedule your free workshop 1-3 weeks before your cohort. Or consider conducting a monthly free workshop to build your email list.</li>
                <li className="pl-1">Promote your workshop via email, your network, communities, and social media to get the word out.</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <PrimaryButton onClick={markAsDone} text="Mark as Done" />
    </div> }
  </div>
}

const AddValue = ({course}) => {
  const [show, setShow] = useState(false)
  const [done, setDone] = useState(course?.launch?.followup === 1)
  const { putpostRequest } = useQuery()

  const markAsDone = () => {
    putpostRequest(`/api/v1/teachers/courses/${course.id}/launch_step`, 'POST', {findniche: 'done'}, (err, jsonData) => {
      if (err) { /* handled in hook */ return }
      setDone(true)
    })
  }

  return <div className={`group ${show? 'rounded-xl border border-solid border-gray-900 shadow-lg' : ''}`}>
    <div onClick={() => setShow(!show)} className={done ? 'prose-product-sm relative flex min-h-16 items-center overflow-hidden p-3 sm:prose-product-lg border-b rounded-t-xl border-gray-200 hover:bg-gray-100 bg-gray-100 dark:bg-gray-800 cursor-pointer' : 'prose-product-sm relative flex min-h-16 items-center overflow-hidden p-3 sm:prose-product-lg  border-gray-200 hover:bg-gray-100  cursor-pointer'}>
      <div className="flex grow items-center justify-between">
        <div className="flex dark:text-gray-300">
          <BulletItem done={done} />
          <div className="ml-4">
            <div className="flex items-center space-x-3">
              <h4>ABCs of Sales</h4>
            </div>
          </div>
        </div>
        <div className="mr-4 text-gray-400">
          <CaretItem show={show} />
        </div>
      </div>
    </div>
    { show && <div className="p-8 pt-5">
      <div className="flex space-x-6">
        <div className="space-y-3">
          <div className="p2 space-y-1 text-gray-900">
            <p>ABC: Always Be Closing. The trick to closing is to both be credible and present in the minds of potential students.</p>
            <ul className="ml-6 list-outside list-disc">
              <li className="pl-1">For credibility: Most of what you share on social media, discords, or anything public should be <b>pure value</b>, not a 'buy now' button. The internet is a torrent of information. If you share pure valueable information, you stand out from the crowd. You might do a free workshop, send out infographics, blog, tweet, host AMAs, etc.</li>
              <li className="pl-1">For presence: Many people think that posting consistently is presence enough, but do not forget the power of a customized email, a 1 on 1 call, a chat conversation. <b>Be available</b>. Be ready to give time to each individual.</li>
            </ul>
          </div>
        </div>
      </div>
      <PrimaryButton onClick={markAsDone} text="Mark as Done" />
    </div> }
  </div>
}

const ShowMarketing = ({course}) => {
  return <ul className="overflow-hidden rounded-md border border-gray-200">
    <li className="border-b first:rounded-t-md last:rounded-b-md last:border-b-0">
      <AddValue course={course} />
    </li>
    <li className="border-b first:rounded-t-md last:rounded-b-md last:border-b-0">
      <EmailList course={course} />
    </li>
    <li className="border-b first:rounded-t-md last:rounded-b-md last:border-b-0">
      <SocialList course={course} />
    </li>
  </ul>
}

export default ShowMarketing

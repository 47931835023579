import React, { useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useParams, useLocation } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { debounce } from 'throttle-debounce'
import { ExclamationCircleIcon, EmojiHappyIcon, LinkIcon, DuplicateIcon } from '@heroicons/react/solid'
import DatePicker from 'react-datepicker'
import { format } from 'date-fns-tz'
import { showNonSpaToast } from '@/utils/nonSpaToast'

import FroalaWrapper from '@/shared/FroalaWrapper'
import { useGlobalState } from '@/state'
import useQuery from '@/hooks/useQuery'
import Tippy from '@tippyjs/react'
import ToolTip from '@/shared/ToolTip'
import PrimaryButton from '@/shared/Buttons/Primary'

const LessonAdd = (props) => {
  const { cohort, course_id } = props;

  const { formState, register, unregister, handleSubmit, setValue, getValues, setError } = useForm({
    defaultValues: {
      name: '',
      publish_at_utc:  new Date(),
      body: '',
      lesson_type: '',
      publication_status: '',
    },
  });

  return <>
    <TheForm course_id={course_id} cohort={cohort} />
  </>
}

const TheForm = ({course_id, cohort}) => {
  const [ currentUser ] = useGlobalState('currentUser')
  const [, setToast] = useGlobalState('toast')
  const [confirmDelete, setConfirmDelete] = useState(false)
  let history = useHistory()
  const [publish_at_utc, setpublish_at_utc] = useState(new Date())
  const { putpostRequest, getRequest } = useQuery()
  const { formState, reset, register, unregister, handleSubmit, setValue, watch, getValues, setError } = useForm({
   defaultValues: {
      name: '',
      publish_at_utc: new Date(),
      body: '',
      lesson_type: '',
      publication_status: ''
    }
  })

  const { isDirty, errors } = formState
  const [loading, setLoading] = useState(false)
  const body = getValues().body

  useEffect(() => {
    register('body', { required: true })
    register('summary')
    register('recap')
    return () => {
      unregister('send_recap_date')
      unregister('body')
      unregister('recap')
    }
  }, [register])

  useEffect(() => {
    setValue('publish_at_utc', format(publish_at_utc, 'yyyy-MM-dd'), { shouldDirty: true, shouldValidate: true })
  }, [publish_at_utc])  

  const handleUpdateFroala = (key, val) => {
    setValue(key, val, { shouldDirty: true, shouldValidate: true })
  }

  const onSubmit = (data) => {
    setLoading(true);
    putpostRequest(`/api/v1/lessons/`, 'POST', { lesson: data }, (err, jsonData) => {
      setLoading(false);
      console.log(err);
      
      if (err) { // 422 code
        if (typeof err !== 'string') { // 500 code on API
          Object.entries(err).forEach(([key, value]) => {
            setError(key, value);
          });
        }
        return;
      }
  
      window.location = `/teachers/lessons/${jsonData.id}/edit?newly=true`
  
    });
  };

  const destroy = () => {
    setLoading(true)
    putpostRequest(`/api/v1/teachers/events/${event.id}`, 'DELETE', {}, (err, jsonData) => {
      setLoading(false)
      if (err) { // 422 code
        if (typeof err !== 'string') { // 500 code on API
          Object.entries(err).forEach(([key, value]) => {
            setError(key, value)
          })
        } else {
          showNonSpaToast(err, false)
        }
        return
      }

      window.location.href = `/teachers/courses/${course.id}/events/`
    })
  }

  return (
    <>
    <div className='p-2'>
      <PrimaryButton onClick={handleSubmit(onSubmit)} loading={loading} text="Save" />
    </div>
    <form className='mt-6'>
      <div className="grid grid-cols-3 gap-6">

          <div className='col-span-2'>
            <div className="col-span-6 sm:col-span-3">
              <select
                {...register('publication_status', { required: true })}
                className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white dark:bg-gray-900 dark:text-gray-100 rounded-md shadow-sm focus:outline-none focus:ring-cccpurple focus:border-cccpurple sm:text-sm"
              >
                <option>draft</option>
                <option>published</option>
              </select>
            </div>
          </div>

        <div className="col-span-3 sm:col-span-2">
          <label htmlFor="display_name" className="block text-sm font-medium text-gray-700 dark:text-gray-300 flex justify-between">
            <span>Name</span>

            <Tippy interactive={true} interactiveBorder={20} content={<ToolTip>Required Field</ToolTip>}>
              <span onClick={(e) => e.preventDefault() }><ExclamationCircleIcon className="h-5 w-5 text-gray-400" aria-hidden="true" /></span>
            </Tippy>
          </label>
          <div className="mt-1 flex flex-col rounded-md shadow-sm">
            <div className='relative'>
              <input type="text" className={errors.name ? 'errors' : ''} {...register('name', { required: true }) } defaultValue={''} placeholder="The health benefits of using a bidet" />
              <input type="hidden" {...register('course_id') } defaultValue={course_id} />
              { errors.name && <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
              </div> }
            </div>
          </div>
          { errors.name && <div className='mt-2 text-sm text-red-600'>This field is required.</div> }
        </div>

        <div className='col-span-1'>
        </div>

        <div className="col-span-3 sm:col-span-3">
          <label htmlFor="display_name" className="block text-sm font-medium text-gray-700 dark:text-gray-300 flex justify-between">
            <span>Body</span>

            <Tippy interactive={true} interactiveBorder={20} content={<ToolTip>Required Field</ToolTip>}>
              <span onClick={(e) => e.preventDefault() }><ExclamationCircleIcon className="h-5 w-5 text-gray-400" aria-hidden="true" /></span>
            </Tippy>
          </label>
          <div className="mt-1 flex flex-1 mx-auto">
            <FroalaWrapper
              hideButtons={false}
              showCharacterCounter={false}
              model={body}
              updateModel={(val) => handleUpdateFroala('body', val)}
            />
          </div>
          { errors.body && <div className='mt-2 text-sm text-red-600'>This field is required.</div> }
        </div>

        <div className="col-span-3">
        </div>

        <div className="col-span-3 sm:col-span-1">
          <label htmlFor="display_name" className="block text-sm font-medium text-gray-700 dark:text-gray-300 flex justify-between">
            <span>Publish Date</span>

            <Tippy interactive={true} interactiveBorder={20} content={<ToolTip>Required Field</ToolTip>}>
              <span onClick={(e) => e.preventDefault() }><ExclamationCircleIcon className="h-5 w-5 text-gray-400" aria-hidden="true" /></span>
            </Tippy>
          </label>
          <div className="mt-1 flex flex-col rounded-md shadow-sm">
            <div className='relative'>
              <DatePicker
                selected={publish_at_utc}
                onChange={(date) => setpublish_at_utc(date)}
                selectsStart
                publish_at_utc={publish_at_utc}
                endDate={new Date().setMonth(publish_at_utc.getMonth() + 1)}
                minDate={new Date()}
                nextMonthButtonLabel=">"
                previousMonthButtonLabel="<"
              />
              { errors.startsAt && <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
              </div> }
            </div>
          </div>
          { errors.startsAt && <div className='mt-2 text-sm text-red-600'>This field is required.</div> }
        </div>

        <div className="col-span-3"> </div>

        <div className='col-span-2'>
          <div className="col-span-6 sm:col-span-3">
            <label htmlFor="country" className="block text-sm font-medium text-gray-700 dark:text-gray-200 flex justify-between">
              <span>Content Type</span>
              <Tippy interactive={true} interactiveBorder={20} content={<ToolTip>Required Field</ToolTip>}>
                <span onClick={(e) => e.preventDefault() }><ExclamationCircleIcon className="h-5 w-5 text-gray-400" aria-hidden="true" /></span>
              </Tippy>
            </label>
            <select
              {...register('lesson_type', {required: true})}
              className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white dark:bg-gray-900 dark:text-gray-100 rounded-md shadow-sm focus:outline-none focus:ring-cccpurple focus:border-cccpurple sm:text-sm"
            >
              <option>Lesson</option>
              <option>Project</option>
            </select>
            { errors.lesson_type && <div className='mt-2 text-sm text-red-600'>This field is required.</div> }
          </div>
        </div>

        <div className='col-span-2'>
          <div className="col-span-6 sm:col-span-3">
            <label htmlFor="country" className="block text-sm font-medium text-gray-700 dark:text-gray-200 flex justify-between">
              <span>Cohort</span>
              <Tippy interactive={true} interactiveBorder={20} content={<ToolTip>Required Field</ToolTip>}>
                <span onClick={(e) => e.preventDefault() }><ExclamationCircleIcon className="h-5 w-5 text-gray-400" aria-hidden="true" /></span>
              </Tippy>
            </label>
            <div className='relative'>
              <select
                {...register('cohort_id', { required: true })}
                className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white dark:bg-gray-900 dark:text-gray-100 rounded-md shadow-sm focus:outline-none focus:ring-cccpurple focus:border-cccpurple sm:text-sm"
              >
                <option key={`c${cohort.id}`} value={cohort.id}>{cohort.moniker}</option>
              </select>
            { errors.cohort_id && <div className='mt-2 text-sm text-red-600'>This field is required.</div> }
            </div>
          </div>
        </div>

      </div>
    </form>
    <div className='mt-6 flex gap-x-5 items-center'>
      {!confirmDelete && <div onClick={() => setConfirmDelete(true)} className='text-red-500 text-sm cursor-pointer'>Delete</div> }
      {confirmDelete && <div onClick={destroy} className='text-red-500 text-sm cursor-pointer'>Really delete?</div> }
      <PrimaryButton onClick={(e) => { e.preventDefault(); handleSubmit(onSubmit)(); }} loading={loading} text="Save" />
    </div>
    </>
  )
}

export default LessonAdd

import React, { useState, useEffect, useRef, useReducer } from 'react'
import { Link, useLocation } from 'react-router-dom'

import { useGlobalState } from '@/state'
import useQuery from '@/hooks/useQuery'
import { ExclamationIcon } from '@heroicons/react/solid'

import Loading from '@/shared/Loading'


const CourseRow = ({course}) => {
  return <div className="flex flex-col rounded-lg shadow-lg overflow-hidden">
    <Link to={`/course-material/${course.slug}`} className="flex-shrink-0">
      <img className="h-48 w-full object-cover" src={course.imageUrl} alt="" />
    </Link>
    <Link to={`/course-material/${course.slug}`} className="flex-1 bg-white dark:bg-gray-800 p-6 flex flex-col justify-between">
      <div className="flex-1">
        <span className="uppercase text-xs dark:text-gray-500 text-gray-500 mr-5">{course.topic}</span>
        <div className="block mt-2">
          <p className="text-xl font-semibold text-gray-900 dark:text-gray-200">{course.name}</p>
        </div>
      </div>
    </Link>
  </div>
}

const courses = [
  {
    id: 1,
    imageUrl: 'https://ccc-emails.s3.amazonaws.com/clc/details-voice-acting.svg',
    slug: 'voice-acting-101',
    topic: 'Voice Acting',
    name: 'Voice Acting 101'
  }, {
    id: 4,
    imageUrl: 'https://ccc-emails.s3.amazonaws.com/clc/details-audio-engineering.svg',
    topic: 'Audio Engineering',
    slug: 'audio-engineering',
    name: 'Audio Engineering'
  }
]

const ContentRow = ({content}) => {
  return <div className="flex flex-col rounded-lg shadow-lg overflow-hidden">
    <Link to={`/library/${content.slug}`} className="flex-1 bg-white hover:bg-gray-50 dark:bg-gray-800 dark:hover:bg-gray-900 p-6 flex flex-col justify-between">
      <div className="flex-1">
        <p className="text-sm font-medium text-clcpurple"> {content.kind} </p>
        <p className="uppercase font-semibold text-sm"> {content.topic} </p>
        <div className="block mt-2">
          <p className="text-xl font-semibold text-gray-900 dark:text-gray-200">{content.name}</p>
        </div>
      </div>
    </Link>
  </div>
}

export default function LibraryIndex(props) {
  const { getRequest } = useQuery()
  const [ loading, setLoading ] = useState(true)
  const [ contents, setContents ] = useState([])
  const location = useLocation()
  const activePath = location.pathname
  let kind = 'Example'
  if (activePath.includes('/concepts')) {
    kind = 'Concept'
  }


  useEffect(() => {
    setLoading(true)
    getRequest(`/api/v1/library_contents`, { kind: kind }, (err, jsonData) => {
      setLoading(false)
      if (err) { /* handled in hook */ return }
      setContents(jsonData.contents)
    })
  }, [])

  if (loading) {
    return <Loading />
  }

  return (
    <div className="relative bg-gray-100 dark:bg-gray-700 pb-20 px-4 sm:px-6 pt-6">
      <div className="relative max-w-7xl mx-auto">

        <div className="relative my-6">
          <div className="absolute inset-0 flex items-center" aria-hidden="true">
            <div className="w-full border-t border-gray-300" />
          </div>
          <div className="relative flex justify-start">
            <span className="pr-3 bg-gray-100 text-lg font-medium text-gray-900 dark:text-gray-300 dark:bg-gray-700">Course Material</span>
          </div>
        </div>

        <div className="mt-12 max-w-lg mx-auto grid gap-5 lg:grid-cols-3 lg:max-w-none">
          {courses.map((course) => (
            <CourseRow key={`coursemateri${course.id}`} course={course} />
          ))}
        </div>

        <div className="relative my-6">
          <div className="absolute inset-0 flex items-center" aria-hidden="true">
            <div className="w-full border-t border-gray-300" />
          </div>
          <div className="relative flex justify-start">
            <span className="pr-3 bg-gray-100 text-lg font-medium text-gray-900 dark:text-gray-300 dark:bg-gray-700">Concepts</span>
          </div>
        </div>

        <div className="mt-12 max-w-lg mx-auto grid gap-5 lg:grid-cols-3 lg:max-w-none">
          {contents.map((content) => (
            <ContentRow key={`content${content.id}`} content={content} />
          ))}
        </div>
      </div>
    </div>
  )
}

import React, { useEffect } from 'react'
import { Switch, Route, useLocation, Link } from 'react-router-dom'

import Breadcrumbs from '@/Layout/Breadcrumbs'
import { useGlobalState } from '@/state'
import PageTitle from '@/Layout/PageTitle'
import CourseIndex from '@/pages/content/CourseIndex'
import CourseMaterialShow from '@/pages/content/CourseMaterialShow'
import LessonShow from '@/pages/content/LessonShow'
import LibraryShow from '@/pages/content/LibraryShow'

function breadCrumbs() {
  const location = useLocation()
  const activePath = location.pathname
  const crumbs = [
    { to: '/reserve_seat', name: 'Courses' }
  ]
  if (activePath.includes('/c/')) {
    const [ currentCourse ] = useGlobalState('currentCourse')
    let crumb = currentCourse?.name || 'Course'
    crumbs.push({ to: activePath, name: crumb })
  }
  if (activePath.includes('/course-material/')) {
    const [ currentCourse ] = useGlobalState('currentCourse')
    let crumb = currentCourse?.name || 'Course'
    crumbs.push({ to: activePath, name: crumb })
  }
  return crumbs
}

function pageTitle() {
  const location = useLocation()
  const activePath = location.pathname
  const titleInfo = {
     title: '',
    buttons: [ ]
  }
  if (activePath.includes('/c/')) {
    const [ currentCourse ] = useGlobalState('currentCourse')
    let title = currentCourse?.name || 'Course'
    titleInfo.title = title
  }
  if (activePath.includes('/course-material/')) {
    const [ currentCourse ] = useGlobalState('currentCourse')
    let title = currentCourse?.name || 'Course'
    titleInfo.title = title
  }
  return titleInfo
}

export default function ContentContainer(props) {
  const crumbs = breadCrumbs()
  const titleInfo = pageTitle()


  return <>
    <Switch>
      <Route {...props} exact path={`/c/:id`}>
        <Breadcrumbs>
          { crumbs.map((crumb, index) => (
            <Link key={crumb.name} to={crumb.to} className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700 dark:text-gray-100">
              {crumb.name}
            </Link>
          ))}
        </Breadcrumbs>
        <PageTitle pagetitle={titleInfo.title}>
          {titleInfo.buttons}
        </PageTitle>
        <CourseIndex />
      </Route>
      <Route {...props} exact path={`/course-material/:courseId`}>
        <Breadcrumbs>
          { crumbs.map((crumb, index) => (
            <Link key={crumb.name} to={crumb.to} className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700 dark:text-gray-100">
              {crumb.name}
            </Link>
          ))}
        </Breadcrumbs>
        <PageTitle pagetitle={titleInfo.title}>
          {titleInfo.buttons}
        </PageTitle>
        <CourseMaterialShow />
      </Route>
      <Route {...props} exact path={`/l/:id`}>
        <LessonShow />
      </Route>
      <Route {...props} exact path={`/library/:id`}>
        <LibraryShow />
      </Route>
    </Switch>
  </>
}

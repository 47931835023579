import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useGlobalState } from '@/state'
import Loading from '@/shared/Loading'
import useQuery from '@/hooks/useQuery'
import PrimaryButton from '@/shared/Buttons/Primary'
import DefaultButton from '@/shared/Buttons/Default'


const TicketTypeButtons =({toggleAttend, attending, tt, loading, event, userId}) => {
  const [paying, setPaying] = useState(false)
  const [currentUser] = useGlobalState('currentUser')
  const [paid, setPaid] = useState(false)
  const [error, setError] = useState(null)
  const [stripeLoading, setStripeLoading] = useState(false)
  const { putpostRequest, getRequest } = useQuery()
  let history = useHistory()

  const buyMoreCredits = () => { history.push('/buy_credits') }

  const goToOneTimeCheckout = () => {
    setStripeLoading(true)

    putpostRequest(`/api/v1/stripe/build_event_payment_link`, 'POST', {mode: 'payment', ticket_type_id: tt.id, user_id: userId}, (err, jsonData) => {
      setStripeLoading(false)
      if (err) {
        console.log(err)
        return
      }
      window.location.href = jsonData.stripeLink
    })
  }

  if (paid) { return <span className='text-green-500'>Confirmed, you're attending this event</span> }

  return <>
    { tt.seatsRemaining > 0 && <>
      { currentUser?.getsCourseDiscount && <>
        { tt.price === 0 && <>
          { attending !== tt.id && <PrimaryButton className='mt-3' disabled={Boolean(attending)} loading={loading} onClick={() => toggleAttend(tt.id)} text={'Attend'} /> }
          { attending === tt.id && <DefaultButton className='mt-3' loading={loading} onClick={() => toggleAttend(tt.id)} text='Cancel attendance' /> }
        </> }
        { tt.price > 0 && <>
          { attending !== tt.id && <PrimaryButton className='mt-3' onClick={goToOneTimeCheckout} text={'Register'} /> }
          { attending === tt.id && <span className='text-green-500'>Confirmed, you're attending this event</span> }
        </> }
      </> }
      { !currentUser?.getsCourseDiscount && <>
        { tt.nonmemberPrice === 0 && <>
          { attending !== tt.id && <PrimaryButton className='mt-3' disabled={Boolean(attending)} loading={loading} onClick={() => toggleAttend(tt.id)} text={'Attend'} /> }
          { attending === tt.id && <DefaultButton className='mt-3' loading={loading} onClick={() => toggleAttend(tt.id)} text='Cancel attendance' /> }
        </> }
        { tt.nonmemberPrice > 0 && <>
          { attending !== tt.id && <PrimaryButton className='mt-3' onClick={goToOneTimeCheckout} text={'Register'} /> }
          { attending === tt.id && <span className='text-green-500'>Confirmed, you're attending this event</span> }
        </> }
      </> }
    </> }
    { tt.seatsRemaining <= 0 && <>
      { attending !== tt.id && <PrimaryButton className='mt-3' disabled={true} loading={loading} onClick={() => {}} text={'No seats remaining'} /> }
      { attending === tt.id && <DefaultButton className='mt-3' loading={loading} onClick={() => toggleAttend(tt.id)} text='Cancel attendance' /> }
    </> }

    { stripeLoading && <div className="text-center">
      <h2 className="text-base font-semibold text-indigo-600 tracking-wide uppercase">Loading</h2>
      <Loading noMessage />

      <p className="max-w-xl mt-5 mx-auto text-xl text-gray-500">Please wait while we setup a checkout session.</p>
    </div> }
    { error && <p className='text-red-600'>{error}</p> }
  </>
}


const TicketTypes = ({event, attending, toggleAttend, loading, userId}) => {
  const [ ticketTypes, setTicketTypes ] = useState([])
  const [ currentUser ] = useGlobalState('currentUser')
  const { getRequest } = useQuery()

  useEffect(() => {
    getRequest(`/api/v1/events/${event.id}/ticket_types`, {}, (err, jsonData) => {
      if (err) { /* handled in hook */ return }

      setTicketTypes(jsonData.ticketTypes)
    })
  }, [])

  return <>
    { ticketTypes?.map((tt, idx) => (
      <div key={`${event.id}${tt.id}`} className='flex flex-col'>
        <span className='p-6'>
          <span className="text-gray-900">{tt.name}</span>
          {(tt.price > 0 || tt.nonmemberPrice > 0) && <div className='flex flex-col justify-start gap-x-2 border border-1 border-black'>
            <div>Price: <span className={currentUser?.getsCourseDiscount ? 'line-through' : ''}>${tt.nonmemberPrice} USD</span></div>
            <div className='text-green-500'>(members ${tt.price} USD)</div>
            { currentUser?.getsCourseDiscount && <div className='text-green-500 text-xs'>You're a member!</div> }
          </div> }
          {tt.price === 0 && tt.nonmemberPrice === 0 && <div className=''>Free to attend</div> }
          <TicketTypeButtons toggleAttend={toggleAttend} attending={attending} tt={tt} loading={loading} event={event} userId={userId} />
        </span>
      </div>
    ))}
  </>
}

export default TicketTypes

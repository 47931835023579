import React, {useState, useEffect} from 'react'
import useQuery from '@/hooks/useQuery'
import { useForm } from 'react-hook-form'
import { ExclamationCircleIcon, EmojiHappyIcon, LinkIcon, DuplicateIcon } from '@heroicons/react/solid'
import {PrimaryButton} from '@/shared/Buttons'
import { BulletItem, CaretItem } from '@/pages/teachers/Launch/Items'

import { showNonSpaToast } from '@/utils/nonSpaToast'

const Listed = ({course}) => {
  const [show, setShow] = useState(false)
  const [done, setDone] = useState(course?.launch?.findniche === 1)
  const { putpostRequest } = useQuery()

  const markAsDone = () => {
    putpostRequest(`/api/v1/teachers/courses/${course.id}/launch_step`, 'POST', {listed: 'done'}, (err, jsonData) => {
      if (err) { /* handled in hook */ return }
      setDone(true)
    })
  }

  return <div className={`group ${show? 'rounded-xl border border-solid border-gray-900 shadow-lg' : ''}`}>
    <div onClick={() => setShow(!show)} className={done ? 'prose-product-sm relative flex min-h-16 items-center overflow-hidden p-3 sm:prose-product-lg border-b rounded-t-xl border-gray-200 hover:bg-gray-100 bg-gray-100 dark:bg-gray-800 cursor-pointer' : 'prose-product-sm relative flex min-h-16 items-center overflow-hidden p-3 sm:prose-product-lg  border-gray-200 hover:bg-gray-100  cursor-pointer'}>
      <div className="flex grow items-center justify-between">
        <div className="flex dark:text-gray-300">
          <BulletItem done={done} />
          <div className="ml-4">
            <div className="dark:text-gray-300 flex items-center space-x-3">
              <h4>How to be listed on the Closing Credits marketplace</h4>
            </div>
          </div>
        </div>
        <div className="mr-4 text-gray-400">
          <CaretItem show={show} />
        </div>
      </div>
    </div>
    { show && <div className="p-8 pt-5">
      <div className="flex space-x-6">
        <div>
          <h4 className="mb-2"></h4>
          <div>
            <ul className="ml-6 list-outside list-disc">
              <li className="pl-1">On the Closing Credits Marketplace, over 3,000 students have taken courses since we launched 18 months ago.</li>
              <li className="pl-1">Closing Credits has a reach of 2.5M+ unique people per month. High quality courses will be heavily featured.</li>
              <li className="pl-1">Roughly 95% of all enrollments can be attributed to the Marketplace.</li>
            </ul>
          </div>
          <a href=''>
            <div className="prose-product-sm sm:prose-product-lg">
              <a target="_blank" href='https://closingcredits.feather.blog/get-listed-on-closing-credits' className="mt-2 justify-center items-center flex shadow-sm hover:shadow-md disabled:shadow-none ease-out duration-200 font-medium disabled:cursor-default h-10 px-4 b2 rounded-lg bg-clcpurple text-white hover:bg-clcnavy disabled:bg-gray-500">
                View Requirements to be Listed
                <svg className="ml-2" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M2.5 8H13.5" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                  <path d="M9 3.5L13.5 8L9 12.5" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                </svg>
              </a>
            </div>
          </a>
        </div>
      </div>
      <div className='mt-6'>
        <PrimaryButton onClick={markAsDone} text="Mark as Done" />
      </div>
    </div> }
  </div>
}

const ShowListed = ({course}) => {
  return <ul className="overflow-hidden rounded-md border border-gray-200">
    <li className="border-b first:rounded-t-md last:rounded-b-md last:border-b-0">
      <Listed course={course} />
    </li>
  </ul>
}

export default ShowListed

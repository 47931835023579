import React, {useState, useEffect, useRef} from 'react'
import useQuery from '@/hooks/useQuery'
import { useDebouncedEffect } from '@/utils/useDebouncedEffect'
import { formatInTimezone } from '@/utils/date'
import { useForm } from 'react-hook-form'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { ExclamationCircleIcon, EmojiHappyIcon, LinkIcon, DuplicateIcon } from '@heroicons/react/solid'
import { PrimaryButton, DefaultButton } from '@/shared/Buttons'
import { Modal, useModal } from '@/shared/Modal'

import { showNonSpaToast } from '@/utils/nonSpaToast'
import { BulletItem, CaretItem } from '@/pages/teachers/Launch/Items'

import InstructorForm from '@/pages/teachers/InstructorForm'
import EventRow from '@/pages/teachers/EventRow'
import Pagination from '@/shared/Pagination'

const Events = ({course, currentUser}) => {
  const [events, setEvents] = useState([])
  const { openModal, closeModal, isOpen } = useModal()
  const [cohorts, setCohorts] = useState([])
  const [meta, setMeta] = useState({})
  const [segment, setSegment] = useState('future')
  const [loading, setLoading] = useState(false)
  const { getRequest, putpostRequest } = useQuery()
  const [page, setPage] = useState(1)
  const [contains, setContains] = useState('')
  const scrollRef = useRef(null)
  const monthYear = useRef(null)

  useDebouncedEffect(() => { fetchData() }, 200, [contains])
  useEffect(() => { fetchData() }, [page, segment])

  const fetchData = () => {
    const data = {
      contains: contains,
      segment: segment,
      page: contains.length > 0 ? 1 : page
    }
    getRequest(`/api/v1/teachers/courses/${course.id}/events`, data, (err, jsonData) => {
      if (err) { /* handled in hook */ return }
      setEvents(jsonData.events)
      setMeta(jsonData.meta)
    })
  }

  useEffect(() => {
    getRequest(`/api/v1/teachers/courses/${course.id}/cohorts`, {}, (err, jsonData) => {
      if (err) { /* handled in hook */ return }
      setCohorts(jsonData.cohorts)
    })
  }, [])

  return <>
    <div className='pb-5' ref={scrollRef}>
      <div className='flex justify-between items-center'>
        <div>
          <div className="prose-product-lg">
            <h5 className="flex justify-between text-gray-800"><label className="mb-1">Filter course sessions:</label></h5>
          </div>
          <div className="grid grid-cols-2 gap-x-5 pb-3">
            <div className="col-span-1">
              <select onChange={(e) => setSegment(e.target.value)} className="block w-full py-2 px-3 border border-gray-300 bg-white dark:bg-gray-900 dark:text-gray-100 rounded-md shadow-sm focus:outline-none focus:ring-cccpurple focus:border-cccpurple sm:text-sm">
                  <option value={'future'}>Future Course Sessions</option>
                  <option value={'past'}>Past Course Sessions</option>
                  { cohorts.map((cohort) => (
                    <option key={`coid${cohort.id}`} value={cohort.id}>{cohort.moniker}</option>
                  ))}
              </select>
            </div>
          </div>
        </div>
        <div>
          <PrimaryButton onClick={openModal} text="Add Course Session" />
        </div>
      </div>
      { events.length >= 0 && <Pagination meta={meta} callback={setPage} scrollRef={scrollRef} /> }
    </div>
    <div className="border-1 mt-6 w-full overflow-hidden rounded-2xl border border-black/[0.03] last:h-full">
      { events.map((event, idx) => {
        let showMonth = idx === 0 || monthYear.current !== formatInTimezone(event.startsAtUtc, currentUser.timezone, 'MMM yyyy')
        monthYear.current = formatInTimezone(event.startsAtUtc, currentUser.timezone, 'MMM yyyy') 
        return <div key={`evnt${event.id}`}>
          { showMonth && <div className="w-full">
            <div className="h-10 bg-black/[.03] px-4 py-2">
              <h3>{formatInTimezone(event.startsAtUtc, currentUser.timezone, 'MMM yyyy')}</h3>
            </div>
          </div> }
          <EventRow event={event} currentUser={currentUser} />
        </div>
      })}
    </div>
    <Modal isOpen={isOpen} closeModal={closeModal} >
      <h3 className="text-lg leading-6 font-medium text-gray-900 dark:text-white"> Create event(s)</h3>
      <div className="px-4 pb-4 sm:px-12 sm:pb-12">
        <a href={`/teachers/events/series_new?course_id=${course.id}`} className="flex max-w-prose cursor-pointer rounded-lg border border-gray-300 p-6 shadow-sm transition duration-200 hover:border-gray-400 hover:shadow-md mt-8" data-cy="create-series-button">
          <div className="mr-4 flex h-12 w-12 rounded-full border border-gray-300 bg-gray-200">
            <svg className="m-auto" width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M18.75 8.25L21 6L18.75 3.75" stroke="#1E1E1F" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"></path>
              <path d="M3 12C3 10.4087 3.63214 8.88258 4.75736 7.75736C5.88258 6.63214 7.4087 6 9 6H21" stroke="#1E1E1F" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"></path>
              <path d="M5.25 15.75L3 18L5.25 20.25" stroke="#1E1E1F" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"></path>
              <path d="M21 12C21 13.5913 20.3679 15.1174 19.2426 16.2426C18.1174 17.3679 16.5913 18 15 18H3" stroke="#1E1E1F" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"></path>
            </svg>
          </div>
          <div className="flex flex-col"><span className="text-md font-medium">Create a series</span><span className="overflow-hidden text-ellipsis pt-0.5 text-sm text-gray-700">Create multiple events</span></div>
        </a>
        <a href={`/teachers/events/new?course_id=${course.id}`} className="flex max-w-prose cursor-pointer rounded-lg border border-gray-300 p-6 shadow-sm transition duration-200 hover:border-gray-400 hover:shadow-md mt-4" data-cy="create-single-event-button">
          <div className="mr-4 flex h-12 w-12 rounded-full border border-gray-300 bg-gray-200">
            <svg className="m-auto" width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M19.5 3.75H4.5C4.08579 3.75 3.75 4.08579 3.75 4.5V19.5C3.75 19.9142 4.08579 20.25 4.5 20.25H19.5C19.9142 20.25 20.25 19.9142 20.25 19.5V4.5C20.25 4.08579 19.9142 3.75 19.5 3.75Z" stroke="#1E1E1F" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"></path>
              <path d="M16.5 2.25V5.25" stroke="#1E1E1F" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"></path>
              <path d="M7.5 2.25V5.25" stroke="#1E1E1F" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"></path>
              <path d="M3.75 8.25H20.25" stroke="#1E1E1F" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"></path>
              <path d="M8.625 11.9995H11.25L9.75 13.8745C9.99664 13.8745 10.2395 13.9353 10.457 14.0516C10.6745 14.1678 10.86 14.3359 10.9971 14.541C11.1341 14.746 11.2185 14.9817 11.2427 15.2271C11.267 15.4726 11.2303 15.7202 11.136 15.9481C11.0417 16.176 10.8926 16.3771 10.702 16.5337C10.5114 16.6902 10.2852 16.7973 10.0433 16.8456C9.80143 16.8938 9.55139 16.8816 9.31534 16.8102C9.07928 16.7387 8.86449 16.6101 8.68999 16.4358" stroke="#1E1E1F" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"></path>
              <path d="M13.5 13.1245L15 11.9995V16.8745" stroke="#1E1E1F" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"></path>
            </svg>
          </div>
          <div className="flex flex-col"><span className="text-md font-medium">Create a single event</span><span className="overflow-hidden text-ellipsis pt-0.5 text-sm text-gray-700">Create a single event</span></div>
        </a>
      </div>

      <DefaultButton onClick={closeModal} text='Cancel' />
    </Modal>
  </>
}

const EventsIndex = (props) => {
  const { course, currentUser } = props
  return ( <>
    <Events course={course} currentUser={currentUser} />
  </>
  )
}

export default EventsIndex

import React, { useState, useEffect, useReducer } from 'react'
import { useStripe, useElements, CardElement, Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'

import useQuery from '@/hooks/useQuery'
import BuyCreditsCheckout from '@/pages/SubscriptionForm/BuyCreditsCheckout'
import Loading from '@/shared/Loading'

export default function CreditCheckoutForm (props) {
  const stripePromise = window.stripeKey ? loadStripe(window.stripeKey) : undefined
  const [errorMessage, setErrorMessage] = useState(null)
  const [updateExisting, setUpdateExisting] = useState(false)
  const [oneTimeCheckout, setOneTimeCheckout] = useState(false)
  const { putpostRequest, getRequest } = useQuery()
  const [state, setState] = useReducer(
    (state, newState) => (
      {...state, ...newState}), {
        paymentMethods: [],
        loadingSub: true,
        loadingPaymentMethods: true,
        existingSubscription: null
      }
    )
  const { paymentMethods, existingSubscription, loadingPaymentMethods, loadingSub } = state

  useEffect(() => {
    fetchPaymentMethods()
    fetchActiveSubscription()
  }, [])

  const fetchActiveSubscription = () => {
    getRequest('/api/v1/subscriptions/active', {}, (err, jsonData) => {
      if (err) {
        console.log(err)
        setState( { loadingSub: false })
      } else {
        setState( { existingSubscription: jsonData.activeSubscription, loadingSub: false })
      }
    })
  }

  const fetchPaymentMethods = () => {
    getRequest('/api/v1/stripe/payment_methods', {}, (err, jsonData) => {
      if (err) {
        setErrorMessage(err.errors)
        setState( { loadingPaymentMethods: false })
        return
      } else {
        setErrorMessage(null)
        setState( { paymentMethods: jsonData.paymentMethods, loadingPaymentMethods: false })
      }
    })
  }


  if (loadingPaymentMethods || loadingSub) {
    return <Loading full />
  }

  return (
    <Elements stripe={stripePromise}>
      <BuyCreditsCheckout paymentMethods={paymentMethods} existingSubscription={existingSubscription} />
    </Elements>
  )
}

import React, { useEffect, useRef, useState } from 'react'
import useQuery from '@/hooks/useQuery'
import { formatInTimezone } from '@/utils/date'
import { DuplicateIcon} from '@heroicons/react/solid'
import { useGlobalState } from '@/state'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { showNonSpaToast } from '@/utils/nonSpaToast'
const showCopiedNotification = () => { showNonSpaToast('Copied', true) }

const LessonRow = ({lesson, currentUser, userAccess}) => {
  let background = 'bg-green-700'
  if (lesson.publication_status === 'draft') { background = 'bg-gray-900' }
  if (lesson.publication_status === 'completed') { background = 'bg-clcnavy' }
  return <div>
    <div>
      <div>
        <div className="group flex flex-col sm:flex-row justify-between items-center cursor-pointer w-full p-3 font-graphik  bg-white hover:bg-white/10">
          <div className="flex items-center" data-cy="cy-calendar-event">
            <div className="mr-6">
              <div className="z-10 h-14 w-14 overflow-hidden rounded border border-gray-900/5 font-graphik transition duration-200 hover:border-gray-900/10 group-hover:shadow-sm">
                <div className={`text-xs font-medium uppercase tracking-wider text-white border-b py-1 text-center ${background}`} >{formatInTimezone(lesson.publish_at_utc, currentUser.timezone, 'EEE')}</div>
                <div className="bg-white text-center text-lg font-medium text-gray-900">{formatInTimezone(lesson.publish_at_utc, currentUser.timezone, 'd')}</div>
              </div>
            </div>
            <div className='flex flex-col'>
              <a href={`/teachers/lessons/${lesson.id}/edit`} className="flex flex-col hover:bg-gray-100">
                <span className="prose-product-lg uppercase tracking-wider text-gray-700">
                  <div className="l2 flex items-center gap-x-5">Lesson {lesson.publication_status === 'draft' && <span className='text-red-600'>DRAFT</span>} </div>
                </span>
                <span className="text-lg font-bold">{lesson.name} - {lesson.cohort.name} {userAccess}</span>
                <div className="flex">
                  <svg className="ml-0 mt-0.5 mr-0.5 text-gray-700" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M8 14C11.3137 14 14 11.3137 14 8C14 4.68629 11.3137 2 8 2C4.68629 2 2 4.68629 2 8C2 11.3137 4.68629 14 8 14Z" stroke="currentColor" strokeWidth="1.2" strokeMiterlimit="10"></path>
                    <path d="M8 4.5V8H11.5" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                  </svg>
                  <div className="flex text-sm text-gray-700">
                    <div className="">{formatInTimezone(lesson.publish_at_utc, currentUser.timezone, 'EEE MMMM d, Y @ h:mmaaa zzz')}</div>
                  </div>
                </div>
              </a>
            </div>
          </div>
          <div className='flex space-x-3 items-center'>
            <CopyToClipboard text={`https://www.closingcredits.com/events/links/${lesson.id}`} onCopy={showCopiedNotification} >
              <button className="justify-center flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm font-medium text-gray-700 bg-white dark:bg-gray-900 dark:text-white dark:hover:bg-gray-800 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cccpurple" type="button">
                <DuplicateIcon className="h-5 w-5 mr-2" aria-hidden="true" />
                Direct Link
              </button>
            </CopyToClipboard>
            <a target="_blank" href={`/lessons/${lesson.id}`}> Student View </a>
          </div>
        </div>
      </div>
    </div>
  </div>
}

const CohortSelector = (props) => {
  const [ currentUser ] = useGlobalState('currentUser')
  const { getRequest } = useQuery()
  const [selectedCohortId, setSelectedCohortId] = useState('all')
  const { cohorts, courseId, userAccess } = props
  
  const [lessons, setLessons] = useState([])
  const [cohort, setCohort] = useState([])

  const isComponentMounted = useRef(false)

  useEffect(() => {
    isComponentMounted.current = true
  }, [])

  const fetchData = async (cohortId) => {
    getRequest(`/api/v1/lessons/list_by_cohort?course_id=${courseId}&cohort_id=${cohortId}`, {}, (err, jsonData) => {
      if (err) {
        console.log("ERROR: " + err);
      }
      setLessons(jsonData.lessons)
    });
  };

  return <>
    <div>
      <label htmlFor="location" className="block text-sm font-medium text-gray-700">
        Cohorts
      </label>

      <select 
        onChange={(e) => {
          setSelectedCohortId(e.target.value);
          fetchData(e.target.value);
        }}
        id="cohortSelect" 
        className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-clcpurple focus:border-clcpurple sm:text-sm rounded-md"
      >
        <option value="">Select a Cohort</option>
        {cohorts.map((cohort) => (
          <option key={cohort.id} value={cohort.id}>
            {cohort.moniker}
          </option>
        ))}
      </select>
    </div>
    <div className="border-1 mt-6 w-full overflow-hidden rounded-2xl border border-black/[0.03] last:h-full">
      {selectedCohortId !== 'all' && selectedCohortId !== '' && <div className='flex justify-end items-center'><a href={`/teachers/lessons/add_lesson/${selectedCohortId}/course/${courseId}`} className='inline-flex mr-3 justify-center px-4 py-2 shadow-sm text-sm font-medium rounded-md text-white hover:text-white dark:text-gray-200 dark:hover:bg-gray-500 bg-clcnavy hover:bg-clcnavy-alt focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900'>Add lesson</a><br /></div>}
      <div className="mt-2">
        {lessons.map((lesson, idx) => (
          <div key={`teacpacoh${lesson.id}`}>
            <LessonRow key={`teaccoh${lesson.id}`} lesson={lesson} currentUser={currentUser} userAccess={userAccess} />
          </div>
        ))}
      </div>
    </div>
  </>
}

export default CohortSelector

import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'

import { useForm } from 'react-hook-form'
import { showNonSpaToast } from '@/utils/nonSpaToast'

import {PrimaryButton, DefaultButton} from '@/shared/Buttons'
import { ExclamationCircleIcon, CheckCircleIcon } from '@heroicons/react/solid'
import useQuery from '@/hooks/useQuery'

const TicketTypeForm = ({ initialTicketType, refreshData }) => {
  const [ticketType, setTicketType] = useState(initialTicketType || {})
  const [confirmDelete, setConfirmDelete] = useState(false)
  const { putpostRequest } = useQuery()
  const { formState, register, unregister, watch, handleSubmit, setValue, getValues, setError, setFocus } = useForm({
   defaultValues: {
      name: initialTicketType.name,
      capacity: initialTicketType.capacity,
      price: initialTicketType.price,
      nonmember_price: initialTicketType.nonmemberPrice,
      status: initialTicketType.status
    }
  })

  const { errors } = formState
  const [loading, setLoading] = useState(false)


  const onSubmit = (data) => {
    setLoading(true)
    putpostRequest(`/api/v1/teachers/ticket_types/${ticketType.id}`, 'PATCH', { ticket_type: { ...data } }, (err, jsonData) => {
      setLoading(false)
      if (err) { // 422 code
        if (typeof err !== 'string') { // 500 code on API
          Object.entries(err).forEach(([key, value]) => {
            setError(key, value)
          })
        }
        return
      }

      showNonSpaToast("Ticket Type Info Saved", true)
    })
  }

  const destroy = () => {
    setLoading(true)
    putpostRequest(`/api/v1/teachers/ticket_types/${ticketType.id}`, 'DELETE', {}, (err, jsonData) => {
      setLoading(false)
      if (err) { // 422 code
        if (typeof err !== 'string') { // 500 code on API
          Object.entries(err).forEach(([key, value]) => {
            setError(key, value)
          })
        }
        return
      }

      refreshData()
      showNonSpaToast("Ticket Type Deleted", true)
    })
  }

  return <li className='sm:col-span-1 bg-gray-200 dark:bg-gray-700 shadow-md transition duration-300 ease-out rounded-lg hover:shadow-xl sm:shadow-lg p-2'>
    <div className="w-full flex flex-col space-y-2">
      <div className="w-full prose-product-sm flex items-center sm:prose-product-lg">
        <input type="text" className={errors.name ? 'errors placeholder-shown:italic placeholder-gray-600 p2 py-3.5 px-4 w-full border rounded-lg' : 'placeholder-shown:italic placeholder-gray-600 p2 py-3.5 px-4 w-full border rounded-lg'} {...register('name', { required: true }) } defaultValue={ticketType.name} placeholder="Mr. Bigglesworth" />
      </div>
      <div className="flex items-center justify-between">
        <div className="flex flex-col justify-between items-center">
          <label htmlFor="display_name" className="block text-sm font-medium text-gray-700 dark:text-gray-300 flex justify-between">
            <span>Capacity</span>
          </label>
          <input type="number" className={errors.capcity ? 'errors placeholder-shown:italic placeholder-gray-600 p2 py-3.5 px-4 w-full border rounded-lg' : 'placeholder-shown:italic placeholder-gray-600 p2 py-3.5 px-4 w-full border rounded-lg'} {...register('capacity', { required: true }) } defaultValue={ticketType.capacity} placeholder="10" />
        </div>
      </div>
      <div className="flex items-center justify-between">
        <div className="flex flex-col justify-between items-center">
          <label htmlFor="display_name" className="block text-sm font-medium text-gray-700 dark:text-gray-300 flex justify-between">
            <span>Price in USD</span>
          </label>
          <input type="number" className={errors.price ? 'errors placeholder-shown:italic placeholder-gray-600 p2 py-3.5 px-4 w-full border rounded-lg' : 'placeholder-shown:italic placeholder-gray-600 p2 py-3.5 px-4 w-full border rounded-lg'} {...register('price', { required: true }) } defaultValue={ticketType.price} placeholder="10" />
        </div>
        <div className="flex flex-col justify-between items-center">
          <label htmlFor="display_name" className="block text-sm font-medium text-gray-700 dark:text-gray-300 flex justify-between">
            <span>NON-member Price in USD</span>
          </label>
          <input type="number" className={errors.nonmember_price ? 'errors placeholder-shown:italic placeholder-gray-600 p2 py-3.5 px-4 w-full border rounded-lg' : 'placeholder-shown:italic placeholder-gray-600 p2 py-3.5 px-4 w-full border rounded-lg'} {...register('nonmember_price', { required: true }) } defaultValue={ticketType.nonmemberPrice} placeholder="20" />
        </div>
      </div>
      <div className="col-span-3 sm:col-span-2">
        <label htmlFor="country" className="block text-sm font-medium text-gray-700 dark:text-gray-200 flex justify-between">
          <span>On sale?</span>
        </label>
        <select
          {...register('status', { required: true })}
          className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white dark:bg-gray-900 dark:text-gray-100 rounded-md shadow-sm focus:outline-none focus:ring-cccpurple focus:border-cccpurple sm:text-sm"
        >
          <option value='active'>active</option>
          <option value='disabled'>disabled</option>
        </select>
      </div>
      <div className='flex gap-2 sm:gap-x-0 justify-between items-center'>
        <PrimaryButton onClick={handleSubmit(onSubmit)} loading={loading} text="Save" />
        {!confirmDelete && <div onClick={() => setConfirmDelete(true)} className='text-red-500 text-sm cursor-pointer'>Delete</div> }
        {confirmDelete && <div onClick={destroy} className='text-red-500 text-sm cursor-pointer'>Really delete?</div> }
      </div>
    </div>

    { errors.name && <div className='mt-2 text-sm text-red-600'>This field is required.</div> }
  </li>
}

export default TicketTypeForm

TicketTypeForm.propTypes = {
  initialTicketType: PropTypes.object.isRequired
}

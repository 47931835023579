import React from 'react'
import { Modal, useModal } from '@/shared/Modal'

import { BadgeCheckIcon, BanIcon, XIcon } from '@heroicons/react/solid'

const FreePlanDetails = () => {
  return <ul className="px-6 pb-6">
    <li className="flex py-2 items-start">
      <BadgeCheckIcon className='w-6 h-6 text-green-500' />
      <p className="ml-3 text-sm text-gray-700 dark:text-gray-300"> Access to free events and events you paid for </p>
    </li>
    <li className="flex py-2 items-start">
      <BadgeCheckIcon className='w-6 h-6 text-green-500' />
      <p className="ml-3 text-sm text-gray-700 dark:text-gray-300"> Access to courses you paid for </p>
    </li>
    <li className="flex py-2 items-start">
      <BadgeCheckIcon className='w-6 h-6 text-yellow-500' />
      <p className="ml-3 text-sm text-gray-700 dark:text-gray-300"> Access to public area in Discord </p>
    </li>
    <li className="flex py-2 items-start text-red-500">
      <BanIcon className='w-6 h-6' />
      <p className="ml-3 text-sm text-gray-700 dark:text-gray-300 line-through"> Access event recordings </p>
    </li>
    <li className="flex py-2 items-start text-red-500">
      <BanIcon className='w-6 h-6' />
      <p className="ml-3 text-sm text-gray-700 dark:text-gray-300 line-through"> Access to in-house work opportunities </p>
    </li>
    <li className="flex py-2 items-start text-red-500">
      <BanIcon className='w-6 h-6' />
      <p className="ml-3 text-sm text-gray-700 dark:text-gray-300 line-through"> Discounts on all courses </p>
    </li>
    <li className="flex py-2 items-start text-red-500">
      <BanIcon className='w-6 h-6' />
      <p className="ml-3 text-sm text-gray-700 dark:text-gray-300 line-through"> Discounts on all events / workshops </p>
    </li>
    <li className="flex py-2 items-start text-red-500">
      <BanIcon className='w-6 h-6' />
      <p className="ml-3 text-sm text-gray-700 dark:text-gray-300 line-through"> Access to events / workshops before anyone else </p>
    </li>
    <li className="flex py-2 items-start text-red-500">
      <BanIcon className='w-6 h-6' />
      <p className="ml-3 text-sm text-gray-700 dark:text-gray-300 line-through"> feedback from a guest industry pro monthly </p>
    </li>
  </ul>
}

const PaidPlanDetails = () => {
  return <ul className="px-6 pb-6">
    <li className="flex py-2 items-start">
      <BadgeCheckIcon className='w-6 h-6 flex-shrink-0' />
      <p className="ml-3 text-sm text-gray-700 dark:text-gray-300"> Access to free events and events you paid for </p>
    </li>
    <li className="flex py-2 items-start">
      <BadgeCheckIcon className='w-6 h-6 flex-shrink-0' />
      <p className="ml-3 text-sm text-gray-700 dark:text-gray-300"> Access to courses you paid for </p>
    </li>
    <li className="flex py-2 items-start">
      <BadgeCheckIcon className='w-6 h-6 flex-shrink-0' />
      <p className="ml-3 text-sm text-gray-700 dark:text-gray-300"> Access to Members Only area in Discord </p>
    </li>
    <li className="flex py-2 items-start">
      <BadgeCheckIcon className='w-6 h-6 flex-shrink-0' />
      <p className="ml-3 text-sm text-gray-700 dark:text-gray-300"> Access to event recordings </p>
    </li>
    <li className="flex py-2 items-start">
      <BadgeCheckIcon className='w-6 h-6 flex-shrink-0' />
      <p className="ml-3 text-sm text-gray-700 dark:text-gray-300"> Access to in-house work opportunities </p>
    </li>
    <li className="flex py-2 items-start">
      <BadgeCheckIcon className='w-6 h-6 flex-shrink-0' />
      <p className="ml-3 text-sm text-gray-700 dark:text-gray-300"> Discounts on all courses </p>
    </li>

    <li className="flex py-2 items-start">
      <BadgeCheckIcon className='w-6 h-6 flex-shrink-0' />
      <p className="ml-3 text-sm text-gray-700 dark:text-gray-300"> Discounts on all events / workshops </p>
    </li>
    <li className="flex py-2 items-start">
      <BadgeCheckIcon className='w-6 h-6 flex-shrink-0' />
      <p className="ml-3 text-sm text-gray-700 dark:text-gray-300"> Access to events / workshops before anyone else </p>
    </li>
    <li className="flex py-2 items-start">
      <BadgeCheckIcon className='w-6 h-6 flex-shrink-0' />
      <p className="ml-3 text-sm text-gray-700 dark:text-gray-300"> feedback from a guest industry pro monthly </p>
    </li>
  </ul>
}

export { FreePlanDetails, PaidPlanDetails }

import React, {useState, useEffect} from 'react'
import useQuery from '@/hooks/useQuery'
import { useForm } from 'react-hook-form'
import { ExclamationCircleIcon, EmojiHappyIcon, LinkIcon, DuplicateIcon } from '@heroicons/react/solid'
import {PrimaryButton} from '@/shared/Buttons'
import { BulletItem, CaretItem } from '@/pages/teachers/Launch/Items'

import { showNonSpaToast } from '@/utils/nonSpaToast'

const CreateSurvey = ({course}) => {
  const [show, setShow] = useState(false)
  const [done, setDone] = useState(course?.launch?.createsurvey === 1)
  const { putpostRequest } = useQuery()

  return <div className={`group ${show? 'rounded-xl border border-solid border-gray-900 shadow-lg' : ''}`}>
    <div onClick={() => setShow(!show)} className={done ? 'prose-product-sm relative flex min-h-16 items-center overflow-hidden p-3 sm:prose-product-lg border-b rounded-t-xl border-gray-200 hover:bg-gray-100 bg-gray-100 dark:bg-gray-800 cursor-pointer' : 'prose-product-sm relative flex min-h-16 items-center overflow-hidden p-3 sm:prose-product-lg  border-gray-200 hover:bg-gray-100  cursor-pointer'}>
      <div className="flex grow items-center justify-between">
        <div className="flex dark:text-gray-300">
          <BulletItem done={done} />
          <div className="ml-4">
            <div className="dark:text-gray-300 flex items-center space-x-3">
              <h4>Create Demand Survey</h4>
            </div>
          </div>
        </div>
        <div className="mr-4 text-gray-400">
          <CaretItem show={show} />
        </div>
      </div>
    </div>
    { show && <div className="p-8 pt-5">
      <div className="flex space-x-6">
        <div className="min-w-80">
          <div className="group aspect-w-16 aspect-h-9 relative overflow-hidden rounded-lg hover:cursor-pointer">
            <div className="flex h-10 w-10 items-center justify-center md:hidden absolute z-10 m-auto bg-black opacity-80 border-2 border-clcnavy group-hover:bg-clcnavy group-hover:opacity-70 transition ease-in duration-100 rounded-full overflow-hidden text-white">
              <svg className="ml-[6px]" width="16" height="16" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M9.58396 7.49904C10.1777 7.10322 10.1777 6.23076 9.58396 5.83494L1.88673 0.703457C1.22217 0.260421 0.332031 0.736814 0.332031 1.53551V11.7985C0.332031 12.5972 1.22218 13.0736 1.88673 12.6305L9.58396 7.49904Z" fill="currentColor"></path>
              </svg>
            </div>
            <div className="hidden h-18 w-18 md:flex md:items-center md:justify-center absolute z-10 m-auto bg-black opacity-80 border-2 border-clcnavy group-hover:bg-clcnavy group-hover:opacity-70 transition ease-in duration-100 rounded-full overflow-hidden text-white">
              <svg className="ml-2" width="28" height="28" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M9.58396 7.49904C10.1777 7.10322 10.1777 6.23076 9.58396 5.83494L1.88673 0.703457C1.22217 0.260421 0.332031 0.736814 0.332031 1.53551V11.7985C0.332031 12.5972 1.22218 13.0736 1.88673 12.6305L9.58396 7.49904Z" fill="currentColor"></path>
              </svg>
            </div>
            <span><img src="" className="duration-300 ease-in-out" /></span>
          </div>
        </div>
        <div className="space-y-3">
          <div className="p2 space-y-1 text-gray-900">
            <ul className="ml-6 list-outside list-disc">
              <li className="pl-1"><b>Before you build a syllabus</b>, use the demand survey to define your topic, positioning, and target student <i></i>.</li>

              <li className="pl-1">Get insights from potential students so you can develop relevant content <b>without guessing what students want</b>.</li>

              <li className="pl-1">This will also set you up with a waitlist to get <b>early traction</b>.</li>
            </ul>
            <p className="pt-2">Some questions to ask:</p>
            <ul className="ml-6 list-outside list-disc">
              <li className="pl-1">“What made you excited about this course?”</li>
              <li className="pl-1">“What problems are you trying to solve?”</li>
              <li className="pl-1">“If you could get one thing out of this course, what would it be?”</li>
            </ul>
            <p className="pt-2"></p>
          </div>
        </div>
      </div>
      <a href={`/teachers/courses/${course.id}/surveys`}>
        <div className="prose-product-sm sm:prose-product-lg">
          <button className="mt-2 justify-center items-center flex shadow-sm hover:shadow-md disabled:shadow-none ease-out duration-200 font-medium disabled:cursor-default h-10 px-4 b2 rounded-lg bg-clcpurple text-white hover:bg-clcnavy disabled:bg-gray-500" type="button">
            Go to demand survey editor
            <svg className="ml-2" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M2.5 8H13.5" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
              <path d="M9 3.5L13.5 8L9 12.5" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
            </svg>
          </button>
        </div>
      </a>
    </div> }
  </div>
}

const ContactList = ({course}) => {
  const [show, setShow] = useState(false)
  const [done, setDone] = useState(course?.launch?.contactlist === 1)
  const { putpostRequest } = useQuery()

  const markAsDone = () => {
    putpostRequest(`/api/v1/teachers/courses/${course.id}/launch_step`, 'POST', {contactlist: 'done'}, (err, jsonData) => {
      if (err) { /* handled in hook */ return }
      setDone(true)
    })
  }

  return <div className={`group ${show? 'rounded-xl border border-solid border-gray-900 shadow-lg' : ''}`}>
    <div onClick={() => setShow(!show)} className={done ? 'prose-product-sm relative flex min-h-16 items-center overflow-hidden p-3 sm:prose-product-lg border-b rounded-t-xl border-gray-200 hover:bg-gray-100 bg-gray-100 dark:bg-gray-800 cursor-pointer' : 'prose-product-sm relative flex min-h-16 items-center overflow-hidden p-3 sm:prose-product-lg  border-gray-200 hover:bg-gray-100  cursor-pointer'}>
      <div className="flex grow items-center justify-between">
        <div className="flex dark:text-gray-300">
          <BulletItem done={done} />
          <div className="ml-4">
            <div className="flex items-center space-x-3">
              <h4>Add People to your List</h4>
            </div>
          </div>
        </div>
        <div className="mr-4 text-gray-400">
          <CaretItem show={show} />
        </div>
      </div>
    </div>
    { show && <div className="p-8 pt-5">
      <div className="flex space-x-6">
        <div className="p2 space-y-1 text-gray-900">
          <p>Building your contact list will help you drive word-of-mouth and gain early traction:</p>
          <ul className="ml-6 list-outside list-disc">
            <li className="pl-1">There are many people who would be glad to find out your course exists. <b>But they won’t know if you keep it to yourself</b>.</li>
            <li className="pl-1">Contact at least 50 people: family members, coworkers, clients, other teachers or students, peer groups, and online communities.</li>
          </ul>
        </div>
      </div>
      <PrimaryButton onClick={markAsDone} text="Mark as Done" />
    </div> }
  </div>
}

const BroadcastSurvey = ({course}) => {
  const [show, setShow] = useState(false)
  const [done, setDone] = useState(course?.launch?.sharesurvey === 1)
  const { putpostRequest } = useQuery()

  const markAsDone = () => {
    putpostRequest(`/api/v1/teachers/courses/${course.id}/launch_step`, 'POST', {sharesurvey: 'done'}, (err, jsonData) => {
      if (err) { /* handled in hook */ return }
      setDone(true)
    })
  }

  return <div className={`group ${show? 'rounded-xl border border-solid border-gray-900 shadow-lg' : ''}`}>
    <div onClick={() => setShow(!show)} className={done ? 'prose-product-sm relative flex min-h-16 items-center overflow-hidden p-3 sm:prose-product-lg border-b rounded-t-xl border-gray-200 hover:bg-gray-100 bg-gray-100 dark:bg-gray-800 cursor-pointer' : 'prose-product-sm relative flex min-h-16 items-center overflow-hidden p-3 sm:prose-product-lg  border-gray-200 hover:bg-gray-100  cursor-pointer'}>
      <div className="flex grow items-center justify-between">
        <div className="flex dark:text-gray-300">
          <BulletItem done={done} />
          <div className="ml-4">
            <div className="flex items-center space-x-3">
              <h4>Share your Demand Survey</h4>
            </div>
          </div>
        </div>
        <div className="mr-4 text-gray-400">
          <CaretItem show={show} />
        </div>
      </div>
    </div>
    { show && <div className="p-8 pt-5">
      <div className="space-y-3">
        <div className="p2 space-y-1 text-gray-900">
          <ul className="ml-6 list-outside list-disc">
            <li className="pl-1">Aim to reach at least 50 people.</li>
            <li className="pl-1">Share with your family, social media, communities like discord/reddit, friends.</li>
            <li className="pl-1">Remember, it's just a survey. You're not asking them to buy anything yet.</li>
          </ul>
          <p className="pt-2"><a href="https://closingcredits.feather.blog/course-interest-survey" className="text-primary-dark cursor-pointer font-medium underline text-primary-dark" target="_blank" rel="noreferrer">Here's a few things to think about.</a> </p>
        </div>
      </div>
      <PrimaryButton onClick={markAsDone} text="Mark as Done" />
    </div> }
  </div>
}

const OrganicGrowthMaterial = ({course}) => {
  const [show, setShow] = useState(false)
  const [done, setDone] = useState(course?.launch?.organicgrowthmaterial === 1)
  const { putpostRequest } = useQuery()

  const markAsDone = () => {
    putpostRequest(`/api/v1/teachers/courses/${course.id}/launch_step`, 'POST', {organicgrowthmaterial: 'done'}, (err, jsonData) => {
      if (err) { /* handled in hook */ return }
      setDone(true)
    })
  }

  return <div className={`group ${show? 'rounded-xl border border-solid border-gray-900 shadow-lg' : ''}`}>
    <div onClick={() => setShow(!show)} className={done ? 'prose-product-sm relative flex min-h-16 items-center overflow-hidden p-3 sm:prose-product-lg border-b rounded-t-xl border-gray-200 hover:bg-gray-100 bg-gray-100 dark:bg-gray-800 cursor-pointer' : 'prose-product-sm relative flex min-h-16 items-center overflow-hidden p-3 sm:prose-product-lg  border-gray-200 hover:bg-gray-100  cursor-pointer'}>
      <div className="flex grow items-center justify-between">
        <div className="flex dark:text-gray-300">
          <BulletItem done={done} />
          <div className="ml-4">
            <div className="flex items-center space-x-3">
              <h4>Get Potential Students While You Sleep</h4>
            </div>
          </div>
        </div>
        <div className="mr-4 text-gray-400">
          <CaretItem show={show} />
        </div>
      </div>
    </div>
    { show && <div className="p-8 pt-5">
      <div className="flex space-x-6">
        <div className="p2 space-y-1 text-gray-900">
          <ul className="ml-6 list-outside list-disc">
            <li className="pl-1">Create free resources that you offer in exchange for someone's email. This could be discord groups, free events or videos, templates, etc.</li>
            <li className="pl-1">Free resources allows you to grow your waitlist without having to always be promoting your course</li>
            <li className="pl-1">They also gives you domain authority by sharing value in public</li>
          </ul>
        </div>
      </div>
      <PrimaryButton onClick={markAsDone} text="Mark as Done" />
    </div> }
  </div>
}

const FollowUp = ({course}) => {
  const [show, setShow] = useState(false)
  const [done, setDone] = useState(course?.launch?.followup === 1)
  const { putpostRequest } = useQuery()

  const markAsDone = () => {
    putpostRequest(`/api/v1/teachers/courses/${course.id}/launch_step`, 'POST', {followup: 'done'}, (err, jsonData) => {
      if (err) { /* handled in hook */ return }
      setDone(true)
    })
  }

  return <div className={`group ${show? 'rounded-xl border border-solid border-gray-900 shadow-lg' : ''}`}>
    <div onClick={() => setShow(!show)} className={done ? 'prose-product-sm relative flex min-h-16 items-center overflow-hidden p-3 sm:prose-product-lg border-b rounded-t-xl border-gray-200 hover:bg-gray-100 bg-gray-100 dark:bg-gray-800 cursor-pointer' : 'prose-product-sm relative flex min-h-16 items-center overflow-hidden p-3 sm:prose-product-lg  border-gray-200 hover:bg-gray-100  cursor-pointer'}>
      <div className="flex grow items-center justify-between">
        <div className="flex dark:text-gray-300">
          <BulletItem done={done} />
          <div className="ml-4">
            <div className="flex items-center space-x-3">
              <h4>Follow Up & Schedule 1:1 Calls</h4>
            </div>
          </div>
        </div>
        <div className="mr-4 text-gray-400">
          <CaretItem show={show} />
        </div>
      </div>
    </div>
    { show && <div className="p-8 pt-5">
      <div className="space-y-3">
        <div className="p2 space-y-1 text-gray-900">
           <p>Call up at least 10 people who resonded to your survey. This will heop you understand more about how to market the course and the material to provide.</p>
           <ul className="ml-6 list-outside list-disc">
              <li className="pl-1">“What got you excited about this course?”</li>
              <li className="pl-1">“What challenges are you looking to tackle?”</li>
              <li className="pl-1">“What have you tried in the past to solve this problem?”</li>
              <li className="pl-1">“If you could get one thing out of this course, what would it be?”</li>
           </ul>
        </div>
      </div>
      <PrimaryButton onClick={markAsDone} text="Mark as Done" />
    </div> }
  </div>
}

const ShowGrowWaitlist = ({course}) => {
  return <ul className="overflow-hidden rounded-md border border-gray-200">
    <li className="border-b first:rounded-t-md last:rounded-b-md last:border-b-0">
      <CreateSurvey course={course} />
    </li>
    <li className="border-b first:rounded-t-md last:rounded-b-md last:border-b-0">
      <BroadcastSurvey course={course} />
    </li>
    <li className="border-b first:rounded-t-md last:rounded-b-md last:border-b-0">
      <OrganicGrowthMaterial course={course} />
    </li>
  </ul>
}

export default ShowGrowWaitlist

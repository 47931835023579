import React, {useState, useEffect} from 'react'

const CaretItem = ({show}) => {
  return <>
    { !show && <svg className="" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M6 3L11 8L6 13" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
    </svg> }
    { show && <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M13 6L8 11L3 6" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
    </svg> }
  </>
}

const BulletItem = ({done}) => {
  if (done) {
    return <svg className="m-auto" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M3 10C3 6.13401 6.13401 3 10 3C13.866 3 17 6.13401 17 10C17 13.866 13.866 17 10 17C6.13401 17 3 13.866 3 10ZM10 2C5.58172 2 2 5.58172 2 10C2 14.4183 5.58172 18 10 18C14.4183 18 18 14.4183 18 10C18 5.58172 14.4183 2 10 2ZM13.7827 8.48668C13.9825 8.29601 13.9898 7.97951 13.7992 7.77976C13.6085 7.58001 13.292 7.57265 13.0923 7.76332L8.85414 11.8088L6.90774 9.95083C6.70799 9.76015 6.3915 9.76751 6.20083 9.96726C6.01015 10.167 6.01751 10.4835 6.21726 10.6742L8.5089 12.8617C8.70211 13.0461 9.00617 13.0461 9.19938 12.8617L13.7827 8.48668Z" fill="#009E3D"></path>
    </svg>
  } else {
    return <svg className="m-auto" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M10 3C6.13401 3 3 6.13401 3 10C3 13.866 6.13401 17 10 17C13.866 17 17 13.866 17 10C17 6.13401 13.866 3 10 3ZM2 10C2 5.58172 5.58172 2 10 2C14.4183 2 18 5.58172 18 10C18 14.4183 14.4183 18 10 18C5.58172 18 2 14.4183 2 10Z" fill="currentColor"></path>
    </svg>
  }
}

export { BulletItem, CaretItem }

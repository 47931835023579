import React, {useState, useEffect, useRef} from 'react'
import useQuery from '@/hooks/useQuery'
import { useDebouncedEffect } from '@/utils/useDebouncedEffect'
import { formatInTimezone } from '@/utils/date'
import { useForm } from 'react-hook-form'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { ExclamationCircleIcon, EmojiHappyIcon, LinkIcon, DuplicateIcon } from '@heroicons/react/solid'
import { PrimaryButton, DefaultButton } from '@/shared/Buttons'
import { Modal, useModal } from '@/shared/Modal'

import { showNonSpaToast } from '@/utils/nonSpaToast'
import { BulletItem, CaretItem } from '@/pages/teachers/Launch/Items'

import InstructorForm from '@/pages/teachers/InstructorForm'
import Pagination from '@/shared/Pagination'

const showCopiedNotification = () => { showNonSpaToast('Copied', true) }

const EmailEventRow = ({event, currentUser}) => {
  let background = 'bg-green-700'
  if (event.status === 'draft') { background = 'bg-gray-900' }
  if (event.status === 'completed') { background = 'bg-clcnavy' }
  return <div>
    <div>
      <div>
        <div className="group flex flex-col sm:flex-row justify-between items-center cursor-pointer w-full p-3 font-graphik  bg-white hover:bg-white/10">
          <div className="flex items-center" data-cy="cy-calendar-event">
            <div className="mr-6">
              <div className="z-10 h-14 w-14 overflow-hidden rounded border border-gray-900/5 font-graphik transition duration-200 hover:border-gray-900/10 group-hover:shadow-sm">
                <div className={`text-xs font-medium uppercase tracking-wider text-white border-b py-1 text-center ${background}`} style={{background: background}}>{formatInTimezone(event.startsAtUtc, currentUser.timezone, 'EEE')}</div>
                <div className="bg-white text-center text-lg font-medium text-gray-900">{formatInTimezone(event.startsAtUtc, currentUser.timezone, 'd')}</div>
              </div>
            </div>
            <div className='flex flex-col'>
              <a href={`/teachers/events/${event.id}/edit`} className="flex flex-col hover:bg-gray-100">
                <span className="prose-product-lg uppercase tracking-wider text-gray-700">
                  <div className="l2 flex items-center gap-x-5">{event.kind} {event.status === 'draft' && <span className='text-red-600'>DRAFT</span>} </div>
                </span>
                <span className="text-lg font-bold">{event.name} {event.cohortMoniker}</span>
                <div className="flex">
                  <svg className="ml-0 mt-0.5 mr-0.5 text-gray-700" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M8 14C11.3137 14 14 11.3137 14 8C14 4.68629 11.3137 2 8 2C4.68629 2 2 4.68629 2 8C2 11.3137 4.68629 14 8 14Z" stroke="currentColor" strokeWidth="1.2" strokeMiterlimit="10"></path>
                    <path d="M8 4.5V8H11.5" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                  </svg>
                  <div className="flex text-sm text-gray-700">
                    <div className="">{formatInTimezone(event.startsAtUtc, currentUser.timezone, 'EEE MMMM d, Y @ h:mmaaa zzz')}</div>
                  </div>
                </div>
                <div className="flex">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" class="h-5 w-5 text-gray-400">
                    <path fill-rule="evenodd" d="M18 5v8a2 2 0 01-2 2h-5l-5 4v-4H4a2 2 0 01-2-2V5a2 2 0 012-2h12a2 2 0 012 2zM7 8H5v2h2V8zm2 0h2v2H9V8zm6 0h-2v2h2V8z" clip-rule="evenodd"></path>
                  </svg>
                  <div className="flex text-sm text-gray-700">
                    <div className="">{event.attendeesCount} attendees</div>
                  </div>
                </div>

              </a>
              {event.ticketTypes?.length > 0 && <div className="flex">
                <table className="border-collapse w-full">
                  <thead>
                    <tr>
                      <th className="border border-gray-300 px-4 py-2">Ticket Type</th>
                      <th className="border border-gray-300 px-4 py-2">Price</th>
                      <th className="border border-gray-300 px-4 py-2">Nonmember Price</th>
                      <th className="border border-gray-300 px-4 py-2">Attendees</th>
                      <th className="border border-gray-300 px-4 py-2">Total Price</th>
                    </tr>
                  </thead>
                  <tbody>
                    {event.ticketTypes?.map((ticketType, idx) => (
                      <tr key={idx}>
                        <td className="border border-gray-300 px-4 py-2">{ticketType.name}</td>
                        <td className="border border-gray-300 px-4 py-2">${ticketType.price}</td>
                        <td className="border border-gray-300 px-4 py-2">${ticketType.nonmemberPrice}</td>
                        <td className="border border-gray-300 px-4 py-2">{ticketType.attendeesCount}</td>
                        <td className="border border-gray-300 px-4 py-2">{ticketType.totalPrice}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div> }
              {event.zoomUrl === '' && <div className='text-red-600'>No Zoom URL -- cannot publish</div>}
              {false && event.zoomUrl !== '' && <button className="justify-center flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm font-medium text-gray-700 bg-white dark:bg-gray-900 dark:text-white dark:hover:bg-gray-800 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cccpurple" type="button">
                <EmojiHappyIcon className="h-5 w-5 mr-2" aria-hidden="true" />
                Publish
              </button> }
            </div>
          </div>
          <div className='flex space-x-3 items-center'>
            <a target="_blank" href={`/teachers/events/${event.id}/emails`}> Emails </a>
            <CopyToClipboard text={`https://www.closingcredits.com/events/links/${event.uuid}`} onCopy={showCopiedNotification} >
              <button className="justify-center flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm font-medium text-gray-700 bg-white dark:bg-gray-900 dark:text-white dark:hover:bg-gray-800 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cccpurple" type="button">
                <DuplicateIcon className="h-5 w-5 mr-2" aria-hidden="true" />
                Direct Link
              </button>
            </CopyToClipboard>
          </div>
        </div>
      </div>
    </div>
  </div>
}

export default EmailEventRow

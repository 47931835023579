import React, {useState, useEffect} from 'react'
import useQuery from '@/hooks/useQuery'
import { Switch } from '@headlessui/react'
import { useForm } from 'react-hook-form'
import FroalaMinimalWrapper from '@/shared/FroalaMinimalWrapper'
import { ExclamationCircleIcon, EmojiHappyIcon, LinkIcon, DuplicateIcon, ClipboardCopyIcon } from '@heroicons/react/solid'
import { CopyToClipboard } from 'react-copy-to-clipboard'

import { PrimaryButton, DefaultButton } from '@/shared/Buttons'
import { Modal, useModal } from '@/shared/Modal'
import classNames from '@/utils/classNamesLocal'

import { showNonSpaToast } from '@/utils/nonSpaToast'
import { BulletItem, CaretItem } from '@/pages/teachers/Launch/Items'

const SendNow = ({emailTemplate}) => {
  const { openModal, closeModal, isOpen } = useModal()
  const { formState, reset, register, unregister, handleSubmit, setValue, watch, getValues, setError } = useForm({
   defaultValues: { email: '' }
  })
  const [loading, setLoading] = useState(false)
  const [number, setNumber] = useState(false)
  const { putpostRequest, getRequest } = useQuery()

  const checkEmailSend = () => {
    setNumber(0)
    setLoading(true)
    openModal()
    getRequest(`/api/v1/teachers/email_templates/${emailTemplate.id}/check_email_send`, {}, (err, jsonData) => {
      setLoading(false)
      if (err) {
        showNonSpaToast(err, false)
        return
      }
      setNumber(jsonData.number)
    })
  }

  const onSubmit = (data) => {
    setLoading(true)
    putpostRequest(`/api/v1/teachers/email_templates/${emailTemplate.id}/send_now`, 'POST', data, (err, jsonData) => {
      setLoading(false)
      if (err) {
        showNonSpaToast(err, false)
        return
      }

      showNonSpaToast("Email sent!", true)
      closeModal()
    })
  }

  return <>
    <DefaultButton onClick={checkEmailSend} text="Verify & Send Now" />
    <Modal isOpen={isOpen} closeModal={closeModal} >
      <h3 className="text-lg leading-6 font-medium text-gray-900 dark:text-white"> Send Email</h3>
      <div className='flex justify-center'>
        <div className="mt-2">
          <div className="bg-red-50 border-l-4 border-red-400 p-4">
            <div className="flex">
              <div className="flex-shrink-0">
                <svg className="h-5 w-5 text-red-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                  <path fill-rule="evenodd" d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z" clip-rule="evenodd" />
                </svg>
              </div>
              <div className="ml-3">
                <p className="text-sm text-red-700"> Did you save your email template? </p>
              </div>
            </div>
          </div>
          <div className="rounded-md bg-red-50 p-4 mt-2">
            <div className="flex">
              <div className="flex-shrink-0">
                <svg className="h-5 w-5 text-red-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                  <path fillRule="evenodd" d="M19 10.5a8.5 8.5 0 11-17 0 8.5 8.5 0 0117 0zM8.25 9.75A.75.75 0 019 9h.253a1.75 1.75 0 011.709 2.13l-.46 2.066a.25.25 0 00.245.304H11a.75.75 0 010 1.5h-.253a1.75 1.75 0 01-1.709-2.13l.46-2.066a.25.25 0 00-.245-.304H9a.75.75 0 01-.75-.75zM10 7a1 1 0 100-2 1 1 0 000 2z" clipRule="evenodd" />
                </svg>
              </div>
              <div className="ml-3 flex-1 md:flex md:justify-between">
                <p className="text-sm text-red-700">You are about to send this email out to <b className='text-xl'>{number}</b> people. This cannot be undone. We highly suggest you send a test email to yourself to make sure it's ready.</p>
              </div>
            </div>
          </div>

          <div className='flex gap-x-5 mt-6'>
            <PrimaryButton onClick={handleSubmit(onSubmit)} loading={loading} text="Send Email" />
            <DefaultButton onClick={closeModal} text="Cancel" />
          </div>
        </div>
      </div>
    </Modal>
  </>
}


const SendTest = ({emailTemplate}) => {
  const { openModal, closeModal, isOpen } = useModal()
  const { formState, reset, register, unregister, handleSubmit, setValue, watch, getValues, setError } = useForm({
   defaultValues: { email: '' }
  })
  const [loading, setLoading] = useState(false)
  const { putpostRequest } = useQuery()
  const { isDirty, errors } = formState

  const onSubmit = (data) => {
    setLoading(true)
    putpostRequest(`/api/v1/teachers/email_templates/${emailTemplate.id}/send_test`, 'POST', data, (err, jsonData) => {
      setLoading(false)
      if (err) {
        showNonSpaToast(err, false)
        return
      }

      showNonSpaToast("Email sent!", true)
      closeModal()
    })
  }

  return <>
    <DefaultButton onClick={openModal} text="Send Test" />
    <Modal isOpen={isOpen} closeModal={closeModal} >
      <h3 className="text-lg leading-6 font-medium text-gray-900 dark:text-white"> Send Test Email</h3>
      <div className='flex justify-center'>
        <div className="mt-2">
          <div className="bg-red-50 border-l-4 border-red-400 p-4">
            <div className="flex">
              <div className="flex-shrink-0">
                <svg className="h-5 w-5 text-red-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                  <path fill-rule="evenodd" d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z" clip-rule="evenodd" />
                </svg>
              </div>
              <div className="ml-3">
                <p className="text-sm text-red-700"> Did you save your email template? </p>
              </div>
            </div>
          </div>
          <div>
            <div className="prose-product-lg">
              <h5 className="flex justify-between text-gray-800 dark:text-gray-100"><label className="mb-1">Email address</label></h5>
            </div>
            <div className="prose-product-sm flex items-center sm:prose-product-lg">
              <input type="text" className={errors.email ? 'errors placeholder-shown:italic placeholder-gray-600 p2 py-3.5 px-4 w-full border rounded-lg' : 'placeholder-shown:italic placeholder-gray-600 p2 py-3.5 px-4 w-full border rounded-lg'} {...register('email', { required: true }) } placeholder="hello@closingcredits.com" />
            </div>

            { errors.email && <div className='mt-2 text-sm text-red-600'>This field is required.</div> }
          </div>

          <div className='flex gap-x-5 mt-6'>
            <PrimaryButton onClick={handleSubmit(onSubmit)} loading={loading} text="Send Test Email" />
            <DefaultButton onClick={closeModal} text="Cancel" />
          </div>
        </div>
      </div>
    </Modal>
  </>
}

const EmailTemplateEdit = ({emailTemplate, courseId, eventId, schoolId}) => {
  const [showForm, setShowForm] = useState(false)
  const [selectedCourseId, setSelectedCourseId] = useState(courseId)
  const [selectedEventId, setSelectedEventId] = useState(eventId)
  const [courses, setCourses] = useState([])
  const [cohorts, setCohorts] = useState([])
  const [events, setEvents] = useState([])
  const [ticketTypes, setTicketTypes] = useState([])
  const [confirmDelete, setConfirmDelete] = useState(false)
  const { putpostRequest, getRequest } = useQuery()
  const { formState, reset, register, unregister, handleSubmit, setValue, watch, getValues, setError } = useForm({
   defaultValues: {
      subject: emailTemplate.subject,
      body: emailTemplate.body,
      interval_unit: emailTemplate.interval_unit,
      interval_value: emailTemplate.interval_value,
      status: emailTemplate.status === 'active'
    }
  })
  const { isDirty, errors } = formState
  const [loading, setLoading] = useState(false)
  const [loadingDynamic, setLoadingDynamic] = useState(false)
  const [manualDirty, setManualDirty] = useState(false)
  const dropdownCourseId = watch('dropdownCourseId')
  const dropdownEventId = watch('dropdownEventId')

  const showCopiedNotification = () => { showNonSpaToast("Copied to your clipboard", true) }

  const handleUpdateSwitch = (key) => {
    setValue(key, !getValues()[key], { shouldDirty: true, shouldValidate: true })
  }

  const handleUpdateList = (val) => {
    if (val === '---') { val = null }
    setValue('list', val, { shouldDirty: true, shouldValidate: true })
    setManualDirty(true)
  }

  const handleUpdateFroala = (key, val) => {
    setValue(key, val, { shouldDirty: true, shouldValidate: true })
    setManualDirty(true)
  }

  useEffect(() => {
    fetchCourses()
    fetchEvents()
  },[])

  useEffect(() => {
    if (typeof dropdownCourseId === 'undefined' || dropdownCourseId === null) { return }
    setManualDirty(true)
    setLoadingDynamic(true)
    setValue('list', null, { shouldDirty: false, shouldValidate: false })

    getRequest(`/api/v1/teachers/courses/${dropdownCourseId}/cohorts`, {}, (err, jsonData) => {
      setLoadingDynamic(false)
      if (err) { return }
      setCohorts(jsonData.cohorts)
    })
  },[dropdownCourseId])

  useEffect(() => {
    if (typeof dropdownEventId === 'undefined' || dropdownEventId === null) { return }
    setManualDirty(true)
    setLoadingDynamic(true)
    setValue('list', null, { shouldDirty: false, shouldValidate: false })

    getRequest(`/api/v1/teachers/events/${dropdownEventId}/ticket_types`, {}, (err, jsonData) => {
      setLoadingDynamic(false)
      if (err) { return }
      setTicketTypes(jsonData.ticketTypes)
    })
  },[dropdownEventId])

  const fetchCourses = () => {
    getRequest(`/api/v1/teachers/schools/${schoolId}/courses`, {}, (err, jsonData) => {
      if (err) { return }
      setValue('dropdownCourseId', selectedCourseId, { shouldDirty: false, shouldValidate: true })
      if (courseId) {
        setCourses(jsonData.courses.filter((c) => c.id === courseId))
        setValue('courseOrEvent', 'course', { shouldDirty: false, shouldValidate: true })
      } else {
        setCourses(jsonData.courses)
      }
    })
  }

  const fetchEvents = () => {
    getRequest(`/api/v1/teachers/schools/${schoolId}/events`, {}, (err, jsonData) => {
      if (err) { return }
      setValue('dropdownEventId', selectedEventId, { shouldDirty: false, shouldValidate: true })
      if (eventId) {
        setEvents(jsonData.events.filter((c) => c.id === eventId))
        setValue('courseOrEvent', 'event', { shouldDirty: false, shouldValidate: true })
      } else {
        setEvents(jsonData.events)
      }
    })
  }

  useEffect(() => {
    register('body', { required: true })
    register('status')
    if (emailTemplate.kind === 'onetime') { register('list', { required: true }) }
    return () => {
      unregister('body')
      unregister('status')
      if (emailTemplate.kind === 'onetime') { unregister('list') }
    }
  }, [register])

  const onSubmit = (data) => {
    setManualDirty(false)
    let formData = {
      email_template: {
        subject: data.subject,
        body: data.body,
        status: data.status,
        interval_unit: data.interval_unit,
        list: data.list,
        interval_value: data.interval_value
      }
    }
    setLoading(true)
    putpostRequest(`/api/v1/teachers/email_templates/${emailTemplate.id}`, 'PATCH', formData, (err, jsonData) => {
      setLoading(false)
      if (err) {
        showNonSpaToast("Error", false)
        if (err.slug) {
          if (err.slug[0] === 'has already been taken') { setError('slug', { type: 'custom', message: 'unique' }) }
          if (err.slug[0] !== 'has already been taken') { setError('slug', { type: 'custom', message: 'format' }) }
        }
        return
      }

      showNonSpaToast("Template Saved", true)
    })
  }

  const destroy = () => {
    setLoading(true)
    putpostRequest(`/api/v1/teachers/email_templates/${emailTemplate.id}`, 'DELETE', {}, (err, jsonData) => {
      setLoading(false)
      if (err) { // 422 code
        if (typeof err !== 'string') { // 500 code on API
          Object.entries(err).forEach(([key, value]) => {
            setError(key, value)
          })
        } else {
          showNonSpaToast(err, false)
        }
        return
      }

      window.location.href = `/teachers/courses`
    })
  }

  const body = getValues().body
  const courseOrEvent = watch('courseOrEvent')
  const status = watch('status')

  return <form>
    <div className="p-8 pt-5">
      <div className="space-y-6">
        { emailTemplate?.kind === 'onetime' && <>
          <div className="prose-product-lg">
            <h5 className="flex justify-between text-gray-800 dark:text-gray-200"><label className="mb-1">Which List?</label></h5>
            { errors.list && <div className='mt-2 text-sm text-red-600'>This field is required.</div> }
            { emailTemplate.email_sent_at_utc && <div className="bg-green-50 border-l-4 border-green-400 p-4">
              <div className="flex">
                <div className="flex-shrink-0">
                  <svg className="h-5 w-5 text-green-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                    <path fill-rule="evenodd" d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z" clip-rule="evenodd" />
                  </svg>
                </div>
                <div className="ml-3">
                  <p className="text-sm text-green-700"> Sent to list {emailTemplate.list} on {emailTemplate.email_sent_at_utc} </p>
                </div>
              </div>
            </div> }
          </div>
          { courseId && <div className="bg-yellow-50 border-l-4 border-yellow-400 p-4">
            <div className="flex">
              <div className="flex-shrink-0">
                <svg className="h-5 w-5 text-yellow-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                  <path fill-rule="evenodd" d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z" clip-rule="evenodd" />
                </svg>
              </div>
              <div className="ml-3">
                <p className="text-sm text-yellow-700"> This email is locked to this course. If you want to send an email to an event, go to the <a href='/teachers/events'>Manage Events</a> page. </p>
              </div>
            </div>
          </div> }
          { eventId && <div className="bg-yellow-50 border-l-4 border-yellow-400 p-4">
            <div className="flex">
              <div className="flex-shrink-0">
                <svg className="h-5 w-5 text-yellow-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                  <path fill-rule="evenodd" d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z" clip-rule="evenodd" />
                </svg>
              </div>
              <div className="ml-3">
                <p className="text-sm text-yellow-700"> This email is locked to this event. If you want to send an email to a course, go to the specific course page. </p>
              </div>
            </div>
          </div> }
          <div className="grid grid-cols-3 gap-x-5">
            <div className="col-span-1">
              <select {...register('courseOrEvent', { required: true })}
                className="block w-full py-2 px-3 border border-gray-300 bg-white dark:bg-gray-900 dark:text-gray-100 rounded-md shadow-sm focus:outline-none focus:ring-cccpurple focus:border-cccpurple sm:text-sm"
              >
                <option value={null}>---</option>
                { !eventId && <option value={'course'}>Specific Course</option> }
                { !courseId && <option value={'event'}>Specific Event</option> }
              </select>
            </div>
            { courseOrEvent === 'course' && <>
              <div className="col-span-1">
                <select {...register('dropdownCourseId')}
                  className="block w-full py-2 px-3 border border-gray-300 bg-white dark:bg-gray-900 dark:text-gray-100 rounded-md shadow-sm focus:outline-none focus:ring-cccpurple focus:border-cccpurple sm:text-sm"
                >
                  <option value={null}>---</option>
                  { courses.map((course) => (
                    <option key={`courseid${course.id}`} value={course.id}>{course.name}</option>
                  ))}
                </select>
              </div>
              { dropdownCourseId !== null && !loadingDynamic && <div className="col-span-1">
                <select
                  onChange={(e) => handleUpdateList(e.target.value)}
                  className="block w-full py-2 px-3 border border-gray-300 bg-white dark:bg-gray-900 dark:text-gray-100 rounded-md shadow-sm focus:outline-none focus:ring-cccpurple focus:border-cccpurple sm:text-sm"
                >
                  <option value={null}>---</option>
                  <option value={`alumni_course_id${dropdownCourseId}`}>All alumni</option>
                  <option value={`waitlist_course_id${dropdownCourseId}`}>Waitlist</option>
                  { cohorts && cohorts.length > 0 && cohorts.map((cohort) => (
                    <option key={`cohort_id${cohort.id}`} value={`cohort_id${cohort.id}`}>{cohort.moniker}</option>
                  ))}
                </select>
              </div> }
            </> }
            { courseOrEvent === 'event' && <>
              <div className="col-span-1">
                <select {...register('dropdownEventId')}
                  className="block w-full py-2 px-3 border border-gray-300 bg-white dark:bg-gray-900 dark:text-gray-100 rounded-md shadow-sm focus:outline-none focus:ring-cccpurple focus:border-cccpurple sm:text-sm"
                >
                  <option value={null}>---</option>
                  { events.map((event) => (
                    <option key={`event_id${event.id}`} value={event.id}>{event.name}</option>
                  ))}
                </select>
              </div>
              { dropdownEventId !== null && !loadingDynamic && <div className="col-span-1">
                <select
                  onChange={(e) => handleUpdateList(e.target.value)}
                  className="block w-full py-2 px-3 border border-gray-300 bg-white dark:bg-gray-900 dark:text-gray-100 rounded-md shadow-sm focus:outline-none focus:ring-cccpurple focus:border-cccpurple sm:text-sm"
                >
                  <option value={null}>---</option>
                  <option value={`allAttendees_event_id${dropdownEventId}`}>All attendees</option>
                  { ticketTypes.map((tt) => (
                    <option key={`ttid${tt.id}`} value={`ticket_type_id${tt.id}`}>{tt.name}</option>
                  ))}
                </select>
              </div> }
            </> }
          </div>
        </> }
        { emailTemplate?.kind !== 'onetime' && <>
          <div>
            <div className='mt-3 grid grid-cols-3'>
              <Switch.Group as="div" className="col-span-1 flex items-center justify-between mb-2">
                <Switch.Label as="span" className="flex-grow flex flex-col" >
                  <div className="prose-product-lg">
                    <h5 className="flex justify-between text-gray-800 dark:text-gray-200"><label className="mb-1">Active</label></h5>
                  </div>
                </Switch.Label>
                <Switch checked={status} onChange={() => handleUpdateSwitch('status')}
                  className={classNames(status ? 'bg-clcpurple' : 'bg-gray-300 dark:bg-gray-500',
                    'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-clcpurple')} >
                  <span className="sr-only" hidden>Email is active</span>
                  <span aria-hidden="true" className={classNames(status ? 'translate-x-5' : 'translate-x-0', 'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200')}
                  />
                </Switch>
              </Switch.Group>
            </div>
            { !status && <div className='mt-2 text-sm text-yellow-600'>This email will not be sent out.</div> }
          </div>
          <div>
            <div className="prose-product-lg">
              <h5 className="flex justify-between text-gray-800 dark:text-gray-200"><label className="mb-1">Trigger</label></h5>
            </div>
            <div className="grid grid-cols-6 gap-x-5">
              <div className="col-span-1">
                <select {...register('interval_value', { required: true })}
                  className="block w-full py-2 px-3 border border-gray-300 bg-white dark:bg-gray-900 dark:text-gray-100 rounded-md shadow-sm focus:outline-none focus:ring-cccpurple focus:border-cccpurple sm:text-sm"
                >
                  { [...Array(60).keys()].map((time) => (
                    <option key={time} value={time}>{time}</option>
                  ))}
                </select>
              </div>
              <div className="col-span-1">
                <select {...register('interval_unit', { required: true })}
                  className="block w-full py-2 px-3 border border-gray-300 bg-white dark:bg-gray-900 dark:text-gray-100 rounded-md shadow-sm focus:outline-none focus:ring-cccpurple focus:border-cccpurple sm:text-sm"
                >
                  <option>minutes</option>
                  <option>hours</option>
                  <option>days</option>
                </select>
              </div>
              { emailTemplate?.kind === 'waitlist' && <div className="col-span-3 flex items-center justify-start text-gray-800 dark:text-gray-200">after someone joins the wailist</div> }
              { emailTemplate?.kind === 'activity' && <div className="col-span-3 flex items-center justify-start text-gray-800 dark:text-gray-200">after someone is enrolled</div> }
            </div>
          </div>
        </> }
        <div>
          <div className="prose-product-lg">
            <h5 className="flex justify-between text-gray-800"><label className="mb-1">Subject Line</label></h5>
          </div>
          <div className="prose-product-sm flex items-center sm:prose-product-lg">
            <input type="text" className={errors.subject ? 'errors placeholder-shown:italic placeholder-gray-600 p2 py-3.5 px-4 w-full border rounded-lg' : 'placeholder-shown:italic placeholder-gray-600 p2 py-3.5 px-4 w-full border rounded-lg'} {...register('subject', { required: true }) } defaultValue={emailTemplate.subject} placeholder="Public Speaking 101" />
          </div>

          { errors.subject && <div className='mt-2 text-sm text-red-600'>This field is required.</div> }
        </div>

        { courseOrEvent === 'course' && <div className='flex gap-x-8'>
          <div>
            <CopyToClipboard text="{{ CourseName }}" onCopy={showCopiedNotification} >
              <span className='flex flex-start rounded-lg bg-white dark:bg-gray-900 text-gray-700 dark:text-gray-200 p-2 cursor-pointer'>
                <div><ClipboardCopyIcon className="h-5 w-5 mr-1 cursor-pointer" aria-hidden="true" /></div>
                <div className='text-sm'>&#123;&#123; CourseName &#125;&#125;</div>
              </span>
            </CopyToClipboard>
          </div>
          <div>
            <CopyToClipboard text="{{ StudentName }}" onCopy={showCopiedNotification} >
              <span className='flex flex-start rounded-lg bg-white dark:bg-gray-900 text-gray-700 dark:text-gray-200 p-2 cursor-pointer'>
                <div><ClipboardCopyIcon className="h-5 w-5 mr-1 cursor-pointer" aria-hidden="true" /></div>
                <div className='text-sm'>&#123;&#123; StudentName &#125;&#125;</div>
              </span>
            </CopyToClipboard>
          </div>
          { emailTemplate?.kind !== 'onetime' && <>
            <div>
              <CopyToClipboard text="{{ CohortStartDate }}" onCopy={showCopiedNotification} >
                <span className='flex flex-start rounded-lg bg-white dark:bg-gray-900 text-gray-700 dark:text-gray-200 p-2 cursor-pointer'>
                  <div><ClipboardCopyIcon className="h-5 w-5 mr-1 cursor-pointer" aria-hidden="true" /></div>
                  <div className='text-sm'>&#123;&#123; CohortStartDate &#125;&#125;</div>
                </span>
              </CopyToClipboard>
            </div>
          </> }
          <div>
            <CopyToClipboard text="{{ CourseLink }}" onCopy={showCopiedNotification} >
              <span className='flex flex-start rounded-lg bg-white dark:bg-gray-900 text-gray-700 dark:text-gray-200 p-2 cursor-pointer'>
                <div><ClipboardCopyIcon className="h-5 w-5 mr-1 cursor-pointer" aria-hidden="true" /></div>
                <div className='text-sm'>&#123;&#123; CourseLink &#125;&#125;</div>
              </span>
            </CopyToClipboard>
          </div>
        </div>}

        <div>
          <div className="prose-product-sm flex justify-between sm:prose-product-lg">
            <div className="prose-product-lg">
              <h5 className="flex justify-between text-gray-800"><label className="mb-1">Email body</label></h5>
            </div>
          </div>
          <div className="prose-product-sm flex items-center sm:prose-product-lg">
            { errors.body && <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
              <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
            </div> }
            <FroalaMinimalWrapper
              hideButtons={false}
              showCharacterCounter={false}
              model={body}
              updateModel={(val) => handleUpdateFroala('body', val)}
            />
          </div>
          { errors.body && <div className='mt-2 text-sm text-red-600'>This field is required.</div> }
        </div>
        <div className='flex gap-x-5'>
          { !emailTemplate.email_sent_at_utc && !confirmDelete && <div onClick={() => setConfirmDelete(true)} className='text-red-500 text-sm cursor-pointer'>Delete</div> }
          {confirmDelete && <div onClick={destroy} className='text-red-500 text-sm cursor-pointer'>Really delete?</div> }
          { !emailTemplate.email_sent_at_utc && <PrimaryButton onClick={handleSubmit(onSubmit)} loading={loading} text="Save" /> }
          { !manualDirty && <SendTest emailTemplate={emailTemplate} /> }
          { emailTemplate.kind === 'onetime' && !emailTemplate.email_sent_at_utc && !manualDirty && <SendNow emailTemplate={emailTemplate} /> }
        </div>
      </div>
    </div>
  </form>
}

export default EmailTemplateEdit

import React, { useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useParams, useLocation } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { debounce } from 'throttle-debounce'
import { ExclamationCircleIcon, EmojiHappyIcon, LinkIcon, DuplicateIcon } from '@heroicons/react/solid'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import DatePicker from 'react-datepicker'
import { format } from 'date-fns-tz'
import { showNonSpaToast } from '@/utils/nonSpaToast'

import EventImage from '@/pages/teachers/EventImage'
import FroalaWrapper from '@/shared/FroalaWrapper'
import { useGlobalState } from '@/state'
import HostForm from '@/pages/teachers/HostForm'
import useQuery from '@/hooks/useQuery'
import { randomToastSuccess } from '@/shared/Toast'
import Tippy from '@tippyjs/react'
import ToolTip from '@/shared/ToolTip'
import Loading from '@/shared/Loading'
import Card from '@/shared/Card'
import PrimaryButton from '@/shared/Buttons/Primary'
import DefaultButton from '@/shared/Buttons/Default'

const startTimes = [ '00:00', '00:30', '01:00', '01:30', '02:00', '02:30', '03:00', '03:30', '04:00', '04:30',
  '05:00', '05:30', '06:00', '06:30', '07:00', '07:30', '08:00', '08:30', '09:00', '09:30', '10:00', '10:30',
  '11:00', '11:30', '12:00', '12:30' ]

const howManySessions = [...Array(20).keys()]
const daysOfWeek = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']

const EventSeriesNew = ({course}) => {
  const [ currentUser ] = useGlobalState('currentUser')
  let history = useHistory()
  const [, setToast] = useGlobalState('toast')
  const [timezones, setTimezones] = useState([])
  const [eventHosts, setEventHosts] = useState([])
  const [zoomAccounts, setZoomAccounts] = useState([])
  const [cohorts, setCohorts] = useState([])
  const [hosts, setHosts] = useState([])
  const [whenDays, setWhenDays] = useState(['Tue'])
  const [startDate, setStartDate] = useState(new Date())
  const { putpostRequest, getRequest } = useQuery()
  const { formState, reset, register, unregister, handleSubmit, setValue, watch, getValues, setError } = useForm({
   defaultValues: {
      start_date: new Date(),
      start_time: '09:00',
      timezone: 'America/New_York',
      how_many_sessions: 6,
      duration: 60,
      kind: 'Class Session',
      ampm: 'AM'
    }
  })

  const { isDirty, errors } = formState
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    register('start_date', { required: true })
    register('when_days', { required: true })
    return () => {
      unregister('start_date')
      unregister('when_days')
    }
  }, [register])

  useEffect(() => {
    setValue('start_date', format(startDate, 'yyyy-MM-dd'), { shouldDirty: true, shouldValidate: true })
  }, [startDate])

  useEffect(() => {
    setValue('when_days', whenDays, { shouldDirty: true, shouldValidate: true })
  }, [whenDays])

  useEffect(() => {
    getRequest(`/api/v1/teachers/courses/${course.id}/zoom_accounts`, {}, (err, jsonData) => {
      if (err) { /* handled in hook */ return }

      setZoomAccounts(jsonData.zoomAccounts)
    })
    getRequest(`/api/v1/teachers/courses/${course.id}/cohorts`, {}, (err, jsonData) => {
      if (err) { /* handled in hook */ return }

      setCohorts(jsonData.cohorts)
    })
    getRequest(`/api/v1/teachers/hosts`, {school_id: course.school_id}, (err, jsonData) => {
      if (err) { /* handled in hook */ return }

      setHosts(jsonData.hosts)
    })
    getRequest('/api/v1/static/timezones', {}, (err, jsonData) => {
      if (err) { /* handled in hook */ return }

      if (jsonData.timezones) {
        setTimezones(jsonData.timezones)
        setValue('timezone', 'America/New_York', { shouldDirty: false, shouldValidate: false })
      }
    })
  }, [])
  
  const addHost = () => {
    setLoading(true)
    const data = {event_id: event.id}
    putpostRequest(`/api/v1/teachers/hosts`, 'POST', data, (err, jsonData) => {
      setLoading(false)
      console.log(err)
      if (err) {
        showNonSpaToast(err, false)
        return
      }
      setHosts([...hosts, jsonData.host ])
      setEventHosts([...eventHosts, jsonData.eventHost ])
    })
  }

  const addEventHost = (e) => {
    const newHost = hosts.find((h) => h.id === parseInt(e.target.value))
    setEventHosts([...eventHosts, newHost ])
  }



  const updateWhenDays = (e, option) => {
    if (whenDays.includes(option)) {
      const oidx = whenDays.indexOf(option)
      whenDays.splice(oidx,1)
      const newdays = whenDays.slice()
      setWhenDays(newdays)
    } else {
      setWhenDays([...whenDays, option])
    }
  }

  const onSubmit = debounce(300, data => {
    setLoading(true)

    const formData = {
      course_id: course.id,
      host_ids: eventHosts.map((eh) => eh.id),
      ...data
    }

    putpostRequest(`/api/v1/teachers/events/create_series`, 'POST', { series: formData }, (err, jsonData) => {
      setLoading(false)
      if (err) { // 422 code
        if (typeof err !== 'string') { // 500 code on API
          Object.entries(err).forEach(([key, value]) => {
            setError(key, value)
          })
        }
        return
      }

      showNonSpaToast("Creating Events", true)
      window.location.href = `/teachers/courses/${course.id}/events`
    })
  })

  const removeEventHost = (hostId) => {
    const newEventHosts = eventHosts.filter((eh) => eh.id !== hostId)
    setEventHosts(newEventHosts)
  }

  return (
    <form className='mt-6'>
      <div className='mt-2 text-lg font-semibold text-gray-900 dark:text-gray-200'>Create a series of events. You will be able to edit each event individually afterwards.</div>
      <div className='p-2'> <PrimaryButton onClick={handleSubmit(onSubmit)} loading={loading} text="Create Events" /> </div>
      <div className="grid grid-cols-3 gap-6">

        <div className="col-span-3 sm:col-span-2">
          <label htmlFor="display_name" className="block text-sm font-medium text-gray-700 dark:text-gray-300 flex justify-between">
            <span>Series Name</span>
            <Tippy interactive={true} interactiveBorder={20} content={<ToolTip>Required Field</ToolTip>}>
              <span onClick={(e) => e.preventDefault() }><ExclamationCircleIcon className="h-5 w-5 text-gray-400" aria-hidden="true" /></span>
            </Tippy>
          </label>
          <div className='mt-2 text-xs text-gray-400 dark:text-gray-500'>We will generate your event names from your series. Example: Series Name - Session 01</div>
          <div className="mt-1 flex flex-col rounded-md shadow-sm">
            <div className='relative'>
              <input type="text" className={errors.name ? 'errors' : ''} {...register('name', { required: true }) } placeholder="The health benefits of using a bidet" />
              { errors.name && <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
              </div> }
            </div>
          </div>
          { errors.name && <div className='mt-2 text-sm text-red-600'>This field is required.</div> }
        </div>
        <div className='col-span-1'></div>
        <div className="col-span-3">
        </div>

        <div className='col-span-1'>
          <div className="col-span-6 sm:col-span-3">
            <label htmlFor="country" className="block text-sm font-medium text-gray-700 dark:text-gray-200 flex justify-between">
              <span>How many events will this series have?</span>
              <Tippy interactive={true} interactiveBorder={20} content={<ToolTip>Required Field</ToolTip>}>
                <span onClick={(e) => e.preventDefault() }><ExclamationCircleIcon className="h-5 w-5 text-gray-400" aria-hidden="true" /></span>
              </Tippy>
            </label>
            <select
              {...register('how_many_sessions', { required: true })}
              className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white dark:bg-gray-900 dark:text-gray-100 rounded-md shadow-sm focus:outline-none focus:ring-cccpurple focus:border-cccpurple sm:text-sm"
            >
              { howManySessions.map((time) => (
                <option key={`howmany${time}`} value={time+1}>{time+1} sessions</option>
              ))}
            </select>
          </div>
        </div>

        <div className="col-span-3">
          <label htmlFor="country" className="block text-sm font-medium text-gray-700 dark:text-gray-200 flex justify-between">
            <span>What days of the week?</span>
            <Tippy interactive={true} interactiveBorder={20} content={<ToolTip>Required Field</ToolTip>}>
              <span onClick={(e) => e.preventDefault() }><ExclamationCircleIcon className="h-5 w-5 text-gray-400" aria-hidden="true" /></span>
            </Tippy>
          </label>
          <div className='mt-2 text-xs text-gray-400 dark:text-gray-500'>If your series does not follow a pattern, please create individual events.</div>
          <div className="row mx-0 mt-6" data-cy="days-of-week-input">
             <div className="grid w-full grid-cols-7 overflow-hidden rounded-lg border bg-white">
               { daysOfWeek.map((day) => (
                 <div key={`day${day}`} onClick={(e) => updateWhenDays(e, day)} className={`flex cursor-pointer items-center justify-center px-2 py-3  ${whenDays.includes(day) ? 'bg-clcnavy text-white' : 'hover:bg-gray-100 text-gray-dark'}`}><span className="align-middle text-sm font-medium">{day}</span></div>
               ))}
             </div>
          </div>
          { errors.when_days && <div className='mt-2 text-sm text-red-600'>This field is required.</div> }
        </div>

        <div className="col-span-3 sm:col-span-1">
          <label htmlFor="display_name" className="block text-sm font-medium text-gray-700 dark:text-gray-300 flex justify-between">
            <span>When is the first event?</span>

            <Tippy interactive={true} interactiveBorder={20} content={<ToolTip>Required Field</ToolTip>}>
              <span onClick={(e) => e.preventDefault() }><ExclamationCircleIcon className="h-5 w-5 text-gray-400" aria-hidden="true" /></span>
            </Tippy>
          </label>
          <div className="mt-1 flex flex-col rounded-md shadow-sm">
            <div className='relative'>
              <DatePicker
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                selectsStart
                startDate={startDate}
                endDate={new Date().setMonth(startDate.getMonth() + 1)}
                minDate={new Date()}
                nextMonthButtonLabel=">"
                previousMonthButtonLabel="<"
              />
              { errors.startsAt && <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
              </div> }
            </div>
          </div>
          { errors.startsAt && <div className='mt-2 text-sm text-red-600'>This field is required.</div> }
        </div>

        <div className='col-span-1'>
          <div className="col-span-6 sm:col-span-3">
            <label htmlFor="country" className="block text-sm font-medium text-gray-700 dark:text-gray-200 flex justify-between">
              <span>Start Time</span>
              <Tippy interactive={true} interactiveBorder={20} content={<ToolTip>Required Field</ToolTip>}>
                <span onClick={(e) => e.preventDefault() }><ExclamationCircleIcon className="h-5 w-5 text-gray-400" aria-hidden="true" /></span>
              </Tippy>
            </label>
            <select
              {...register('start_time', { required: true })}
              className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white dark:bg-gray-900 dark:text-gray-100 rounded-md shadow-sm focus:outline-none focus:ring-cccpurple focus:border-cccpurple sm:text-sm"
            >
              { startTimes.map((time) => (
                <option key={time} value={time}>{time}</option>
              ))}
            </select>
          </div>
        </div>

        <div className='col-span-1'>
          <div className="col-span-6 sm:col-span-3">
            <label htmlFor="country" className="block text-sm font-medium text-gray-700 dark:text-gray-200 flex justify-between">
              <span>AM/PM</span>
              <Tippy interactive={true} interactiveBorder={20} content={<ToolTip>Required Field</ToolTip>}>
                <span onClick={(e) => e.preventDefault() }><ExclamationCircleIcon className="h-5 w-5 text-gray-400" aria-hidden="true" /></span>
              </Tippy>
            </label>
            <select
              {...register('ampm', { required: true })}
              className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white dark:bg-gray-900 dark:text-gray-100 rounded-md shadow-sm focus:outline-none focus:ring-cccpurple focus:border-cccpurple sm:text-sm"
            >
              <option>AM</option>
              <option>PM</option>
            </select>
          </div>
        </div>
        <div className='col-span-1'>
          <div className="col-span-6 sm:col-span-3">
            <label htmlFor="country" className="block text-sm font-medium text-gray-700 dark:text-gray-200 flex justify-between">
              <span>Timezone</span>
              <Tippy interactive={true} interactiveBorder={20} content={<ToolTip>Required Field</ToolTip>}>
                <span onClick={(e) => e.preventDefault() }><ExclamationCircleIcon className="h-5 w-5 text-gray-400" aria-hidden="true" /></span>
              </Tippy>
            </label>
            <select
              {...register('timezone', { required: true })}
              className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white dark:bg-gray-900 dark:text-gray-100 rounded-md shadow-sm focus:outline-none focus:ring-cccpurple focus:border-cccpurple sm:text-sm"
            >
              { timezones.map((tz) => (
                <option key={tz.computer} value={tz.computer}>{tz.human.replace(/_/g, ' ')}</option>
              ))}
            </select>
          </div>
        </div>
        <div className="col-span-3 sm:col-span-1">
          <label htmlFor="display_name" className="block text-sm font-medium text-gray-700 dark:text-gray-300 flex justify-between">
            <span>Duration in Minutes</span>

            <Tippy interactive={true} interactiveBorder={20} content={<ToolTip>Required Field</ToolTip>}>
              <span onClick={(e) => e.preventDefault() }><ExclamationCircleIcon className="h-5 w-5 text-gray-400" aria-hidden="true" /></span>
            </Tippy>
          </label>
          <div className="mt-1 flex flex-col rounded-md shadow-sm">
            <div className='relative'>
              <input type="number" className={errors.duration ? 'errors' : ''} {...register('duration', { required: true }) } />
              { errors.duration && <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
              </div> }
            </div>
          </div>
          { errors.duration && <div className='mt-2 text-sm text-red-600'>This field is required.</div> }
        </div>

        <div className="col-span-3"> </div>

        <div className='col-span-2'>
          <div className="col-span-6 sm:col-span-3">
            <label htmlFor="country" className="block text-sm font-medium text-gray-700 dark:text-gray-200 flex justify-between">
              <span>Event Type</span>
              <Tippy interactive={true} interactiveBorder={20} content={<ToolTip>Required Field</ToolTip>}>
                <span onClick={(e) => e.preventDefault() }><ExclamationCircleIcon className="h-5 w-5 text-gray-400" aria-hidden="true" /></span>
              </Tippy>
            </label>
            <select
              {...register('kind', {required: true})}
              className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white dark:bg-gray-900 dark:text-gray-100 rounded-md shadow-sm focus:outline-none focus:ring-cccpurple focus:border-cccpurple sm:text-sm"
            >
              <option>Class Session</option>
            </select>
            { errors.kind && <div className='mt-2 text-sm text-red-600'>This field is required.</div> }
          </div>
        </div>

        <div className='col-span-2'>
          <div className="col-span-6 sm:col-span-3">
            <label htmlFor="country" className="block text-sm font-medium text-gray-700 dark:text-gray-200 flex justify-between">
              <span>Cohort</span>
              <Tippy interactive={true} interactiveBorder={20} content={<ToolTip>Required Field</ToolTip>}>
                <span onClick={(e) => e.preventDefault() }><ExclamationCircleIcon className="h-5 w-5 text-gray-400" aria-hidden="true" /></span>
              </Tippy>
            </label>
            <div className='relative'>
              <select
                {...register('cohort_id', { required: true })}
                className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white dark:bg-gray-900 dark:text-gray-100 rounded-md shadow-sm focus:outline-none focus:ring-cccpurple focus:border-cccpurple sm:text-sm"
              >
                <option></option>
                { cohorts.map((cohort, idx) => (
                  <option key={`c${cohort.id}`} value={cohort.id}>{cohort.moniker}</option>
                ))}
              </select>
            { errors.cohort_id && <div className='mt-2 text-sm text-red-600'>This field is required.</div> }
            </div>
          </div>
        </div>

        <div className='col-span-2'>
          <div className="col-span-6 sm:col-span-3">
            <label htmlFor="country" className="block text-sm font-medium text-gray-700 dark:text-gray-200 flex justify-between">
              <div>Host</div>
              <div onClick={addHost} className='text-clcnavy cursor-pointer'>Create Host</div>
            </label>
            <select id="host-select" className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white dark:bg-gray-900 dark:text-gray-100 rounded-md shadow-sm focus:outline-none focus:ring-cccpurple focus:border-cccpurple sm:text-sm mb-3" onChange={addEventHost} >
              <option>---select host---</option>
              { hosts.map((host, idx) => (
                <option key={`h${host.id}`} value={host.id}>{host.name}</option>
              ))}
            </select>
          </div>

          <ul className="space-y-2 flex flex-col">
            { eventHosts.map((eh, index) => (
              <HostForm key={`hostf${eh.id}`} initialEventHost={eh} removeEventHost={() => removeEventHost(eh.id)} />
            ))}
          </ul>
        </div>

        <div className="col-span-3 sm:col-span-2">
          <label htmlFor="display_name" className="block text-sm font-medium text-gray-700 dark:text-gray-300 flex justify-between">
            <span>Zoom URL (or any meeting URL)</span>
          </label>
          <span className='dark:text-gray-300'>Attendees will be redirected to this link when the event starts.</span>

          <div className='relative'>
            <div className='mt-2 text-xs text-gray-400 dark:text-gray-500'>Create an event using the attached Zoom Account</div>
            <select
              {...register('zoom_account_id')}
              className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white dark:bg-gray-900 dark:text-gray-100 rounded-md shadow-sm focus:outline-none focus:ring-cccpurple focus:border-cccpurple sm:text-sm"
            >
              <option>---</option>
              { zoomAccounts.map((za, idx) => (
                <option key={`zac${za.id}`} value={za.id}>{za.name}</option>
              ))}
            </select>
          </div>
          <div className='py-2 px-5 font-semibold'>OR</div>
          <div className="flex flex-col rounded-md shadow-sm">
            <div className='relative'>
              <div className='text-xs text-gray-400 dark:text-gray-500'>Use a custom Zoom/Meeting URL</div>
              <input type="text" className={errors.zoomUrl ? 'errors' : ''} {...register('zoom_url') } placeholder="https://www.zoom.us/..." />
              { errors.zoom_url && <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
              </div> }
            </div>
            { errors.zoom_url && <div className='mt-2 text-sm text-red-600'>This field is required if publishing.</div> }
          </div>
        </div>
      </div>
      <div className='mt-6'>
        <PrimaryButton onClick={handleSubmit(onSubmit)} loading={loading} text="Create Events" />
      </div>
    </form>
  )
}

export default EventSeriesNew

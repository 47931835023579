import React, { useEffect, useState, useRef } from 'react'
import ReactDOM from 'react-dom'
import { Link, useParams, useLocation, useHistory } from 'react-router-dom'

import { useGlobalState } from '@/state'
import Breadcrumbs from '@/Layout/Breadcrumbs'
import PrimaryButton from '@/shared/Buttons/Primary'
import PageTitle from '@/Layout/PageTitle'

function breadCrumbs(content) {
  const location = useLocation()
  const activePath = location.pathname
  const crumbs = [
    { to: '/concepts', name: 'Concepts' }
  ]
  if (content) {
    crumbs.push({ to: `/library/${content?.id}-${content?.slug}`, name: content?.name })
  }
  return crumbs
}

import Loading from '@/shared/Loading'
import useQuery from '@/hooks/useQuery'

function pageTitle(content) {
  const location = useLocation()
  const activePath = location.pathname
  const titleInfo = {
     title: content?.name,
    buttons: [ ]
  }
  return titleInfo
}

const LibraryShow = () => {
  const { id } = useParams()
  const [ content, setContent ] = useState(null)
  const [ loading, setLoading ] = useState(true)
  const [ toc, setToc ] = useState([])
  let history = useHistory()
  const isComponentMounted = useRef(false)
  const contentRef = useRef(null)
  const contentId = id.split(/-/)[0]
  const { putpostRequest, getRequest } = useQuery()
  const crumbs = breadCrumbs(content)
  const titleInfo = pageTitle(content)

  useEffect(() => {
    const doSomething = (e) => {
      if (e.target.className.includes('clc-liblinks')) {
        if (e.target.getAttribute('data-to')) {
          history.push(e.target.getAttribute('data-to'))
        }
      }
    };

    window.addEventListener("click", doSomething);
    return () => {
      window.removeEventListener("click", doSomething);
    };
  }, []);

  useEffect(() => {
    setLoading(true)
    getRequest(`/api/v1/library_contents/${contentId}`, {}, (err, jsonData) => {
      setLoading(false)
      if (err) { /* handled in hook */ return }
      setContent(jsonData.content)
    })
    isComponentMounted.current = true
  }, [contentId])

  useEffect(() => {
    if (contentRef.current) {
      contentRef.current.scrollIntoView()
    }
  }, [content])

  const handleContentClick = (e) => {
    const targetLink =  e.target.closest('a')
    if (!targetLink) { return }

    const data = JSON.parse(e.target.getAttribute('data'))

    if (data?.linktype === 'internalLink') {
      const url = data.url
      e.preventDefault()
      history.push(url)
      return
    }
  }

  const InternalLink = ({url}) => {
    return <Link to={url}>Link!!</Link>
  }

  if (loading) {
    return <Loading />
  }
  return (<>
    <div className='flex flex-col' ref={contentRef}>
      <Breadcrumbs>
        { crumbs.map((crumb, index) => (
          <Link key={`crum${crumb.name}`} to={crumb.to} className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700 dark:text-gray-100">
            {crumb.name}
          </Link>
        ))}
      </Breadcrumbs>
      <PageTitle pagetitle={titleInfo.title}>
        {titleInfo.buttons}
      </PageTitle>
      <div className="flex">
        <div className='flex-1' ref={contentRef}>
          <div className="relative py-16 bg-gray-100 dark:bg-gray-700 overflow-hidden mx-auto">
            <div className="relative px-4 sm:px-6 lg:px-8 prose lg:prose-xl dark:text-gray-200">
              { !loading && <div onClick={handleContentClick} dangerouslySetInnerHTML={{__html: content?.body }} /> }
            </div>
          </div>
        </div>
        { loading && <div className='w-full min-w-32 py-16'> <Loading /> </div> }
      </div>
    </div>
  </>
  ) 
}

export default LibraryShow

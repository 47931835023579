import React from 'react'

const CreditExplainer = () => {
  return <div className="">
    <div className="text-center text-xl text-gray-700 dark:text-gray-200 pb-3 flex flex-col">
      <span>Credits are used to enroll in live instructor-led courses.</span>
      <span>They never expire, even if you don't use them this year.</span>
      <span>You can still use them even if you're not a paying member anymore.</span>
    </div>
    <div className="text-center text-xl text-gray-700 dark:text-gray-200 pb-3">
      Sample Courses Below. For actual course prices, <a href='/courses'>Browse Courses</a>
    </div>
    <div className="relative">
      <div className="relative z-10 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="max-w-md mx-auto space-y-4 lg:max-w-5xl lg:grid lg:grid-cols-2 lg:gap-5 lg:space-y-0">
          <div className="flex flex-col border border-gray-200 rounded-2xl  overflow-hidden">
            <div className="px-6 bg-white dark:bg-gray-800">
              <div className='text-center py-3'>
                <h3 className="inline-flex px-4 py-1 font-semibold tracking-wide uppercase" id="tier-standard"> 15-Credit Courses </h3>
                <p className='text-gray-400'>6 weeks</p>
              </div>
            </div>
            <div className="flex-1 flex flex-col justify-between px-5 py-2 bg-gray-50 dark:bg-gray-750">
              <ul className="space-y-2">
                <li className="flex items-center">
                  <div className="flex-shrink-0">
                    <img src="https://ccc-emails.s3.amazonaws.com/clc/details-voice-acting.svg" className='h-10 w-10' alt='Voice Acting 101' />
                  </div>
                  <p className="ml-3 text-base text-gray-700 dark:text-gray-200">
                    Voice Acting 101
                  </p>
                </li>
                <li className="flex items-center">
                  <div className="flex-shrink-0">
                    <img src="https://ccc-emails.s3.amazonaws.com/clc/details-voice-acting.svg" className='h-10 w-10' alt='Business for Voice Actors' />
                  </div>
                  <p className="ml-3 text-base text-gray-700 dark:text-gray-200">
                    Business for Voice Actors
                  </p>
                </li>
                <li className="flex items-center">
                  <div className="flex-shrink-0">
                    <img src="https://ccc-emails.s3.amazonaws.com/clc/details-music.svg" className='h-10 w-10' alt='Music Composition' />
                  </div>
                  <p className="ml-3 text-base text-gray-700 dark:text-gray-200">
                    Music Composition
                  </p>
                </li>
                <li className="flex items-center">
                  <div className="flex-shrink-0">
                    <img src="https://ccc-emails.s3.amazonaws.com/clc/details-audio-engineering.svg" className='h-10 w-10' alt='Demo Production' />
                  </div>
                  <p className="ml-3 text-base text-gray-700 dark:text-gray-200">
                    Demo Engineering
                  </p>
                </li>
                <li className="flex items-center">
                  <div className="flex-shrink-0">
                    <img src="https://ccc-emails.s3.amazonaws.com/clc/details-manga.svg" className='h-10 w-10' alt='Creating Manga and Webcomics' />
                  </div>
                  <p className="ml-3 text-base text-gray-700 dark:text-gray-200">
                    Creating Manga and Webcomics
                  </p>
                </li>
                <li className="flex items-center">
                  <div className="flex-shrink-0">
                    <img src="https://ccc-emails.s3.amazonaws.com/clc/details-improv.svg" className='h-10 w-10' alt='Improv' />
                  </div>
                  <p className="ml-3 text-base text-gray-700 dark:text-gray-200">
                    Improv
                  </p>
                </li>
              </ul>
            </div>
          </div>

          <div className="flex flex-col border border-gray-200 rounded-2xl overflow-hidden">
            <div className="px-6 bg-white dark:bg-gray-800">
              <div className='text-center py-3'>
                <h3 className="inline-flex px-4 py-1 font-semibold tracking-wide uppercase" id="tier-standard"> 30-Credit Courses </h3>
                <p className='text-gray-400'>12 weeks</p>
              </div>
            </div>
            <div className="flex-1 flex flex-col justify-between px-5 py-2 pb-8 bg-gray-50 dark:bg-gray-750">
              <ul className="space-y-2">
                <li className="flex items-center">
                  <div className="flex-shrink-0">
                    <img src="https://ccc-emails.s3.amazonaws.com/clc/details-voice-acting.svg" className='h-10 w-10' alt='Voice Acting 201' />
                  </div>
                  <p className="ml-3 text-base text-gray-700 dark:text-gray-200">
                    Voice Acting 201
                  </p>
                </li>
                <li className="flex items-center">
                  <div className="flex-shrink-0">
                    <img src="https://ccc-emails.s3.amazonaws.com/clc/details-voice-acting.svg" className='h-10 w-10' alt='Voice Acting 301' />
                  </div>
                  <p className="ml-3 text-base text-gray-700 dark:text-gray-200">
                    Voice Acting 301
                  </p>
                </li>
                <li className="flex items-center">
                  <div className="flex-shrink-0">
                    <img src="https://ccc-emails.s3.amazonaws.com/clc/details-improv.svg" className='h-10 w-10' alt='Mastering Characters' />
                  </div>
                  <p className="ml-3 text-base text-gray-700 dark:text-gray-200">
                    Mastering Characters
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
}

export default CreditExplainer

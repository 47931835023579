import React, { useEffect, useState, useRef } from 'react'
import { Link, useParams } from 'react-router-dom'

import Loading from '@/shared/Loading'
import useQuery from '@/hooks/useQuery'
import { useGlobalState } from '@/state'

const courseImageMap = {
  'Voice Acting 101': 'https://ccc-emails.s3.amazonaws.com/clc/details-voice-acting.svg',
  'Voice Acting 201': 'https://ccc-emails.s3.amazonaws.com/clc/details-voice-acting.svg',
  'Voice Acting 301': 'https://ccc-emails.s3.amazonaws.com/clc/details-voice-acting.svg',
  'Business for Voice Actors': 'https://ccc-emails.s3.amazonaws.com/clc/details-voice-acting.svg',
  'Audio Engineering': 'https://ccc-emails.s3.amazonaws.com/clc/details-audio-engineering.svg',
  'Demo Engineering': 'https://ccc-emails.s3.amazonaws.com/clc/details-audio-engineering.svg',
  'Creating Manga and Webcomics': 'https://ccc-emails.s3.amazonaws.com/clc/details-manga.svg',
  'Music Composition': 'https://ccc-emails.s3.amazonaws.com/clc/details-music.svg',
  'Improvisation': 'https://ccc-emails.s3.amazonaws.com/clc/details-improv.svg'
}

const CourseMaterialShow = () => {
  const [ currentUser ] = useGlobalState('currentUser')
  const [ , setCurrentCourse ] = useGlobalState('currentCourse')
  const { courseId } = useParams()
  const [ course, setCourse ] = useState(null)
  const [ contents, setContents ] = useState([])
  const [ cohorts, setCohorts ] = useState([])
  const [ completedUserCohorts, setCompletedUserCohorts ] = useState([])
  const [ events, setEvents ] = useState([])
  const [ attendingEventIds, setAttendingEventIds ] = useState([])
  const [ loading, setLoading ] = useState(false)
  const [ allTaken, setAllTaken ] = useState(true)
  const isComponentMounted = useRef(false)
  const { putpostRequest, getRequest } = useQuery()
  console.log(courseId)

  useEffect(() => {
    setLoading(true)
    getRequest(`/api/v1/courses/course_material`, {id: courseId}, (err, jsonData) => {
      if (err) { /* handled in hook */ return }
      setCourse(jsonData.course)
      setCurrentCourse(jsonData.course)
      setContents(jsonData.contents)
      setLoading(false)
    })
    isComponentMounted.current = true
  }, [courseId])

  if (!isComponentMounted.current) { return <Loading /> }
  if (loading) { return <Loading /> }

  return (
    <div className="relative bg-gray-100 dark:bg-gray-700 pb-20 px-4 sm:px-6 pt-6">
      <div className="relative max-w-7xl mx-auto">

        {contents.length > 0 && <>
          <div className="relative my-6">
            <div className="absolute inset-0 flex items-center" aria-hidden="true">
              <div className="w-full border-t border-gray-300" />
            </div>
            <div className="relative flex justify-start">
              <span className="pr-3 bg-gray-100 text-lg font-medium text-gray-900 dark:text-gray-300 dark:bg-gray-700">Course Content</span>
            </div>
          </div>
          <div className="mt-12 max-w-lg mx-auto grid gap-5 lg:grid-cols-3 lg:max-w-none">
            { contents.map((content, idx) => (
              <span key={`mod${content.id}`}>
                <h3 className="text-clcpurple text-lg leading-6 font-medium">{content.name}</h3>
                <p className="mt-3 text-base text-gray-500">
                  {content.lessons.map((lesson, lidx) => (
                    <Link key={`less${lesson.id}`} to={`/l/${lesson.slug}`} className="flex items-center px-3 py-2 text-sm font-medium">{lesson.name}</Link>
                  ))}
                </p>
              </span>
            ))}
          </div>
        </> }
      </div>
    </div>
  ) 
}

export default CourseMaterialShow

import React from 'react'
import { Switch, Route, useLocation, Link } from 'react-router-dom'
import { ExclamationIcon } from '@heroicons/react/solid'

import PageTitle from '@/Layout/PageTitle'
import Breadcrumbs from '@/Layout/Breadcrumbs'
import { useGlobalState } from '@/state'

function breadCrumbs() {
  const location = useLocation()
  const activePath = location.pathname
  const crumbs = [
    { to: '/chat', name: 'Chat' }
  ]
  // if (activePath === '/profile/options') { crumbs.push({ to: activePath, name: 'Options' }) }
  return crumbs
}

function pageTitle() {
  const location = useLocation()
  const activePath = location.pathname
  const titleInfo = {
     title: '',
    buttons: [ ]
  }
  if (activePath === '/chat') { titleInfo.title = 'Chat' }
  return titleInfo
}

export default function ChatsContainer(props) {
  const titleInfo = pageTitle()
  const crumbs = breadCrumbs()
  const [ currentUser ] = useGlobalState('currentUser')

  // api call to grab all the data here, see EventsContainer

  return <>
    <Breadcrumbs>
      { crumbs.map((crumb, index) => (
        <Link key={crumb.name} to={crumb.to} className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700 dark:text-gray-100">
          {crumb.name}
        </Link>
      ))}
    </Breadcrumbs>
    <PageTitle pagetitle={titleInfo.title}>
      {titleInfo.buttons}
    </PageTitle>
    <Switch>
      <Route {...props} exact path='/chat'>
        <div>
          { (currentUser.access !== 'member' && currentUser.access !== 'staff') && <>
            <div className="rounded-md bg-yellow-50 p-4 mt-10">
              <div className="flex">
                <div className="flex-shrink-0">
                  <ExclamationIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
                </div>
                <div className="ml-3">
                  <div className='pb-3 text-sm'>
                    You will be able to join the chat and discussion groups regarding your course.
                  </div>
                  <h3 className="text-sm font-medium text-yellow-800">
                    If you'd like to join the member chat and discussion groups, please <Link to='/subscriptions'>upgrade to the membership</Link>.
                  </h3>
                </div>
              </div>
            </div>
          </> }
          { (currentUser.access === 'member' || currentUser.access === 'staff') && <>
            <div className="max-w-7xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8">
              <div className="text-center">
                <h2 className="text-base font-semibold text-clcpurple tracking-wide uppercase">Chat</h2>
                <p className="mt-1 text-4xl font-extrabold text-gray-900 dark:text-gray-100 sm:text-5xl sm:tracking-tight lg:text-6xl">
                  Community Chat
                </p>
                <p className="max-w-xl mt-5 mx-auto text-xl text-gray-500">
                  Join our private chat server on Discord and stay connected with all the Closing Credits members.
                </p>
                <div className='mt-6'>
                  <a target="_blank" href='https://discord.gg/ykbpN8s' className='inline-flex justify-center px-4 py-2 shadow-sm text-sm font-medium rounded-md text-white dark:hover:bg-clcnavy-light dark:text-white bg-clcnavy dark:hover:text-white hover:bg-clcnavy-alt focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900'>Start Chatting!</a>
                </div>
              </div>
            </div>
          </> }
        </div>
      </Route>
    </Switch>
  </>
}

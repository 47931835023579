// To see this message, add the following to the `<head>` section in your
// views/layouts/application.html.erb
//
//    <%= vite_client_tag %>
//    <%= vite_javascript_tag 'application' %>
console.log('Vite ⚡️ Rails')

// If using a TypeScript entrypoint file:
//     <%= vite_typescript_tag 'application' %>
//
// If you want to use .jsx or .tsx, add the extension:
//     <%= vite_javascript_tag 'application.jsx' %>

console.log('Visit the guide for more information: ', 'https://vite-ruby.netlify.app/guide/rails')

// Example: Load Rails libraries in Vite.
//
// import * as Turbo from '@hotwired/turbo'
// Turbo.start()
//
// import ActiveStorage from '@rails/activestorage'
// ActiveStorage.start()
//
// // Import all channels.
// const channels = import.meta.globEager('./**/*_channel.js')

// Example: Import a stylesheet in app/frontend/index.css
// import '~/index.css'
import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import 'react-datepicker/dist/react-datepicker.css'


import App from "@/App.jsx"
import Header from '@/Layout/Header'
import CreditPopover from '@/pages/static/CreditPopover'
import RemindMeButton from '@/shared/Buttons/RemindMeButton'
import PublicAttendButton from '@/shared/Buttons/PublicAttendButton'
import SurveyShow from '@/shared/SurveyShow'


import CohortIndex from '@/pages/teachers/CohortIndex'
import CohortEdit from '@/pages/teachers/CohortEdit'
import CourseSettings from '@/pages/teachers/CourseSettings'
import EmailTemplateEdit from '@/pages/teachers/EmailTemplateEdit'
import EventsIndex from '@/pages/teachers/EventsIndex'
import SchoolEventsIndex from '@/pages/teachers/SchoolEventsIndex'
import EventEdit from '@/pages/teachers/EventEdit'
import EventSeriesNew from '@/pages/teachers/EventSeriesNew'
import LandingPage from '@/pages/teachers/LandingPage'
import LaunchStep1 from '@/pages/teachers/LaunchStep1'
import LaunchStep2 from '@/pages/teachers/LaunchStep2'
import LaunchStep3 from '@/pages/teachers/LaunchStep3'
import SurveyEdit from '@/pages/teachers/SurveyEdit'
import StudentsIndex from '@/pages/teachers/StudentsIndex'
import SchoolEdit from '@/pages/teachers/SchoolEdit'
import CohortSelector from '@/pages/teachers/CohortSelector'
import LessonEdit from '@/pages/teachers/LessonEdit'
import LessonAdd from '@/pages/teachers/LessonAdd'
import PayoutsIndex from '@/pages/teachers/PayoutsIndex.jsx'
import ManageSchools from '@/pages/teachers/ManageSchools'
import ManageCourses from '@/pages/teachers/ManageCourses'
import ManageEvents from '@/pages/teachers/ManageEvents'
import MyCourses from '@/pages/MyCourses'
import MyEvents from '@/pages/MyEvents'

const attachReact = (el, cb) => {
  const node = document.getElementById(el)
  if (node) { cb(node) }
}

document.addEventListener('DOMContentLoaded', () => {
  attachReact('react-header', function (node) {
    ReactDOM.render(
      <BrowserRouter>
        <Header {...JSON.parse(node.getAttribute('data'))} />
      </BrowserRouter>, node
    )
  })
  attachReact('react-teacher-cohort-index', function (node) {
    ReactDOM.render(
      <CohortIndex {...JSON.parse(node.getAttribute('data'))} />, node
    )
  })
  attachReact('react-teacher-cohort-edit', function (node) {
    ReactDOM.render(
      <CohortEdit {...JSON.parse(node.getAttribute('data'))} />, node
    )
  })
  attachReact('react-teacher-course-settings', function (node) {
    ReactDOM.render(
      <CourseSettings {...JSON.parse(node.getAttribute('data'))} />, node
    )
  })
  attachReact('react-email-template-edit', function (node) {
    ReactDOM.render(
      <EmailTemplateEdit {...JSON.parse(node.getAttribute('data'))} />, node
    )
  })
  attachReact('react-landing-page', function (node) {
    ReactDOM.render(
      <LandingPage {...JSON.parse(node.getAttribute('data'))} />, node
    )
  })
  attachReact('react-launch-step1', function (node) {
    ReactDOM.render(
      <LaunchStep1 {...JSON.parse(node.getAttribute('data'))} />, node
    )
  })
  attachReact('react-launch-step2', function (node) {
    ReactDOM.render(
      <LaunchStep2 {...JSON.parse(node.getAttribute('data'))} />, node
    )
  })
  attachReact('react-launch-step3', function (node) {
    ReactDOM.render(
      <LaunchStep3 {...JSON.parse(node.getAttribute('data'))} />, node
    )
  })
  attachReact('react-survey-edit', function (node) {
    ReactDOM.render(
      <SurveyEdit {...JSON.parse(node.getAttribute('data'))} />, node
    )
  })
  attachReact('react-students-index', function (node) {
    ReactDOM.render(
      <StudentsIndex {...JSON.parse(node.getAttribute('data'))} />, node
    )
  })
  attachReact('react-teacher-events-index', function (node) {
    ReactDOM.render(
      <EventsIndex {...JSON.parse(node.getAttribute('data'))} />, node
    )
  })
  attachReact('react-teacher-school-events-index', function (node) {
    ReactDOM.render(
      <SchoolEventsIndex {...JSON.parse(node.getAttribute('data'))} />, node
    )
  })
  attachReact('react-teacher-event-edit', function (node) {
    ReactDOM.render(
      <EventEdit {...JSON.parse(node.getAttribute('data'))} />, node
    )
  })
  attachReact('react-teacher-event-series-new', function (node) {
    ReactDOM.render(
      <EventSeriesNew {...JSON.parse(node.getAttribute('data'))} />, node
    )
  })
  attachReact('react-school-edit', function (node) {
    ReactDOM.render(
      <SchoolEdit {...JSON.parse(node.getAttribute('data'))} />, node
    )
  })
  attachReact('react-cohort-selector', function (node) {
    ReactDOM.render(
      <CohortSelector {...JSON.parse(node.getAttribute('data'))} />, node
    )
  })
  attachReact('react-course-payouts', function (node) {
    ReactDOM.render(
      <PayoutsIndex {...JSON.parse(node.getAttribute('data'))} />, node
    )
  })
  attachReact('react-teacher-lesson-edit', function (node) {
    ReactDOM.render(
      <LessonEdit {...JSON.parse(node.getAttribute('data'))} />, node
    )
  })
  attachReact('react-teacher-lesson-add', function (node) {
    ReactDOM.render(
      <LessonAdd {...JSON.parse(node.getAttribute('data'))} />, node
    )
  })
  attachReact('react-teachers-schools', function (node) {
    ReactDOM.render(
      <ManageSchools {...JSON.parse(node.getAttribute('data'))} />, node
    )
  })
  attachReact('react-teachers-courses', function (node) {
    ReactDOM.render(
      <ManageCourses {...JSON.parse(node.getAttribute('data'))} />, node
    )
  })
  attachReact('react-teachers-events', function (node) {
    ReactDOM.render(
      <ManageEvents {...JSON.parse(node.getAttribute('data'))} />, node
    )
  })
  attachReact('react-mycourses', function (node) {
    ReactDOM.render(
      <MyCourses {...JSON.parse(node.getAttribute('data'))} />, node
    )
  })
  attachReact('react-myevents', function (node) {
    ReactDOM.render(
      <MyEvents {...JSON.parse(node.getAttribute('data'))} />, node
    )
  })

  attachReact('react-header', function (node) {
    ReactDOM.render(
      <BrowserRouter>
        <Header {...JSON.parse(node.getAttribute('data'))} />
      </BrowserRouter>, node
    )
  })
  attachReact('react-spa-root', function (node) {
    ReactDOM.render(
      <BrowserRouter>
        <App {...JSON.parse(node.getAttribute('data'))} />
      </BrowserRouter>, node
    )
  })

  Array.from(document.querySelectorAll('.react-creditpopover')).forEach(function (
    node
  ) {
    const data = JSON.parse(node.getAttribute('data'))
    ReactDOM.render(<CreditPopover {...data} />, node)
  })
  Array.from(document.querySelectorAll('.remind-me-button')).forEach(function (
    node
  ) {
    const data = JSON.parse(node.getAttribute('data'))
    ReactDOM.render(<RemindMeButton {...data} />, node)
  })
  attachReact('public-attend-button', function (node) {
    ReactDOM.render(
      <PublicAttendButton {...JSON.parse(node.getAttribute('data'))} />, node
    )
  })
  attachReact('react-survey-show', function (node) {
    ReactDOM.render(
      <SurveyShow {...JSON.parse(node.getAttribute('data'))} />, node
    )
  })
})

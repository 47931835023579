import React, {useState, useEffect, useRef} from 'react'
import useQuery from '@/hooks/useQuery'
import { useDebouncedEffect } from '@/utils/useDebouncedEffect'
import { useForm } from 'react-hook-form'
import { ExclamationCircleIcon, EmojiHappyIcon, LinkIcon, DuplicateIcon } from '@heroicons/react/solid'
import PrimaryButton from '@/shared/Buttons/Primary'

import { showNonSpaToast } from '@/utils/nonSpaToast'
import { BulletItem, CaretItem } from '@/pages/teachers/Launch/Items'

import InstructorForm from '@/pages/teachers/InstructorForm'
import Pagination from '@/shared/Pagination'

const Students = ({course}) => {
  const [users, setUsers] = useState([])
  const [cohorts, setCohorts] = useState([])
  const [meta, setMeta] = useState({})
  const [list, setList] = useState('waitlist')
  const [loading, setLoading] = useState(false)
  const { getRequest, putpostRequest } = useQuery()
  const [page, setPage] = useState(1)
  const [contains, setContains] = useState('')
  const scrollRef = useRef(null)

  useDebouncedEffect(() => { fetchData() }, 200, [contains])
  useEffect(() => { fetchData() }, [page, list])

  const fetchData = () => {
    const data = {
      contains: contains,
      list: list,
      page: contains.length > 0 ? 1 : page
    }
    getRequest(`/api/v1/teachers/courses/${course.id}/users`, data, (err, jsonData) => {
      if (err) { /* handled in hook */ return }
      setUsers(jsonData.users)
      setMeta(jsonData.meta)
    })
  }

  useEffect(() => {
    getRequest(`/api/v1/teachers/courses/${course.id}/cohorts`, {}, (err, jsonData) => {
      if (err) { /* handled in hook */ return }
      setCohorts(jsonData.cohorts)
    })
  }, [])


  const addUser = () => {
    setLoading(true)
    const data = {course_id: course.id}
    putpostRequest(`/api/v1/teachers/courses/${course.id}/adduser`, 'POST', data, (err, jsonData) => {
      setLoading(false)
      if (err) {
        showNonSpaToast(err, false)
        return
      }
      setUsers([...users, jsonData.user ])
    })
  }

  return <>
    <div className='pb-5' ref={scrollRef}>
      <input name='contains' type="text" onChange={(e) => setContains(e.target.value)}
        className="shadow-sm focus:ring-cccpurple dark:bg-gray-900 dark:text-white focus:border-cccpurple block w-full sm:text-sm border-gray-300 rounded-md mb-2"
        placeholder="Search by student name"
      />
      <div className="prose-product-lg">
        <h5 className="flex justify-between text-gray-800"><label className="mb-1">Which List?</label></h5>
      </div>
      <div className="grid grid-cols-6 gap-x-5 pb-3">
        <div className="col-span-1">
          <select onChange={(e) => setList(e.target.value)} className="block w-full py-2 px-3 border border-gray-300 bg-white dark:bg-gray-900 dark:text-gray-100 rounded-md shadow-sm focus:outline-none focus:ring-cccpurple focus:border-cccpurple sm:text-sm">
              <option value={'waitlist'}>Waitlist</option>
              <option value={'all'}>All students</option>
              { cohorts.map((cohort) => (
                <option key={`coid${cohort.id}`} value={cohort.id}>{cohort.moniker}</option>
              ))}
          </select>
        </div>
      </div>
      { users.length >= 0 && <Pagination meta={meta} callback={setPage} scrollRef={scrollRef} /> }
    </div>
    <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
      <table className="min-w-full divide-y divide-gray-200">
        <thead className="bg-gray-50">
          <tr>
            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Name
            </th>
            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Email
            </th>
            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Cohort
            </th>
          </tr>
        </thead>
        <tbody>
          { users.map((user, idx) => (
            <tr key={`teacheruser${user.id}`} className={idx % 2 === 0 ? 'bg-white' : 'bg-gray-50' }>
              <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                {user.displayName}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                {user.email}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                {user.cohortMoniker}
              </td>
            </tr>
          ))}

        </tbody>
      </table>
    </div>
  </>
}

const StudentsIndex = (props) => {
  const { course } = props
  return ( <>
    <Students course={course} />
  </>
  )
}

export default StudentsIndex

import React, { useState } from 'react'

import { LazyLoadImage } from 'react-lazy-load-image-component'
import { ClockIcon, CalendarIcon, FastForwardIcon, ReceiptTaxIcon } from '@heroicons/react/solid'
import { formatInTimezone } from '@/utils/date'
import { useGlobalState } from '@/state'
import useQuery from '@/hooks/useQuery'
import PrimaryButton from '@/shared/Buttons/Primary'
import DefaultButton from '@/shared/Buttons/Default'
import { Modal, useModal } from '@/shared/Modal'

const CohortRow = ({cohort, cohortInterest, updateCost, alreadyEnrolled, alreadyTaken, following}) => {
  const { putpostRequest } = useQuery()
  const [, setToast] = useGlobalState('toast')
  const [currentUser] = useGlobalState('currentUser')
  const isFreeMember = ['lead', 'beta'].includes(currentUser.access)
  const hideSelectToggle = alreadyEnrolled || alreadyTaken
  const { openModal, closeModal, isOpen } = useModal()

  const [ selected, setSelected ] = useState(cohortInterest?.includes(cohort.id))
  const [ followed, setFollowed ] = useState(following?.includes(cohort.courseId))
  const [ loading, setLoading ] = useState(false)
  const soldOut = cohort.seatsRemaining <= 0

  const toggleFollow = () => {
    setLoading(true)
    putpostRequest(`/api/v1/courses/${cohort.courseId}/follow`, 'POST', {}, (err, jsonData) => {
      if (err) { console.log('toast errors'); return }

      setLoading(false)
      const newValue = !followed
      setFollowed(newValue)
    })
  }
  const toggleSelect = () => {
    setLoading(true)
    let data = {
      cohort_id: cohort.id
    }
    putpostRequest(`/api/v1/cohort_interests/select`, 'POST', data, (err, jsonData) => {
      if (err) { console.log('toast errors'); return }

      setLoading(false)
      const newValue = !selected
      setSelected(newValue)
      if (newValue === true) {
        updateCost(cohort.creditCost)
      } else {
        updateCost(-cohort.creditCost)
      }
    })
  }
  return <>
    <div className={`${selected ? 'bg-clcnavy bg-opacity-10' : 'bg-white dark:bg-gray-800'} flex flex-col sm:flex-row items-center gap-x-3 p-0 sm:p-2 rounded-lg shadow-lg flex-wrap :flex-nowrap`}>
      <div className="min-w-0 flex-1 flex flex-col md:flex-row items-center justify-start flex-wrap sm:flex-nowrap">
        <a href={`/courses/${cohort.courseSlug}`} className='hover:bg-gray-50 dark:hover:bg-gray-900' >
          <div className='w-60 sm:w-48'>
            <div className="aspect-w-16 aspect-h-9 mt-2 sm:mt-0">
              <img className="object-cover shadow-lg rounded-lg" src={`${cohort.imageUrl}?aspect=16:9&width=420`} alt="" />
            </div>
          </div>
        </a>
        <div className="min-w-0 flex-auto px-0 sm:px-4 md:grid md:grid-cols-2 md:gap-4">
          <a href={`/courses/${cohort.courseSlug}`} className='hover:bg-gray-50 dark:hover:bg-gray-900' >
            <span className="uppercase text-xs dark:text-gray-500 text-gray-500 mr-5">{cohort.moniker}</span>
            { selected && <span className="inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium bg-green-100 text-green-800">Selected </span> }
            <p className="font-medium text-clcpurple truncate">{cohort.name}</p>
            <div className='mt-4 text-sm'>Full Course Details</div>
          </a>
          <div className="text-gray-500 dark:text-gray-300 text-sm">
            <span className='flex'><CalendarIcon className='w-5 h-5 mr-2' /><strong>{formatInTimezone(cohort.startsAtUtc, currentUser.timezone, 'EEE, MMM d, Y')} - {formatInTimezone(cohort.endsAtUtc, currentUser.timezone, 'EEE, MMM d, Y')}</strong></span>
            <span className='flex'>
              <ClockIcon className='w-5 h-5 mr-2' /> <div onClick={openModal} className='text-clcnavy underline cursor-pointer'>See Detailed Schedule</div>
            </span>
            { !alreadyEnrolled && <span className='text-gray-500 dark:text-gray-300 text-sm flex mt-1'><ReceiptTaxIcon className='w-5 h-5 mr-2' />${cohort.nonmemberPrice} (members ${cohort.price})</span> }
          </div>
        </div>
      </div>
      <div className='w-48'>
        { Boolean(hideSelectToggle) && <>
          <div className='flex-shrink-0 w-medium flex flex-col  flex-wrap sm:flex-nowrap mt-5 sm:mt-0'>
            { alreadyTaken && <span className="inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium bg-green-100 text-green-800">You've taken this course</span> }
            { alreadyEnrolled && <span className="inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium bg-green-100 text-green-800">Enrolled</span> }
          </div>
        </> }
        { !Boolean(hideSelectToggle) && <>
          <div className='flex-shrink-0 w-medium flex flex-col flex-wrap sm:flex-nowrap my-2 sm:mt-0'>
            { !selected && !soldOut && <PrimaryButton onClick={toggleSelect} disabled={soldOut} text={`Select (${cohort.seatsRemaining} seats left)`} /> }
            { soldOut && <>
              <PrimaryButton onClick={toggleSelect} className='w-full' disabled={soldOut} text={`Sold Out`} />
              { !followed && <PrimaryButton className='mt-4' onClick={toggleFollow} text='Remind Me' /> }
              { followed && <DefaultButton className='mt-4' onClick={toggleFollow} text={<span className='text-green-500'>Reminder Set!</span>} /> }
            </>
              }
            { selected && <DefaultButton  onClick={toggleSelect} text='Cancel' /> }
            { !soldOut && <>
              { !followed && <DefaultButton className='mt-4' onClick={toggleFollow} text='Remind Me' /> }
              { followed && <DefaultButton className='mt-4' onClick={toggleFollow} text={<span className='text-green-500'>Reminder Set!</span>} /> }
            </> }
          </div>
        </> }
      </div>
    </div>
    <Modal isOpen={isOpen} closeModal={closeModal} >
      <h2 className="text-base font-semibold text-indigo-600 tracking-wide uppercase">Detailed Schedule</h2>
      <div className="max-w-7xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8">

        <ul className="border-l-2 mx-auto max-w-lg border-dashed border-gray-500 mt-4">
          {cohort.schedule.map((scheduleItem, index) => <li key={`sch${index}`} className="flex flex-col px-4 pb-8 last:pb-0 sm:px-8">
            <h4 className="mb-1 text-gray-900 text-xl font-extrabold">{scheduleItem.title}</h4>
            <p className="text-200 mb-3 font-semibold text-clcnavy">{scheduleItem.time_slot}</p>
            <span dangerouslySetInnerHTML={{__html: scheduleItem.desc}}></span>
          </li> )}
        </ul>
      </div>
      <button className='inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white dark:bg-gray-900 dark:text-white dark:hover:bg-gray-800 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2' onClick={closeModal}>Okay</button>
    </Modal>
  </>
}

export default CohortRow

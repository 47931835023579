import React, {useState, useEffect, useRef} from 'react'
import useQuery from '@/hooks/useQuery'
import { Switch } from '@headlessui/react'
import { useForm } from 'react-hook-form'
import FroalaMinimalWrapper from '@/shared/FroalaMinimalWrapper'
import { TrashIcon, EmojiHappyIcon, LinkIcon, DuplicateIcon, ClipboardCopyIcon } from '@heroicons/react/solid'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { format } from 'date-fns-tz'
import DatePicker from 'react-datepicker'
import { Modal, useModal } from '@/shared/Modal'

import { PrimaryButton, DefaultButton } from '@/shared/Buttons'
import classNames from '@/utils/classNamesLocal'

import { showNonSpaToast } from '@/utils/nonSpaToast'
import { BulletItem, CaretItem } from '@/pages/teachers/Launch/Items'

const times = ['00:00', '00:30', '01:00', '01:30', '02:00', '02:30', '03:00', '03:30', '04:00', '04:30', '05:00', '05:30', '06:00', '06:30', '07:00', '07:30', '08:00', '08:30', '09:00', '09:30', '10:00', '10:30', '11:00', '11:30', '12:00', '12:30']

const CohortEdit = ({cohort}) => {
  const { putpostRequest, getRequest } = useQuery()
  const { openModal, closeModal, isOpen } = useModal()
  const [timezones, setTimezones] = useState([])
  const [startDate, setStartDate] = useState(cohort.startdate ? new Date(cohort.startdate) : new Date())
  const [endDate, setEndDate] = useState(cohort.startdate ? new Date(cohort.enddate) : new Date())
  const [paymentDate, setPaymentDate] = useState(cohort.paymentdate ? new Date(cohort.paymentdate) : new Date())

  const { formState, register, unregister, watch, handleSubmit, setValue, getValues, setError, setFocus } = useForm({
   defaultValues: {
      moniker: cohort.moniker,
      capacity: cohort.capacity,
      timezone: cohort.timezone,
      wants_ta: cohort.wantsTa,
      starts_at_date: cohort.startdate || new Date(),
      starts_at_time: times.includes(cohort.starttime) ? cohort.starttime : times[0],
      starts_at_ampm: cohort.startampm,
      ends_at_date: cohort.enddate || new Date(),
      ends_at_time: times.includes(cohort.endtime) ? cohort.endtime : times[0],
      ends_at_ampm: cohort.endampm,
      payment_deadline_at_date: cohort.paymentdate || new Date(),
      payment_deadline_at_time: times.includes(cohort.paymenttime) ? cohort.paymenttime : times[0],
      payment_deadline_at_ampm: cohort.paymentampm
    }
  })

  const { errors } = formState
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    getRequest('/api/v1/static/timezones', {}, (err, jsonData) => {
      if (err) { /* handled in hook */ return }

      if (jsonData.timezones) {
        setTimezones(jsonData.timezones)
        setValue('timezone', cohort.timezone, { shouldDirty: false, shouldValidate: false })
      }
    })
  }, [])

  useEffect(() => {
    register('starts_at_date', { required: true })
    register('ends_at_date', { required: true })
    register('wants_ta')
    register('payment_deadline_at_date', { required: true })
    return () => {
      unregister('starts_at_date')
      unregister('ends_at_date')
      unregister('want_ta')
      unregister('payment_deadline_at_date')
    }
  }, [register])

  useEffect(() => {
    setValue('starts_at_date', format(startDate, 'yyyy-MM-dd'), { shouldDirty: true, shouldValidate: true })
    setValue('starts_at_utc', null, { shouldDirty: true, shouldValidate: true })
  }, [startDate])

  useEffect(() => {
    setValue('ends_at_date', format(endDate, 'yyyy-MM-dd'), { shouldDirty: true, shouldValidate: true })
  }, [endDate])

  useEffect(() => {
    setValue('payment_deadline_at_date', format(paymentDate, 'yyyy-MM-dd'), { shouldDirty: true, shouldValidate: true })
  }, [paymentDate])

  const handleDateChange = (date) => {
    setDeadlineDate(date)
    setValue('deadline_date', format(date, 'yyyy-MM-dd'), { shouldDirty: true, shouldValidate: true })
  }

  const onSubmit = (data) => {
    setLoading(true)
    putpostRequest(`/api/v1/teachers/cohorts/${cohort.id}`, 'PATCH', { cohort: { ...data } }, (err, jsonData) => {
      setLoading(false)
      if (err) { // 422 code
        if (typeof err !== 'string') { // 500 code on API
          Object.entries(err).forEach(([key, value]) => {
            setError(key, value)
          })
        }
        return
      }

      showNonSpaToast("Cohort Info Saved", true)
    })
  }
  const handleUpdateSwitch = (key) => {
    setValue(key, !getValues()[key], { shouldDirty: true, shouldValidate: true })
  }

  const price = watch('price')
  const ta_needed = watch('wants_ta')
  const students = watch('capacity')
  const lectureHours = watch('lecture_hours')

  const revPerStudent = price
  const totalRev = revPerStudent * students
  let clcFeeFirst1000 = 0
  clcFeeFirst1000 = totalRev * 0.15
  let clcTAFee = 0
  if (lectureHours > 0 && ta_needed) { clcTAFee = lectureHours * 50 } else { clcTAFee = 0 }
  const clcTotalFee = clcFeeFirst1000  + clcTAFee
  const totalProfit = totalRev - clcTotalFee

  return <div>
      <div className='mt-6'>
        <PrimaryButton onClick={handleSubmit(onSubmit)} loading={loading} text="Save" />
      </div>
      <h2 className="flex w-full mx-auto text-2xl font-extrabold py-4">{cohort.name}</h2>
      <div className="flex items-baseline text-2xl font-semibold rounded-lg">
        { cohort.status === 'started' && <div className='text-green-800 bg-green-500 p-1 rounded-md'>{cohort.status}</div> }
        { cohort.status === 'enrolling' && <div className='text-yellow-900 bg-yellow-400 p-1 rounded-md'>{cohort.status}</div> }
        { cohort.status === 'completed' && <div className='text-gray-900 text-gray-400 p-1 rounded-md'>{cohort.status}</div> }
        { cohort.status === 'draft' && <div className='text-red-900 bg-red-400 p-1 rounded-md'>{cohort.status}</div> }
      </div>
      <div className='mt-6'>
        { cohort.status === 'draft' && <>
          <div className='col-span-2'>
            <div className="col-span-6 sm:col-span-3">

              <h5 className="flex justify-between text-gray-800"><label className="mb-1">Status</label></h5>
              <div className='mt-2 text-clcnavy'>Once you change the cohort status to 'enrolling', you will not be able to change it back.</div>
              <select
                {...register('status', { required: true })}
                className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white dark:bg-gray-900 dark:text-gray-100 rounded-md shadow-sm focus:outline-none focus:ring-cccpurple focus:border-cccpurple sm:text-sm"
              >
                <option>draft</option>
                <option>enrolling</option>
              </select>
            </div>
          </div>
        </> }
      </div>
      <div className='mt-6'>
        <div className="prose-product-lg">
          <h5 className="flex justify-between text-gray-800"><label className="mb-1">Cohort Name</label></h5>
        </div>
        <div className="prose-product-sm flex items-center sm:prose-product-lg">
          <input type="text" className={errors.moniker ? 'errors placeholder-shown:italic placeholder-gray-600 p2 py-3.5 px-4 w-full border rounded-lg' : 'placeholder-shown:italic placeholder-gray-600 p2 py-3.5 px-4 w-full border rounded-lg'} {...register('moniker') } defaultValue={cohort.moniker} />
        </div>
        <div className='mt-2 text-xs text-gray-700'>We highly recommend you put the year and month of the cohort start in the name so you (and your students) can easily reference this later.</div>
      </div>
      <div className='mt-6'>
        <div>
          <div className="col-span-6 sm:col-span-3">

            <h5 className="flex justify-between text-gray-800"><label className="mb-1">Timezone</label></h5>
            <select
              {...register('timezone', { required: true })}
              className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white dark:bg-gray-900 dark:text-gray-100 rounded-md shadow-sm focus:outline-none focus:ring-cccpurple focus:border-cccpurple sm:text-sm"
            >
              { timezones.map((tz) => (
                <option key={tz.computer} value={tz.computer}>{tz.human.replace(/_/g, ' ')}</option>
              ))}
            </select>
          </div>
        </div>
      </div>
      <div className='mt-6'>
        <div className="prose-product-lg">
          <h5 className="flex justify-between text-gray-800"><label className="mb-1">Cohort Start</label></h5>
        </div>
        <div className="grid grid-cols-3 gap-x-5">
          <div className="col-span-1">
            <div className='relative'>
              <DatePicker
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                selectsStart
                startDate={startDate}
                endDate={new Date().setMonth(startDate.getMonth() + 6)}
                minDate={new Date()}
                nextMonthButtonLabel=">"
                previousMonthButtonLabel="<"
              />
            </div>
          </div>
          <div className="col-span-1">
            <select
              {...register('starts_at_time', { required: true })}
              className="block w-full py-2 px-3 border border-gray-300 bg-white dark:bg-gray-900 dark:text-gray-100 rounded-md shadow-sm focus:outline-none focus:ring-cccpurple focus:border-cccpurple sm:text-sm"
            >
              { times.map((time) => (
                <option key={time} value={time}>{time}</option>
              ))}
            </select>
          </div>
          <div className="col-span-1">
            <select
              {...register('starts_at_ampm', { required: true })}
              className="block w-full py-2 px-3 border border-gray-300 bg-white dark:bg-gray-900 dark:text-gray-100 rounded-md shadow-sm focus:outline-none focus:ring-cccpurple focus:border-cccpurple sm:text-sm"
            >
              <option>AM</option>
              <option>PM</option>
            </select>
          </div>
        </div>
        <div className='mt-2 text-xs text-gray-700'>The first day of your cohort.</div>
        { errors.starts_at_utc && <div className='mt-2 text-sm text-red-600'>Courses must start at least 4 weeks from now, but it is suggested they start 8 weeks from now in order to properly market the course.</div> }
      </div>
      <div className='mt-6'>
        <div className="prose-product-lg">
          <h5 className="flex justify-between text-gray-800"><label className="mb-1">Cohort End</label></h5>
        </div>
        <div className="grid grid-cols-3 gap-x-5">
          <div className="col-span-1">
            <div className='relative'>
              <DatePicker
                selected={endDate}
                onChange={(date) => setEndDate(date)}
                selectsStart
                startDate={endDate}
                endDate={new Date().setMonth(endDate.getMonth() + 6)}
                minDate={new Date()}
                nextMonthButtonLabel=">"
                previousMonthButtonLabel="<"
              />
            </div>
          </div>
          <div className="col-span-1">
            <select
              {...register('ends_at_time', { required: true })}
              className="block w-full py-2 px-3 border border-gray-300 bg-white dark:bg-gray-900 dark:text-gray-100 rounded-md shadow-sm focus:outline-none focus:ring-cccpurple focus:border-cccpurple sm:text-sm"
            >
              { times.map((time) => (
                <option key={time} value={time}>{time}</option>
              ))}
            </select>
          </div>
          <div className="col-span-1">
            <select
              {...register('ends_at_ampm', { required: true })}
              className="block w-full py-2 px-3 border border-gray-300 bg-white dark:bg-gray-900 dark:text-gray-100 rounded-md shadow-sm focus:outline-none focus:ring-cccpurple focus:border-cccpurple sm:text-sm"
            >
              <option>AM</option>
              <option>PM</option>
            </select>
          </div>
        </div>
        <div className='mt-2 text-xs text-gray-700'>The last day of your cohort.</div>
        { errors.ends_at_date && <div className='mt-2 text-sm text-red-600'>This field is required.</div> }
      </div>
      <div className='mt-6'>
        <div className="prose-product-lg">
          <h5 className="flex justify-between text-gray-800"><label className="mb-1">Payment Deadline</label></h5>
        </div>
        <div className="grid grid-cols-3 gap-x-5">
          <div className="col-span-1">
            <div className='relative'>
              <DatePicker
                selected={paymentDate}
                onChange={(date) => setPaymentDate(date)}
                selectsStart
                startDate={paymentDate}
                endDate={new Date().setMonth(paymentDate.getMonth() + 6)}
                minDate={new Date()}
                nextMonthButtonLabel=">"
                previousMonthButtonLabel="<"
              />
            </div>
          </div>
          <div className="col-span-1">
            <select
              {...register('payment_deadline_at_time', { required: true })}
              className="block w-full py-2 px-3 border border-gray-300 bg-white dark:bg-gray-900 dark:text-gray-100 rounded-md shadow-sm focus:outline-none focus:ring-cccpurple focus:border-cccpurple sm:text-sm"
            >
              { times.map((time) => (
                <option key={time} value={time}>{time}</option>
              ))}
            </select>
          </div>
          <div className="col-span-1">
            <select
              {...register('payment_deadline_at_ampm', { required: true })}
              className="block w-full py-2 px-3 border border-gray-300 bg-white dark:bg-gray-900 dark:text-gray-100 rounded-md shadow-sm focus:outline-none focus:ring-cccpurple focus:border-cccpurple sm:text-sm"
            >
              <option>AM</option>
              <option>PM</option>
            </select>
          </div>
        </div>
        <div className='mt-2 text-xs text-gray-700'>The last day for students to pay before you close enrollment.</div>
        { errors.payment_deadline_at_date && <div className='mt-2 text-sm text-red-600'>This field is required.</div> }
      </div>
      <div className='mt-6'>
        <div className="prose-product-lg">
          <h5 className="flex justify-between text-gray-800"><label className="mb-1">Maximum Capacity </label></h5>
        </div>
        <div className="prose-product-sm flex items-center sm:prose-product-lg">
          <input type="number" className={errors.capacity ? 'errors placeholder-shown:italic placeholder-gray-600 p2 py-3.5 px-4 w-full border rounded-lg' : 'placeholder-shown:italic placeholder-gray-600 p2 py-3.5 px-4 w-full border rounded-lg'} {...register('capacity') } defaultValue={cohort.capacity} placeholder="0" />
        </div>
        <div className='mt-2 text-xs text-gray-700'>This is the maximum number of students who can join your cohort. Once this number is hit, your landing page will show a waitlist form..</div>
      </div>
      <div className='mt-6'>
        <div className="prose-product-lg">
          <h5 className="flex justify-between text-gray-800"><label className="mb-1">Member Price in USD </label></h5>
        </div>
        <div className="prose-product-sm flex items-center sm:prose-product-lg">
          <input type="number" className={errors.price ? 'errors placeholder-shown:italic placeholder-gray-600 p2 py-3.5 px-4 w-full border rounded-lg' : 'placeholder-shown:italic placeholder-gray-600 p2 py-3.5 px-4 w-full border rounded-lg'} {...register('price') } defaultValue={cohort.price} placeholder="0" />
        </div>
        <div className='p-2 border border-gray-300 bg-gray-100 rounded-lg mt-1'>
          <div className='mt-2 text-lg text-gray-800'>Credit cost: <span className="font-semibold" >{parseInt(price / 10, 10)} credits</span></div>
          <div className='mt-2 text-xs text-gray-700'>Some members accumulate credits. These are the number of credits it costs students to take your course. Each credit is worth $10 USD. This number is calculated from your member price.</div>
        </div>
        <div className="prose-product-lg">
          <h5 className="flex justify-between text-gray-800"><label className="mb-1">Non Member Price in USD </label></h5>
        </div>
        <div className="prose-product-sm flex items-center sm:prose-product-lg">
          <input type="number" className={errors.nonmember_price ? 'errors placeholder-shown:italic placeholder-gray-600 p2 py-3.5 px-4 w-full border rounded-lg' : 'placeholder-shown:italic placeholder-gray-600 p2 py-3.5 px-4 w-full border rounded-lg'} {...register('nonmember_price') } defaultValue={cohort.nonmember_price} placeholder="0" />
        </div>
        <div className='mt-2 text-xs text-gray-700'>We advise you charge about 10% more for non members. Membership is encouraged because it drives further engagement into the ecosystem and gives students a robust education, which sells more of your courses.</div>
        { cohort.schoolId !== 1 && <>
          <div className='mt-6 border border-2 border-clcnavy bg-clcnavy bg-opacity-5 rounded-lg p-5'>
            <div className="flex items-center space-x-3">
              <h4 className='mt-6 text-clcpurple text-xl font-extrabold'>Estimate Your Earnings</h4>
            </div>
            <div className='grid grid-cols-2 gap-4 mt-5 text-gray-800'>
              <div className='col-span-1 text-sm'>Revenue per Student (USD):</div>
              <div className="font-normal" >${revPerStudent}</div>
              <div className='col-span-1 text-lg'>Total Revenue:</div>
              <div className="font-normal font-semibold" >${totalRev}</div>
              <div className='col-span-1 text-lg'>Closing Credits fee (15%):</div>
              <div className="font-normal text-red-600" >(${clcTotalFee})</div>
              <div className='col-span-2 border border-b-2 border-clcnavy'></div>
              <div className='col-span-1 text-lg'>Your Estimated Profit:</div>
              <div className="font-extrabold text-green-600" >${totalProfit}</div>
            </div>
          </div>
        </> }
      </div>
      <div className='mt-6'>
        <PrimaryButton onClick={handleSubmit(onSubmit)} loading={loading} text="Save" />
      </div>
    <Modal isOpen={isOpen} closeModal={closeModal} >
      <h3 className="text-lg leading-6 font-medium text-gray-900 dark:text-white"> Teacher Assistant Duties</h3>
      <div className='flex flex-col justify-center'>
        <ul className='mt-5 flex flex-col gap-y-3 bg-clcnavy list-disc list-inside bg-opacity-5 border-2 border border-clcnavy p-5 rounded-lg'>
          <li>Attend all lectures</li>
          <li>Manage your Class Sessions, Zoom events, scheduling and event config</li>
          <li>Recording and Uploading class sessions</li>
          <li>Manage student chats and questions during the lecture</li>
          <li>Respond to student questions via email for the duration of the course</li>
          <li>Two 1-hour prep meetings with the instructor to discuss learning strategies, best practices, and course engagement</li>
          <li>Assistance with the creation of slides from the notes that you provide</li>
        </ul>

        <div className='flex gap-x-5 mt-6'>
          <DefaultButton onClick={closeModal} text="Okay" />
        </div>
      </div>
    </Modal>
  </div>
}

export default CohortEdit

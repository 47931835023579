import React, { useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useParams, useLocation } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { debounce } from 'throttle-debounce'
import { ExclamationCircleIcon, EmojiHappyIcon, LinkIcon, DuplicateIcon } from '@heroicons/react/solid'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import DatePicker from 'react-datepicker'
import { format } from 'date-fns-tz'
import { showNonSpaToast } from '@/utils/nonSpaToast'

import TicketTypeForm from '@/pages/teachers/TicketTypeForm'
import EventImage from '@/pages/teachers/EventImage'
import FroalaWrapper from '@/shared/FroalaWrapper'
import { useGlobalState } from '@/state'
import useQuery from '@/hooks/useQuery'
import { randomToastSuccess } from '@/shared/Toast'
import HostForm from '@/pages/teachers/HostForm'
import Tippy from '@tippyjs/react'
import ToolTip from '@/shared/ToolTip'
import Loading from '@/shared/Loading'
import Card from '@/shared/Card'
import PrimaryButton from '@/shared/Buttons/Primary'
import DefaultButton from '@/shared/Buttons/Default'

const eventDefaults = {
  timezone: 'America/New_York',
  capacity: 1000,
  status: 'draft',
  cohortId: 0,
  recapHoursAfter: 0,
  duration: 60,
  startTime: '07:00',
  shouldCreateCccProject: false,
  ampm: 'PM'
}

const startTimes = [ '00:00', '00:30', '01:00', '01:30', '02:00', '02:30', '03:00', '03:30', '04:00', '04:30',
  '05:00', '05:30', '06:00', '06:30', '07:00', '07:30', '08:00', '08:30', '09:00', '09:30', '10:00', '10:30',
  '11:00', '11:30', '12:00', '12:30' ]

const EventEdit = ({initEvent, course}) => {
  const [event, setEvent] = useState(initEvent)
  const [attendees, setAttendees] = useState([])
  const [canDelete, setCanDelete] = useState(false)
  const { putpostRequest, getRequest } = useQuery()
  const isComponentMounted = useRef(false)

  useEffect(() => {
    if (event.id) {
      getRequest(`/api/v1/teachers/events/${event.id}/edit`, {}, (err, jsonData) => {
        if (err) { /* handled in hook */ return }
        setEvent(jsonData.event)
        setAttendees(jsonData.attendees)
        setCanDelete(jsonData.can_delete_event)
      })
    } else {
      setEvent(eventDefaults)
    }
    isComponentMounted.current = true
  },[])

  if (!isComponentMounted.current) {
    return <Loading full={true} />
  }

  return <>
    <TheForm theEvent={event} course={course} canDelete={canDelete} />
    { attendees.length > 0 && <div className='p-8 py-5 mt-2 col-span-3  bg-gray-100 p-2 rounded-md border border-2 border-gray-900 mt-6'>
      <div className='max-w-5xl break-all'>
        <b>Attendees (just emails)</b>: { attendees.map((att, idx) => <>{att.email},</> )}
        <br />
        <br />
        <b>Attendees formatted:</b>
        <ul className='w-full max-w-5xl'>
          { attendees.map((att, idx) => <li className='grid grid-cols-3'><span className='col-span-1 w-96'>{att.email}</span> <span>{att.displayName}</span> <span>{att.ticketTypeName}</span></li> )}
        </ul>
      </div>
    </div> }
  </>
}


const TheForm = ({theEvent, course, canDelete}) => {
  const [ currentUser ] = useGlobalState('currentUser')
  const [event, setEvent] = useState(theEvent)
  const [, setToast] = useGlobalState('toast')
  const [confirmDelete, setConfirmDelete] = useState(false)
  let history = useHistory()
  const [timezones, setTimezones] = useState([])
  const [ticketTypes, setTicketTypes] = useState([])
  const [eventHosts, setEventHosts] = useState([])
  const [cohorts, setCohorts] = useState([])
  const [zoomAccounts, setZoomAccounts] = useState([])
  const [hosts, setHosts] = useState([])
  const [startDate, setStartDate] = useState(event.id ? new Date(event.startDate) : new Date())
  const [sendRecapDate, setSendRecapDate] = useState(event.id ? new Date(event.sendRecapDate) : new Date())
  const { putpostRequest, getRequest } = useQuery()
  const { formState, reset, register, unregister, handleSubmit, setValue, watch, getValues, setError } = useForm({
   defaultValues: {
      name: event.name,
      timezone: event.timezone,
      start_date: event.startDate || new Date(),
      start_time: event.startTime,
      send_recap_date: event.sendRecapDate || new Date(),
      send_recap_time: event.sendRecapTime,
      recap_ampm: event.recapAmpm,
      kind: event.kind,
      status: event.status,
      description: event.description,
      short_description: event.shortDescription,
      admittance: event.admittance,
      summary: event.summary,
      recap: event.recap,
      cohort_id: event.cohortId,
      zoom_account_id: event.zoomAccountId,
      user_id: event.userId,
      should_send_recap_email: event.shouldSendRecapEmail ? 'true' : 'false',
      should_create_ccc_project: event.shouldCreateCccProject ? 'true' : 'false',
      ccc_project_url: event.cccProjectUrl,
      ampm: event.ampm
    }
  })

  const { isDirty, errors } = formState
  const [loading, setLoading] = useState(false)
  const description = getValues().description
  const shortDescription = getValues().short_description
  const summary = getValues().summary
  const recap = getValues().recap

  useEffect(() => {
    register('start_date', { required: true })
    register('send_recap_date', { required: true })
    register('description', { required: true })
    register('short_description')
    register('summary')
    register('recap')
    return () => {
      unregister('start_date')
      unregister('send_recap_date')
      unregister('description')
      unregister('short_description')
      unregister('recap')
    }
  }, [register])

  useEffect(() => {
    setValue('start_date', format(startDate, 'yyyy-MM-dd'), { shouldDirty: true, shouldValidate: true })
  }, [startDate])

  useEffect(() => {
    setValue('send_recap_date', format(sendRecapDate, 'yyyy-MM-dd'), { shouldDirty: true, shouldValidate: true })
  }, [sendRecapDate])

  useEffect(() => {
    if (ticketTypes.length === 0) { fetchTicketTypes() }
    if (eventHosts.length === 0) { fetchEventHosts() }
    if (course) {
      getRequest(`/api/v1/teachers/courses/${course.id}/zoom_accounts`, {}, (err, jsonData) => {
        if (err) { /* handled in hook */ return }

        setZoomAccounts(jsonData.zoomAccounts)
        setValue('zoom_account_id', event.zoomAccountId, { shouldDirty: false, shouldValidate: false })
      })
      getRequest(`/api/v1/teachers/courses/${course.id}/cohorts`, {}, (err, jsonData) => {
        if (err) { /* handled in hook */ return }

        setCohorts(jsonData.cohorts)
        setValue('cohort_id', event.cohortId, { shouldDirty: false, shouldValidate: false })
      })
    }
    getRequest(`/api/v1/teachers/hosts`, {school_id: event.schoolId}, (err, jsonData) => {
      if (err) { /* handled in hook */ return }

      setHosts(jsonData.hosts)
    })
    getRequest('/api/v1/static/timezones', {}, (err, jsonData) => {
      if (err) { /* handled in hook */ return }

      if (jsonData.timezones) {
        setTimezones(jsonData.timezones)
        setValue('timezone', event.timezone, { shouldDirty: false, shouldValidate: false })
      }
    })
  }, [])

  const fetchTicketTypes = () => {
    getRequest(`/api/v1/teachers/events/${event.id}/ticket_types`, {}, (err, jsonData) => {
      if (err) { /* handled in hook */ return }

      setTicketTypes(jsonData.ticketTypes)
    })
  }

  const fetchEventHosts = () => {
    getRequest(`/api/v1/events/${event.id}/event_hosts`, {}, (err, jsonData) => {
      if (err) { /* handled in hook */ return }

      setEventHosts(jsonData.eventHosts)
    })
  }

  const addTicketType = () => {
    setLoading(true)
    const data = {event_id: event.id}
    putpostRequest(`/api/v1/teachers/ticket_types`, 'POST', data, (err, jsonData) => {
      setLoading(false)
      console.log(err)
      if (err) {
        showNonSpaToast(err, false)
        return
      }
      setTicketTypes([...ticketTypes, jsonData.ticketType ])
    })
  }

  const addHost = () => {
    setLoading(true)
    const data = {event_id: event.id}
    putpostRequest(`/api/v1/teachers/hosts`, 'POST', data, (err, jsonData) => {
      setLoading(false)
      console.log(err)
      if (err) {
        showNonSpaToast(err, false)
        return
      }
      setHosts([...hosts, jsonData.host ])
      setEventHosts([...eventHosts, jsonData.eventHost ])
    })
  }

  const addEventHost = (e) => {
    setLoading(true)
    const data = {event_id: event.id, host_id: e.target.value}
    putpostRequest(`/api/v1/teachers/event_hosts`, 'POST', data, (err, jsonData) => {
      setLoading(false)
      console.log(err)
      if (err) {
        showNonSpaToast(err, false)
        return
      }
      document.getElementById('host-select').value = ''
      setEventHosts([...eventHosts, jsonData.eventHost ])
    })
  }

  const removeEventHost = (hostId) => {
    const findEventHost = eventHosts.find((eh) => eh.hostId === hostId)
    setLoading(true)
    putpostRequest(`/api/v1/teachers/event_hosts/${findEventHost.id}`, 'DELETE', {}, (err, jsonData) => {
      setLoading(false)
      if (err) { // 422 code
        if (typeof err !== 'string') { // 500 code on API
          Object.entries(err).forEach(([key, value]) => {
            setError(key, value)
          })
        }
        return
      }

      const newEventHosts = eventHosts.filter((eh) => eh.hostId !== hostId)
      setEventHosts(newEventHosts)
      showNonSpaToast("Host Removed", true)
    })
  }

  const handleUpdateFroala = (key, val) => {
    setValue(key, val, { shouldDirty: true, shouldValidate: true })
  }

  const onSubmit = debounce(300, data => {
    setLoading(true)

    putpostRequest(`/api/v1/teachers/events/${event.id}`, 'PATCH', { event: data }, (err, jsonData) => {
      setLoading(false)
      console.log(err)
      if (err) { // 422 code
        if (typeof err !== 'string') { // 500 code on API
          Object.entries(err).forEach(([key, value]) => {
            setError(key, value)
          })
        }
        showNonSpaToast("Errors. See below", false)
        return
      }

      setEvent({ ...jsonData.event })

      showNonSpaToast("Saved", true)
    })
  })

  const sendNow = debounce(300, data => {
    setLoading(true)
    const URL = `/api/v1/admin/events/${event.id}`
    const action = 'PATCH'
    const formData = { ...data, send_now: true }

    putpostRequest(URL, action, { event: formData }, (err, jsonData) => {
      setLoading(false)
      if (err) { // 422 code
        if (typeof err !== 'string') { // 500 code on API
          Object.entries(err).forEach(([key, value]) => {
            setError(key, value)
          })
        }
        return
      }

      setEvent({ ...jsonData.event })

      showNonSpaToast("Sent. Check your email for a copy.", true)
    })
  })

  const showCopiedNotification = () => {
    setToast(<div className="ml-3 w-0 flex-1 pt-0.5">
      <p className="text-sm font-medium dark:text-green-300 text-green-600">Copied</p>
      <p className="mt-1 text-sm dark:text-green-300 text-green-400">You did it. It's copied. You can paste it anywhere you want now.</p>
    </div>)
  }

  const shouldCreateCccProject = watch('should_create_ccc_project')
  const shouldSendRecapEmail = watch('should_send_recap_email')
  const admittance = watch('admittance')

  const generateCCCProjectUrl = () => {
    putpostRequest(`/api/v1/admin/events/${event.id}/force_ccc_project`, "POST", { }, (err, jsonData) => {
      setLoading(false)
      if (err) { // 422 code
        if (typeof err !== 'string') { // 500 code on API
          Object.entries(err).forEach(([key, value]) => {
            setError(key, value)
          })
        }
        return
      }

      window.location.reload()
    })
  }

  const destroy = () => {
    setLoading(true)
    putpostRequest(`/api/v1/teachers/events/${event.id}`, 'DELETE', {}, (err, jsonData) => {
      setLoading(false)
      if (err) { // 422 code
        if (typeof err !== 'string') { // 500 code on API
          Object.entries(err).forEach(([key, value]) => {
            setError(key, value)
          })
        } else {
          showNonSpaToast(err, false)
        }
        return
      }

      if (course) {
        window.location.href = `/teachers/courses/${course.id}/events/`
      } else {
        window.location.href = `/dashboard`
      }
    })
  }

  console.log(errors)

  return (
    <form className='mt-6'>
      <div className='p-2'>
        <PrimaryButton onClick={handleSubmit(onSubmit)} loading={loading} text="Save" />
      </div>
      <div className="grid grid-cols-3 gap-6">

        { event.status === 'draft' && <>
          <div className='col-span-2'>
            <div className="col-span-6 sm:col-span-3">
              <label htmlFor="country" className="block text-sm font-medium text-gray-700 dark:text-gray-200 flex flex-col">
                <span>Status</span>
                <div className='mt-2 text-xs text-gray-400 dark:text-gray-500'>Events are not visible to the public until they are 'published'</div>
              </label>
              <select
                {...register('status', { required: true })}
                className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white dark:bg-gray-900 dark:text-gray-100 rounded-md shadow-sm focus:outline-none focus:ring-cccpurple focus:border-cccpurple sm:text-sm"
              >
                <option>draft</option>
                <option>published</option>
              </select>
            </div>
          </div>
        </> }

        <div className='col-span-3 uppercase text-clcpurple bg-gray-50 dark:bg-gray-750 p-3 border-2 border-gray-200 dark:border-gray-900 text-center mt-6'> --- Before the event -- </div>
        <div className="col-span-3 sm:col-span-2">
          <label htmlFor="display_name" className="block text-sm font-medium text-gray-700 dark:text-gray-300 flex justify-between">
            <span>Name</span>

            <Tippy interactive={true} interactiveBorder={20} content={<ToolTip>Required Field</ToolTip>}>
              <span onClick={(e) => e.preventDefault() }><ExclamationCircleIcon className="h-5 w-5 text-gray-400" aria-hidden="true" /></span>
            </Tippy>
          </label>
          <div className="mt-1 flex flex-col rounded-md shadow-sm">
            <div className='relative'>
              <input type="text" className={errors.name ? 'errors' : ''} {...register('name', { required: true }) } defaultValue={event.name} placeholder="The health benefits of using a bidet" />
              { errors.name && <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
              </div> }
            </div>
          </div>
          { errors.name && <div className='mt-2 text-sm text-red-600'>This field is required.</div> }
        </div>

        <div className='col-span-1'>
          <div className="col-span-6 sm:col-span-3">
            <label htmlFor="country" className="block text-sm font-medium text-gray-700 dark:text-gray-200 flex justify-between">
              <span>Admittance</span>
              <Tippy interactive={true} interactiveBorder={20} content={<ToolTip>Required Field</ToolTip>}>
                <span onClick={(e) => e.preventDefault() }><ExclamationCircleIcon className="h-5 w-5 text-gray-400" aria-hidden="true" /></span>
              </Tippy>
            </label>
            <select {...register('admittance', { required: true })} className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white dark:bg-gray-900 dark:text-gray-100 rounded-md shadow-sm focus:outline-none focus:ring-cccpurple focus:border-cccpurple sm:text-sm" >
              <option value='members-only'>Members Only</option>
              <option value='public'>Anyone Can RSVP</option>
            </select>
          </div>
        </div>

        <div className='col-span-1'>
        </div>

        <div className="col-span-3 sm:col-span-3">
          <label htmlFor="display_name" className="block text-sm font-medium text-gray-700 dark:text-gray-300 flex justify-between">
            <span>Short Description for Social Media (280 characters)</span>

            <Tippy interactive={true} interactiveBorder={20} content={<ToolTip>Required Field</ToolTip>}>
              <span onClick={(e) => e.preventDefault() }><ExclamationCircleIcon className="h-5 w-5 text-gray-400" aria-hidden="true" /></span>
            </Tippy>
          </label>
          <div className="mt-1 flex flex-1 mx-auto">
            <FroalaWrapper
              hideButtons={true}
              showCharacterCounter={true}
              model={shortDescription}
              updateModel={(val) => handleUpdateFroala('short_description', val)}
            />
          </div>
          { errors.short_description && <div className='mt-2 text-sm text-red-600'>This field is required.</div> }
        </div>

        <div className="col-span-3 sm:col-span-3">
          <label htmlFor="display_name" className="block text-sm font-medium text-gray-700 dark:text-gray-300 flex justify-between">
            <span>Full Description for the Registration Page</span>

            <Tippy interactive={true} interactiveBorder={20} content={<ToolTip>Required Field</ToolTip>}>
              <span onClick={(e) => e.preventDefault() }><ExclamationCircleIcon className="h-5 w-5 text-gray-400" aria-hidden="true" /></span>
            </Tippy>
          </label>
          <div className="mt-1 flex flex-1 mx-auto">
            <FroalaWrapper
              hideButtons={false}
              showCharacterCounter={false}
              model={description}
              updateModel={(val) => handleUpdateFroala('description', val)}
            />
          </div>
          { errors.description && <div className='mt-2 text-sm text-red-600'>This field is required.</div> }
        </div>

        <div className="col-span-3">
        </div>

        <div className="col-span-3 sm:col-span-1">
          <label htmlFor="display_name" className="block text-sm font-medium text-gray-700 dark:text-gray-300 flex justify-between">
            <span>Start Date</span>

            <Tippy interactive={true} interactiveBorder={20} content={<ToolTip>Required Field</ToolTip>}>
              <span onClick={(e) => e.preventDefault() }><ExclamationCircleIcon className="h-5 w-5 text-gray-400" aria-hidden="true" /></span>
            </Tippy>
          </label>
          <div className="mt-1 flex flex-col rounded-md shadow-sm">
            <div className='relative'>
              <DatePicker
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                selectsStart
                startDate={startDate}
                endDate={new Date().setMonth(startDate.getMonth() + 1)}
                minDate={new Date()}
                nextMonthButtonLabel=">"
                previousMonthButtonLabel="<"
              />
              { errors.startsAt && <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
              </div> }
            </div>
          </div>
          { errors.startsAt && <div className='mt-2 text-sm text-red-600'>This field is required.</div> }
        </div>

        <div className='col-span-1'>
          <div className="col-span-6 sm:col-span-3">
            <label htmlFor="country" className="block text-sm font-medium text-gray-700 dark:text-gray-200 flex justify-between">
              <span>Start Time</span>
              <Tippy interactive={true} interactiveBorder={20} content={<ToolTip>Required Field</ToolTip>}>
                <span onClick={(e) => e.preventDefault() }><ExclamationCircleIcon className="h-5 w-5 text-gray-400" aria-hidden="true" /></span>
              </Tippy>
            </label>
            <select
              {...register('start_time', { required: true })}
              className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white dark:bg-gray-900 dark:text-gray-100 rounded-md shadow-sm focus:outline-none focus:ring-cccpurple focus:border-cccpurple sm:text-sm"
            >
              { startTimes.map((time) => (
                <option key={time} value={time}>{time}</option>
              ))}
            </select>
          </div>
        </div>

        <div className='col-span-1'>
          <div className="col-span-6 sm:col-span-3">
            <label htmlFor="country" className="block text-sm font-medium text-gray-700 dark:text-gray-200 flex justify-between">
              <span>AM/PM</span>
              <Tippy interactive={true} interactiveBorder={20} content={<ToolTip>Required Field</ToolTip>}>
                <span onClick={(e) => e.preventDefault() }><ExclamationCircleIcon className="h-5 w-5 text-gray-400" aria-hidden="true" /></span>
              </Tippy>
            </label>
            <select
              {...register('ampm', { required: true })}
              className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white dark:bg-gray-900 dark:text-gray-100 rounded-md shadow-sm focus:outline-none focus:ring-cccpurple focus:border-cccpurple sm:text-sm"
            >
              <option>AM</option>
              <option>PM</option>
            </select>
          </div>
        </div>
        <div className='col-span-1'>
          <div className="col-span-6 sm:col-span-3">
            <label htmlFor="country" className="block text-sm font-medium text-gray-700 dark:text-gray-200 flex justify-between">
              <span>Timezone</span>
              <Tippy interactive={true} interactiveBorder={20} content={<ToolTip>Required Field</ToolTip>}>
                <span onClick={(e) => e.preventDefault() }><ExclamationCircleIcon className="h-5 w-5 text-gray-400" aria-hidden="true" /></span>
              </Tippy>
            </label>
            <select
              {...register('timezone', { required: true })}
              className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white dark:bg-gray-900 dark:text-gray-100 rounded-md shadow-sm focus:outline-none focus:ring-cccpurple focus:border-cccpurple sm:text-sm"
            >
              { timezones.map((tz) => (
                <option key={tz.computer} value={tz.computer}>{tz.human.replace(/_/g, ' ')}</option>
              ))}
            </select>
          </div>
        </div>
        <div className="col-span-3 sm:col-span-1">
          <label htmlFor="display_name" className="block text-sm font-medium text-gray-700 dark:text-gray-300 flex justify-between">
            <span>Duration in Minutes</span>

            <Tippy interactive={true} interactiveBorder={20} content={<ToolTip>Required Field</ToolTip>}>
              <span onClick={(e) => e.preventDefault() }><ExclamationCircleIcon className="h-5 w-5 text-gray-400" aria-hidden="true" /></span>
            </Tippy>
          </label>
          <div className="mt-1 flex flex-col rounded-md shadow-sm">
            <div className='relative'>
              <input type="number" className={errors.duration ? 'errors' : ''} {...register('duration', { required: true }) } defaultValue={event.duration} />
              { errors.duration && <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
              </div> }
            </div>
          </div>
          { errors.duration && <div className='mt-2 text-sm text-red-600'>This field is required.</div> }
        </div>

        <div className="col-span-3"> </div>

        <div className='col-span-2'>
          <div className="col-span-6 sm:col-span-3">
            <label htmlFor="country" className="block text-sm font-medium text-gray-700 dark:text-gray-200 flex justify-between">
              <span>Event Type</span>
              <Tippy interactive={true} interactiveBorder={20} content={<ToolTip>Required Field</ToolTip>}>
                <span onClick={(e) => e.preventDefault() }><ExclamationCircleIcon className="h-5 w-5 text-gray-400" aria-hidden="true" /></span>
              </Tippy>
            </label>
            <select
              {...register('kind', {required: true})}
              className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white dark:bg-gray-900 dark:text-gray-100 rounded-md shadow-sm focus:outline-none focus:ring-cccpurple focus:border-cccpurple sm:text-sm"
            >
              <option>AMA</option>
              <option>Case Study</option>
              { course && <option>Class Session</option> }
              <option>Community</option>
              <option>Competition</option>
              <option>Panel</option>
              <option>Workshop</option>
            </select>
            { errors.kind && <div className='mt-2 text-sm text-red-600'>This field is required.</div> }
          </div>
        </div>

        { course && <div className='col-span-2'>
          <div className="col-span-6 sm:col-span-3">
            <label htmlFor="country" className="block text-sm font-medium text-gray-700 dark:text-gray-200 flex justify-between">
              <span>Cohort</span>
              <Tippy interactive={true} interactiveBorder={20} content={<ToolTip>Required Field</ToolTip>}>
                <span onClick={(e) => e.preventDefault() }><ExclamationCircleIcon className="h-5 w-5 text-gray-400" aria-hidden="true" /></span>
              </Tippy>
            </label>
            <div className='relative'>
              <select
                {...register('cohort_id', { required: true })}
                className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white dark:bg-gray-900 dark:text-gray-100 rounded-md shadow-sm focus:outline-none focus:ring-cccpurple focus:border-cccpurple sm:text-sm"
              >
                <option></option>
                { cohorts.map((cohort, idx) => (
                  <option key={`c${cohort.id}`} value={cohort.id}>{cohort.moniker}</option>
                ))}
              </select>
            { errors.cohort_id && <div className='mt-2 text-sm text-red-600'>This field is required.</div> }
            </div>
          </div>
        </div> }

        <div className='col-span-2'>
          <div className="col-span-6 sm:col-span-3">
            <label htmlFor="country" className="block text-sm font-medium text-gray-700 dark:text-gray-200 flex justify-between">
              <div>Host</div>
              <div onClick={addHost} className='text-clcnavy cursor-pointer'>Create Host</div>
            </label>
            <select id="host-select" className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white dark:bg-gray-900 dark:text-gray-100 rounded-md shadow-sm focus:outline-none focus:ring-cccpurple focus:border-cccpurple sm:text-sm mb-3" onChange={addEventHost} >
              <option>---select host---</option>
              { hosts.map((host, idx) => (
                <option key={`h${host.id}`} value={host.id}>{host.name}</option>
              ))}
            </select>
          </div>

          <ul className="space-y-2 flex flex-col">
            { eventHosts.map((eh, index) => (
              <HostForm key={`hostf${eh.id}`} initialEventHost={eh} removeEventHost={() => removeEventHost(eh.hostId)} />
            ))}
          </ul>
        </div>

        <div className="col-span-3 sm:col-span-2">
          <label htmlFor="display_name" className="block text-sm font-medium text-gray-700 dark:text-gray-300 flex justify-between">
            <span>Zoom URL (or any meeting URL)</span>
          </label>
          <span className='dark:text-gray-300'>Attendees will be redirected to this link when the event starts.</span>

          { !event.zoomUrl && <div className='relative'>
            <div className='mt-2 text-xs text-gray-400 dark:text-gray-500'>Create an event using the attached Zoom Account</div>
            <select
              {...register('zoom_account_id')}
              className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white dark:bg-gray-900 dark:text-gray-100 rounded-md shadow-sm focus:outline-none focus:ring-cccpurple focus:border-cccpurple sm:text-sm"
            >
              <option>---</option>
              { zoomAccounts.map((za, idx) => (
                <option key={`zac${za.id}`} value={za.id}>{za.name}</option>
              ))}
            </select>
          </div> }
          { !event.zoomUrl && <div className='py-2 px-5 font-semibold'>OR</div> }
          <div className="flex flex-col rounded-md shadow-sm">
            <div className='relative'>
              <div className='text-xs text-gray-400 dark:text-gray-500'>Use a custom Zoom/Meeting URL</div>
              <input type="text" className={errors.zoomUrl ? 'errors' : ''} {...register('zoom_url') } defaultValue={event.zoomUrl} placeholder="https://www.zoom.us/..." />
              { errors.zoom_url && <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
              </div> }
            </div>
            { errors.zoom_url && <div className='mt-2 text-sm text-red-600'>This field is required if publishing.</div> }
          </div>
        </div>

        <div className="col-span-3 sm:col-span-2">
          <label htmlFor="display_name" className="block text-sm font-medium text-gray-700 dark:text-gray-300 flex justify-between">
            <span>Image</span>
          </label>
          <EventImage event={event} />
        </div>

        <div className="col-span-3"> </div>

        { course && <>
          <div className='col-span-1'>
            <div className="col-span-6 sm:col-span-3">
              <label htmlFor="country" className="block text-sm font-medium text-gray-700 dark:text-gray-200 flex justify-between">
                <span>Is there homework related to this event?</span>
              </label>
              <select
                {...register('should_create_ccc_project', { required: true })}
                className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white dark:bg-gray-900 dark:text-gray-100 rounded-md shadow-sm focus:outline-none focus:ring-cccpurple focus:border-cccpurple sm:text-sm"
              >
                <option value='false'>no</option>
                <option value='true'>yes</option>
              </select>
            </div>
          </div>
        </>}

        { shouldCreateCccProject === 'true' && <>
          <div className="col-span-3 sm:col-span-2">
            <label htmlFor="display_name" className="block text-sm font-medium text-gray-700 dark:text-gray-300 flex justify-between">
              <span>Homework URL</span>
            </label>
            <div className="mt-1 flex flex-col rounded-md shadow-sm">
              <div className='relative'>
                <input type="text" className={errors.cccProjectUrl ? 'errors' : ''} {...register('ccc_project_url') } defaultValue={event.zoomUrl} placeholder="https://www.castingcall.club/projects/..." />
                { errors.cccProjectUrl && <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                  <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
                </div> }
              </div>
            </div>
            <div className='mt-2 text-xs text-gray-400 dark:text-gray-500'>If you leave this blank, a CastingCallClub project will be created for you when the event ends.</div>
            <DefaultButton onClick={generateCCCProjectUrl} loading={loading} text="Generate CCC Project URL Now" />
          </div>
        </> }

        { !course && <div className="p-8 py-5 col-span-3  bg-gray-100 p-2 rounded-md border border-2 border-gray-900">
          <div className='mt-4'>
            <PrimaryButton onClick={addTicketType} loading={loading} text="Add Ticket Type" />
          </div>
          <ul role="list" className="mt-6 space-y-12 sm:grid sm:grid-cols-2 sm:gap-x-2 sm:gap-y-6 sm:space-y-0 lg:gap-x-4">
            { ticketTypes.map((tt, index) => (
              <TicketTypeForm key={`tt${tt.id}`} initialTicketType={tt} refreshData={fetchTicketTypes} />
            ))}
          </ul>
        </div> }

        <div className='col-span-3 uppercase text-clcpurple bg-gray-50 dark:bg-gray-750 p-3 border-2 border-gray-200 dark:border-gray-900 text-center mt-6'> --- After the Event is Over -- </div>

        <div className="col-span-3 sm:col-span-2">
          <label htmlFor="display_name" className="block text-sm font-medium text-gray-700 dark:text-gray-300 flex justify-between">
            <span>Recording URL</span>
          </label>
          <div className="mt-1 flex flex-col rounded-md shadow-sm">
            <div className='relative'>
              <input type="text" className={errors.recordingUrl ? 'errors' : ''} {...register('recording_url') } defaultValue={event.recordingUrl} placeholder="https://www.loom.com/embed/..." />
              { errors.recordingUrl && <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
              </div> }
            </div>
          </div>
        </div>

        <div className="col-span-3"> </div>


        <div className="col-span-3 sm:col-span-3">
          <label htmlFor="display_name" className="block text-sm font-medium text-gray-700 dark:text-gray-300 flex justify-between">
            <span>Event Summary - Any notes or hand-outs (this will appear on the student view alongside the recording)</span>
          </label>
          <div className="mt-1 flex flex-1 mx-auto">
            <FroalaWrapper
              hideButtons={false}
              showCharacterCounter={false}
              model={summary}
              updateModel={(val) => handleUpdateFroala('summary', val)}
            />
            { errors.summary && <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
              <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
            </div> }
          </div>
        </div>

        <div className="col-span-3"> </div>

        <div className="col-span-3 sm:col-span-2">
          <label htmlFor="country" className="block text-sm font-medium text-gray-700 dark:text-gray-200 flex justify-between">
            <span>Do you want to send a recap email?</span>
          </label>
          <select
            {...register('should_send_recap_email', { required: true })}
            className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white dark:bg-gray-900 dark:text-gray-100 rounded-md shadow-sm focus:outline-none focus:ring-cccpurple focus:border-cccpurple sm:text-sm"
          >
            <option value='false'>no</option>
            <option value='true'>yes</option>
          </select>
        </div>
        <div className="col-span-3"> </div>

        <div className={`${shouldSendRecapEmail === 'true' ? '' : 'hidden'} col-span-3 bg-gray-100 p-2 rounded-md border border-2 border-gray-900`}>
          Email recap
          <div className="col-span-3 sm:col-span-1">
            <label htmlFor="display_name" className="block text-sm font-medium text-gray-700 dark:text-gray-300 flex justify-between">
              <span>Recap Email Date</span>

              <Tippy interactive={true} interactiveBorder={20} content={<ToolTip>Required Field</ToolTip>}>
                <span onClick={(e) => e.preventDefault() }><ExclamationCircleIcon className="h-5 w-5 text-gray-400" aria-hidden="true" /></span>
              </Tippy>
            </label>
            <div className="mt-1 flex flex-col rounded-md shadow-sm">
              <div className='relative'>
                <DatePicker
                  selected={sendRecapDate}
                  onChange={(date) => setSendRecapDate(date)}
                  selectsStart
                  startDate={sendRecapDate}
                  endDate={new Date().setMonth(sendRecapDate.getMonth() + 1)}
                  minDate={new Date()}
                  nextMonthButtonLabel=">"
                  previousMonthButtonLabel="<"
                />
                { errors.sendRecapAt && <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                  <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
                </div> }
              </div>
            </div>
            { errors.sendRecapAt && <div className='mt-2 text-sm text-red-600'>This field is required.</div> }
          </div>

          <div className="col-span-3 sm:col-span-1">
            <div className="col-span-6 sm:col-span-3">
              <label htmlFor="country" className="block text-sm font-medium text-gray-700 dark:text-gray-200 flex justify-between">
                <span>Send Recap Time</span>
                <Tippy interactive={true} interactiveBorder={20} content={<ToolTip>Required Field</ToolTip>}>
                  <span onClick={(e) => e.preventDefault() }><ExclamationCircleIcon className="h-5 w-5 text-gray-400" aria-hidden="true" /></span>
                </Tippy>
              </label>
              <select
                {...register('send_recap_time', { required: true })}
                className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white dark:bg-gray-900 dark:text-gray-100 rounded-md shadow-sm focus:outline-none focus:ring-cccpurple focus:border-cccpurple sm:text-sm"
              >
                { startTimes.map((time) => (
                  <option key={time} value={time}>{time}</option>
                ))}
              </select>
            </div>
          </div>

          <div className="col-span-3 sm:col-span-1">
            <div className="col-span-6 sm:col-span-3">
              <label htmlFor="country" className="block text-sm font-medium text-gray-700 dark:text-gray-200 flex justify-between">
                <span>AM/PM</span>
                <Tippy interactive={true} interactiveBorder={20} content={<ToolTip>Required Field</ToolTip>}>
                  <span onClick={(e) => e.preventDefault() }><ExclamationCircleIcon className="h-5 w-5 text-gray-400" aria-hidden="true" /></span>
                </Tippy>
              </label>
              <select
                {...register('recap_ampm', { required: true })}
                className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white dark:bg-gray-900 dark:text-gray-100 rounded-md shadow-sm focus:outline-none focus:ring-cccpurple focus:border-cccpurple sm:text-sm"
              >
                <option>AM</option>
                <option>PM</option>
              </select>
            </div>
          </div>

          <div className="col-span-3 sm:col-span-3">
            <label htmlFor="display_name" className="block text-sm font-medium text-gray-700 dark:text-gray-300 flex justify-between">
              <span>Email Recap</span>
            </label>
            <p className='text-xs text-gray-700 dark:text-gray-300'> This is the text that will be emailed to the event attendees after the event.</p>
            <div className="mt-1 flex flex-1 mx-auto">
              <FroalaWrapper
                hideButtons={false}
                showCharacterCounter={false}
                model={recap}
                updateModel={(val) => handleUpdateFroala('recap', val)}
              />
              { errors.recap && <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
              </div> }
            </div>
            { errors.recap && <div className='mt-2 text-sm text-red-600'>This field is required when sending recaps.</div> }
          </div>
          <div className="col-span-3 sm:col-span-3">
            <DefaultButton onClick={handleSubmit(sendNow)} loading={loading} text="Save & Send Recap Now" />
          </div>
        </div>
      </div>
      <div className='mt-6 flex gap-x-5 items-center'>
        {canDelete && !confirmDelete && <div onClick={() => setConfirmDelete(true)} className='text-red-500 text-sm cursor-pointer'>Delete</div> }
        {confirmDelete && <div onClick={destroy} className='text-red-500 text-sm cursor-pointer'>Really delete?</div> }
        <PrimaryButton onClick={handleSubmit(onSubmit)} loading={loading} text="Save" />
      </div>
    </form>
  )
}

export default EventEdit

import React, {useState, useEffect} from 'react'
import useQuery from '@/hooks/useQuery'
import { Switch } from '@headlessui/react'
import { useForm } from 'react-hook-form'
import FroalaMinimalWrapper from '@/shared/FroalaMinimalWrapper'
import { ExclamationCircleIcon, EmojiHappyIcon, LinkIcon, DuplicateIcon, ClipboardCopyIcon } from '@heroicons/react/solid'

import { PrimaryButton, DefaultButton } from '@/shared/Buttons'
import { Modal, useModal } from '@/shared/Modal'
import classNames from '@/utils/classNamesLocal'

import { showNonSpaToast } from '@/utils/nonSpaToast'
import { BulletItem, CaretItem } from '@/pages/teachers/Launch/Items'

const VideoUrlForm = ({course}) => {
  const {video_url} = course
  const [videoUrl, setVideoUrl] = useState(video_url)
  const [editing, setEditing] = useState(false)
  const { putpostRequest } = useQuery()
  const { formState, reset, register, unregister, handleSubmit, setValue, watch, getValues, setError } = useForm({
   defaultValues: {
      video_url: video_url,
    }
  })
  const { isDirty, errors } = formState
  const [loading, setLoading] = useState(false)

  const onSubmit = (data) => {
    const formData = { course: data }
    setLoading(true)
    putpostRequest(`/api/v1/teachers/courses/${course.id}`, 'PATCH', formData, (err, jsonData) => {
      setLoading(false)
      console.log(err)
      if (err) {
        showNonSpaToast("Error", false)
        if (err.slug) {
          if (err.slug[0] === 'has already been taken') { setError('slug', { type: 'custom', message: 'unique' }) }
          if (err.slug[0] !== 'has already been taken') { setError('slug', { type: 'custom', message: 'format' }) }
        }
        return
      }

      showNonSpaToast("Saved", true)
      console.log(jsonData)
      setVideoUrl(jsonData.course.video_url)
      setEditing(false)
    })
  }

  return <>
    { !editing && <div id="hero" className="hover:shadow-xl hover:opacity-50 py-2 cursor-pointer" onClick={() => setEditing(true)}>
      <div className="flex text-clcnavy justify-end">edit</div>
      <p className="text-sm uppercase text-gray-700">Video Overview</p>
      <div className="prose prose-purple text-gray-500 mx-auto lg:max-w-none">
        <div className="mt-6 border-t border-gray-200 py-6 space-y-8">

          { videoUrl?.includes('loom.com') && <>
            <div style={{position: 'relative', paddingBottom: "56.25%", height: '0'}}>
              <iframe src={`${videoUrl}?hide_owner=true&hide_share=true&hide_title=true&hideEmbedTopBar=true`}
                frameBorder="0" webkitallowfullscreen='true' mozallowfullscreen='true' allowFullScreen
                style={{position: 'absolute', top: '0', left: '0',width: '100%', height: '100%'}}
              ></iframe>
            </div>
          </>}
          { videoUrl?.includes('youtube') && <>
            <div style={{position: 'relative', paddingBottom: "56.25%", height: '0'}}>
              <iframe src={videoUrl}
                frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen
                style={{position: 'absolute', top: '0', left: '0',width: '100%', height: '100%'}}
              ></iframe>
            </div>
          </>}

          { !videoUrl && <div className={`h-54 w-96 sm:w-960px sm:h-540px sm:w-960 relative block border-2 border-gray-300 flex flex-col justify-center cursor-pointer border-dashed rounded-lg p-12 text-center hover:border-gray-400`} >
          </div> }
        </div> 
      </div>
    </div> }
    { editing && <form className='p-8 border-4 border-clcpurple rounded-lg'>
      <div className="p-8 pt-5">
        <div className="space-y-6">
          <div className="col-span-3 sm:col-span-2">
            <div className="prose-product-lg">
              <h5 className="flex justify-between text-gray-800"><label className="mb-1">Video Overview</label></h5>
            </div>
            <span>This will be used to market the course. Introduce the teacher and go over course outline and results. Use a YouTube or Loom URL.</span>
            <div className="mt-1 flex flex-col rounded-md shadow-sm">
              <div className='relative'>
                <input type="text" className={errors.video_url ? 'errors' : ''} {...register('video_url') } defaultValue={video_url} placeholder="https://www.loom.com/embed/..." />
                { errors.video_url && <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                  <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
                </div> }
              </div>
            </div>
          </div>
          <div className='flex gap-x-5'>
            <PrimaryButton onClick={handleSubmit(onSubmit)} loading={loading} text="Save" />
            <DefaultButton onClick={() => setEditing(false)} text="Cancel" />
          </div>
        </div>
      </div>

    </form> }
  </>

}

export default VideoUrlForm

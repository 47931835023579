import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'

import { useForm } from 'react-hook-form'
import { showNonSpaToast } from '@/utils/nonSpaToast'

import FroalaMinimalWrapper from '@/shared/FroalaMinimalWrapper'
import {PrimaryButton, DefaultButton} from '@/shared/Buttons'
import { ExclamationCircleIcon, CheckCircleIcon } from '@heroicons/react/solid'
import useQuery from '@/hooks/useQuery'

import HostImage from '@/pages/teachers/HostImage'

const HostForm = ({ initialEventHost, removeEventHost }) => {
  const [host, setHost] = useState(initialEventHost || {}) // TODO: hosts are really event_hosts
  const [confirmDelete, setConfirmDelete] = useState(false)
  const { putpostRequest } = useQuery()
  const { formState, register, unregister, watch, handleSubmit, setValue, getValues, setError, setFocus } = useForm({
   defaultValues: {
      name: host.name,
      imageUrl: host.imageUrl,
      bio: host.bio
    }
  })

  const { errors } = formState
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    register('bio', { required: true })
    return () => {
      unregister('bio')
    }
  }, [register])

  const handleUpdateFroala = (key, val) => {
    setValue(key, val, { shouldDirty: true, shouldValidate: true })
  }

  const onSubmit = (data) => {
    setLoading(true)
    putpostRequest(`/api/v1/teachers/hosts/${host.hostId}`, 'PATCH', { host: { ...data } }, (err, jsonData) => {
      setLoading(false)
      if (err) { // 422 code
        if (typeof err !== 'string') { // 500 code on API
          Object.entries(err).forEach(([key, value]) => {
            setError(key, value)
          })
        }
        return
      }

      showNonSpaToast("Host Info Saved", true)
    })
  }

  const bio = getValues().bio

  return <li className='sm:col-span-1 bg-gray-200 dark:bg-gray-700 shadow-md transition duration-300 ease-out rounded-lg hover:shadow-xl sm:shadow-lg p-2'>
    <div className="min-w-0 flex-1 flex sm:gap-x-2 flex-col gap-2 sm:gap-y-0 sm:flex-row items-center justify-start flex-wrap sm:flex-nowrap">
      <div className="flex-shrink-0">
        <HostImage eventHost={host} />
      </div>
      <div className="w-full flex flex-col space-y-2">
        <div className="prose-product-lg">
          <h5 className="flex justify-between text-gray-800"><label className="mb-1">Name</label></h5>
        </div>
        <div className="w-full prose-product-sm flex items-center sm:prose-product-lg">
          <input type="text" className={errors.name ? 'errors placeholder-shown:italic placeholder-gray-600 p2 py-3.5 px-4 w-full border rounded-lg' : 'placeholder-shown:italic placeholder-gray-600 p2 py-3.5 px-4 w-full border rounded-lg'} {...register('name', { required: true }) } defaultValue={host.name} placeholder="Mr. Bigglesworth" />
        </div>
        <div>
          <div className="prose-product-sm flex justify-between sm:prose-product-lg">
            <div className="prose-product-lg">
              <h5 className="flex justify-between text-gray-800"><label className="mb-1">Bio</label></h5>
            </div>
          </div>
          <div className="prose-product-sm flex items-center sm:prose-product-lg">
            { errors.bio && <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
              <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
            </div> }
            <FroalaMinimalWrapper
              hideButtons={false}
              showCharacterCounter={false}
              model={bio}
              updateModel={(val) => handleUpdateFroala('bio', val)}
            />
          </div>
          { errors.bio && <div className='mt-2 text-sm text-red-600'>This field is required.</div> }
        </div>
        <div className='flex gap-2 sm:gap-x-0 justify-between items-center'>
          <PrimaryButton onClick={handleSubmit(onSubmit)} loading={loading} text="Save" />
          { !confirmDelete && <div onClick={() => setConfirmDelete(true)} className='text-red-500 text-sm cursor-pointer'>Remove from Event</div> }
          {confirmDelete && <div onClick={removeEventHost} className='text-red-500 text-sm cursor-pointer'>Really delete?</div> }
        </div>
      </div>

    </div>
    { errors.name && <div className='mt-2 text-sm text-red-600'>This field is required.</div> }
  </li>
}

export default HostForm

HostForm.propTypes = {
  initialEventHost: PropTypes.object.isRequired
}

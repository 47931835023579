import React, { useEffect, useState, useReducer, useRef } from 'react'
import { useGlobalState } from '@/state'
import EventRow from '@/shared/EventRow'
import Loading from '@/shared/Loading'

import useQuery from '@/hooks/useQuery'

const MyEvents = (props) => {
  const [currentUser] = useGlobalState('currentUser')
  const [attendingEvents, setAttendingEvents] = useState([])
  const isComponentMounted = useRef(false)
  const [state, setState] = useReducer(
    (state, newState) => (
      {...state, ...newState}), {
        attendingEventIds: [],
        events: [],
        classSessions: []
      }
    )
  const { attendingEventIds, classSessions, events } = state
  const { getRequest } = useQuery()
  
  useEffect(() => {
    getRequest(`/api/v1/events?this_week=true`, {}, (err, jsonData) => {
      if (err) { /* handled in hook */ return }
      setState( {
        events: jsonData.events,
        attendingEventIds: jsonData.attendingEventIds,
        classSessions: jsonData.classSessions
      })
    })
    getRequest(`/api/v1/events/attending`, {}, (err, jsonData) => {
      if (err) { /* handled in hook */ return }
      setAttendingEvents(jsonData.attendingEvents)
    })
    isComponentMounted.current = true

  }, [])

  if (!isComponentMounted.current) {
    return <Loading />
  }

  return <div className='max-w-7xl mx-auto'>

    <div className='mt-2 bg-white dark:bg-gray-900 rounded-lg p-2'>
      <div className="relative flex justify-between">
        <h2 className="mt-6 text-gray-900 dark:text-gray-100 text-xl font-extrabold">Upcoming Events I'm Attending</h2>
      </div>

      <section aria-labelledby="events-this-week">
        <div className="relative">
          { attendingEvents.length === 0 && (
            <div>
              <div className="mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8">
                <div className="text-center">
                  <h2 className="text-base font-semibold text-clcpurple tracking-wide uppercase">No Events</h2>
                  <p className="max-w-xl mt-5 mx-auto text-xl text-gray-500 dark:text-gray-300">
                    You haven't signed up for any events yet.
                  </p>
                  <p className="mt-5 mx-auto text-xl">
                    <a href='/events'>See upcoming events</a>
                  </p>
                </div>
              </div>
            </div>
          )}
          <div className="mt-12 max-w-lg mx-auto grid gap-5 lg:grid-cols-3 lg:max-w-none">
            { attendingEvents.map((event, idx) => <EventRow key={`amattendevent${event.id}`} event={event} user={currentUser} attendingEventIds={[event.id.toString()]} /> )}
          </div>
        </div>
      </section>
    </div>
    <div className='mt-2 bg-white dark:bg-gray-900 rounded-lg p-2'>
      <div className="relative flex justify-between">
        <h2 className="mt-6 text-gray-900 dark:text-gray-100 text-xl font-extrabold">All My Upcoming Class Sessions</h2>
      </div>

      <section aria-labelledby="events-this-week">
        <div className="relative">
          { classSessions.length === 0 && (
            <div>
              <div className="mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8">
                <div className="text-center">
                  <h2 className="text-base font-semibold text-clcpurple tracking-wide uppercase">No Class Sessions the next two weeks</h2>
                  <p className="max-w-xl mt-5 mx-auto text-xl text-gray-500 dark:text-gray-300">
                    There are no class sessions for you this week. You can <a href='/reserve_seat'> enroll in a course here</a>.
                  </p>
                </div>
              </div>
            </div>
          )}
          <div className="mt-12 max-w-lg mx-auto grid gap-5 lg:grid-cols-4 lg:max-w-none">
            { classSessions.map((event, idx) => <EventRow key={`myevent${event.id}`} event={event} user={currentUser} attendingEventIds={attendingEventIds} /> )}
          </div>
        </div>
      </section>
    </div>
  </div>
}

export default MyEvents

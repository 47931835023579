import React from 'react'
import { Switch, Route } from 'react-router-dom'

import OnboardingForm from '@/pages/OnboardingForm'

export default function OnboardingContainer(props) {

  return <>
    <div>
      <div className="max-w-7xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8">
        <div className="text-center">
          <h2 className="text-base font-semibold text-indigo-600 tracking-wide uppercase">Onboarding</h2>
          <p className="mt-1 text-4xl font-extrabold text-gray-900 dark:text-gray-100 sm:text-5xl sm:tracking-tight lg:text-6xl">
            Nice to see you.
          </p>
        </div>
      </div>
    </div>
    <Switch>
      <Route {...props} exact path='/onboarding'>
        <OnboardingForm />
      </Route>
    </Switch>
  </>
}

import React, { useState, useEffect, useRef } from 'react'
import { useStripe, useElements, CardElement, Elements } from '@stripe/react-stripe-js'
import { loadScript } from "@paypal/paypal-js"
import { useHistory, Link } from 'react-router-dom'
import { CreditCardIcon, CheckCircleIcon, ExclamationIcon, EmojiHappyIcon, EmojiSadIcon } from '@heroicons/react/solid'

import WhatsIncluded from '@/pages/SubscriptionForm/WhatsIncluded'
import CohortInterest from '@/pages/SubscriptionForm/CohortInterest'

import DefaultButton from '@/shared/Buttons/Default'
import PrimaryButton from '@/shared/Buttons/Primary'
import useQuery from '@/hooks/useQuery'
import { useGlobalState } from '@/state'
import SVGIcon from '@/shared/icon'

import Loading from '@/shared/Loading'

const options = {
  hidePostalCode: true,
  style: {
    base: {
      'fontSize': '16px',
      'color': '#424770',
      'letterSpacing': '0.025em',
      'fontFamily': 'Source Code Pro, monospace',
      '::placeholder': {
        color: '#aab7c4'
      }
    },
    invalid: {
      color: '#9e2146'
    }
  }
}

const PurchaseCredits = (props) => {
  const { creditsNeeded, pricePerCredit } = props
  const [creditsToBuy, setCreditsToBuy] = useState(creditsNeeded)
  window.oneTimeAmount = pricePerCredit * creditsToBuy
  window.paypalCreditsToBuy = creditsToBuy
  const [currentUser, setCurrentUser] = useGlobalState('currentUser')
  const [ paymentMethods, setPaymentMethods ] = useState(props.paymentMethods)
  const [newPaymentMethod, setNewPaymentMethod] = useState(true)
  const pollRef = useRef(20)
  const creditsToBuyRef = useRef(10)
  const [, setToast] = useGlobalState('toast')
  const { putpostRequest, getRequest } = useQuery()
  const [selectedPaymentMethodId, setSelectedPaymentMethodId] = useState(null)
  const [stripeReady, setStripeReady] = useState(false)
  const [loading, setLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState(props.errorMessage)

  let history = useHistory()
  const stripe = useStripe()
  const elements = useElements()

  const assignDefaultPaymentMethod = () => {
    const defaultPaymentMethod = paymentMethods.find(paymentMethod => paymentMethod.defaultPaymentMethod)
    if (defaultPaymentMethod) { setSelectedPaymentMethodId(defaultPaymentMethod.id) }
  }

  useEffect(() => { setCreditsToBuy(creditsNeeded) }, [creditsNeeded])
  useEffect(() => {
    if (paymentMethods.length > 0) { setNewPaymentMethod(false) }
    assignDefaultPaymentMethod()
  }, [paymentMethods])

  useEffect(() => {
    if (newPaymentMethod === true) {
      setSelectedPaymentMethodId(null)
    } else {
      assignDefaultPaymentMethod()
    }
  }, [newPaymentMethod])

  useEffect(() => {
    setStripeReady(Boolean(selectedPaymentMethodId)) 
  }, [selectedPaymentMethodId])

  useEffect(() => {

    loadScript({ "client-id": "AZaFM8bhNlQ1i7eFqPqJAsbgsorc-iwV-pSwNwmALmqAvUfuf6IfaTS3ejx9rMFLvIF_EK-UETR_8U_v" }).then((paypal) => {
      paypal.Buttons({
        style: { layout: 'horizontal' },
        createOrder: createPaypalOrder,
        onApprove: paypalOrderApproved
      }).render("#paypal-button-container").catch((error) => {
        console.error("failed to render the PayPal Buttons", error);
      })
    }).catch((error) => {
      console.error("failed to load the PayPal JS SDK script", error);
    })
  }, [])

  const createPaypalOrder = async (data, actions) => {
    if (window.oneTimeAmount === 0) { alert('error'); return }

    return actions.order.create({ purchase_units: [{ amount: { value: window.oneTimeAmount } }] })
  }

  const paypalOrderApproved = (data, actions) => {
    return actions.order.capture().then(function(details) {
      setLoading(true)
      const data = {
        pp_order_id: details.id,
        credits_to_buy: paypalCreditsToBuy
      }
      putpostRequest('/api/v1/paypal/checkout_credits', 'POST', { checkout: data }, async (err, jsonData) => {
        setLoading(false)
        if (err) { setErrorMessage(err) }
        if (jsonData && jsonData.approved) {
          setCurrentUser({
            ...currentUser,
            credits: jsonData.credits
          })
          successfulPurchase()
        }
      })
    })
  }


  const updateCreditsToBuy = (num) => {
    if (creditsToBuy + num < 1) { return }
    if (creditsToBuy + num > 100) { return }

    window.oneTimeAmount = pricePerCredit * (creditsToBuy + num)
    window.paypalCreditsToBuy = creditsToBuy + num

    setCreditsToBuy(creditsToBuy + num)
  }

  const onNewPaymentMethodChange = (e) => {
    if (e.error) {
      setErrorMessage('This appears to be an invalid card number.')
    } else {
      setErrorMessage(null)
      if (e.complete) {
        setStripeReady(true)
      } else {
        setStripeReady(false)
      }
    }
  }

  const createStripePaymentMethod = async () => {
    setLoading(true)
    const stripePayload = await stripe.createPaymentMethod({ type: 'card', card: elements.getElement(CardElement) })
    setLoading(false)

    if (stripePayload.error || !stripePayload.paymentMethod || !stripePayload.paymentMethod.id) {
      // spam slack channel with stripePayload.error.message, current_user.id
      setErrorMessage(stripePayload.error.message)
      return
    }
    const newCard = {
      ...stripePayload.paymentMethod.card,
      id: stripePayload.paymentMethod.id,
      ccExpYear: stripePayload.paymentMethod.card.exp_year,
      ccExpMonth: stripePayload.paymentMethod.card.exp_month
    }
    setPaymentMethods([...paymentMethods, newCard ])
    return stripePayload.paymentMethod.id
  }

  const purchase = async () => {
    setLoading(true)
    setErrorMessage(null)
    const paymentMethodId = selectedPaymentMethodId || await createStripePaymentMethod()
    setLoading(false)
    if (paymentMethodId) {
      setSelectedPaymentMethodId(paymentMethodId)
      createOneTimePurchase(paymentMethodId)
    }
    /* The createStripePaymentMethod method will handle error messaging if it fails to create a payment method */
  }

  const createOneTimePurchase = async (paymentMethodId) => {
    setLoading(true)
    const data = {
      payment_method_id: paymentMethodId,
      quoted_price: pricePerCredit * creditsToBuy,
      credits_to_buy: creditsToBuy
    }

    putpostRequest('/api/v1/stripe/checkout_credits', 'POST', { checkout: data }, async (err, jsonData) => {
      setLoading(false)
      if (err) { setErrorMessage(err) }
      if (jsonData && !jsonData.requiresAction) {
        setCurrentUser({
          ...currentUser,
          credits: jsonData.credits
        })
        successfulPurchase()
      }
      if (jsonData && jsonData.requiresAction) {
        await handlePaymentThatRequiresCustomerAction({
          paymentIntentClientSecret: jsonData.paymentIntentClientSecret,
          paymentMethodId: paymentMethodId
        })
      }
    })
  }

  const pollForAccess = () => {
    setLoading(true)
    pollRef.current -= 1
    getRequest(`/api/v1/users/${currentUser.id}`, {}, (err, jsonData) => {
      if (pollRef.current <= 0) {
        setErrorMessage('Code 987: Could not verify card in time')
        pollRef.current = 20
        return
      }
      if (jsonData.user.credits !== jsonData.credits) {
        setCurrentUser({ ...currentUser, credits: jsonData.credits })
        setLoading(false)
        successfulPurchase()
        return
      } else {
        setTimeout(pollForAccess, 2000)
      }
    })
  }

  const successfulPurchase = (upgraded) => {
    setToast(<>
      <div className="flex-shrink-0">
        <EmojiHappyIcon className="h-6 w-6 text-green-500" aria-hidden="true" />
      </div>
      <div className="ml-3 w-0 flex-1 pt-0.5">
        <p className="text-sm font-medium dark:text-white text-gray-800">Your credits have been added.</p>
        <p className="mt-1 text-sm dark:text-gray-100 text-gray-500">Please finalize your enrollment.</p>
      </div>
    </>)
    history.push('/reserve_seat')
  }

  const handlePaymentThatRequiresCustomerAction = async ({ paymentIntentClientSecret, paymentMethodId }) => {
    setLoading(true)
    const confirmCardResult = await stripe.confirmCardPayment(paymentIntentClientSecret, { payment_method: paymentMethodId })
    setLoading(false)
    if (confirmCardResult.error) {
      // The card was declined (i.e. insufficient funds, card has expired, etc). Try another payment method
      console.log(`handlePaymentThatRequiresCustomerAction - throwing ${confirmCardResult.error.message}`)
      setErrorMessage(confirmCardResult.error.message)
    } else {
      if (confirmCardResult.paymentIntent.status === 'succeeded') {
        // handled invoice.payment_succeeded webhook.
        // There's a risk of the customer closing the window before callback
        // execution.
        pollForAccess()
        console.log('handlePaymentThatRequiresCustomerAction - confirmCardPayment PI succeeded')
      } else {
        setErrorMessage('Code 1943: We were unable to charge your card at this time. Please try another card.')
      }
    }
  }

  return <>
    <div className='flex items-center justify-between p-6 bg-white dark:bg-gray-800'>
      <span className='dark:text-gray-200'>How many credits would you like to buy?</span>
      <div className='min-w-32 no-user-select flex flex-col sm:flex-row gap-x-1'>
        <span onClick={() => updateCreditsToBuy(-1)}>
          <SVGIcon name='minus-in-circle' height='20' width='20' autoDark
            className={`${(creditsToBuy === 1) ? 'cursor-not-allowed' : 'cursor-pointer'}`}
          />
        </span>
        <span className='text-clcpurple semibold'>{creditsToBuy}</span>
        <span onClick={() => updateCreditsToBuy(1)}>
          <SVGIcon name='plus-in-circle' height='20' width='20' autoDark
            className={`${(creditsToBuy >= 100) ? 'cursor-not-allowed' : 'cursor-pointer'}`}
          />
        </span>
      </div>
    </div>

    { /* SALE is off
    <div className='mt-10 flex gap-x-1 font-bold justify-center flex-row'>
      <span className='text-lg text-red-500'>Holiday</span>
      <span className='text-lg text-green-500'>Special</span>
    </div>
    <div className='mt-3 flex gap-x-1 font-bold justify-center flex-row'>
      <span className='text-lg text-green-500'>Promo Code <i>'hello2023'</i></span>
      <span className='text-lg text-red-500'>Automatically Applied!</span>
    </div>
    */ }
    <div className="flex items-center p-6">
      <h4 className="flex-shrink-0 mr-3 bg-gray-100 dark:bg-gray-700 text-sm tracking-wider font-semibold uppercase text-clcpurple">
        Total
      </h4>
      <div className="flex-1 border-t-2 border-gray-200"></div>
      <div className='ml-5 text-2xl dark:text-gray-100 font-semibold'>${pricePerCredit * creditsToBuy}</div>
    </div>
    <label htmlFor="card-element" className="block text-sm font-medium text-gray-700 dark:text-gray-300 flex justify-between">
      Credit or Debit Card
    </label>
    <div className='mt-1'>
      { !newPaymentMethod && !loading && <>
        { paymentMethods.length > 0 && <>
          <ul role="list" className="flex flex-col">
            { paymentMethods.map((pm, idx) => (
              <li key={pm.id} onClick={() => setSelectedPaymentMethodId(pm.id)} className="relative cursor-pointer">
                <dl className="mt-3 p-3 bg-gray-50 hover:bg-gray-100 dark:bg-gray-750 dark:hover:bg-gray-800 text-gray-600 dark:text-gray-300 border-2 rounded-md">
                  <dd className="flex flex-col sm:flex-row sm:items-center text-sm justify-start sm:justify-between font-medium sm:mr-6">
                    <div className='flex justify-start'>
                      <CreditCardIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                      <span className='whitespace-nowrap text-sm capitalize'>{pm.brand} ending in <strong>{pm.last4}</strong></span>
                      <span className="text-sm font-medium ml-3">{pm.ccExpMonth}/{pm.ccExpYear}</span>
                    </div>
                    { (selectedPaymentMethodId === pm.id) && <span className="inline-flex items-center px-3 rounded-full text-sm font-medium bg-green-100 text-green-800">Selected</span> }
                  </dd>
                </dl>
              </li>
            ))}
          </ul>
          <div className='cursor-pointer text-clcnavy hover:text-clcnavy-light dark:hover:text-clcnavy-light text-sm py-3' onClick={() => setNewPaymentMethod(true)}>Add New Payment Method</div>
        </> }
      </> }
      { newPaymentMethod && <>
        <CardElement id='card-element' options={options} onChange={onNewPaymentMethodChange} />
        { paymentMethods.length > 0 && <>
          <div className='cursor-pointer text-clcnavy hover:text-clcnavy-light text-sm py-3' onClick={() => setNewPaymentMethod(false)}>Use A Saved Card</div>
        </> }
      </>}
      { errorMessage && <div className='mt-2 text-sm text-red-600'>{errorMessage}</div> }
    </div>

    <div className="flex justify-start items-center">
      <PrimaryButton onClick={purchase} loading={loading} disabled={!stripeReady} text="Purchase" />
    </div>
    <span className='text-xs text-gray-400 dark:text-gray-400'>We use Stripe to securely process transactions</span>

    { paymentMethods.length === 0 && <div className="flex justify-start items-center my-2">
      <div id="paypal-button-container"></div>
    </div> }
  </>
}

const BuyCreditsCheckout = (props) => {
  const { setOneTimeCheckout, paymentMethods, existingSubscription } = props
  const [ creditsNeeded, setCreditsNeeded ] = useState(0)

  // As of May 1, all credits are $10
  let pricePerCredit = 10
  //if (existingSubscription?.plan === 'apprentice' || existingSubscription?.plan === 'maker') {
    //pricePerCredit = 15
  //} else if (existingSubscription?.plan === 'master') {
    //pricePerCredit = 10
  //}

  return <>
    <div className="mt-12 space-y-12 lg:space-y-0 lg:grid lg:grid-cols-3 lg:gap-x-8">
      <div className='col-span-2'>
        <CohortInterest creditCheckout setCreditsNeeded={setCreditsNeeded} />
        { false && <div id='whatifmembership' /> }
        <PurchaseCredits paymentMethods={paymentMethods} creditsNeeded={creditsNeeded} pricePerCredit={pricePerCredit} />
        <div className="flex flex-col items-center p-6">
          <div className="flex items-center p-6">
            <Link to='/subscriptions' className='mr-5'>Back to Subscription Overview</Link>
            <Link to='/reserve_seat' className='mr-5'>Course Selection</Link>
          </div>
        </div>
      </div>
      <div className='col-span-1'>
        { false && <WhatsIncluded planId={existingSubscription?.plan || 'base'} /> }
      </div>
    </div>
  </>
}

export default BuyCreditsCheckout

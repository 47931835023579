import React from 'react'
import { ExclamationIcon } from '@heroicons/react/solid'

const Downgrade = ({existingSubscription}) => {
  return <>
    <div className="rounded-md bg-yellow-50 p-4 mt-10">
      <div className="flex">
        <div className="flex-shrink-0">
          <ExclamationIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
        </div>
        <div className="ml-3">
          <h3 className="text-sm font-medium text-yellow-800">Your plan will change!</h3>
          <div className="mt-2 text-sm text-yellow-700">
            {existingSubscription.downgradeTo === 'cancel' && (
              <p className='p-2'>You have already paid for the {existingSubscription.currentPlan} plan for the rest of the term, but your plan will expire at the next subscription renewal cycle and you will not be charged again after that time.</p>
            )}
            {existingSubscription.downgradeTo !== 'cancel' && (
              <p>You have already paid for the {existingSubscription.currentPlan} plan, but your plan will change to {existingSubscription.downgradeTo} at the next subscription renewal cycle.</p>
            )}
            <p>If you make additional changes to your subscription, it will override your previous planned change</p>
          </div>
        </div>
      </div>
    </div>
  </>
}
export default Downgrade

import React, { useEffect, useRef, useState } from 'react'
import { Switch, Route, useLocation, useHistory, Link } from 'react-router-dom'
import { SpeakerphoneIcon, XIcon } from '@heroicons/react/solid'

import { useGlobalState } from '@/state'

const EnrollmentBanner = () => {
  const dismissalCookie = 'enrollmentbanner1'
  const hasDismissed = window.localStorage.getItem(dismissalCookie) === '1'
  const [showNothing, setShowNothing] = useState(hasDismissed)

  const handleDismissal = () => {
    window.localStorage.setItem(dismissalCookie, '1')
    setShowNothing(true)
  }

  if (showNothing) { return null }

  return <div className="bg-clcpurple dark:bg-clcnavy">
    <div className="max-w-7xl mx-auto py-3 px-3 sm:px-6 lg:px-8">
      <div className="flex items-start sm:items-center justify-start sm:justify-between flex-col sm:flex-row">
        <div className="w-0 flex-1 flex items-start sm:items-center">
          <span className="flex p-2 rounded-lg bg-indigo-800 hidden sm:block">
            <SpeakerphoneIcon className="h-6 w-6 text-white" aria-hidden="true" />
          </span>
          <p className="ml-3 font-medium text-white whitespace-nowrap">
            <span>Enrollment for next semester is open.</span>
          </p>
        </div>
        <div className="order-3 mt-2 flex-shrink-0 w-full flex sm:order-2 sm:mt-0 sm:w-auto">
          <Link to='/reserve_seat' className="flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-clcpurple dark:text-clcnavy bg-white dark:hover:bg-clcnavy-dark hover:bg-clcpurple-dark" >Schedule </Link>
          <button type="button" onClick={handleDismissal} className="ml-1 flex p-2 rounded-md hover:bg-clcpurple-dark focus:outline-none focus:ring-2 focus:ring-white" >
            <span className="sr-only" hidden>Dismiss</span>
            <XIcon className="h-6 w-6 text-white" aria-hidden="true" />
          </button>
        </div>
      </div>
    </div>
  </div>
}

const Banners = () => {
  const [currentUser] = useGlobalState('currentUser')

  return <>
    { currentUser.access === 'beta' && <EnrollmentBanner /> }
  </>
}

export default Banners

import React, { useEffect, useState, useRef } from 'react'
import { Link, useParams } from 'react-router-dom'

import Loading from '@/shared/Loading'
import useQuery from '@/hooks/useQuery'
import CohortRow from '@/shared/CohortRow'
import EventRow from '@/shared/EventRow'
import PrimaryButton from '@/shared/Buttons/Primary'
import { useGlobalState } from '@/state'
import { formatInTimezone } from '@/utils/date'

const LessonRow = ({lesson, currentUser}) => {
  let background = 'bg-green-700'
  if (lesson.publication_status === 'draft') { background = 'bg-gray-900' }
  if (lesson.publication_status === 'completed') { background = 'bg-clcnavy' }
  return <div>
    <div>
      <div>
        <div className="group flex flex-col sm:flex-row justify-between items-center cursor-pointer w-full p-3 font-graphik  bg-white hover:bg-white/10">
          <div className="flex items-center" data-cy="cy-calendar-event">
            <div className="mr-6">
              <div className="z-10 h-14 w-14 overflow-hidden rounded border border-gray-900/5 font-graphik transition duration-200 hover:border-gray-900/10 group-hover:shadow-sm">
                <div className={`text-xs font-medium uppercase tracking-wider text-white border-b py-1 text-center ${background}`} >{formatInTimezone(lesson.publish_at_utc, currentUser.timezone, 'EEE')}</div>
                <div className="bg-white text-center text-lg font-medium text-gray-900">{formatInTimezone(lesson.publish_at_utc, currentUser.timezone, 'd')}</div>
              </div>
            </div>
            <div className='flex flex-col'>
              {lesson.publication_status != 'draft' && <a href={`/lessons/${lesson.id}`} className="flex flex-col hover:bg-gray-100">
                <span className="prose-product-lg uppercase tracking-wider text-gray-700">
                  <div className="l2 flex items-center gap-x-5">Lesson {lesson.publication_status === 'draft' && <span className='text-red-600'>DRAFT</span>} </div>
                </span>
                <span className="text-lg font-bold">{lesson.name}</span>
                <div className="flex">
                  <svg className="ml-0 mt-0.5 mr-0.5 text-gray-700" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M8 14C11.3137 14 14 11.3137 14 8C14 4.68629 11.3137 2 8 2C4.68629 2 2 4.68629 2 8C2 11.3137 4.68629 14 8 14Z" stroke="currentColor" strokeWidth="1.2" strokeMiterlimit="10"></path>
                    <path d="M8 4.5V8H11.5" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                  </svg>
                  <div className="flex text-sm text-gray-700">
                    <div className="">{formatInTimezone(lesson.publish_at_utc, currentUser.timezone, 'EEE MMMM d, Y @ h:mmaaa zzz')}</div>
                  </div>
                </div>
              </a>
              }
              {lesson.publication_status === 'draft' && <>
                <span className="prose-product-lg uppercase tracking-wider text-gray-700">
                  <div className="l2 flex items-center gap-x-5">Lesson </div>
                </span>
                <span className="text-lg font-bold">{lesson.name}</span>
                <div className="flex">
                  <svg className="ml-0 mt-0.5 mr-0.5 text-gray-700" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M8 14C11.3137 14 14 11.3137 14 8C14 4.68629 11.3137 2 8 2C4.68629 2 2 4.68629 2 8C2 11.3137 4.68629 14 8 14Z" stroke="currentColor" strokeWidth="1.2" strokeMiterlimit="10"></path>
                    <path d="M8 4.5V8H11.5" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                  </svg>
                  <div className="flex text-sm text-gray-700">
                    <div className="">{formatInTimezone(lesson.publish_at_utc, currentUser.timezone, 'EEE MMMM d, Y @ h:mmaaa zzz')}</div>
                  </div>
                </div>
                </>
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
}

const CourseIndex = ({courseIdOverride}) => {
  const [ currentUser ] = useGlobalState('currentUser')
  const [ , setCurrentCourse ] = useGlobalState('currentCourse')
  const { id } = useParams()
  const [ course, setCourse ] = useState(null)
  const [ contents, setContents ] = useState([])
  const [ cohorts, setCohorts ] = useState([])
  const [ lessons, setLessons ] = useState([])
  const [ upcomingUserCohorts, setUpcomingUserCohorts ] = useState([])
  const [ completedUserCohorts, setCompletedUserCohorts ] = useState([])
  const [ events, setEvents ] = useState([])
  const [ attendingEventIds, setAttendingEventIds ] = useState([])
  const [ loading, setLoading ] = useState(false)
  const isComponentMounted = useRef(false)
  const courseId = id
  const { putpostRequest, getRequest } = useQuery()

  const join = () => { document.location.href = '/select_course' }

  useEffect(() => {
    setLoading(true)
    getRequest(`/api/v1/courses/${courseId}`, {}, (err, jsonData) => {
      if (err) { /* handled in hook */ return }
      console.log("DATA: "+JSON.stringify(jsonData))
      setCourse(jsonData.course)
      setCurrentCourse(jsonData.course)
      setContents(jsonData.contents)
      setCohorts(jsonData.cohorts)
      setEvents(jsonData.events)
      setUpcomingUserCohorts(jsonData.upcomingUserCohorts)
      setCompletedUserCohorts(jsonData.completedUserCohorts)
      setAttendingEventIds(jsonData.attendingEventIds)
      setLessons(jsonData.lessons)
      setLoading(false)
    })
    isComponentMounted.current = true
  }, [courseId])

  if (!isComponentMounted.current) { return <Loading /> }
  if (loading) { return <Loading /> }

  return (
    <div className="relative bg-gray-100 dark:bg-gray-700 pb-20 px-4 sm:px-6 pt-6">
      <div className="relative max-w-7xl mx-auto">

        {upcomingUserCohorts.length > 0 && <>
          <div className="relative mt-6">
            <div className="absolute inset-0 flex items-center" aria-hidden="true">
              <div className="w-full border-t border-gray-300" />
            </div>
            <div className="relative flex justify-start">
              <span className="pr-3 bg-gray-100 text-lg font-medium text-gray-900 dark:text-gray-300 dark:bg-gray-700"> Your enrolled {course.name} Course </span>
            </div>
          </div>
          <div className="max-w-lg mx-auto grid gap-5 lg:grid-cols-3 lg:max-w-none">
            {upcomingUserCohorts.map((cohort) => (
              <div key={`c${cohort.id}`} className='mt-1 pt-1 col-span-3'>
                <CohortRow cohort={cohort} alreadyEnrolled={cohort.alreadyEnrolled} alreadyTaken={cohort.alreadyTaken} />
              </div>
            ))}
          </div>
        </> }

        {cohorts.length > 0 && <>
          <div className="relative mt-6">
            <div className="relative flex justify-between">
              <span className="pr-3 bg-gray-100 text-lg font-medium text-gray-900 dark:text-gray-300 dark:bg-gray-700"> Upcoming {course.name} Courses </span>
              <PrimaryButton key='continue-button' onClick={join} text='Continue Enrollment' />
            </div>
          </div>
          <div className="max-w-lg mx-auto grid gap-5 lg:grid-cols-3 lg:max-w-none">
            {cohorts.map((cohort) => (
              <div key={`c${cohort.id}`} className='mt-1 pt-1 col-span-3'>
                <CohortRow cohort={cohort} alreadyEnrolled={cohort.alreadyEnrolled} alreadyTaken={cohort.alreadyTaken} />
              </div>
            ))}
          </div>
        </> }

        {completedUserCohorts.length > 0 && <>
          <div className="relative mt-6">
            <div className="absolute inset-0 flex items-center" aria-hidden="true">
              <div className="w-full border-t border-gray-300" />
            </div>
            <div className="relative flex justify-start">
              <span className="pr-3 bg-gray-100 text-lg font-medium text-gray-900 dark:text-gray-300 dark:bg-gray-700"> Completed {course.name} Courses </span>
            </div>
          </div>
          <div className="max-w-lg mx-auto grid gap-5 lg:grid-cols-3 lg:max-w-none">
            {completedUserCohorts.map((uc) => (
              <div key={`complucc${uc.id}`} className='mt-1 pt-1'>
                <a target="_blank" href={`https://www.closingcredits.com/certificates/${uc.id}.pdf`}>Download Letter of Completion</a>
              </div>
            ))}
          </div>
        </> }



        {events.length > 0 && <>
          <div className="relative my-6">
            <div className="absolute inset-0 flex items-center" aria-hidden="true">
              <div className="w-full border-t border-gray-300" />
            </div>
            <div className="relative flex justify-start">
              <span className="pr-3 bg-gray-100 text-lg font-medium text-gray-900 dark:text-gray-300 dark:bg-gray-700">Class Session Events</span>
            </div>
          </div>
          <div className='text-sm text-gray-500 dark:text-gray-400'>
            Other Closing Credits Events can be found on the <Link to='/events'>Events tab</Link>
          </div>
          <div className="mt-12 max-w-lg mx-auto grid gap-5 lg:grid-cols-3 lg:max-w-none">
            {events.map((event) => (
              <EventRow compact={true} key={`event${event.id}`} event={event} user={currentUser} attendingEventIds={attendingEventIds} />
            ))}
          </div>
        </> }

        {lessons.length > 0 && <>
          <div className="relative my-6">
            <div className="absolute inset-0 flex items-center" aria-hidden="true">
              <div className="w-full border-t border-gray-300" />
            </div>
            <div className="relative flex justify-start">
              <span className="pr-3 bg-gray-100 text-lg font-medium text-gray-900 dark:text-gray-300 dark:bg-gray-700">Course Lessons</span>
            </div>
          </div>
          <div className="mt-12 max-w-lg mx-auto grid gap-5 lg:grid-cols-3 lg:max-w-none">
          {lessons.map((lesson, idx) => (
          <div key={`teacpacoh${lesson.id}`}>
            <LessonRow key={`teaccoh${lesson.id}`} lesson={lesson} currentUser={currentUser}/>
          </div>
        ))}
          </div>
        </> }
      </div>
    </div>
  ) 
}

export default CourseIndex

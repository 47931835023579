import React, { useEffect, useRef, useState } from 'react'
import useQuery from '@/hooks/useQuery'
import { Link } from 'react-router-dom'
import { formatInTimezone } from '@/utils/date'
import { LinkIcon, DuplicateIcon, ReceiptTaxIcon, ClockIcon, ArrowDownIcon, ArrowUpIcon, ExclamationIcon, CalendarIcon } from '@heroicons/react/solid'
import Loading from '@/shared/Loading'
import { useGlobalState } from '@/state'
import { Modal, useModal } from '@/shared/Modal'
import EventRow from '@/pages/teachers/EventRow'

const TeacherPayoutRow = ({payout, currentUser}) => {
  const { dollars, payout_amount, students, total_revenue } = payout.data

  let background = 'bg-green-700'
  if (payout.status === 'finalized') { background = 'bg-yellow-600' }
  if (payout.status === 'paid') { background = 'bg-gray-800' }
  if (payout.status === 'upcoming') { background = 'bg-red-500' }

  return <div>
    <div className="group flex justify-between items-center cursor-pointer w-full p-3 font-graphik  bg-white">
      <div className="flex gap-x-5 items-center" data-cy="cy-calendar-event">
        <a href={`/teachers/cohort/${payout.cohortId}/edit`} className='hover:bg-gray-50 dark:hover:bg-gray-900' >
          <div className='w-60 sm:w-48'>
            <div className="aspect-w-16 aspect-h-9 mt-2 sm:mt-0">
              <img className="w-full object-cover  rounded-lg" src={payout.imageUrl} alt="" />
            </div>
          </div>
        </a>
        <div className='flex flex-col'>
          <a href={`/teachers/cohorts/${payout.cohortId}/edit`} className="flex flex-col hover:bg-gray-100">
            <span className="prose-product-lg uppercase tracking-wider text-gray-700">
              <div className="l2 flex items-center gap-x-5">
                <span>{payout.cohortId}: {payout.moniker}</span>
              </div>
            </span>
            <span className="text-lg font-bold">Course: {payout.courseId} {payout.name}</span>
            <div className="flex">
              <svg className="ml-0 mt-0.5 mr-0.5 text-gray-700" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8 14C11.3137 14 14 11.3137 14 8C14 4.68629 11.3137 2 8 2C4.68629 2 2 4.68629 2 8C2 11.3137 4.68629 14 8 14Z" stroke="currentColor" strokeWidth="1.2" strokeMiterlimit="10"></path>
                <path d="M8 4.5V8H11.5" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
              </svg>
              <div className="flex text-sm text-gray-700">
                <div className="font-semibold">{formatInTimezone(payout.startsAtUtc, currentUser.timezone, 'EEE MMMM d, Y')} <span className='font-normal'>to</span> {formatInTimezone(payout.endsAtUtc, currentUser.timezone, 'EEE MMMM d, Y')}</div>
              </div>
            </div>
            <span className='text-gray-500 dark:text-gray-300 text-sm flex mt-1'><ReceiptTaxIcon className='w-5 h-5 mr-2' />{payout.creditCost} credits (or ${payout.creditCost * 10})</span>
          </a>
        </div>
      </div>
      <div className='flex space-x-3 items-center'>
        <div className="px-4 py-5 sm:p-6">
          <dt className="text-base font-normal text-gray-900">Students</dt>
          <dd className="mt-1 flex justify-between items-baseline md:block lg:flex">
            <div className={`flex items-baseline text-2xl font-semibold ${ payout.userCohortsCount === payout.capacity ? 'text-green-500' : 'text-gray-500' }`}>
              {payout.userCohortsCount}
              <span className={`ml-2 text-sm font-medium ${ payout.userCohortsCount === payout.capacity ? 'text-green-500' : 'text-gray-500' }`}>of {payout.capacity}</span>
            </div>
          </dd>
        </div>
        <div className="px-4 py-5 sm:p-6">
          <dt className="text-base font-normal text-gray-900">Total Revenue</dt>
          <dd className="mt-1 flex justify-between items-baseline md:block lg:flex">
            <div className={`flex items-baseline text-2xl font-semibold'text-gray-500`}>
              <span className={`ml-2 text-sm font-mediumtext-gray-500`}>${total_revenue} USD</span>
            </div>
          </dd>
        </div>
        <div className="px-4 py-5 sm:p-6">
          <dt className="text-base font-normal text-gray-900">Payout Amount</dt>
          <dd className="mt-1 flex justify-between items-baseline md:block lg:flex">
            <div className={`flex items-baseline text-2xl font-semibold'text-gray-500`}>
              <span className={`ml-2 text-sm font-mediumtext-gray-500`}>${payout_amount} USD</span>
            </div>
          </dd>
        </div>
        <span className='flex flex-col gap-y-3 border border-1 border-black p-2 w-48'>
          <div className='text-sm'>"<b>{payout.part}</b>" Payout Info id: {payout.id}</div>
          <div className={`p-2 text-white rounded-md ${background} text-xs`}>{payout.status}</div>
          <div className="flex flex-col text-xs text-gray-700">
            <span>
              Planned: <div className="font-semibold">{formatInTimezone(payout.plannedPaidAtUtc, currentUser.timezone, 'EEE MMMM d, Y')}</div>
            </span>
            { payout.paidAtUtc && <span>
              Paid: <div className="font-semibold">{formatInTimezone(payout.paidAtUtc, currentUser.timezone, 'EEE MMMM d, Y')}</div>
            </span> }
          </div>
        </span>
      </div>
    </div>
  </div>
}

const PayoutsIndex = (props) => {
  const isComponentMounted = useRef(false)
  const [ currentUser ] = useGlobalState('currentUser')
  const { getRequest } = useQuery()
  const [onlyShow, setOnlyShow] = useState('all')
  const [payouts, setPayouts] = useState([])
  const monthYear = useRef(null)
  const { courseId } = props

  useEffect(() => {
    getRequest(`/api/v1/teachers/courses/${courseId}/payouts`, {}, (err, jsonData) => {
      if (err) { /* handled in hook */ return }
      setPayouts(jsonData.payouts)
    })
    isComponentMounted.current = true
  }, [])

  if (!isComponentMounted.current) { return <Loading full={true} /> }

  return <>
      <div>
        <label htmlFor="location" className="block text-sm font-medium text-gray-700">
          Only Show
        </label>
        <select onChange={(e) => setOnlyShow(e.target.value)} className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-clcpurple focus:border-clcpurple sm:text-sm rounded-md" >
          <option value='all'>all</option>
          <option value='paid'>Paid</option>
          <option value='upcoming'>Upcoming</option>
          <option value='finalized'>Finalized</option>
        </select>
      </div>
    <div className="border-1 mt-6 w-full overflow-hidden rounded-2xl border border-black/[0.03] last:h-full">
      { payouts.map((payout, idx) => {
        if (onlyShow !== 'all' && payout.status !== onlyShow) { return null }

        let showMonth = idx === 0 || monthYear.current !== formatInTimezone(payout.plannedPaidAtUtc, currentUser.timezone, 'MMM yyyy')
        monthYear.current = formatInTimezone(payout.plannedPaidAtUtc, currentUser.timezone, 'MMM yyyy') 
        return <div key={`teacpacoh${payout.id}`}>
          { showMonth && <div className="w-full">
            <div className="h-10 bg-black/[.03] px-4 py-2">
              <h3>{formatInTimezone(payout.plannedPaidAtUtc, currentUser.timezone, 'MMM yyyy')}</h3>
            </div>
          </div> }
          <TeacherPayoutRow key={`teaccoh${payout.id}`} payout={payout} onlyShow={onlyShow} currentUser={currentUser} />
        </div>
      })}
    </div>
  </>
}

export default PayoutsIndex

import React, {useState, useEffect} from 'react'
import useQuery from '@/hooks/useQuery'
import { useForm } from 'react-hook-form'
import FroalaMinimalWrapper from '@/shared/FroalaMinimalWrapper'
import { ExclamationCircleIcon, EmojiHappyIcon, LinkIcon, DuplicateIcon } from '@heroicons/react/solid'
import PrimaryButton from '@/shared/Buttons/Primary'

import { showNonSpaToast } from '@/utils/nonSpaToast'
import { BulletItem, CaretItem } from '@/pages/teachers/Launch/Items'

import SchoolImage from '@/pages/teachers/SchoolImage'

const SchoolEdit = ({school}) => {
  const [showForm, setShowForm] = useState(true)
  const { putpostRequest } = useQuery()
  const { formState, reset, register, unregister, handleSubmit, setValue, watch, getValues, setError } = useForm({
   defaultValues: {
      name: school.name,
      about: school.about,
      slug: school.slug
    }
  })
  const { isDirty, errors } = formState
  const [loading, setLoading] = useState(false)
  const handleUpdateFroala = (key, val) => {
    setValue(key, val, { shouldDirty: true, shouldValidate: true })
  }

  useEffect(() => {
    register('about', { required: true })
    return () => {
      unregister('about')
    }
  }, [register])

  const onSubmit = (data) => {
    setLoading(true)
    putpostRequest(`/api/v1/teachers/schools/${school.id}`, 'PATCH', data, (err, jsonData) => {
      setLoading(false)
      console.log(err)
      if (err) {
        showNonSpaToast("Error", false)
        if (err.slug) {
          if (err.slug[0] === 'has already been taken') { setError('slug', { type: 'custom', message: 'unique' }) }
          if (err.slug[0] !== 'has already been taken') { setError('slug', { type: 'custom', message: 'format' }) }
        }
        return
      }

      showNonSpaToast("Basic Info Saved", true)
    })
  }

  const watchSlug = watch('slug')
  const about = getValues().about

  return <form>
    <div className={`group mt-6 ${showForm ? ' rounded-xl border border-solid border-gray-900 shadow-lg' : ''}`}>
      <div onClick={() => setShowForm(!showForm)} className="prose-product-sm relative flex min-h-16 items-center overflow-hidden p-3 sm:prose-product-lg border-b rounded-t-xl border-gray-200 hover:bg-gray-100 bg-gray-100 dark:bg-gray-800 cursor-pointer">
        <div className="flex grow items-center justify-between">
          <div className="flex">
            <svg className="m-auto" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fillRule="evenodd" clipRule="evenodd" d="M3 10C3 6.13401 6.13401 3 10 3C13.866 3 17 6.13401 17 10C17 13.866 13.866 17 10 17C6.13401 17 3 13.866 3 10ZM10 2C5.58172 2 2 5.58172 2 10C2 14.4183 5.58172 18 10 18C14.4183 18 18 14.4183 18 10C18 5.58172 14.4183 2 10 2ZM13.7827 8.48668C13.9825 8.29601 13.9898 7.97951 13.7992 7.77976C13.6085 7.58001 13.292 7.57265 13.0923 7.76332L8.85414 11.8088L6.90774 9.95083C6.70799 9.76015 6.3915 9.76751 6.20083 9.96726C6.01015 10.167 6.01751 10.4835 6.21726 10.6742L8.5089 12.8617C8.70211 13.0461 9.00617 13.0461 9.19938 12.8617L13.7827 8.48668Z" fill="#009E3D"></path>
            </svg>
            <div className="ml-4">
              <div className="text-gray-600 dark:text-gray-300  flex items-center space-x-3">
                <h4>Edit your school details</h4>
              </div>
            </div>
          </div>
          <div className="mr-4 text-gray-400">
            <CaretItem show={showForm} />
          </div>
        </div>
      </div>
      { showForm && <div className="p-8 pt-5">
        <div className="space-y-6">
          <div>
            <div className="prose-product-lg">
              <h5 className="flex justify-between text-gray-800"><label className="mb-1">School name</label></h5>
            </div>
            <div className="prose-product-sm flex items-center sm:prose-product-lg">
              <input type="text" className={errors.name ? 'errors placeholder-shown:italic placeholder-gray-600 p2 py-3.5 px-4 w-full border rounded-lg' : 'placeholder-shown:italic placeholder-gray-600 p2 py-3.5 px-4 w-full border rounded-lg'} {...register('name', { required: true }) } defaultValue={school.name} placeholder="Public Speaking 101" />
            </div>

            { errors.name && <div className='mt-2 text-sm text-red-600'>This field is required.</div> }
          </div>
          <div>
            <div>
              <div className="prose-product-sm flex justify-between sm:prose-product-lg">
                <div className="prose-product-lg">
                  <h5 className="flex justify-between text-gray-800"><label className="mb-1">School URL</label></h5>
                </div>
              </div>
              <div className="prose-product-sm flex items-center sm:prose-product-lg">
                <div className="rounded-l-lg border border-r-0 border-gray-300 bg-gray-100 py-3.5 px-3 text-gray-600 shadow-sm shrink-0">closingcredits.com/schools/</div>
                <input type="text" className={errors.slug ? 'errors placeholder-shown:italic placeholder-gray-600 p2 py-3.5 px-4 w-full border rounded-lg rounded-r-lg' : 'placeholder-shown:italic placeholder-gray-600 p2 py-3.5 px-4 w-full border rounded-none'} {...register('slug', { required: true }) } defaultValue={school.slug} placeholder="public-speaking-101" />
              </div>
              { errors.slug?.type === 'required' && <div className='mt-2 text-sm text-red-600'>This field is required.</div> }
              { errors.slug?.message === 'unique' && <div className='mt-2 text-sm text-red-600'>Already in use by someone</div> }
              { errors.slug?.message === 'format' && <div className='mt-2 text-sm text-red-600'>Numbers 0-9, Letters a-z, hyphens -, or underlines _ only</div> }
              <div className="mt-1 flex justify-between ">
                <div className="prose-product-sm sm:prose-product-lg">
                  <p className="text-xs text-gray-600 dark:text-gray-400">Updating this URL will break any links that you have shared.</p>
                </div>
              </div>
              <pre>https://www.closingcredits.com/schools/{watchSlug}</pre>
            </div>
            <hr />
            <div className='py-4'>
              <div className="prose-product-sm flex justify-between sm:prose-product-lg">
                <div className="prose-product-lg">
                  <h5 className="flex justify-between text-gray-800"><label className="mb-1">About</label></h5>
                </div>
              </div>
              <div className="prose-product-sm flex items-center sm:prose-product-lg">
                { errors.about && <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                  <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
                </div> }
                <FroalaMinimalWrapper
                  hideButtons={false}
                  showCharacterCounter={false}
                  model={about}
                  updateModel={(val) => handleUpdateFroala('about', val)}
                />
              </div>
              { errors.desc && <div className='mt-2 text-sm text-red-600'>This field is required.</div> }
            </div>
          </div>
          <PrimaryButton onClick={handleSubmit(onSubmit)} loading={loading} text="Save" />
        </div>
      </div> }
    </div>

    <SchoolImage school={school} />
  </form>
}

export default SchoolEdit

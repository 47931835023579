import React, { useState, useEffect, useRef, useReducer } from 'react'
import { Link, useParams } from 'react-router-dom'
import { formatInTimezone } from '@/utils/date'

import { useGlobalState } from '@/state'
import useQuery from '@/hooks/useQuery'
import PrimaryButton from '@/shared/Buttons/Primary'
import DefaultButton from '@/shared/Buttons/Default'
import { Modal, useModal } from '@/shared/Modal'
import TicketTypes from '@/shared/TicketTypes'

import classNames from '@/utils/classNamesLocal'
import Loading from '@/shared/Loading'
import EventRow from '@/shared/EventRow'

import {
  CalendarIcon,
  ChatAltIcon,
  LockOpenIcon,
} from '@heroicons/react/solid'

export default function Event(props) {
  const [ currentUser ] = useGlobalState('currentUser')
  const [ currentEvent, setCurrentEvent ] = useGlobalState('currentEvent')
  const { id } = useParams()
  const [, setToast] = useGlobalState('toast')
  const { getRequest, putpostRequest } = useQuery()
  const isComponentMounted = useRef(false)
  const { openModal, closeModal, isOpen } = useModal()

  const [state, setState] = useReducer(
    (state, newState) => (
      {...state, ...newState}), {
        event: null,
        eventHosts: [],
        attendingEventIds: []
      }
    )
  const { event, eventHosts, attendingEventIds } = state

  const [ attending, setAttending ] = useState(false)
  const [ loading, setLoading ] = useState(false)
  const [ hideFullDescription, setHideFullDescription ] = useState(false)
  const attendNow = () => { window.location = `/events/links/${event.uuid}` }

  const toggleAttend = (ticketType) => {
    setLoading(true)
    let data = {
      ticket_type_id: ticketType
    }
    putpostRequest(`/api/v1/events/${event.id}/attend`, 'POST', data, (err, jsonData) => {
      setLoading(false)
      if (err) {
        return
      }

      if (ticketType) {
        setAttending(attending === ticketType ? null : ticketType)
      } else {
        setAttending(!attending)
      }
      if (!attending === true) {
        setToast(<div className="ml-3 w-0 flex-1 pt-0.5">
          <p className="text-sm font-medium dark:text-green-100 text-green-600">Attending!</p>
          <p className="mt-1 text-sm dark:text-green-300 text-green-600">You're going! You'll get an email about this.</p>
        </div>)
      } else { 
        setToast(<div className="ml-3 w-0 flex-1 pt-0.5">
          <p className="text-sm font-medium dark:text-yellow-100 text-yellow-600">RSVP is canceled</p>
          <p className="mt-1 text-sm dark:text-gray-200 text-gray-900">You are not attending this event anymore.</p>
        </div>)
      }
    })
  }

  useEffect(() => {
    if (event === null) { return }
    if (Object.keys(event.ticketTypes).length === 0) {
      setAttending(Object.keys(attendingEventIds).includes(event.id.toString()))
    } else {
      setAttending(attendingEventIds[event.id])
    }
  }, [attendingEventIds])

  useEffect(() => {
    setCurrentEvent(event)
    if (event) {
      setHideFullDescription(event.summary || event.recordingUrl)
    }
  }, [event])

  useEffect(() => {
    getRequest(`/api/v1/events/${id}`, {}, (err, jsonData) => {
      if (err) { /* handled in hook */ return }

      setState( {
        event: jsonData.event,
        eventHosts: jsonData.eventHosts,
        attendingEventIds: jsonData.attendingEventIds
      })
    })
    isComponentMounted.current = true
  }, [])

  if (!isComponentMounted.current) { return <Loading /> }
  if (event === null) {
    return <div className="rounded-md bg-yellow-50 p-4 mt-10">
      <div className="flex">
        <div className="ml-3">
          <div className='pb-3 text-sm'>
            This is a member-only event. You must be a member to attend.
          </div>
          <h3 className="text-sm font-medium text-yellow-800">
            If you'd like to join the member only events, discussion groups, and many others, please <Link to='/subscriptions'>upgrade to the membership</Link>.
          </h3>
        </div>
      </div>
    </div>
  }
  const modalTickets = Object.keys(event.ticketTypes).length > 0

  return <>
    <main className="flex-1 relative overflow-y-auto focus:outline-none">
      <div className="py-8 xl:py-10">
        <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:px-8 xl:max-w-5xl xl:grid xl:grid-cols-3">
          <div className="xl:col-span-2 xl:pr-8 xl:border-r xl:border-gray-200">
            <div>
              <div className='mb-2  md:space-x-4 xl:border-b xl:pb-6'>
                <div className="aspect-w-16 aspect-h-9">
                  <img className="rounded-lg shadow-lg object-cover object-center h-full w-full" src={event.imageUrl} />
                </div>
              </div>
              <div>
                <div className="md:flex md:items-center md:justify-between md:space-x-4 xl:border-b xl:pb-6">

                  <div className="flex-shrink-0">
                    <span className="inline-flex items-center justify-center h-12 w-12 rounded-full bg-gray-500">
                      <span className="leading-none text-white">
                        <div className='flex flex-col justify-center items-center'>
                          <span>{formatInTimezone(event.startsAtUtc, currentUser.timezone, 'MMM')}</span>
                          <span>{formatInTimezone(event.startsAtUtc, currentUser.timezone, 'd')}</span>
                        </div>
                      </span>
                    </span>
                  </div>
                  <div>
                    <p className="mt-2 text-sm text-gray-500 dark:text-gray-300">
                      <i>{event.kind}</i>
                    </p>
                  </div>
                  <div className="mt-4 flex space-x-3 md:mt-0">
                    { event.status !== 'completed' && <>
                      { modalTickets && <>
                        { event.status !== 'started' && <>
                          { !attending && <PrimaryButton loading={loading} onClick={openModal} text='Attend' /> }
                          { attending && <DefaultButton loading={loading} onClick={openModal} text='Cancel attendance' /> }
                        </> }
                      </> }
                      { !modalTickets && <>
                        { event.courseId === null && !currentUser.canAttendMembersOnlyEvents && event.admittance === 'members-only' ? (
                          <>
                            { !attending && <PrimaryButton loading={loading} onClick={openModal} text='Attend' /> }
                            { attending && <DefaultButton loading={loading} onClick={openModal} text='Cancel attendance' /> }
                          </>
                        ) : (
                          <>
                            { !attending && <PrimaryButton loading={loading} onClick={() => toggleAttend(null)} text='Attend' /> }
                            { attending && <DefaultButton loading={loading} onClick={() => toggleAttend(null)} text='Cancel attendance' /> }
                          </>
                        )}

                      </> }
                      { event.status === 'started' && <>
                        <PrimaryButton loading={loading} onClick={attendNow} text='Attend Event Now' />
                      </> }
                    </> }
                  </div>
                </div>
                <div className="py-3 xl:pt-6 xl:pb-0">
                  { !currentUser.canAttendMembersOnlyEvents && event.courseId === null && event.admittance === 'members-only' && <div className="bg-yellow-50 border-l-4 border-yellow-400 p-4 my-2">
                    <div className="flex">
                      <div className="flex-shrink-0">
                        <svg className="h-5 w-5 text-yellow-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                          <path fill-rule="evenodd" d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z" clip-rule="evenodd" />
                        </svg>
                      </div>
                      <div className="ml-3">
                        <p className="text-sm text-yellow-700"> Only Closing Credits members can attend this event. <a  className="ml-1 font-medium text-clcnavy dark:text-clcpurple" href='/subscriptions'>Please join to attend</a>. </p>
                      </div>
                    </div>
                  </div> }

                  <h2 className='text-2xl'>Description</h2>
                  <div className="prose max-w-none">
                    { hideFullDescription && <>
                      <p className="mt-3 text-base text-gray-500 dark:text-gray-300 line-clamp-3"><span dangerouslySetInnerHTML={{__html: event.description}} /></p>
                      <span className='text-clcnavy cursor-pointer' onClick={() => setHideFullDescription(!hideFullDescription)}> Show More...</span>
                    </> }
                    { !hideFullDescription && <p className="mt-3 text-base text-gray-500 dark:text-gray-300" dangerouslySetInnerHTML={{__html: event.description}}></p> }
                  </div>
                  { eventHosts.length > 0 && <>
                    <h2 className='text-2xl'>Hosted By</h2>
                    <div className="prose max-w-none">
                      { eventHosts.map((eventHost, idx) => (
                        <div className="flex flex-col">
                          <div className="flex items-center space-x-3">
                            <div className="flex-shrink-0">
                              <span className="inline-flex items-center justify-center h-20 w-20">
                                <span className="leading-none text-white">
                                  <div className='flex flex-col justify-center items-center'>

                                    <img className='h-20 w-20 rounded-md' src={eventHost.imageUrl} alt={eventHost.name} />
                                  </div>
                                </span>
                              </span>
                            </div>
                            <div className="text-sm font-medium text-gray-900  dark:text-gray-100">{eventHost.name}</div>
                          </div>
                          <p className="text-base text-gray-500 dark:text-gray-300"><span dangerouslySetInnerHTML={{__html: eventHost.bio}} /></p>
                        </div>
                      ))}
                    </div>
                  </> }
                </div>
                {(event.cccProjectUrl) && (
                  <div className="mt-6 border-t border-gray-200 py-6 space-y-8">
                    <h2 className='text-2xl mb-3'>Homework</h2>
                    <p>Please submit your homework on Casting Call Club</p>
                    <a href={event.cccProjectUrl} target="_blank">{event.cccProjectUrl}</a>
                  </div>
                )}
                {event.status === 'completed' && (event.summary || event.recordingUrl) && (
                  <div className="mt-6 border-t border-gray-200 py-6 space-y-8">
                    <h2 className='text-2xl mb-3'>Event Summary / Follow-up</h2>
                    { event.recordingUrl?.includes('loom.com') && <>
                      <div style={{position: 'relative', paddingBottom: "56.25%", height: '0'}}>
                        <iframe src={`${event.recordingUrl}?hide_owner=true&hide_share=true&hide_title=true&hideEmbedTopBar=true`}
                          frameBorder="0" webkitallowfullscreen='true' mozallowfullscreen='true' allowFullScreen
                          style={{position: 'absolute', top: '0', left: '0',width: '100%', height: '100%'}}
                        ></iframe>
                      </div>
                    </>}
                    { event.recordingUrl && !event.recordingUrl.includes('loom.com') && <a href={event.recordingUrl} target="_blank">Watch the Recording Here</a> }
                    <div className="prose max-w-none">
                      <p className="mt-3 text-base text-gray-500 dark:text-gray-300" dangerouslySetInnerHTML={{__html: event.summary}}></p>
                    </div>
                  </div> 
                )}
              </div>
            </div>
          </div>
          <aside className="xl:pl-8">
            <h2 className="sr-only" hidden>Details</h2>
            <div className="space-y-5">
              <div className="flex items-center space-x-2">
                <LockOpenIcon className="h-5 w-5 text-green-500" aria-hidden="true" />
                <span className="text-green-700 dark:text-green-400 text-sm font-medium">
                  { event.status === 'published' && <span>Upcoming Event</span> }
                  { event.status === 'started' && <span>Event has started!</span> }
                  { event.status === 'completed' && <span>Event has ended</span> }
                </span>
              </div>
              <div className="flex items-center space-x-2">
                <ChatAltIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                <span className="text-gray-900 dark:text-gray-100 text-sm font-medium">{event.attendeesCount} attendees</span>
              </div>
              <div className="flex items-center space-x-2">
                <CalendarIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                <span className="text-gray-900 dark:text-gray-100 text-sm font-medium">
                  { event.status === 'started' && <span>Event has started!</span> }
                  { event.status !== 'started' && <time dateTime={event.startsAtUtc}>
                    {formatInTimezone(event.startsAtUtc, currentUser.timezone, 'EEE MMMM d, Y h:mmaaa - ')}
                    {formatInTimezone(event.endsAtUtc, currentUser.timezone, 'h:mmaaa zzz')}
                  </time> }
                  <span className='text-gray-500 dark:text-gray-400 ml-2'>( {event.lengthOfEvent} min )</span>
                </span>
              </div>
            </div>
            { eventHosts.length > 0 && <div className="mt-6 border-t border-gray-200 py-6 space-y-8">
              <div>
                <h2 className="text-sm font-medium text-gray-500">Hosted by</h2>
                <ul className="mt-3 space-y-3">
                  { eventHosts.map((eventHost, idx) => (
                    <li className="flex justify-start">
                      <div className="flex items-center space-x-3">
                        <div className="flex-shrink-0">
                          <span className="inline-flex items-center justify-center h-12 w-12 rounded-full bg-gray-500">
                            <span className="leading-none text-white">
                              <div className='flex flex-col justify-center items-center'>

                                <img className='h-12 w-12 rounded-md' src={eventHost.imageUrl} alt={eventHost.name} />
                              </div>
                            </span>
                          </span>
                        </div>
                        <div className="text-sm font-medium text-gray-900  dark:text-gray-100">{eventHost.name}</div>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </div> }
          </aside>
        </div>
      </div>
    </main>
    <Modal isOpen={isOpen} closeModal={closeModal} >
      <TicketTypes
        event={event}
        attending={attending}
        toggleAttend={toggleAttend}
        loading={loading}
        userId={currentUser.id}
        />
      { !currentUser.canAttendMembersOnlyEvents && event.courseId === null && event.admittance === 'members-only' && <>
        <div className="flex">
          <div className="flex-shrink-0">
            <svg className="h-5 w-5 text-yellow-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z"  />
            </svg>
          </div>
          <div className="ml-3">
            <p className="text-sm text-yellow-700"> Only Closing Credits members can attend this event. <a  className="ml-1 font-medium text-clcnavy dark:text-clcpurple" href='/subscriptions'>Please join to attend</a>. </p>
          </div>
        </div><br />
      </>
      }
      <button className='inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white dark:bg-gray-900 dark:text-white dark:hover:bg-gray-800 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2' onClick={closeModal}>Okay</button>
    </Modal>
  </>
}

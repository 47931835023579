import React, { useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { debounce } from 'throttle-debounce'
import { formatInTimezone } from '@/utils/date'
import { ExclamationCircleIcon, EmojiHappyIcon } from '@heroicons/react/solid'

import useQuery from '@/hooks/useQuery'
import { useGlobalState } from '@/state'
import { randomToastSuccess } from '@/shared/Toast'
import Tippy from '@tippyjs/react'
import ToolTip from '@/shared/ToolTip'

import PrimaryButton from '@/shared/Buttons/Primary'
import CohortRow from '@/shared/CohortRow'
import UnscheduledCohortRow from '@/shared/UnscheduledCohortRow'

const SelectCourseForm = (props) => {
  let history = useHistory()
  const [currentUser] = useGlobalState('currentUser')
  const { cohorts, cohortInterest, unscheduledCohorts, interestedCohortCost, following } = props
  const [ selectedCohorts, setSelectedCohorts ] = useState(cohortInterest)
  const [ totalCost, setTotalCost ] = useState(interestedCohortCost)
  const monthYear = useRef(null)

  const updateCost = (cost) => {
    setTotalCost(totalCost + cost)
  }

  const promoCode = () => { history.push('/promo_code') }

  return (
    <div className="relative bg-gray-100 dark:bg-gray-700 text-gray-700 dark:text-gray-300 pb-20 px-0 sm:px-6 pt-6">
      <button className='text-clcnavy' onClick={promoCode}>Have a promo code?</button>
      <div className="relative max-w-7xl mx-auto">
        <div className="mt-4 max-w-lg mx-auto grid gap-5 sm:max-w-none">
          {cohorts.map((cohort, idx) => {
            let showMonth = idx === 0 || monthYear.current !== formatInTimezone(cohort.startsAtUtc, currentUser.timezone, 'MMM yyyy')
            monthYear.current = formatInTimezone(cohort.startsAtUtc, currentUser.timezone, 'MMM yyyy') 

            return <span key={`c${cohort.id}`}>
              { showMonth && <div className="w-full">
                <div className='flex justify-center text-xl leading-6 leading-6 py-2 font-medium text-gray-900 dark:text-gray-100'>
                  <h3>Courses starting in {formatInTimezone(cohort.startsAtUtc, currentUser.timezone, 'MMM, yyyy')}</h3>
                </div>
              </div> }
              <CohortRow
                cohort={cohort}
                cohortInterest={cohortInterest}
                updateCost={updateCost}
                following={following}
              />
            </span>
          })}
        </div>
        { unscheduledCohorts.length > 0 && <div className="mt-4 max-w-lg mx-auto grid gap-5 lg:max-w-none">
          <div className='flex justify-center text-xl leading-6 leading-6 py-2 font-medium text-gray-900 dark:text-gray-100'>
            Courses with no start date yet
          </div>
          {unscheduledCohorts.map((cohort) => <UnscheduledCohortRow cohort={cohort} following={following} /> )}
        </div> }
      </div>
    </div>
  )
}

export default SelectCourseForm

import React, {useState, useEffect} from 'react'
import useQuery from '@/hooks/useQuery'
import { useForm } from 'react-hook-form'
import { ExclamationCircleIcon, EmojiHappyIcon, LinkIcon, DuplicateIcon } from '@heroicons/react/solid'
import PrimaryButton from '@/shared/Buttons/Primary'

import { showNonSpaToast } from '@/utils/nonSpaToast'
import { BulletItem, CaretItem } from '@/pages/teachers/Launch/Items'

import ShowSchedule from '@/pages/teachers/Launch/Schedule'
import ShowCommunity from '@/pages/teachers/Launch/Community'
import ShowLogistics from '@/pages/teachers/Launch/Logistics'

const EnableEnrollment = ({course}) => {
  const [show, setShow] = useState(false)
  const [done, setDone] = useState(course?.launch?.contactlist === 1)

  return <div className={`group ${show? 'rounded-xl border border-solid border-gray-900 shadow-lg' : ''}`}>
    <div onClick={() => setShow(!show)} className={done ? 'prose-product-sm relative flex min-h-16 items-center p-3 sm:prose-product-lg border-b rounded-t-xl border-gray-200 hover:bg-gray-100 bg-gray-100 dark:bg-gray-800 cursor-pointer' : 'prose-product-sm relative flex min-h-16 items-center p-3 sm:prose-product-lg  border-gray-200 hover:bg-gray-100  cursor-pointer'}>
      <div className="flex grow items-center justify-between">
        <div className="flex dark:text-gray-300">
          <BulletItem done={done} />
          <div className="ml-4">
            <div className="flex items-center space-x-3">
              <h4>Enable Enrollment</h4>
            </div>
          </div>
        </div>
        <div className="mr-4 text-gray-400">
          <CaretItem show={show} />
        </div>
      </div>
    </div>
    { show && <div className="p-8 pt-5">
      <div className="flex space-x-6">
        <div className="p2 space-y-1 text-gray-900">
          <p className="p4 mt-2 text-gray-600">By turning this setting on, students can begin to enroll in this cohort.</p>
          radio button
        </div>
      </div>
    </div> }
  </div>
}


const LaunchStep3 = (props) => {
  const { course } = props
  const [showSchedule, setShowSchedule] = useState(true)
  const [showCommunity, setShowCommunity] = useState(true)
  const [showLogistics, setShowLogistics] = useState(true)
  return ( <>
    <ul className="space-y-12">
      <li>
        <div onClick={() => setShowSchedule(!showSchedule)} className="mb-3 flex cursor-pointer items-center space-x-2 text-clcnavy">
          { !showSchedule && <div className="rounded-full bg-clcnavy bg-opacity-5 p-3">
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M6 3L11 8L6 13" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
            </svg>
          </div> }
          { showSchedule && <div className="rounded-full bg-clcnavy bg-opacity-5 p-3">
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M13 6L8 11L3 6" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
            </svg>
          </div> }
          <p className="text-large">Course Schedule</p>
        </div>
        { showSchedule && <ShowSchedule course={course} /> }
      </li>
      <li>
        <div onClick={() => setShowCommunity(!showCommunity)} className="mb-3 flex cursor-pointer items-center space-x-2 text-clcnavy">
          { !showCommunity && <div className="rounded-full bg-clcnavy bg-opacity-5 p-3">
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M6 3L11 8L6 13" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
            </svg>
          </div> }
          { showCommunity && <div className="rounded-full bg-clcnavy bg-opacity-5 p-3">
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M13 6L8 11L3 6" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
            </svg>
          </div> }
          <p className="text-large">Setup Your Course Community</p>
        </div>
        { showCommunity && <ShowCommunity course={course} /> }
      </li>
      <li>
        <div onClick={() => setShowLogistics(!showLogistics)} className="mb-3 flex cursor-pointer items-center space-x-2 text-clcnavy">
          { !showLogistics && <div className="rounded-full bg-clcnavy bg-opacity-5 p-3">
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M6 3L11 8L6 13" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
            </svg>
          </div> }
          { showLogistics && <div className="rounded-full bg-clcnavy bg-opacity-5 p-3">
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M13 6L8 11L3 6" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
            </svg>
          </div> }
          <p className="text-large">Final Logistics</p>
        </div>
        { showLogistics && <ShowLogistics course={course} /> }
      </li>
    </ul>
    <div className='mt-10'>
      <div className="bg-clcnavy bg-opacity-5 border-l-4 border-clcnavy p-4">
        <div className="flex">
          <div className="flex-shrink-0">
            <svg className="h-5 w-5 text-clcpurple" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path fill-rule="evenodd" d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z" clip-rule="evenodd" />
            </svg>
          </div>
          <div className="ml-3">
            <p className="text-sm text-clcpurple"> Once you push this button, students will be able to enroll to your course. </p>
          </div>
        </div>
      </div>
      <a className='mt-6' href={`/teachers/courses/${course.id}/publish`}>
        <div className="prose-product-sm sm:prose-product-lg">
          <button className="mt-2 justify-center items-center flex shadow-sm hover:shadow-md disabled:shadow-none ease-out duration-200 font-medium disabled:cursor-default h-10 px-4 b2 rounded-lg bg-gradient-to-r from-clcnavy to-clcpurple  text-white hover:bg-clcnavy disabled:bg-gray-500" type="button">
            Enable Enrollment!
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="ml-2 w-6 h-6">
              <path strokeLinecap="round" strokeLinejoin="round" d="M4.26 10.147a60.436 60.436 0 00-.491 6.347A48.627 48.627 0 0112 20.904a48.627 48.627 0 018.232-4.41 60.46 60.46 0 00-.491-6.347m-15.482 0a50.57 50.57 0 00-2.658-.813A59.905 59.905 0 0112 3.493a59.902 59.902 0 0110.399 5.84c-.896.248-1.783.52-2.658.814m-15.482 0A50.697 50.697 0 0112 13.489a50.702 50.702 0 017.74-3.342M6.75 15a.75.75 0 100-1.5.75.75 0 000 1.5zm0 0v-3.675A55.378 55.378 0 0112 8.443m-7.007 11.55A5.981 5.981 0 006.75 15.75v-1.5" />
            </svg>
          </button>
        </div>
      </a>
    </div>
  </>
  )
}

export default LaunchStep3

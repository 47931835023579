import React, { useState, useEffect, useReducer } from 'react'
import { Switch, Route, useLocation, useHistory } from 'react-router-dom'

import PageTitle from '@/Layout/PageTitle'
import { useGlobalState } from '@/state'
import useQuery from '@/hooks/useQuery'
import Loading from '@/shared/Loading'
import PrimaryButton from '@/shared/Buttons/Primary'
import DefaultButton from '@/shared/Buttons/Default'

import SelectCourseForm from '@/pages/SelectCourseForm'
import SelectEnrollmentType from '@/pages/SelectEnrollmentType'

function pageTitle() {
  const location = useLocation()
  let history = useHistory()
  const activePath = location.pathname
  const titleInfo = {
     title: '',
    buttons: [ ]
  }

  const join = () => { history.push('/select_enrollment_type') }
  const home = () => { window.location.href = '/dashboard' }
  const enrollLater = () => { history.push('/dashboard') }

  if (activePath === '/select_course') {
    titleInfo.title = 'Select Course'
    titleInfo.buttons.push(
      <PrimaryButton key='continue-button' onClick={join} text='Continue Enrollment' />
    )
    titleInfo.buttons.push(
      <DefaultButton key='skip-button' className='ml-4' onClick={home} text='Skip Enrollment' />
    )
  }

  if (activePath === '/reserve_seat') { titleInfo.title = 'Reserve Your Seat' }
  return titleInfo
}

export default function SelectCourseContainer(props) {
  const titleInfo = pageTitle()
  const [currentUser] = useGlobalState('currentUser')
  const { getRequest } = useQuery()

  const [state, setState] = useReducer(
    (state, newState) => (
      {...state, ...newState}), {
        cohorts: [],
        unscheduledCohorts: [],
        cohortInterest: [],
        following: [],
        initialLoading: true,
        interestedCohortCost: 0
      }
    )
  const { cohorts, cohortInterest, unscheduledCohorts, interestedCohortCost, following, initialLoading } = state

  const location = useLocation()
  const activePath = location.pathname

  useEffect(() => {
    getRequest(`/api/v1/cohorts`, { status: 'enrolling' }, (err, jsonData) => {
      if (err) { /* handled in hook */ return }
      setState( {
        cohorts: jsonData.cohorts,
        cohortInterest: jsonData.cohortInterest,
        unscheduledCohorts: jsonData.unscheduledCohorts,
        following: jsonData.following,
        initialLoading: false,
        interestedCohortCost: jsonData.interestedCohortCost
      })
    })
  }, [activePath])

  if (initialLoading) { return <Loading /> }

  return <div className="max-w-7xl mx-auto py-10 px-4 sm:px-6 lg:px-8">
    <PageTitle pagetitle={titleInfo.title}>
      {titleInfo.buttons}
    </PageTitle>
    <Switch>
      <Route {...props} exact path='/select_course'>
        <SelectCourseForm
          cohorts={cohorts}
          unscheduledCohorts={unscheduledCohorts}
          cohortInterest={cohortInterest}
          following={following}
          interestedCohortCost={interestedCohortCost}
        />
      </Route>
      <Route {...props} exact path='/reserve_seat'>
        <SelectCourseForm
          cohorts={cohorts}
          unscheduledCohorts={unscheduledCohorts}
          cohortInterest={cohortInterest}
          following={following}
          interestedCohortCost={interestedCohortCost}
        />
      </Route>
      <Route {...props} exact path='/select_enrollment_type'>
        <SelectEnrollmentType
          cohorts={cohorts}
          unscheduledCohorts={unscheduledCohorts}
          cohortInterest={cohortInterest}
          following={following}
          interestedCohortCost={interestedCohortCost}
        />
      </Route>
    </Switch>
  </div>
}

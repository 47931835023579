import React, {useState, useEffect} from 'react'
import useQuery from '@/hooks/useQuery'
import { Switch } from '@headlessui/react'
import { useForm } from 'react-hook-form'
import FroalaMinimalWrapper from '@/shared/FroalaMinimalWrapper'
import { ExclamationCircleIcon, EmojiHappyIcon, LinkIcon, DuplicateIcon, ClipboardCopyIcon } from '@heroicons/react/solid'

import { PrimaryButton, DefaultButton } from '@/shared/Buttons'
import { Modal, useModal } from '@/shared/Modal'
import classNames from '@/utils/classNamesLocal'

import { showNonSpaToast } from '@/utils/nonSpaToast'
import { BulletItem, CaretItem } from '@/pages/teachers/Launch/Items'
import FaqForm from '@/pages/teachers/LandingPage/FaqForm'

const FaqHeaderForm = ({course}) => {
  const {landing_page} = course
  const [landingPage, setLandingPage] = useState(landing_page)
  const [editing, setEditing] = useState(false)
  const [faqs, setFaqs] = useState([])
  const { putpostRequest, getRequest } = useQuery()
  const { formState, reset, register, unregister, handleSubmit, setValue, watch, getValues, setError } = useForm({
   defaultValues: {
      faq_header: landing_page.faq_header
    }
  })
  const { isDirty, errors } = formState
  const [loading, setLoading] = useState(false)

  useEffect(() => fetchdata(), [])

  const fetchdata = () => {
    getRequest(`/api/v1/teachers/courses/${course.id}/faqs`, {}, (err, jsonData) => {
      if (err) { /* handled in hook */ return }
      setFaqs(jsonData.faqs)
    })
  }

  const add = () => {
    setLoading(true)
    const data = {course_id: course.id}
    putpostRequest(`/api/v1/teachers/landing_page_faqs`, 'POST', data, (err, jsonData) => {
      setLoading(false)
      if (err) {
        showNonSpaToast(err, false)
        return
      }
      setFaqs([...faqs, jsonData.faq ])
    })
  }


  const onSubmit = (data) => {
    const formData = {
      landing_page: data
    }
    setLoading(true)
    putpostRequest(`/api/v1/teachers/courses/${course.id}/landing_page`, 'POST', formData, (err, jsonData) => {
      setLoading(false)
      if (err) {
        showNonSpaToast("Error", false)
        if (err.slug) {
          if (err.slug[0] === 'has already been taken') { setError('slug', { type: 'custom', message: 'unique' }) }
        }
        return
      }

      showNonSpaToast("Saved", true)
      setLandingPage({ ...landingPage,
        faqs_header: jsonData.landing_page.faqs_header
      })
      setEditing(false)
    })
  }

  return <>
    { !editing && <div id="faqs" className="hover:shadow-xl hover:opacity-50 py-2 cursor-pointer" onClick={() => setEditing(true)}>
      <div className="text-center mb-4">
        <h1 className="mt-1 text-2xl font-extrabold text-gray-900 sm:text-4xl sm:tracking-tight">Frequently Asked Questions</h1>
        <div className="flex text-clcnavy justify-end">edit</div>
      </div>
      { faqs.map((faq, idx) => (
        <div key={`faqsasdf${faq.id}`} className="relative mb-4 cursor-pointer rounded-2xl border border-solid border-gray-300 p-4 shadow-sm transition duration-200 ease-out hover:bg-gray-100 sm:p-6">
          <div className="flex flex-row items-center justify-between">
            <h6 className="pr-6 text-gray-800">{faq.title}</h6>
            <svg className="h-6 w-6 shrink-0 rotate-90 transition-transform duration-200 ease-out" width="32" height="32" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M6 3L11 8L6 13" stroke="#858582" strokeLinecap="round" strokeLinejoin="round"></path>
            </svg>
          </div>
          <div className="pt-4 text-gray-700"><span dangerouslySetInnerHTML={{ __html: faq.desc }}></span></div>
        </div>
      ))}
    </div> }
    { editing && <form className='p-8 border-4 border-clcpurple rounded-lg'>
      <div className="p-8 pt-5">
        <div className="space-y-6">
          <div>
            <div className="prose-product-sm flex justify-between sm:prose-product-lg">
              <div className="prose-product-lg">
                <h5 className="flex justify-between text-gray-800"><label className="mb-1">Frequently Asked Questions List</label></h5>
              </div>
            </div>
          </div>
          <ul className="list-disc space-y-2 flex flex-col">
            { faqs.map((faq, index) => (
              <FaqForm key={`faqf${faq.id}`} faq={faq} refreshData={fetchdata} />
            ))}
          </ul>
        </div>
      </div>
      <div className='flex gap-x-5'>
        <PrimaryButton onClick={add} loading={loading} text="Add Question" />
        <DefaultButton onClick={() => setEditing(false)} text="Done Editing" />
      </div>

    </form> }
  </>

}

export default FaqHeaderForm

import React, { useEffect, useRef } from 'react'
import { Switch, Route, useLocation, Link } from 'react-router-dom'

import { useGlobalState } from '@/state'
import Breadcrumbs from '@/Layout/Breadcrumbs'
import PageTitle from '@/Layout/PageTitle'
import Settings from '@/pages/Settings'
import Loading from '@/shared/Loading'
import useQuery from '@/hooks/useQuery'

function breadCrumbs() {
  const location = useLocation()
  const activePath = location.pathname
  const crumbs = [
    { to: '/settings', name: 'Settings' }
  ]
  // if (activePath === '/profile/options') { crumbs.push({ to: activePath, name: 'Options' }) }
  return crumbs
}

function pageTitle() {
  const location = useLocation()
  const activePath = location.pathname
  const titleInfo = {
     title: '',
    buttons: [ ]
  }
  if (activePath === '/settings') { titleInfo.title = 'Settings' }
  return titleInfo
}

export default function SettingsContainer(props) {
  const titleInfo = pageTitle()
  const crumbs = breadCrumbs()
  const isComponentMounted = useRef(false)
  const { getRequest } = useQuery()
  const [, setUser] = useGlobalState('settingsContainerUser')
  const [currentUser] = useGlobalState('currentUser')

  useEffect(() => {
    getRequest(`/api/v1/users/${currentUser.id}/edit`, {}, (err, jsonData) => {
      if (err) { /* handled in hook */ return }
      setUser(jsonData.user)
    })
    isComponentMounted.current = true
  }, [])

  if (!isComponentMounted.current) {
    return <Loading />
  }

  return <>
    <Breadcrumbs>
      { crumbs.map((crumb, index) => (
        <Link key={crumb.name} to={crumb.to} className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700 dark:text-gray-100">
          {crumb.name}
        </Link>
      ))}
    </Breadcrumbs>
    <PageTitle pagetitle={titleInfo.title}>
      {titleInfo.buttons}
    </PageTitle>
    <Switch>
      <Route {...props} exact path='/settings'>
        <Settings />
      </Route>
    </Switch>
  </>
}

import React, {useState, useEffect} from 'react'
import useQuery from '@/hooks/useQuery'
import { useForm } from 'react-hook-form'
import { ExclamationCircleIcon, EmojiHappyIcon, LinkIcon, DuplicateIcon } from '@heroicons/react/solid'
import {PrimaryButton} from '@/shared/Buttons'
import { BulletItem, CaretItem } from '@/pages/teachers/Launch/Items'

import { showNonSpaToast } from '@/utils/nonSpaToast'

const Setup = ({course}) => {
  const [show, setShow] = useState(false)
  const [done, setDone] = useState(course?.launch?.community === 1)
  const { putpostRequest } = useQuery()

  const markAsDone = () => {
    putpostRequest(`/api/v1/teachers/courses/${course.id}/launch_step`, 'POST', {community: 'done'}, (err, jsonData) => {
      if (err) { /* handled in hook */ return }
      setDone(true)
    })
  }

  return <div className={`group ${show? 'rounded-xl border border-solid border-gray-900 shadow-lg' : ''}`}>
    <div onClick={() => setShow(!show)} className={done ? 'prose-product-sm relative flex min-h-16 items-center overflow-hidden p-3 sm:prose-product-lg border-b rounded-t-xl border-gray-200 hover:bg-gray-100 bg-gray-100 dark:bg-gray-800 cursor-pointer' : 'prose-product-sm relative flex min-h-16 items-center overflow-hidden p-3 sm:prose-product-lg  border-gray-200 hover:bg-gray-100  cursor-pointer'}>
      <div className="flex grow items-center justify-between">
        <div className="flex dark:text-gray-300">
          <BulletItem done={done} />
          <div className="ml-4">
            <div className="dark:text-gray-300 flex items-center space-x-3">
              <h4>Safe but honest environment</h4>
            </div>
          </div>
        </div>
        <div className="mr-4 text-gray-400">
          <CaretItem show={show} />
        </div>
      </div>
    </div>
    { show && <>
      <div className="p-8 pt-5">
        <div className="flex-col">
          <p>It's good to let students interact, so setting up a Discord or a Circle would be ideal:</p>
          <ul className="ml-6 list-outside list-disc">
            <li className="pl-1">Introduction: Having students introduce themselves is an easy way to break the ice.</li>
            <li className="pl-1">Reply or send emojis for each person who introduces themselves.</li>
            <li className="pl-1">Create 'safe' questions to get class started. e.g. What Netflix show are you watching right now?</li>
            <li className="pl-1">Positive feedback will go much further than negative. Celebrate positive behaviors.</li>
            <li className="pl-1">Encourage peer feedback by asking them to give 3 other students feedback.</li>
          </ul>
        </div>
        <div className='mt-6'>
          <PrimaryButton onClick={markAsDone} text="Mark as Done" />
        </div>
      </div>
    </> }
  </div>
}

const Introductions = ({course}) => {
  const [show, setShow] = useState(false)
  const [done, setDone] = useState(course?.launch?.schedule === 1)
  const { putpostRequest } = useQuery()

  const markAsDone = () => {
    putpostRequest(`/api/v1/teachers/courses/${course.id}/launch_step`, 'POST', {listed: 'done'}, (err, jsonData) => {
      if (err) { /* handled in hook */ return }
      setDone(true)
    })
  }

  return <div className={`group ${show? 'rounded-xl border border-solid border-gray-900 shadow-lg' : ''}`}>
    <div onClick={() => setShow(!show)} className={done ? 'prose-product-sm relative flex min-h-16 items-center overflow-hidden p-3 sm:prose-product-lg border-b rounded-t-xl border-gray-200 hover:bg-gray-100 bg-gray-100 dark:bg-gray-800 cursor-pointer' : 'prose-product-sm relative flex min-h-16 items-center overflow-hidden p-3 sm:prose-product-lg  border-gray-200 hover:bg-gray-100  cursor-pointer'}>
      <div className="flex grow items-center justify-between">
        <div className="flex dark:text-gray-300">
          <BulletItem done={done} />
          <div className="ml-4">
            <div className="dark:text-gray-300 flex items-center space-x-3">
              <h4>Customize Instroductions</h4>
            </div>
          </div>
        </div>
        <div className="mr-4 text-gray-400">
          <CaretItem show={show} />
        </div>
      </div>
    </div>
    { show && <>
      <div className="p-8 pt-5">
        <div className="p2 space-y-1 text-gray-900">
          <p></p>
          <p className="pt-2"></p>
        </div>
        <div className='mt-6'>
          <PrimaryButton onClick={markAsDone} text="Mark as Done" />
        </div>
      </div>
    </> }
  </div>
}

const PositiveVibes = ({course}) => {
  const [show, setShow] = useState(false)
  const [done, setDone] = useState(course?.launch?.schedule === 1)
  const { putpostRequest } = useQuery()

  const markAsDone = () => {
    putpostRequest(`/api/v1/teachers/courses/${course.id}/launch_step`, 'POST', {listed: 'done'}, (err, jsonData) => {
      if (err) { /* handled in hook */ return }
      setDone(true)
    })
  }

  return <div className={`group ${show? 'rounded-xl border border-solid border-gray-900 shadow-lg' : ''}`}>
    <div onClick={() => setShow(!show)} className={done ? 'prose-product-sm relative flex min-h-16 items-center overflow-hidden p-3 sm:prose-product-lg border-b rounded-t-xl border-gray-200 hover:bg-gray-100 bg-gray-100 dark:bg-gray-800 cursor-pointer' : 'prose-product-sm relative flex min-h-16 items-center overflow-hidden p-3 sm:prose-product-lg  border-gray-200 hover:bg-gray-100  cursor-pointer'}>
      <div className="flex grow items-center justify-between">
        <div className="flex dark:text-gray-300">
          <BulletItem done={done} />
          <div className="ml-4">
            <div className="dark:text-gray-300 flex items-center space-x-3">
              <h4>Positive Feedback</h4>
            </div>
          </div>
        </div>
        <div className="mr-4 text-gray-400">
          <CaretItem show={show} />
        </div>
      </div>
    </div>
    { show && <>
      <div className="p-8 pt-5">
        <div className="flex space-x-6">
          <div className="space-y-3">
            <div className="p2 space-y-1 text-gray-900">
              <p>Here are a few ideas for building a vibrant community:</p>
              <ul className="ml-6 list-outside list-disc">
                <li className="pl-1">React and reply to each student’s introduction with a warm and personalized welcome</li>
                <li className="pl-1">Create an icebreaker project like “What’s your favorite ice cream?” to flood #share-your-work with fun submissions</li>
                <li className="pl-1">Ask a no-fail-question at the start of class like “What TV show are you binging right now?”</li>
                <li className="pl-1">Give kudos to celebrate positive behaviors like helping a fellow student or giving peer feedback</li>
                <li className="pl-1">Encourage peer feedback by asking students to give feedback to 2 people before asking for feedback</li>
              </ul>
              <p className="pt-2"></p>
            </div>
          </div>
        </div>
      </div>
      <PrimaryButton onClick={markAsDone} text="Mark as Done" />
    </> }
  </div>
}

const ShowCommunity = ({course}) => {
  return <ul className="overflow-hidden rounded-md border border-gray-200">
    <li className="border-b first:rounded-t-md last:rounded-b-md last:border-b-0">
      <Setup course={course} />
    </li>
  </ul>
}

export default ShowCommunity

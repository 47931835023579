import React, { useEffect, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import useQuery from '@/hooks/useQuery'
import { debounce } from 'throttle-debounce'
import { ExclamationCircleIcon } from '@heroicons/react/solid'
import { useGlobalState } from '@/state'
import { Toast } from '@/shared/Toast'
import { randomToastSuccess } from '@/shared/Toast'
import { Modal, useModal } from '@/shared/Modal'
import Loading from '@/shared/Loading'

const ThankYou = (props) => {
  const survey = props.survey

  return <div className="mx-auto max-w-7xl ">
  <div className="bg-green-100 mt-6 rounded border-2 border-green-500">
    <div className="max-w-7xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8">
      <div className="text-center">
        <h2 className="text-base font-semibold text-clcnavy tracking-wide uppercase">Confirmation</h2>
        <p className="mt-1 text-4xl font-extrabold text-gray-900 sm:text-5xl sm:tracking-tight lg:text-6xl">Thank You</p>

        { survey.kind === 'interest' && <>
          <p className="max-w-xl mt-5 mx-auto text-xl text-gray-500">You are currently on the waitlist. The instructor will reach out when new updates are ready.</p>
          <b>Please check your email to confirm</b>
        </> }
        { survey.kind === 'post-cohort' && <> <p className="max-w-xl mt-5 mx-auto text-xl text-gray-500">We will review your feedback.</p> </> }
      </div>
    </div>
  </div>
</div>
}

const validateEmail = (email) => {
  console.log(email)
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
}

const QuestionAnswer = ({question, idx, updateAnswers, initAnswer, autof}) => {
  const { title, options } = question
  const ratings = Array.from({length: 10}, (_, i) => i + 1)
  const [ answer, setAnswer ] = useState(initAnswer || (question.kind === 'textarea' ? undefined : []))
  const isComponentMounted = useRef(false)

  const updateAnswer = (e) => { setAnswer(e.target.value) }
  const updateSelection = (e, option) => {
    if (question.kind === 'radio' || question.kind === 'rating') { setAnswer([option]) }
    if (question.kind === 'checkbox') {
      if (answer.includes(option)) {
        const oidx = answer.indexOf(option)
        answer.splice(oidx,1)
        const newAnswers = answer.slice()
        setAnswer(newAnswers)
      } else {
        setAnswer([...answer, option])
      }
    }
  }

  useEffect(() => {
    if (!isComponentMounted.current) {
      isComponentMounted.current = true
      return
    }

    updateAnswers(idx, {
      title: title,
      answer: answer
    })

  }, [answer])

  return <div className='group rounded-xl border border-solid border-gray-900 shadow-lg mt-2'>
    <div className='flex justify-between items-center space-x-4 prose-product-sm p-3 sm:prose-product-lg border-gray-200'>
      <div className='flex flex-grow items-center space-x-2'>
        <div>{idx < 10 && <>0</>}{idx + 1}.</div>
        <div className='w-full flex-1'>
          <div className='p2 py-3.5 px-4 w-full rounded-lg font-semibold'>{title}</div>
        </div>
      </div>
    </div>
    <div className='p-3 pt-0 pb-0'>
      { question.kind === 'textarea' &&
        <textarea type="text" autoFocus={(autof === 'index' && idx === 0) || (autof === 'value' && answer === undefined)} className='placeholder-shown:italic placeholder-gray-600 p2 py-3.5 px-4 w-full border rounded-lg font-semibold' defaultValue={answer} onBlur={(e) => updateAnswer(e)} placeholder="Type your answer" />
      }
    </div>
    { (question.kind === 'checkbox' || question.kind === 'radio') && <>
      <div className='p-3 pt-0'>
        { question.kind === 'checkbox' && <div className='text-sm text-gray-500'>Select multiple</div> }
        { question.kind === 'radio' && <div className='text-sm text-gray-500'>Select one.</div> }
        { options.map((option, oidx) => (
          <div key={`${option[0]}q${question.id}${option[2]}op${oidx}`}>
            <div className='group p-1 rounded-sm mt-1 hover:bg-gray-100 cursor-pointer flex items-center'>
              <div className={`p2 py-3.5 px-4 w-full border rounded-lg hover:bg-clcnavy hover:bg-opacity-20 ${answer.includes(option) ? 'bg-clcnavy text-white' : 'bg-white ' }`} onClick={(e) => updateSelection(e, option)}>{option} </div>
            </div>
          </div>
        ))}
      </div>
    </> }
    { (question.kind === 'rating') && <>
      <div className='p-3 pt-0 flex space-x-1 justify-between'>
        { ratings.map((option, oidx) => (
          <div key={`${option[0]}q${question.id}iraop${oidx}`}>
            <div className={`p-4 border cursor-pointer rounded-lg hover:bg-clcnavy hover:bg-opacity-20 ${answer.includes(option) ? 'bg-clcnavy text-white' : 'bg-white ' }`} onClick={(e) => updateSelection(e, option)}>{option}</div>
          </div>
        ))}
      </div>
    </> }
  </div>
}

const postCohortQuestions = [
  { kind: 'rating', title: 'Please rate this course. 10 being best.', required: true },
  { kind: 'textarea', title: 'Please write a review for this course.', required: true }
]

const SurveyQuestions = ({survey, answerNonce}) => {
  const [answers, setAnswers] = useState([])
  const [questions, setQuestions] = useState(survey.kind === 'post-cohort' ? [...postCohortQuestions, ...survey.questions] : survey.questions)
  const [loading, setLoading] = useState(false)
  const [showThankYou, setShowThankYou] = useState(false)
  const { getRequest, putpostRequest } = useQuery()

  const updateAnswers = (idx, answer) => {
    const newAnswers = answers.slice()
    newAnswers[idx] = answer
    setAnswers(newAnswers)
  }

  const autof = answers.length === 0 ? 'index' : 'value'

  const submit = () => {
    setLoading(true)
    const data = { answers: answers }
    putpostRequest(`/api/v1/survey_answers/${answerNonce}`, 'PATCH', {survey_answer: data}, (err, jsonData) => {
      if (err) { console.log('toast errors'); return }

      setLoading(false)
      setShowThankYou(true)
    })
  }

  if (showThankYou) return <ThankYou survey={survey} />

  return <>
    { questions.map((question, idx) => (
      <QuestionAnswer
        key={`q${question.title}${idx}${question.kind}`}
        initAnswer={answers[idx]}
        idx={idx}
        autof={autof}
        question={question}
        updateAnswers={updateAnswers}
      />
    ))}
    { loading && <Loading /> }
    <div className='mt-3'>
      <button onClick={submit} className='mt-6 justify-center items-center flex shadow-sm hover:shadow-md disabled:shadow-none ease-out duration-200 font-medium disabled:cursor-default h-12 px-6 b2 rounded-lg bg-gray-900 text-white hover:bg-black disabled:bg-gray-500'>
        <span>Submit Answers</span>
        <svg className="ml-2" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M2.5 8H13.5" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
          <path d="M9 3.5L13.5 8L9 12.5" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
        </svg>
      </button>
    </div>
  </>
}

const SurveyShow = ({survey, course, resume_survey}) => {
  const { getRequest, putpostRequest } = useQuery()
  const emailRef = useRef(null)
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(false)
  const [answerNonce, setAnswerNonce] = useState(resume_survey)
  

  const submit = () => {
    if (!validateEmail(emailRef?.current?.value)) {
      setError('Is that a valid email?')
      return
    }
    setLoading(true)

    const data = {
      email: emailRef.current.value,
      course_id: course.id
    }
    putpostRequest(`/api/v1/survey_answers`, 'POST', {student_lead: data, survey_id: survey.id}, (err, jsonData) => {
      if (err) { console.log('toast errors'); return }

      setLoading(false)
      setAnswerNonce(jsonData.answerNonce)
    })
  }

  const keyPress = (e) => {
    if (error) { setError(null) }

    if (e.target.name === 'email' && e.keyCode === 13) {
      submit()
    }
    if ((e.metaKey || e.ctrlKey) && e.keyCode === 13) { submit() }
  }

  if (answerNonce) { return <SurveyQuestions survey={survey} answerNonce={answerNonce} /> }

  return <>
    <div className="mt-5 rounded-xl bg-white drop-shadow-lg clp-theme sm:mb-3 md:mb-4">
      <div className="flex flex-col items-start justify-between p-6 py-12 sm:items-center sm:py-18 sm:text-center">
        { survey.kind === 'interest' && <h3 className="mt-2 text-center font-semibold">Survey for the upcoming course</h3> }
        { survey.kind === 'post-cohort' && <h3 className="mt-2 text-center font-semibold">Tell us how the course was</h3> }
        <div className="mt-6 w-full sm:max-w-120">
          <div className='flex flex-col justify-center w-full items-center'>
            <div className="sm:w-96 w-full flex flex-col">
              <input ref={emailRef} onKeyDown={keyPress} name='email' type='email' autoFocus placeholder='enter your email' className='placeholder-shown:italic placeholder-gray-600 p2 py-3.5 px-4 w-full border rounded-lg border-gray-300 bg-white shadow-sm hover:border-gray-400 hover:shadow-md transition ease-out duration-200' />
              { error && <div className='text-red-500'>{error}</div> }
            </div>
            { loading && <Loading /> }
            <div className='mt-3'>
              <button onClick={submit} className='mt-6 justify-center items-center flex shadow-sm hover:shadow-md disabled:shadow-none ease-out duration-200 font-medium disabled:cursor-default h-12 px-6 b2 rounded-lg bg-gray-900 text-white hover:bg-black disabled:bg-gray-500'>
                <span>Let's go!</span>
                <svg className="ml-2" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M2.5 8H13.5" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                  <path d="M9 3.5L13.5 8L9 12.5" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                </svg>
              </button>
            </div>
          </div>
        </div>
        
        <div className="mt-6">
          <p className="text-sm text-gray-700">By continuing, you agree to Closing Credits' <a className="font-semibold underline" target="_blank" rel="noreferrer" href="/tos">Terms</a> and <a className="font-semibold underline" target="_blank" rel="noreferrer" href="/privacy">Privacy Policy</a>.</p>
        </div>
      </div>
    </div>
  </>
}

export default SurveyShow

import React, { useState, useEffect, useRef } from 'react'
import { useStripe, useElements, CardElement, Elements } from '@stripe/react-stripe-js'
import classNames from '@/utils/classNamesLocal'
import { Switch } from "@headlessui/react";
import { useHistory } from 'react-router-dom'
import { BadgeCheckIcon, BanIcon } from '@heroicons/react/solid'
import SVGIcon from '@/shared/icon'
import { Modal, useModal } from '@/shared/Modal'

import { FreePlanDetails, PaidPlanDetails } from '@/pages/SubscriptionForm/PlanDetails'
import CohortInterest from '@/pages/SubscriptionForm/CohortInterest'

import DefaultButton from '@/shared/Buttons/Default'
import PrimaryButton from '@/shared/Buttons/Primary'
import useQuery from '@/hooks/useQuery'
import Loading from '@/shared/Loading'
import { useGlobalState } from '@/state'

const CohortInterestRow = ({cohort, showNonMemberPrice, showMemberPrice, strikeNonMemberPrice}) => {
  return <>
    <div className="flex-1mt-1  flex w-full items-center justify-between border border-gray-200 bg-white dark:bg-gray-800 rounded-md truncate">

      <div className='w-20'>
        <div className="aspect-w-16 aspect-h-9">
          <img src={cohort.imageUrl} className='object-cover w-full rounded-lg' alt={cohort.name} />
        </div>
      </div>
      <div className="flex-1 px-4 py-2 text-sm truncate">
        <p className="text-gray-800 dark:text-gray-200">{cohort.name}</p>
      </div>
      { showNonMemberPrice && <div className="flex-1 px-4 py-2 text-sm truncate dark:text-gray-200"> ${cohort.nonMemberPrice} </div> }
      { showMemberPrice && <div className="flex-1 px-4 py-2 text-sm truncate dark:text-gray-200 flex gap-x-1">
        <span className='text-gray-800 dark:text-gray-200 line-through'>${cohort.nonMemberPrice}</span>
        <span className='text-green-500'>${cohort.memberPrice}</span>
      </div> }
    </div>
  </>
}

const SelectEnrollmentType = (props) => {
  const { getRequest, putpostRequest } = useQuery()
  const { openModal, closeModal, isOpen } = useModal()
  const [currentUser] = useGlobalState('currentUser')
  const [creditsToSpend, setCreditsToSpend] = useState(0)
  let history = useHistory()
  const [ cohorts, setCohorts ] = useState([])
  const [ totalCost, setTotalCost ] = useState(0)
  const [ yearly, setYearly ] = useState(false)
  const [ recommendedPlan, setRecommendedPlan ] = useState(null)
  const [ recommendedAction, setRecommendedAction ] = useState(null)
  const [ loading, setLoading ] = useState(true)
  const [ stripeLoading, setStripeLoading ] = useState(false)

  const goToOneTimeCheckoutFree = () => {
    const data = {
      credits_to_spend: creditsToSpend
    }

    putpostRequest(`/api/v1/cohort_interests/enroll`, 'POST', data, (err, jsonData) => {
      if (err) {
        alert(err)
        return
      }
      window.location.href = '/dashboard'
    })
  }

  //const goToOneTimeCheckout = () => { history.push('/subscriptions?oneTime=true') }
  const goToOneTimeCheckout = () => {
    setStripeLoading(true)

    if (memberCost - (creditsToSpend * 10) === 0) {
      return goToOneTimeCheckoutFree()
    }

    const data = {
      mode: 'payment',
      include_interests: true,
      credits_to_spend: creditsToSpend,
      member: 'non'
    }

    putpostRequest(`/api/v1/stripe/build_payment_link`, 'POST', data, (err, jsonData) => {
      if (err) {
        alert(err)
        return
      }
      window.location.href = jsonData.stripeLink
    })
  }
  const goToMembership = () => {
    setStripeLoading(true)

    const data = {
      mode: 'subscription',
      include_interests: true,
      credits_to_spend: creditsToSpend,
      member: 'member',
      yearly: yearly ? 'yearly' : 'monthly'
    }

    putpostRequest(`/api/v1/stripe/build_payment_link`, 'POST', data, (err, jsonData) => {
      if (err) {
        alert(err)
        return
      }
      window.location.href = jsonData.stripeLink
    })
  }

  useEffect(() => {
    getRequest(`/api/v1/cohort_interests`, {}, (err, jsonData) => {
      setLoading(false)
      if (err) { /* handled in hook */ return }

      setCohorts(jsonData.cohorts)
    })
  }, [])

  const goBack = () => {
    history.push('/select_course')
  }

  const updateCreditsToSpend = (num) => {
    if (creditsToSpend + num < 0) { return }
    if (creditsToSpend + num > currentUser.credits) { return }
    if ((creditsToSpend + num) * 10 > memberCost) { return }

    setCreditsToSpend(creditsToSpend + num)
  }

  if (cohorts.length === 0) {
    return <>
    <DefaultButton className='mr-5' onClick={goBack} text="Back" />
    <div>
      <div className="mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8">
        <div className="text-center">
          <h2 className="text-base font-semibold text-clcpurple tracking-wide uppercase">You didn't select any course to enroll in.</h2>
          <p className="max-w-xl mt-5 mx-auto text-xl text-gray-500 dark:text-gray-300">
            Head back to the <a href='/select_course' className='text-clcnavy'>course selection page</a> to enroll in a course.
          </p>
        </div>
      </div>
    </div>

    </>
  }

  const memberCost = cohorts.map(element => element.memberPrice).reduce((a, b) => a + b, 0)
  const nonMemberCost = cohorts.map(element => element.nonMemberPrice).reduce((a, b) => a + b, 0)

  return <>
    <DefaultButton className='mr-5' onClick={goBack} text="Back" />
    { !currentUser.showMembershipUponPurchase && <>
      <div className="flex flex-col items-center px-6 max-w-2xl justify-center content-center mx-auto">
        <div className="bg-white mt-3 dark:bg-gray-800 rounded-md  border-cccorange dark:border-cccpurple-alt w-full">
          <div className="flex items-center px-6 py-3">
            <h4 className="flex-shrink-0 mr-3 bg-white dark:bg-gray-800 text-sm tracking-wider font-semibold uppercase text-clcpurple">
              One Time Checkout
            </h4>
            <div className="flex-1 border-t-2 border-gray-200"></div>
          </div>
          <div className="flex flex-col items-center px-6">
            {cohorts.map((cohort, idx) => (
              <CohortInterestRow key={`coho${cohort.id}`} cohort={cohort} strikeNonMemberPrice showMemberPrice />
            ))}
            <span className='text-gray-500 my-2 text-xs'>You will be enrolled in the selected courses.</span>
          </div>
        </div>
        { currentUser.credits > 0 && <div className='mt-6 flex flex-col gap-y-1 p-6 gap-x-1 bg-white dark:bg-gray-800'>
          <span className='dark:text-gray-200'>You have {currentUser.credits} credits.</span>
          <div className='flex items-center justify-between gap-x-1'>
            <span className='dark:text-gray-200'>Do you want to use your unspent credits?</span>
            <div className='min-w-32 no-user-select flex flex-col sm:flex-row gap-x-1'>
              <span onClick={() => updateCreditsToSpend(-1)}>
                <SVGIcon name='minus-in-circle' height='20' width='20' autoDark
                  className={`${(creditsToSpend <= 0) ? 'cursor-not-allowed' : 'cursor-pointer'}`}
                />
              </span>
              <span className='text-clcpurple semibold'>{creditsToSpend}</span>
              <span onClick={() => updateCreditsToSpend(1)}>
                <SVGIcon name='plus-in-circle' height='20' width='20' autoDark
                  className={`${(creditsToSpend >= currentUser.credits) ? 'cursor-not-allowed' : 'cursor-pointer'}`}
                />
              </span>
            </div>
          </div>
        </div> }
        <div className="flex items-center p-6">
          <h4 className="flex-shrink-0 mr-3 bg-gray-100 dark:bg-gray-700 text-sm tracking-wider font-semibold uppercase text-clcpurple">
            Total
          </h4>
          <div className="flex-1 border-t-2 border-gray-200"></div>
          <div className='ml-5 text-2xl dark:text-gray-100 font-semibold text-gray-800 dark:text-gray-200 line-through'>${nonMemberCost}</div>
          <div className='ml-5 text-2xl dark:text-gray-100 font-semibold text-green-500 dark:text-green-500'>${memberCost - (creditsToSpend * 10)}</div>
        </div>
        <div className='flex justify-center font-semibold py-2'>
          Your membership saved you ${nonMemberCost - memberCost}.
        </div>
        <div className='flex justify-center mt-1'>
          <PrimaryButton className='mr-5' onClick={goToOneTimeCheckout} loading={stripeLoading} text="Finalize Enrollment" />
        </div>
      </div>
    </> }
    { currentUser.showMembershipUponPurchase && <>
      <div className="mt-12 space-y-12 lg:space-y-0 lg:grid lg:grid-cols-4 lg:gap-x-8">
        <div className='col-span-2'>
          <div className="bg-white mt-3 dark:bg-gray-800 rounded-md  border-cccorange dark:border-cccpurple-alt">
            <div className="flex items-center px-6 py-3">
              <h4 className="flex-shrink-0 mr-3 bg-white dark:bg-gray-800 text-sm tracking-wider font-semibold uppercase text-clcpurple">
                One Time Checkout
              </h4>
              <div className="flex-1 border-t-2 border-gray-200"></div>
            </div>
            <div className="flex flex-col items-center px-6">
              {cohorts.map((cohort, idx) => (
                <CohortInterestRow key={`coho${cohort.id}`} cohort={cohort} showNonMemberPrice />
              ))}
              <span className='text-gray-500 my-2 text-xs'>You will be enrolled in the selected courses.</span>
            </div>
            <FreePlanDetails />
          </div>
          <div className="flex items-center p-6">
            <h4 className="flex-shrink-0 mr-3 bg-gray-100 dark:bg-gray-700 text-sm tracking-wider font-semibold uppercase text-clcpurple">
              Total
            </h4>
            <div className="flex-1 border-t-2 border-gray-200"></div>
            <div className='ml-5 text-2xl dark:text-gray-100 font-semibold text-gray-800 dark:text-gray-200'>${nonMemberCost}</div>
          </div>
          <div className='flex justify-center'>
            <PrimaryButton className='mr-5' onClick={goToOneTimeCheckout} loading={stripeLoading} text="One-time checkout, please!" />
          </div>
        </div>
        <div className='col-span-2'>
          <div className="bg-white mt-3 dark:bg-gray-800 rounded-md  border-cccorange dark:border-cccpurple-alt">
            <div className="flex items-center px-6 py-3">
              <h4 className="flex-shrink-0 mr-3 bg-white dark:bg-gray-800 text-sm tracking-wider font-semibold uppercase text-clcpurple">
                Membership
              </h4>
              <div className="flex-1 border-t-2 border-gray-200"></div>
            </div>
          </div>

          <div className="bg-white dark:bg-gray-800 rounded-md text-green-500 border-cccorange dark:border-cccpurple-alt">
            <div className="flex flex-col items-center px-6">
              {cohorts.map((cohort, idx) => (
                <CohortInterestRow key={`cohmemo${cohort.id}`} cohort={cohort} showMemberPrice />
              ))}
              <span className='text-gray-500 my-2 text-xs'>You will be enrolled in the selected courses.</span>
            </div>
            <PaidPlanDetails />
          </div>
          <div className="flex items-center flex-col p-6 pb-0 w-full">
            <div className='flex items-center px-6 w-full'>
              <h4 className="flex-shrink-0 mr-3 bg-gray-100 dark:bg-gray-700 text-sm">
                Membership
              </h4>
              <div className="flex-1 border-t-2 border-gray-200"></div>
              <div className='ml-5 dark:text-gray-100 font-semibold text-gray-800 dark:text-gray-200'>
                ${yearly ? 150 : 15}
              </div>
            </div>
            <div className='flex items-center px-6 w-full'>
              <h4 className="flex-shrink-0 mr-3 bg-gray-100 dark:bg-gray-700 text-sm tracking-wider font-semibold uppercase text-clcpurple">
                Total Today
              </h4>
              <div className="flex-1 border-t-2 border-gray-200"></div>
              <div className='ml-5 text-2xl dark:text-gray-100 font-semibold text-gray-800 dark:text-gray-200'>${memberCost + (yearly ? 150 : 15)}</div>
            </div>
          </div>
          <div className="flex items-center justify-end p-6 pt-0">
            <div className='ml-5 dark:text-gray-100 text-green-500'>Then { yearly ? '$150 / year' : '$15 / month'}</div>
          </div>
          <Switch.Group as="div" className="flex items-center justify-between px-6 py-2 w-full bg-white rounded-lg">
            <Switch.Label as="span" className="flex-grow flex flex-col" passive>
              <span className="text-sm font-medium text-gray-900 dark:text-gray-200">Switch to {yearly ? 'monthly' : 'yearly. 2 months free.' }</span>
            </Switch.Label>
            <Switch
              checked={yearly}
              onChange={() => setYearly(!yearly)}
              className={classNames(
                yearly ? 'bg-clcnavy' : 'bg-gray-300 dark:bg-gray-500',
                'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cccpurple'
              )}
            >
              <span className="sr-only" hidden>Switch Plans</span>
              <span
                aria-hidden="true"
                className={classNames(
                  yearly ? 'translate-x-5' : 'translate-x-0',
                  'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
                )}
              />
            </Switch>
          </Switch.Group>
          <div className='flex justify-center mt-4'>
            <PrimaryButton className='mr-5' onClick={goToMembership} loading={stripeLoading} text={`I want the ${yearly ? 'yearly' : 'monthly' } membership!`} />
          </div>
        </div>
      </div>
    </> }
  </>
}

export default SelectEnrollmentType

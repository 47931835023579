export const PLANS = [
  {
    name: 'Membership', plan: 'premium_membership', priceMonthly: 15, priceYearly: 150, 
    credits: 0, limit: 'Membership Benefits'
  },
  {
    name: 'Membership', plan: 'membership', priceMonthly: 10, priceYearly: 150, 
    credits: 0, limit: 'Membership Benefits'
  },
  {
    name: 'Membership', plan: 'base', priceMonthly: 10, priceYearly: 100, 
    credits: 1, limit: 'Membership Benefits', savings: null, coursePerYear: 0,
    paypalPlanId: 'P-5SU99805RD142112NMLBHGSI'
  },
  {
    name: 'Apprentice', plan: 'apprentice', priceMonthly: 13, priceYearly: 150, 
    credits: 10, limit: 'Base + 10 Credits to Spend Yearly', savings: null, coursePerYear: 1,
    paypalPlanId: 'P-7ES02968P19293921MLF76LY'
  },
  {
    name: 'Maker', plan: 'maker', priceMonthly: 30, priceYearly: 350, 
    credits: 30, limit: 'Base + 30 Credits to Spend Yearly', savings: "22% savings", coursePerYear: 3,
    paypalPlanId: 'P-4G555215KD074584SMLF766Q'
  },
  {
    name: 'Master', plan: 'master', priceMonthly: 42, priceYearly: 500, 
    credits: 50, limit: 'Base + 50 Credits to Spend Yearly', savings: "33% savings", coursePerYear: 5,
    paypalPlanId: 'P-4WR36813883775351MLF77KQ'
  },
]
//export const PLANS = [
  //{
    //name: 'Membership', plan: 'membership', priceMonthly: 10, priceYearly: 100, 
    //credits: 1, limit: '', savings: null, coursePerYear: 0,
    //paypalPlanId: 'P-5SU99805RD142112NMLBHGSI'
  //}
//]

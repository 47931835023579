import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'

import { formatInTimezone } from '@/utils/date'
import { useGlobalState } from '@/state'
import useQuery from '@/hooks/useQuery'
import PrimaryButton from '@/shared/Buttons/Primary'
import DefaultButton from '@/shared/Buttons/Default'
import TicketTypes from '@/shared/TicketTypes'
import { Modal, useModal } from '@/shared/Modal'

const EventRow = ({event, user, attendingEventIds, compact}) => {
  const { putpostRequest } = useQuery()
  const [ currentUser ] = useGlobalState('currentUser')
  const [, setToast] = useGlobalState('toast')
  const { openModal, closeModal, isOpen } = useModal()

  const [ attending, setAttending ] = useState(false)
  const [ loading, setLoading ] = useState(false)
  const ticketTypes = event.ticketTypes || {}
  const attendNow = () => { window.location = `/events/links/${event.uuid}` }

  useEffect(() => {
    if (event === null) { return }

    if (Object.keys(ticketTypes).length > 0) {
      setAttending(attendingEventIds[event.id])
    } else {
      setAttending(Object.keys(attendingEventIds).includes(event.id.toString()))
    }
  }, [attendingEventIds])

  const toggleAttend = (ticketTypeId) => {
    setLoading(true)
    let data = {
      ticket_type_id: ticketTypeId
    }
    putpostRequest(`/api/v1/events/${event.id}/attend`, 'POST', data, (err, jsonData) => {
      setLoading(false)
      if (err) { console.log('toast errors'); return }

      if (ticketTypeId) {
        setAttending(attending === ticketTypeId ? null : ticketTypeId)
      } else {
        setAttending(!attending)
      }
      if (!attending === true) {
        setToast(<div className="ml-3 w-0 flex-1 pt-0.5">
          <p className="text-sm font-medium dark:text-green-100 text-green-600">Attending!</p>
          <p className="mt-1 text-sm dark:text-green-300 text-green-600">You're going! You'll get an email about this.</p>
        </div>)
      } else { 
        setToast(<div className="ml-3 w-0 flex-1 pt-0.5">
          <p className="text-sm font-medium dark:text-yellow-100 text-yellow-600">RSVP is canceled</p>
          <p className="mt-1 text-sm dark:text-gray-200 text-gray-900">You are not attending this event anymore.</p>
        </div>)
      }
    })
  }

  const modalTickets = Object.keys(ticketTypes).length > 0

  return <div className="flex flex-col rounded-lg shadow-lg overflow-hidden">
    { !compact && <Link to={`/events/${event.id}-${event.slug}`} className="flex-shrink-0">
      <div className="aspect-w-16 aspect-h-9">
        <img className="object-cover" src={`${event.imageUrl}?aspect=16:9&width=420`} alt="" />
      </div>
    </Link> }
    <Link to={`/events/${event.id}-${event.slug}`} className="flex-1 bg-clcnavy bg-opacity-10 dark:bg-gray-800 p-2 flex flex-col justify-between">
      <div className="flex-1">
        <p className="text-xl font-semibold text-gray-900 dark:text-gray-200">{event.name}</p>
        { !currentUser.canAttendMembersOnlyEvents && event.admittance === 'members-only' && event.courseId === null && <div className="text-sm font-medium text-red-500">Members Only</div> }
        <p className="text-sm font-medium text-clcpurple"> {event.kind} - <span className='text-gray-500'>{event.lengthOfEvent} min</span> </p>
        { event.eventHosts.length > 0 && <>
          { event.eventHosts.map((eventHost, index) => (
          <div key={`ehhost${eventHost.id}`} className="flex items-center mt-2">
            <p className="text-gray-900">Host: {eventHost.name}</p>
          </div>
          ))}
        </> }
        <div className="text-clcpurple mt-3">
          { event.status === 'started' && <span>Event has started!</span> }
          { event.status !== 'started' && <time dateTime={event.startsAtUtc}>
            {formatInTimezone(event.startsAtUtc, user.timezone, 'EEE MMMM d, Y h:mmaaa - ')}
            {formatInTimezone(event.endsAtUtc, user.timezone, 'h:mmaaa zzz')}
          </time> }
        </div>
        <div className="block mt-2">
          <p className="mt-3 text-base text-gray-500 line-clamp-3"><span dangerouslySetInnerHTML={{__html: event.description}}></span></p>
        </div>
      </div>
    </Link>
    <div className="bg-clcnavy bg-opacity-10 dark:bg-gray-900 p-2 border-b border-gray-200 dark:border-gray-800">
      <div className="flex justify-between items-center">
        <div className="flex-shrink-0">
          { event.status !== 'completed' && <>
            { modalTickets && <>
              { event.status !== 'started' && <>
                { !attending && <PrimaryButton loading={loading} onClick={openModal} text='Attend' /> }
                { attending && <DefaultButton loading={loading} onClick={openModal} text='Cancel attendance' /> }
              </> }
            </> }
            { !modalTickets && <>
              { event.status !== 'started' && <>
                { !attending && <PrimaryButton loading={loading} onClick={() => toggleAttend(null)} text='Attend' /> }
                { attending && <DefaultButton loading={loading} onClick={() => toggleAttend(null)} text='Cancel attendance' /> }
              </> }
            </> }
          </> }
          { event.status === 'started' && <>
            <PrimaryButton loading={loading} onClick={attendNow} text='Attend Event Now' />
          </> }
        </div>
        <Link to={`/events/${event.id}-${event.slug}`} className="flex-shrink-0">
          Event Details
        </Link>
      </div>
    </div>
    <Modal isOpen={isOpen} closeModal={closeModal}>
      <TicketTypes
        event={event}
        attending={attending}
        toggleAttend={toggleAttend}
        loading={loading}
        userId={user.id}
        />
      <button className='inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white dark:bg-gray-900 dark:text-white dark:hover:bg-gray-800 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2' onClick={closeModal}>Okay</button>
    </Modal>
  </div>
}

export default EventRow

import React, {useState, useEffect, useRef} from 'react'
import useQuery from '@/hooks/useQuery'
import { useDebouncedEffect } from '@/utils/useDebouncedEffect'
import { formatInTimezone } from '@/utils/date'
import { useForm } from 'react-hook-form'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { ExclamationCircleIcon, EmojiHappyIcon, LinkIcon, DuplicateIcon } from '@heroicons/react/solid'
import { PrimaryButton, DefaultButton } from '@/shared/Buttons'
import { Modal, useModal } from '@/shared/Modal'

import { showNonSpaToast } from '@/utils/nonSpaToast'
import { BulletItem, CaretItem } from '@/pages/teachers/Launch/Items'

import InstructorForm from '@/pages/teachers/InstructorForm'
import EventRow from '@/pages/teachers/EventRow'
import Pagination from '@/shared/Pagination'

const Events = ({school, currentUser}) => {
  const [events, setEvents] = useState([])
  const { openModal, closeModal, isOpen } = useModal()
  const [cohorts, setCohorts] = useState([])
  const [meta, setMeta] = useState({})
  const [segment, setSegment] = useState('future')
  const [loading, setLoading] = useState(false)
  const { getRequest, putpostRequest } = useQuery()
  const [page, setPage] = useState(1)
  const [contains, setContains] = useState('')
  const scrollRef = useRef(null)
  const monthYear = useRef(null)

  useDebouncedEffect(() => { fetchData() }, 200, [contains])
  useEffect(() => { fetchData() }, [page, segment])

  const fetchData = () => {
    const data = {
      contains: contains,
      segment: segment,
      page: contains.length > 0 ? 1 : page
    }
    getRequest(`/api/v1/teachers/schools/${school.id}/events`, data, (err, jsonData) => {
      if (err) { /* handled in hook */ return }
      setEvents(jsonData.events)
      setMeta(jsonData.meta)
    })
  }

  return <div className='p-8 py-5 mt-2 col-span-3  bg-gray-100 p-2 rounded-md border border-2 border-gray-900'>
    <div className='pb-5' ref={scrollRef}>
      <div className='flex justify-between items-center'>
        <div>
          <div className="prose-product-lg">
            <h5 className="flex justify-between text-gray-800"><label className="mb-1">Filter school events:</label></h5>
          </div>
          <div className="grid grid-cols-2 gap-x-5 pb-3">
            <div className="col-span-1">
              <select onChange={(e) => setSegment(e.target.value)} className="block w-full py-2 px-3 border border-gray-300 bg-white dark:bg-gray-900 dark:text-gray-100 rounded-md shadow-sm focus:outline-none focus:ring-cccpurple focus:border-cccpurple sm:text-sm">
                  <option value={'future'}>Future school events</option>
                  <option value={'past'}>Past school events</option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>
    { events.length >= 0 && <Pagination meta={meta} callback={setPage} scrollRef={scrollRef} /> }
    <div className="border-1 my-3 w-full overflow-hidden rounded-2xl border border-black/[0.03] last:h-full">
      { events.map((event, idx) => {
        let showMonth = idx === 0 || monthYear.current !== formatInTimezone(event.startsAtUtc, currentUser.timezone, 'MMM yyyy')
        monthYear.current = formatInTimezone(event.startsAtUtc, currentUser.timezone, 'MMM yyyy') 
        return <div key={`evnt${event.id}`}>
          { showMonth && <div className="w-full">
            <div className="h-10 bg-black/[.03] px-4 py-2">
              <h3>{formatInTimezone(event.startsAtUtc, currentUser.timezone, 'MMM yyyy')}</h3>
            </div>
          </div> }
          <EventRow event={event} currentUser={currentUser} />
        </div>
      })}
    </div>
    { events.length >= 0 && <Pagination meta={meta} callback={setPage} scrollRef={scrollRef} /> }
  </div>
}

const SchoolEventsIndex = (props) => {
  const { school, currentUser } = props
  return ( <>
    <Events school={school} currentUser={currentUser} />
  </>
  )
}

export default SchoolEventsIndex

import React, {useState, useEffect} from 'react'
import useQuery from '@/hooks/useQuery'
import { Switch } from '@headlessui/react'
import { useForm } from 'react-hook-form'

import { PrimaryButton, DefaultButton } from '@/shared/Buttons'
import { Modal, useModal } from '@/shared/Modal'
import classNames from '@/utils/classNamesLocal'

import InstructorForm from '@/pages/teachers/InstructorForm'
import { showNonSpaToast } from '@/utils/nonSpaToast'
import { BulletItem, CaretItem } from '@/pages/teachers/Launch/Items'

const InstructorList = ({course}) => {
  const [showList, setShowList] = useState(false)
  const [instructors, setInstructors] = useState([])
  const [editing, setEditing] = useState(false)
  const [loading, setLoading] = useState(false)
  const { getRequest, putpostRequest } = useQuery()

  useEffect(() => fetchInstructors(), [])

  const fetchInstructors = () => {
    getRequest(`/api/v1/teachers/courses/${course.id}/instructors`, {}, (err, jsonData) => {
      if (err) { /* handled in hook */ return }
      setInstructors(jsonData.instructors)
    })
  }
  const addInstructor = () => {
    setLoading(true)
    const data = {course_id: course.id}
    putpostRequest(`/api/v1/teachers/instructors`, 'POST', data, (err, jsonData) => {
      setLoading(false)
      console.log(err)
      if (err) {
        showNonSpaToast(err, false)
        return
      }
      setInstructors([...instructors, jsonData.instructor ])
    })
  }

  return <>

    { !editing && <div className="hover:shadow-xl hover:opacity-50 cursor-pointer py-2" onClick={() => setEditing(true)}>
      <div className='flex justify-between'>
        <p className="text-sm uppercase text-gray-700">Hosted by</p>
        <div className="flex text-clcnavy justify-end">edit</div>
      </div>
      <div className='flex items-center space-x-3'>
        <div className="flex -space-x-2 py-1">
          { instructors.map((inst, index) => <img key={`instim${inst.id}`} className="inline-block ml-2 h-24 w-24 rounded-3xl ring-2 ring-clcpurple border-white border-4" src={inst.imageUrl} alt="" /> )}
        </div>
        <div className="flex flex-col">
          { instructors.map((inst, index) => <div key={`instnam${inst.id}`} className="text-xl font-extrabold tracking-tight text-gray-900">{inst.name}</div> )}
        </div>
      </div>
    </div> }
    { editing && <div className="p-8 border-4 border-clcpurple rounded-lg">
      <ul role="list" className="space-y-12 sm:grid sm:grid-cols-2 sm:gap-x-2 sm:gap-y-6 sm:space-y-0 lg:gap-x-4">
        { instructors.map((inst, index) => (
          <InstructorForm key={`inst${inst.id}`} initialInst={inst} refreshData={fetchInstructors} />
        ))}
      </ul>
      <div className='mt-4 flex space-x-2'>
        <PrimaryButton onClick={addInstructor} loading={loading} text="Add Instructor" />
        <DefaultButton onClick={() => setEditing(false)} loading={loading} text="Done Editing" />
      </div>
    </div> }
  </>
}


export default InstructorList

import React from 'react'
import { Modal, useModal } from '@/shared/Modal'

import CreditExplainer from '@/shared/CreditExplainer'


const CreditPopover = (props) => {
  const { color, boldText, normalText } = props
  const { openModal, closeModal, isOpen } = useModal()
  return ( <>
    <Modal full isOpen={isOpen} closeModal={closeModal} >
      <CreditExplainer />
      <button className='inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white dark:bg-gray-900 dark:text-white dark:hover:bg-gray-800 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2' onClick={closeModal}>Okay</button>
    </Modal>
    <span onClick={openModal} className={color}>
      <svg className="flex-shrink-0 w-6 h-6 text-indigo-500" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
      </svg>
      <span className="mx-3"><b>{boldText}</b> {normalText}</span>
      <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
      </svg>
    </span>
  </>
  )
}

export default CreditPopover

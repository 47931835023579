import React, {useState, useEffect} from 'react'
import useQuery from '@/hooks/useQuery'
import { Switch } from '@headlessui/react'
import { useForm } from 'react-hook-form'
import FroalaMinimalWrapper from '@/shared/FroalaMinimalWrapper'
import { ExclamationCircleIcon, EmojiHappyIcon, LinkIcon, DuplicateIcon, ClipboardCopyIcon } from '@heroicons/react/solid'

import { PrimaryButton, DefaultButton } from '@/shared/Buttons'
import { Modal, useModal } from '@/shared/Modal'
import classNames from '@/utils/classNamesLocal'

import { showNonSpaToast } from '@/utils/nonSpaToast'
import { BulletItem, CaretItem } from '@/pages/teachers/Launch/Items'
import OutcomesForm from '@/pages/teachers/LandingPage/OutcomesForm'

const OutcomeHeaderForm = ({course}) => {
  const {landing_page} = course
  const [landingPage, setLandingPage] = useState(landing_page)
  const [editing, setEditing] = useState(false)
  const [outcomes, setOutcomes] = useState([])
  const { putpostRequest, getRequest } = useQuery()
  const { formState, reset, register, unregister, handleSubmit, setValue, watch, getValues, setError } = useForm({
   defaultValues: {
      overview_header: landing_page.overview_header,
      overview: landing_page.overview
    }
  })
  const { isDirty, errors } = formState
  const [loading, setLoading] = useState(false)

  useEffect(() => fetchOutcomes(), [])

  const fetchOutcomes = () => {
    getRequest(`/api/v1/teachers/courses/${course.id}/outcomes`, {}, (err, jsonData) => {
      if (err) { /* handled in hook */ return }
      setOutcomes(jsonData.outcomes)
    })
  }

  const addOutcome = () => {
    setLoading(true)
    const data = {course_id: course.id}
    putpostRequest(`/api/v1/teachers/landing_page_outcomes`, 'POST', data, (err, jsonData) => {
      setLoading(false)
      if (err) {
        showNonSpaToast(err, false)
        return
      }
      setOutcomes([...outcomes, jsonData.outcome ])
    })
  }


  const onSubmit = (data) => {
    const formData = {
      landing_page: data
    }
    setLoading(true)
    putpostRequest(`/api/v1/teachers/courses/${course.id}/landing_page`, 'POST', formData, (err, jsonData) => {
      setLoading(false)
      if (err) {
        showNonSpaToast("Error", false)
        if (err.slug) {
          if (err.slug[0] === 'has already been taken') { setError('slug', { type: 'custom', message: 'unique' }) }
          if (err.slug[0] !== 'has already been taken') { setError('slug', { type: 'custom', message: 'format' }) }
        }
        return
      }

      showNonSpaToast("Saved", true)
      setLandingPage({ ...landingPage, outcomes_header: jsonData.landing_page.outcomes_header })
      setEditing(false)
    })
  }

  return <>
    { !editing && <div id="outcomes" className="hover:shadow-xl hover:opacity-50 py-2 cursor-pointer" onClick={() => setEditing(true)}>
      <div className="flex text-clcnavy justify-end">edit</div>
      <div className="py-6 flex flex-col justify-center sm:py-12">
        <div className="relative py-3 sm:max-w-xl sm:mx-auto">
          <div className="absolute inset-0 bg-gradient-to-r from-clcnavy to-clcpurple shadow-lg transform -skew-y-6 sm:skew-y-0 sm:-rotate-6 sm:rounded-3xl"></div>
          <div className="relative px-4 py-10 bg-white shadow-lg sm:rounded-3xl sm:p-10">
            <div className="max-w-md mx-auto">
              <div className="divide-y divide-gray-200">
                <div className="py-8 text-base leading-6 space-y-4 text-gray-700 sm:text-lg sm:leading-7">
                  <h2 className="text-3xl font-extrabold tracking-tight text-gray-900">
                    {landingPage.outcomes_header}
                  </h2>
                  <ul className="list-disc space-y-2">
                    { outcomes.map((outc, index) => <li key={`outli${outc.id}`} className="flex items-start">
                      <span className="h-6 flex items-center sm:h-7">
                        <svg className="flex-shrink-0 h-5 w-5 text-clcpurple" viewBox="0 0 20 20" fill="currentColor">
                          <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
                        </svg>
                      </span>
                      <div className="ml-2 flex flex-col">
                        <b>{outc.title}</b>
                        <div><span dangerouslySetInnerHTML={{__html: outc.desc}}></span></div>
                      </div>
                    </li> )}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> }
    { editing && <form className='p-8 border-4 border-clcpurple rounded-lg'>
      <div className="p-8 pt-5">
        <div className="space-y-6">
          <div>
            <div className="prose-product-lg">
              <h5 className="flex justify-between text-gray-800"><label className="mb-1">Course Outcomes</label></h5>
            </div>
            <div className="prose-product-sm flex items-center sm:prose-product-lg">
              <input type="text" className={errors.outcomes_header ? 'errors placeholder-shown:italic placeholder-gray-600 p2 py-3.5 px-4 w-full border rounded-lg' : 'placeholder-shown:italic placeholder-gray-600 p2 py-3.5 px-4 w-full border rounded-lg'} {...register('outcomes_header', { required: true }) } defaultValue={landingPage.outcomes_header} placeholder="Outcomes" />
            </div>

            { errors.outcomes_header && <div className='mt-2 text-sm text-red-600'>This field is required.</div> }
          </div>
          <div className='flex gap-x-5'>
            <PrimaryButton onClick={handleSubmit(onSubmit)} loading={loading} text="Save" />
            <DefaultButton onClick={() => setEditing(false)} text="Cancel" />
          </div>
          <div>
            <div className="prose-product-sm flex justify-between sm:prose-product-lg">
              <div className="prose-product-lg">
                <h5 className="flex justify-between text-gray-800"><label className="mb-1">Outcome List</label></h5>
              </div>
            </div>
          </div>
          <ul className="list-disc space-y-2 flex flex-col">
            { outcomes.map((outc, index) => (
              <OutcomesForm key={`outcf${outc.id}`} outcome={outc} refreshData={fetchOutcomes} />
            ))}
          </ul>
        </div>
      </div>
      <div className='flex gap-x-5'>
        <PrimaryButton onClick={addOutcome} loading={loading} text="Add Outcome" />
        <DefaultButton onClick={() => setEditing(false)} text="Done Editing" />
      </div>

    </form> }
  </>

}

export default OutcomeHeaderForm

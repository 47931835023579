import React, { useState, useEffect, useRef, useReducer } from 'react'
import { Link } from 'react-router-dom'
import WelcomePanel from '@/shared/WelcomePanel'

import { useGlobalState } from '@/state'
import useQuery from '@/hooks/useQuery'
import { ExclamationIcon } from '@heroicons/react/solid'

import Loading from '@/shared/Loading'
import EventRow from '@/shared/EventRow'

export default function EventIndex(props) {
  const [ currentUser ] = useGlobalState('currentUser')
  const { getRequest } = useQuery()
  const isComponentMounted = useRef(false)
  const [ loading, setLoading ] = useState(false)
  const urlParams = new window.URLSearchParams(window.location.search)
  const welcome = urlParams.get('zoom_success')

  const [state, setState] = useReducer(
    (state, newState) => (
      {...state, ...newState}), {
        events: [],
        pastEvents: [],
        attendingEventIds: []
      }
    )
  const { events, attendingEventIds, pastEvents } = state

  useEffect(() => {
    getRequest(`/api/v1/events`, {}, (err, jsonData) => {
      if (err) { /* handled in hook */ return }

      setState( {
        events: jsonData.events,
        attendingEventIds: jsonData.attendingEventIds
      })
    })
    isComponentMounted.current = true
  }, [])

  if (!isComponentMounted.current) {
    return <Loading />
  }

  return (
    <div className="relative bg-gray-100 dark:bg-gray-700 pb-20 px-4 sm:px-6 pt-6">
      <div className="relative max-w-7xl mx-auto">
        {events.length === 0 && (
          <div>
            <div className="mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8">
              <div className="text-center">
                <h2 className="text-base font-semibold text-clcpurple tracking-wide uppercase">No Upcoming events</h2>
                <p className="max-w-xl mt-5 mx-auto text-xl text-gray-500 dark:text-gray-300">
                  If you are part of an upcoming semester, your events will appear one week before the course begins.
                </p>
              </div>
            </div>
          </div>
        )}


        <div className="relative my-6">
          <div className="absolute inset-0 flex items-center" aria-hidden="true">
            <div className="w-full border-t border-gray-300" />
          </div>
          <div className="relative flex justify-start">
            <span className="pr-3 bg-gray-100 text-lg font-medium text-gray-900 dark:text-gray-300 dark:bg-gray-700">Upcoming Events</span>
          </div>
        </div>

        <div className="mt-12 max-w-lg mx-auto grid gap-5 lg:grid-cols-3 lg:max-w-none">
          {events.map((event) => (
            <EventRow key={`event${event.id}`} event={event} user={currentUser} attendingEventIds={attendingEventIds} />
          ))}
        </div>

        <div className="relative my-6">
          <div className="absolute inset-0 flex items-center" aria-hidden="true">
            <div className="w-full border-t border-gray-300" />
          </div>
          <div className="relative flex justify-start">
            <span className="pr-3 bg-gray-100 text-lg font-medium text-gray-900 dark:text-gray-300 dark:bg-gray-700">Past Events</span>
          </div>
        </div>
        { pastEvents.length === 0 && <a href='/past-events' className='text-clcnavy cursor-pointer'> Load Past Events</a> }
      </div>
    </div>
  )
}

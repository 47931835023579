import React, { useState, useEffect } from 'react'
import { Route, useLocation, Link, useHistory } from 'react-router-dom'

import Breadcrumbs from '@/Layout/Breadcrumbs'
import PageTitle from '@/Layout/PageTitle'
import { useGlobalState } from '@/state'
import useQuery from '@/hooks/useQuery'
import DefaultButton from '@/shared/Buttons/Default'

import SubscriptionIndex from '@/pages/SubscriptionIndex'
import CreditCheckoutForm from '@/pages/CreditCheckoutForm'
import ChangeCardForm from '@/pages/ChangeCardForm'

function breadCrumbs() {
  const location = useLocation()
  const activePath = location.pathname
  const crumbs = [
    { to: '/subscriptions', name: 'Subscription' }
  ]
  return crumbs
}

function pageTitle() {
  const location = useLocation()
  let history = useHistory()
  const [currentUser] = useGlobalState('currentUser')
  const activePath = location.pathname
  const titleInfo = {
     title: '',
    buttons: [ ]
  }
  const changeCourses = () => { history.push('/select_course') }

  const title = ['beta','lead'].includes(currentUser.access) ? 'Become a Member' : 'Subscription'
  // TODO: update title to 'Checkout' if currentUser.onetime is set
  if (activePath === '/subscriptions') {
    titleInfo.title = title
    if (title === 'Become a Member') {
      titleInfo.buttons.push(
        <DefaultButton onClick={changeCourses} text='Back to Course Selection' />
      )
    }
  }
  if (activePath === '/buy_credits') { titleInfo.title = 'Checkout' }
  return titleInfo
}

export default function SubscriptionContainer(props) {
  const titleInfo = pageTitle()
  const crumbs = breadCrumbs()
  const [currentUser, setCurrentUser] = useGlobalState('currentUser')
  const { getRequest } = useQuery()
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    // credits/plan could come in through webhook. need refresh
    getRequest(`/api/v1/users/${currentUser.id}`, {}, (err, jsonData) => {
      setCurrentUser({ ...currentUser, credits: jsonData.user.credits, plan: jsonData.user.plan })
      setLoading(false)
    })
  },[])

  if (loading) return null

  return <>
    <Breadcrumbs>
      { crumbs.map((crumb, index) => (
        <Link key={crumb.name} to={crumb.to} className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700 dark:text-gray-100">
          {crumb.name}
        </Link>
      ))}
    </Breadcrumbs>
    <PageTitle pagetitle={titleInfo.title}>
      {titleInfo.buttons}
    </PageTitle>
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <Route {...props} exact path='/subscriptions'>
        <SubscriptionIndex />
      </Route>
      <Route {...props} exact path='/buy_credits'>
        <CreditCheckoutForm />
      </Route>
      <Route {...props} exact path='/change_card'>
        <ChangeCardForm />
      </Route>
    </div>
  </>
}

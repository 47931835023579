import React, { useState, useEffect, useRef } from 'react'
import { useStripe, useElements, CardElement, Elements } from '@stripe/react-stripe-js'
import { useHistory } from 'react-router-dom'
import classNames from '@/utils/classNamesLocal'
import { Switch } from "@headlessui/react";
import { BadgeCheckIcon, BanIcon } from '@heroicons/react/solid'
import { Modal, useModal } from '@/shared/Modal'

import { FreePlanDetails, PaidPlanDetails } from '@/pages/SubscriptionForm/PlanDetails'
import ExistingPlan from '@/pages/SubscriptionForm/ExistingPlan'

import DefaultButton from '@/shared/Buttons/Default'
import PrimaryButton from '@/shared/Buttons/Primary'
import useQuery from '@/hooks/useQuery'
import Loading from '@/shared/Loading'
import { useGlobalState } from '@/state'

const SubscriptionIndex = (props) => {
  const { getRequest, putpostRequest } = useQuery()
  const { openModal, closeModal, isOpen } = useModal()
  let history = useHistory()
  const [ cohorts, setCohorts ] = useState([])
  const [ totalCost, setTotalCost ] = useState(0)
  const [ yearly, setYearly ] = useState(false)
  const [ recommendedPlan, setRecommendedPlan ] = useState(null)
  const [ recommendedAction, setRecommendedAction ] = useState(null)
  const [ loading, setLoading ] = useState(true)
  const [ stripeLoading, setStripeLoading ] = useState(false)
  const [ existingSubscription, setExistingSubscriptoin ] = useState(null)

  useEffect(() => {
    fetchActiveSubscription()
  }, [])

  const fetchActiveSubscription = () => {
    getRequest('/api/v1/subscriptions/active', {}, (err, jsonData) => {
      setLoading(false)
      if (err) {
        console.log(err)
      } else {
        setExistingSubscriptoin(jsonData.activeSubscription)
      }
    })
  }

  const goToMembership = () => {
    setStripeLoading(true)
    openModal()

    const data = {
      mode: 'subscription',
      include_interests: false,
      member: 'member',
      yearly: yearly ? 'yearly' : 'monthly'
    }

    putpostRequest(`/api/v1/stripe/build_payment_link`, 'POST', data, (err, jsonData) => {
      if (err) {
        alert(err)
        return
      }
      window.location.href = jsonData.stripeLink
    })


  }

  if (loading) { return <Loading full /> }
  if (existingSubscription) { return <ExistingPlan existingSubscription={existingSubscription} /> }

  return <>
    <Modal full isOpen={isOpen} closeModal={closeModal} >
      <div className="max-w-7xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8">
        <div className="text-center">
          <h2 className="text-base font-semibold text-indigo-600 tracking-wide uppercase">Loading</h2>
          <Loading noMessage />

          <p className="max-w-xl mt-5 mx-auto text-xl text-gray-500">Please wait while we setup a checkout session.</p>
        </div>
      </div>
      <button className='inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white dark:bg-gray-900 dark:text-white dark:hover:bg-gray-800 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2' onClick={closeModal}>Okay</button>
    </Modal>
    <div className="mt-12 space-y-12 lg:space-y-0 lg:grid lg:grid-cols-4 lg:gap-x-8">
      <div className='col-span-2'>
        <div className="bg-white mt-3 dark:bg-gray-800 rounded-md  border-cccorange dark:border-cccpurple-alt">
          <div className="flex items-center px-6 py-3">
            <h4 className="flex-shrink-0 mr-3 bg-white dark:bg-gray-800 text-sm tracking-wider font-semibold uppercase text-clcpurple">
              Free Plan
            </h4>
            <div className="flex-1 border-t-2 border-gray-200"></div>
          </div>

          <FreePlanDetails />
        </div>
      </div>
      <div className='col-span-2'>
        <div className="bg-white mt-3 dark:bg-gray-800 rounded-md  border-cccorange dark:border-cccpurple-alt">
          <div className="flex items-center px-6 py-3">
            <h4 className="flex-shrink-0 mr-3 bg-white dark:bg-gray-800 text-sm tracking-wider font-semibold uppercase text-clcpurple">
              Membership
            </h4>
            <div className="flex-1 border-t-2 border-gray-200"></div>
          </div>
        </div>

        <div className="bg-white dark:bg-gray-800 rounded-md text-green-500 border-cccorange dark:border-cccpurple-alt">
          <PaidPlanDetails />
        </div>
        <div className="flex items-center flex-col p-6 pb-0 w-full">
          <div className='flex items-center px-6 w-full'>
            <h4 className="flex-shrink-0 mr-3 bg-gray-100 dark:bg-gray-700 text-sm">
              Membership
            </h4>
            <div className="flex-1 border-t-2 border-gray-200"></div>
            <div className='ml-5 dark:text-gray-100 font-semibold text-gray-800 dark:text-gray-200'>
              ${yearly ? 150 : 15}
            </div>
          </div>
        </div>
        <div className="flex items-center justify-end p-6 pt-0">
          <div className='ml-5 dark:text-gray-100 text-green-500'>Then { yearly ? '$150 / year' : '$15 / month'}</div>
        </div>

        <Switch.Group as="div" className="flex items-center justify-between px-6 w-full">
          <Switch.Label as="span" className="flex-grow flex flex-col" passive>
            <span className="text-sm font-medium text-gray-900 dark:text-gray-200">Switch to {yearly ? 'monthly' : 'yearly. 2 months free.' }</span>
          </Switch.Label>
          <Switch
            checked={yearly}
            onChange={() => setYearly(!yearly)}
            className={classNames(
              yearly ? 'bg-clcnavy' : 'bg-gray-300 dark:bg-gray-500',
              'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cccpurple'
            )}
          >
            <span className="sr-only" hidden>Switch Plans</span>
            <span
              aria-hidden="true"
              className={classNames(
                yearly ? 'translate-x-5' : 'translate-x-0',
                'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
              )}
            />
          </Switch>
        </Switch.Group>
        <div className='flex justify-center mt-4'>
          {!stripeLoading && <PrimaryButton className='mr-5' onClick={goToMembership} loading={loading} text={`I want the ${yearly ? 'yearly' : 'monthly' } membership!`} /> }
        </div>
      </div>
    </div>
  </>
}

export default SubscriptionIndex

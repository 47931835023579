import React, { useEffect, useState, useRef } from 'react'
import ReactDOM from 'react-dom'
import { Link, useParams, useLocation, useHistory } from 'react-router-dom'

import Breadcrumbs from '@/Layout/Breadcrumbs'
import PrimaryButton from '@/shared/Buttons/Primary'

function breadCrumbs(lesson) {
  const location = useLocation()
  const activePath = location.pathname
  const crumbs = [
    { to: '/reserve_seat', name: 'Courses' }
  ]
  if (lesson) {
    crumbs.push({ to: `/c/${lesson?.courseId}-${lesson?.courseSlug}`, name: lesson?.courseName })
    crumbs.push({ to: `/c/${lesson?.id}-${lesson?.slug}`, name: lesson?.name })
  }
  return crumbs
}

import Loading from '@/shared/Loading'
import useQuery from '@/hooks/useQuery'

const LessonShow = () => {
  const { id } = useParams()
  const [ lesson, setLesson ] = useState(null)
  const [ loading, setLoading ] = useState(true)
  const [ toc, setToc ] = useState([])
  let history = useHistory()
  const isComponentMounted = useRef(false)
  const contentRef = useRef(null)
  const lessonId = id.split(/-/)[0]
  const { putpostRequest, getRequest } = useQuery()
  const crumbs = breadCrumbs(lesson)

  useEffect(() => {
    const doSomething = (e) => {
      console.log('clicked')
      console.log(e.target.className)
      if (e.target.className.includes('clc-liblinks')) {
        if (e.target.getAttribute('data-to')) {
          history.push(e.target.getAttribute('data-to'))
        }
      }
    };

    window.addEventListener("click", doSomething);
    return () => {
      window.removeEventListener("click", doSomething);
    };
  }, []);

  useEffect(() => {
    setLoading(true)
    getRequest(`/api/v1/course_contents/${lessonId}`, {}, (err, jsonData) => {
      setLoading(false)
      if (err) { /* handled in hook */ return }
      setLesson(jsonData.lesson)
      setToc(jsonData.toc)
    })
    isComponentMounted.current = true
  }, [lessonId])

  useEffect(() => {
    if (contentRef.current) {
      contentRef.current.scrollIntoView()
    }
  }, [lesson])

  const handleContentClick = (e) => {
    const targetLink =  e.target.closest('a')
    if (!targetLink) { return }

    const data = JSON.parse(e.target.getAttribute('data'))

    if (data?.linktype === 'internalLink') {
      const url = data.url
      e.preventDefault()
      history.push(url)
      return
    }
  }

  if (loading) {
    return <Loading />
  }

  return (<>
    <div className='flex flex-col' ref={contentRef}>
    <Breadcrumbs>
      { crumbs.map((crumb, index) => (
        <Link key={`crum${crumb.name}`} to={crumb.to} className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700 dark:text-gray-100">
          {crumb.name}
        </Link>
      ))}
    </Breadcrumbs>
    <div className="flex">
      <div className="hidden md:flex md:flex-shrink-0">
        <div className="flex flex-col w-64">
          <div className="flex flex-col overflow-y-auto dark:bg-gray-700 fixed w-64">
            <nav className="pr-2">
              <div className='mt-6 flex flex-col'>
                <Link to={`/c/${lesson?.courseId}-${lesson?.courseSlug}`} className="text-sm font-medium">
                  Course: {lesson?.courseName}
                </Link>
                <p className="mb-0 text-xs font-semibold text-gray-400 uppercase tracking-wider mt-2">{lesson?.parentName}</p>
                {toc.map((entry, lidx) => (
                  <Link key={`entry${entry.id}`} to={`/l/${entry.slug}`} className={`px-1 py-2 text-sm font-medium ${entry.slug === lesson.slug ? 'text-clcpink hover:text-clcpink-dark dark:text-clcpurple-light dark:hover:text-clcpurple' : '' }`}>{entry.name}</Link>
                ))}
              </div>
            </nav>
          </div>
        </div>
      </div>
      <div className='flex-1'>
        <div className='mt-6 flex flex-col md:hidden'>
          <p className="mb-0 text-xs font-semibold text-gray-400 uppercase tracking-wider">{lesson?.parentName}</p>
          {toc.map((entry, lidx) => (
            <Link key={`mobileentry${entry.id}`} to={`/l/${entry.slug}`} className={`px-1 py-2 text-sm font-medium ${entry.slug === lesson.slug ? 'text-clcpink hover:text-clcpink-dark dark:text-clcpurple-light dark:hover:text-clcpurple' : '' }`}>{entry.name}</Link>
          ))}
        </div>
        <div className="relative py-16 bg-gray-100 dark:bg-gray-700 overflow-hidden mx-auto">
          <div className="relative px-4 sm:px-6 lg:px-8 prose lg:prose-xl dark:text-gray-200">
            { !loading && <div onClick={handleContentClick} dangerouslySetInnerHTML={{__html: lesson?.body }} /> }
            { !loading && lesson?.nextLessonText && <PrimaryButton onClick={() => history.push(`/l/${lesson?.nextLessonSlug}`)} text={`Next Lesson: ${lesson?.nextLessonText}`} /> }
          </div>
        </div>
      </div>
      { loading && <div className='w-full min-w-32 py-16'> <Loading /> </div> }
    </div>
  </div>
  </>
  ) 
}

export default LessonShow

import React, { useState } from 'react'
import { debounce } from 'throttle-debounce'
import { ExclamationIcon } from '@heroicons/react/solid'

import useQuery from '@/hooks/useQuery'
import Loading from '@/shared/Loading'

const DeleteAccount = () => {
  const [deleteConfirm, setDeleteConfirm] = useState(false)
  const { putpostRequest } = useQuery()
  const [loading, setLoading] = useState(false)

  const deleteAccount = debounce(300, () => {
    setLoading(true)
    putpostRequest('/api/v1/users/delete_account', 'POST', {}, (err, _) => {
      if (err) { /* hooks */ return }

      window.location = 'https://www.closingcredits.com/login'
    })
  })

  return <div className='grid grid-cols-3 mt-10'>
    <div className="md:col-span-1">
      <div className="px-0 sm:px-4 sm:px-0">
        <h3 className="text-lg font-medium leading-6 text-gray-900 dark:text-gray-100">Delete Account</h3>
        <p className="mt-1 text-sm text-gray-600 dark:text-gray-300">
          If you have no more need for Closing Credits, this is where you go to delete everything. It's where you say adios, au revior, sayonara, dovijdane, dosvedanya, later aligator, I am Iron Man.
        </p>
      </div>
    </div>
    <div className="grid grid-cols-3 gap-6 mt-3 sm:mt-0">
      <div className='col-span-3'>
        <div onClick={() => setDeleteConfirm(true)} aria-label='Delete Account' className='text-red-500 cursor-pointer'>Delete Account</div>
        { loading && <Loading /> }
        { deleteConfirm && !loading && <>
          <div className="rounded-md bg-red-50 p-4 mt-10">
            <div className="flex">
              <div className="flex-shrink-0">
                <ExclamationIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
              </div>
              <div className="ml-3">
                <h3 className="text-sm font-medium text-red-800">Take me to the Danger Zone</h3>
                <div className="mt-2 text-sm text-red-700"> This <b>cannot be undone</b>. You must be absolutely sure before you go off and push this button. I'm serious, no takebacks.</div>
                <div onClick={deleteAccount} aria-label='Confirm Delete Account' className='text-red-500 cursor-pointer'>Yes, really delete everything</div>
              </div>
            </div>
          </div>
        </> }
      </div>
    </div>
  </div>
}

export default DeleteAccount

import React, {useState, useEffect, useRef} from 'react'
import useQuery from '@/hooks/useQuery'
import { useDebouncedEffect } from '@/utils/useDebouncedEffect'
import { formatInTimezone } from '@/utils/date'
import { useForm } from 'react-hook-form'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { ExclamationCircleIcon, EmojiHappyIcon, LinkIcon, DuplicateIcon, TrashIcon } from '@heroicons/react/solid'
import { PrimaryButton, DefaultButton } from '@/shared/Buttons'
import { Modal, useModal } from '@/shared/Modal'
import Loading from '@/shared/Loading'

import { showNonSpaToast } from '@/utils/nonSpaToast'
import { BulletItem, CaretItem } from '@/pages/teachers/Launch/Items'

import InstructorForm from '@/pages/teachers/InstructorForm'
import SchoolEdit from '@/pages/teachers/SchoolEdit'
import CourseEdit from '@/pages/teachers/CourseEdit'
import PayoutRecipientsForm from '@/pages/teachers/PayoutRecipientsForm'
import Pagination from '@/shared/Pagination'

const validateEmail = (email) => {
  console.log(email)
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
}

const SchoolAdminRow = ({admin, refreshData}) => {
  const [confirmDelete, setConfirmDelete] = useState(false)
  const [loading, setLoading] = useState(false)
  const { putpostRequest } = useQuery()

  const destroy = () => {
    setLoading(true)
    putpostRequest(`/api/v1/teachers/school_admins/${admin.id}`, 'DELETE', {}, (err, jsonData) => {
      setLoading(false)
      if (err) { // 422 code
        if (typeof err !== 'string') { // 500 code on API
          Object.entries(err).forEach(([key, value]) => {
            setError(key, value)
          })
        }
        return
      }

      refreshData()
      showNonSpaToast("Admin Deleted", true)
    })
  }
  if (loading) return <Loading />

  return <div className='px-4 py-2 bg-white'>
    <div className="grid grid-cols-4">
      <p className="col-span-2 text-gray-900">{admin.name} ({admin.email})</p>
      <p className="col-span-1 capitalize text-gray-700">{admin.role}</p>
      <span className="col-span-1">
        {admin.role === 'School Admin' && <>
          {!confirmDelete && <TrashIcon onClick={() => setConfirmDelete(true)}  className='cursor-pointer hover:text-red-500 h-5 w-5 text-gray-700' /> }
          {confirmDelete && <div onClick={destroy} className='text-red-500 text-sm cursor-pointer'>Really delete?</div> }
        </> }
      </span>
    </div>
  </div>
}

const SchoolAdmins = ({course, school, currentUser}) => {
  const [admins, setAdmins] = useState([])
  const { openModal, closeModal, isOpen } = useModal()
  const emailRef = useRef(null)
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(false)
  const { getRequest, putpostRequest } = useQuery()
  const scrollRef = useRef(null)
  const monthYear = useRef(null)

  useEffect(() => { fetchData() }, [])

  const fetchData = () => {
    const data = { }
    getRequest(`/api/v1/teachers/courses/${course.id}/school_admins`, data, (err, jsonData) => {
      if (err) { /* handled in hook */ return }
      setAdmins(jsonData.schoolAdmins)
    })
  }

  const addAdmin = (email) => {
    if (!validateEmail(emailRef?.current?.value)) {
      setError('Is that a valid email?')
      return
    }
    setLoading(true)
    const data = {course_id: course.id, school_id: school.id, email: emailRef.current.value}
    putpostRequest(`/api/v1/teachers/school_admins`, 'POST', data, (err, jsonData) => {
      setLoading(false)
      console.log(err)
      if (err) {
        showNonSpaToast(err, false)
        return
      }
      setAdmins([...admins, jsonData.admin ])
      showNonSpaToast('Added Admin', true)
      emailRef.current.value = ''
      closeModal()
    })
  }

  const keyPress = (e) => {
    if (error) { setError(null) }

    if (e.target.name === 'email' && e.keyCode === 13) {
      addAdmin()
    }
    if ((e.metaKey || e.ctrlKey) && e.keyCode === 13) { addAdmin() }
  }

  return <>
    <h2 className="mt-6 text-clcnavy text-xl font-extrabold">School staff</h2>
    <div className="border-1 w-full overflow-hidden rounded-2xl border border-black/[0.03] last:h-full">
      <div className="w-full">
        <div className="bg-black/[.03] px-4 py-2">
          <p className="text-sm mt-1 text-gray-700">School admins have access to all functionality at every level of the platform, including creating new courses, and adding new staff members</p>
        </div>
      </div>
      { admins.map((admin, idx) => {
        return <SchoolAdminRow  key={`schooladminst${admin.id}`} admin={admin} refreshData={fetchData} />
      })}
      <div className='px-4 py-2'>
        <DefaultButton onClick={openModal} loading={loading} text="Add School Admin" />
      </div>
    </div>
    <Modal isOpen={isOpen} closeModal={closeModal} >
      <h3 className="text-lg leading-6 font-medium text-gray-900 dark:text-white"> Create admin</h3>

      <div className="w-full flex flex-col space-y-2 py-2">
        <div className="w-full prose-product-sm flex flex-col items-center sm:prose-product-lg">
          <input ref={emailRef} onKeyDown={keyPress} name='email' type='email' autoFocus placeholder='sally@sample.com' className='placeholder-shown:italic placeholder-gray-600 p2 py-3.5 px-4 w-full border rounded-lg border-gray-300 bg-white shadow-sm hover:border-gray-400 hover:shadow-md transition ease-out duration-200' />

          { error && <div className='text-red-500'>{error}</div> }
        </div>
      </div>
      { loading && <Loading /> }

      <PrimaryButton onClick={addAdmin} loading={loading} text="Add School Admin" />
      <DefaultButton onClick={closeModal} text='Cancel' />
    </Modal>
  </>
}


const CourseAdminRow = ({admin, refreshData}) => {
  const [confirmDelete, setConfirmDelete] = useState(false)
  const [loading, setLoading] = useState(false)
  const { putpostRequest } = useQuery()

  const destroy = () => {
    setLoading(true)
    putpostRequest(`/api/v1/teachers/course_admins/${admin.id}`, 'DELETE', {}, (err, jsonData) => {
      setLoading(false)
      if (err) { // 422 code
        if (typeof err !== 'string') { // 500 code on API
          Object.entries(err).forEach(([key, value]) => {
            setError(key, value)
          })
        }
        return
      }

      refreshData()
      showNonSpaToast("Admin Deleted", true)
    })
  }
  if (loading) return <Loading />

  return <div className='px-4 py-2 bg-white'>
    <div className="grid grid-cols-4">
      <p className="col-span-2 text-gray-900">{admin.name} ({admin.email})</p>
      <p className="col-span-1 capitalize text-gray-700">{admin.role}</p>
      <span className="col-span-1">
        {admin.role === 'School Admin' && <>
          {!confirmDelete && <TrashIcon onClick={() => setConfirmDelete(true)}  className='cursor-pointer hover:text-red-500 h-5 w-5 text-gray-700' /> }
          {confirmDelete && <div onClick={destroy} className='text-red-500 text-sm cursor-pointer'>Really delete?</div> }
        </> }
      </span>
    </div>
  </div>
}

const PayoutRecipients = ({school, currentUser}) => {
  const { putpostRequest, getRequest } = useQuery()
  const [payoutRecipients, setPayoutRecipients] = useState([])
  const [loading, setLoading] = useState(false)
  const nameref = useRef()

  useEffect(() => {
    fetchPayoutRecipients()
  },[])

  const fetchPayoutRecipients = () => {
    getRequest(`/api/v1/teachers/payout_recipients?school_id=${school.id}`, {}, (err, jsonData) => {
      if (err) { /* handled in hook */ return }

      setPayoutRecipients(jsonData.payoutRecipients)
    })
  }
  const addPayoutRecipient = () => {
    setLoading(true)
    //const data = {event_id: event.id}
    const data = { name: nameref.current.value }
    putpostRequest(`/api/v1/teachers/payout_recipients?school_id=${school.id}`, 'POST', { payout_recipient: data }, (err, jsonData) => {
      setLoading(false)
      console.log(err)
      if (err) {
        showNonSpaToast(err, false)
        return
      }
      window.location.reload()
    })
  }
  console.log(payoutRecipients)

  return <div className="p-8 py-5 col-span-3  bg-gray-100 p-2 rounded-md border border-2 border-gray-900">
    <h2 className="mt-6 text-clcnavy text-xl font-extrabold">School Payout Recipient List</h2>
    <span className='text-xs'>You can attach these recipients to events and courses</span>
    <div className='mt-4 flex justify-start'>
      <div className="w-full prose-product-sm flex items-center sm:prose-product-lg">
        <input ref={nameref} type="text" className={'placeholder-shown:italic placeholder-gray-600 p2 py-3.5 px-4 w-full border rounded-lg'} placeholder="Mr. Bigglesworth" />
        <PrimaryButton onClick={addPayoutRecipient}  loading={loading} text="Add Payout Recipient" />
      </div>
    </div>
    <ul role="list" className="mt-6 space-y-12 sm:grid sm:grid-cols-2 sm:gap-x-2 sm:gap-y-6 sm:space-y-0 lg:gap-x-4">
      { payoutRecipients.map((pr, index) => (
        <>{pr.name}</>
      ))}
    </ul>
  </div>
}

const CourseAdmins = ({course, school, currentUser}) => {
  const [admins, setAdmins] = useState([])
  const { openModal, closeModal, isOpen } = useModal()
  const emailRef = useRef(null)
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(false)
  const { getRequest, putpostRequest } = useQuery()
  const scrollRef = useRef(null)
  const monthYear = useRef(null)

  useEffect(() => { fetchData() }, [])

  const fetchData = () => {
    const data = { }
    getRequest(`/api/v1/teachers/courses/${course.id}/course_admins`, data, (err, jsonData) => {
      if (err) { /* handled in hook */ return }
      setAdmins(jsonData.courseAdmins)
    })
  }

  const addAdmin = (email) => {
    if (!validateEmail(emailRef?.current?.value)) {
      setError('Is that a valid email?')
      return
    }
    setLoading(true)
    const data = {course_id: course.id, school_id: school.id, email: emailRef.current.value}
    putpostRequest(`/api/v1/teachers/course_admins`, 'POST', data, (err, jsonData) => {
      setLoading(false)
      console.log(err)
      if (err) {
        showNonSpaToast(err, false)
        return
      }
      setAdmins([...admins, jsonData.admin ])
      showNonSpaToast('Added Admin', true)
      emailRef.current.value = ''
      closeModal()
    })
  }

  const keyPress = (e) => {
    if (error) { setError(null) }

    if (e.target.name === 'email' && e.keyCode === 13) {
      addAdmin()
    }
    if ((e.metaKey || e.ctrlKey) && e.keyCode === 13) { addAdmin() }
  }

  return <>
    <h2 className="mt-6 text-clcnavy text-xl font-extrabold">Course staff</h2>
    <div className="border-1 w-full overflow-hidden rounded-2xl border border-black/[0.03] last:h-full">
      <div className="w-full">
        <div className="bg-black/[.03] px-4 py-2">
          <p className="text-sm mt-1 text-gray-700">Course admins can only manage landing page, cohorts, events, surveys, students, emails.</p>
          <p className="text-sm mt-1 text-gray-700 font-bold">All of your School Admins are automatically Course Admins as well.</p>
        </div>
      </div>
      { admins.map((admin, idx) => {
        return <CourseAdminRow  key={`schooladminst${admin.id}`} admin={admin} refreshData={fetchData} />
      })}
      <div className='px-4 py-2'>
        <DefaultButton onClick={openModal} loading={loading} text="Add Course Admin" />
      </div>
    </div>
    <Modal isOpen={isOpen} closeModal={closeModal} >
      <h3 className="text-lg leading-6 font-medium text-gray-900 dark:text-white"> Create admin</h3>

      <div className="w-full flex flex-col space-y-2 py-2">
        <div className="w-full prose-product-sm flex flex-col items-center sm:prose-product-lg">
          <input ref={emailRef} onKeyDown={keyPress} name='email' type='email' autoFocus placeholder='sally@sample.com' className='placeholder-shown:italic placeholder-gray-600 p2 py-3.5 px-4 w-full border rounded-lg border-gray-300 bg-white shadow-sm hover:border-gray-400 hover:shadow-md transition ease-out duration-200' />

          { error && <div className='text-red-500'>{error}</div> }
        </div>
      </div>
      { loading && <Loading /> }

      <PrimaryButton onClick={addAdmin} loading={loading} text="Add Course Admin" />
      <DefaultButton onClick={closeModal} text='Cancel' />
    </Modal>
  </>
}

const CourseSettings = (props) => {
  const { course, school, currentUser } = props
  return ( <>
    <SchoolAdmins course={course} school={school} currentUser={currentUser} />
    <PayoutRecipients school={school} currentUser={currentUser} />
    <CourseAdmins course={course} school={school} currentUser={currentUser} />
    <h2 className="my-6 text-clcnavy text-xl font-extrabold">Course Details</h2>
    <CourseEdit course={course} />
    <h2 className="mt-6 text-clcnavy text-xl font-extrabold">School Details</h2>
    <SchoolEdit school={school} />
  </>
  )
}

export default CourseSettings

import React, { useState, useEffect } from 'react'
import { debounce } from 'throttle-debounce'
import PropTypes from 'prop-types'
import { SVGIcon } from '@/shared/icon'
import { showNonSpaToast } from '@/utils/nonSpaToast'

import useQuery from '@/hooks/useQuery'

import Card from '@/shared/Card'
import Loading from '@/shared/Loading'

const oauths = {
google_oauth2: {
  name: 'Google',
  url: '/auth/google_oauth2?settings_page=true'
},
discord: {
  name: 'Discord',
  url: '/auth/discord?settings_page=true'
},
linkedin: {
  name: 'LinkedIn',
  url: '/auth/linkedin?settings_page=true'
},
ccc: {
  name: 'Casting Call Club',
  url: 'https://www.castingcall.club/sso?settings_page=true'
}
}

const OmniAuthRow = ({ providerName, omniAuthAccounts }) => {
  const { putpostRequest } = useQuery()
  const [confirmDelete, setConfirmDelete] = useState(false)
  const [account, setAccount] = useState(false)

  useEffect(() => {
    setAccount(omniAuthAccounts.find(({ provider }) => provider === providerName))
  }, [omniAuthAccounts])

  const destroy = () => {
    putpostRequest(`/api/v1/omni_auth_accounts/${account.id}`, 'DELETE', {}, (err, jsonData) => {
      if (err) { // 422 code
        showNonSpaToast(err, false)
        return
      }

      setAccount(null)
      showNonSpaToast("Connect Account Deleted", true)
    })
  }

  return <>
    <div className="grid grid-cols-3 gap-6 p-4 bg-white dark:bg-gray-700 rounded-sm">
      <div className='col-span-1 flex items-center text-gray-900 dark:text-gray-100 space-x-2'>
        <span><SVGIcon name={providerName} width='40' height='40' autoDark/></span>
        <span>{oauths[providerName].name}</span>
      </div>
      <div className='col-span-1 text-gray-800 dark:text-gray-200'>
        { account && <span className='text-green-500 flex flex-col'>
          <span>Connected</span>
          <span>{account.createdAt}</span>
        </span> }
        { !account && <span>Not Connected</span> }
      </div>
      <div className='col-span-1 text-gray-800 dark:text-gray-200'>
        { !account && <a href={oauths[providerName].url} className="font-medium text-cccblue hover:text-cccblue-alt"> Connect </a> }
        { account && !confirmDelete && <button onClick={() => setConfirmDelete(true)} className="font-medium cursor-pointer text-red-500"> Disconnect </button> }
        { account && confirmDelete && <button onClick={destroy} className="font-medium cursor-pointer text-red-500"> Really Disconnect? </button> }
      </div>
    </div>
  </>
}

const OmniAuthSettings = () => {
  const [omniAuthAccounts, setOmniAuthAccounts] = useState([])
  const { getRequest } = useQuery()
  const [loading, setLoading] = useState(true)

  useEffect(() => { fetchData() }, [])

  const fetchData = debounce(300, () => {
    setLoading(true)
    getRequest('/api/v1/omni_auth_accounts', {}, (err, jsonData) => {
      setLoading(false)
      if (err) { /* hooks */ return }
      setOmniAuthAccounts(jsonData.omniAuthAccounts)
    })
  })

  return <>
    <div className="md:col-span-1 mb-3">
      <div className="px-0 sm:px-4">
        <h3 className="text-lg font-medium leading-6 text-gray-900 dark:text-gray-100">Connected Accounts</h3>
        <p className="mt-1 text-sm text-gray-600 dark:text-gray-300">
          You can login with any connected account.
        </p>
        <p className="mt-1 text-sm text-gray-600 dark:text-gray-300">
          You will get a "verified" badge when you connect an account.
        </p>
      </div>
    </div>
    <Card title='Connected Acounts'>
      { loading && <Loading /> }
      { !loading && <>
        <OmniAuthRow providerName='google_oauth2' omniAuthAccounts={omniAuthAccounts} />
        <OmniAuthRow providerName='ccc' omniAuthAccounts={omniAuthAccounts} />
        <OmniAuthRow providerName='discord' omniAuthAccounts={omniAuthAccounts} />
        <OmniAuthRow providerName='linkedin' omniAuthAccounts={omniAuthAccounts} />
      </> }
    </Card>
  </>
}

export default OmniAuthSettings

OmniAuthRow.propTypes = {
  providerName: PropTypes.string.isRequired,
  omniAuthAccounts: PropTypes.array.isRequired
}

import React, { useEffect, useState, useRef } from 'react'
import { useGlobalState } from '@/state'
import { Link } from 'react-router-dom'
import { formatInTimezone } from '@/utils/date'
import { CheckCircleIcon, CalendarIcon, ClockIcon, ArrowRightIcon } from '@heroicons/react/solid'

import Loading from '@/shared/Loading'
import useQuery from '@/hooks/useQuery'
import EmailEventRow from '@/pages/teachers/EmailEventRow'
import CohortRow from '@/shared/CohortRow'
import VoiceActing101Index from '@/pages/content/voice-acting-101/Index'
import CourseIndex from '@/pages/content/CourseIndex'
import { Modal, useModal } from '@/shared/Modal'
import { useDebouncedEffect } from '@/utils/useDebouncedEffect'
import Pagination from '@/shared/Pagination'

const Events = ({school, currentUser}) => {
  const [events, setEvents] = useState([])
  const { openModal, closeModal, isOpen } = useModal()
  const [cohorts, setCohorts] = useState([])
  const [meta, setMeta] = useState({})
  const [segment, setSegment] = useState('future')
  const [loading, setLoading] = useState(false)
  const { getRequest, putpostRequest } = useQuery()
  const [page, setPage] = useState(1)
  const [contains, setContains] = useState('')
  const scrollRef = useRef(null)
  const monthYear = useRef(null)

  useDebouncedEffect(() => { fetchData() }, 200, [contains])
  useEffect(() => { fetchData() }, [page, segment])

  const fetchData = () => {
    const data = {
      contains: contains,
      segment: segment,
      page: contains.length > 0 ? 1 : page
    }
    getRequest(`/api/v1/teachers/schools/${school.id}/events`, data, (err, jsonData) => {
      if (err) { /* handled in hook */ return }
      setEvents(jsonData.events)
      setMeta(jsonData.meta)
    })
  }

  return <div className='p-8 py-5 mt-2 col-span-3  bg-gray-100 p-2 rounded-md border border-2 border-gray-900'>
    <div className='pb-5' ref={scrollRef}>
      <div className='flex justify-between items-center'>
        <div>
          <div className="prose-product-lg">
            <h5 className="flex justify-between text-gray-800"><label className="mb-1">Filter school events:</label></h5>
          </div>
          <div className="grid grid-cols-2 gap-x-5 pb-3">
            <div className="col-span-1">
              <select onChange={(e) => setSegment(e.target.value)} className="block w-full py-2 px-3 border border-gray-300 bg-white dark:bg-gray-900 dark:text-gray-100 rounded-md shadow-sm focus:outline-none focus:ring-cccpurple focus:border-cccpurple sm:text-sm">
                  <option value={'future'}>Future school events</option>
                  <option value={'past'}>Past school events</option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>
    { events.length >= 0 && <Pagination meta={meta} callback={setPage} scrollRef={scrollRef} /> }
    <div className="border-1 my-3 w-full overflow-hidden rounded-2xl border border-black/[0.03] last:h-full">
      { events.map((event, idx) => {
        let showMonth = idx === 0 || monthYear.current !== formatInTimezone(event.startsAtUtc, currentUser.timezone, 'MMM yyyy')
        monthYear.current = formatInTimezone(event.startsAtUtc, currentUser.timezone, 'MMM yyyy') 
        return <div key={`evnt${event.id}`}>
          { showMonth && <div className="w-full">
            <div className="h-10 bg-black/[.03] px-4 py-2">
              <h3>{formatInTimezone(event.startsAtUtc, currentUser.timezone, 'MMM yyyy')}</h3>
            </div>
          </div> }
          <EmailEventRow event={event} currentUser={currentUser} />
        </div>
      })}
    </div>
    { events.length >= 0 && <Pagination meta={meta} callback={setPage} scrollRef={scrollRef} /> }
  </div>
}

const ManageEvents = (props) => {
  const [currentUser] = useGlobalState('currentUser')
  const { putpostRequest, getRequest } = useQuery()
  const [dashboardContainer] = useGlobalState('dashboardContainer')
  const { schoolAdmins } = props
  const [ loading, setLoading ] = useState(false)

  console.log('schoolAdmins', schoolAdmins)

  return <div className='max-w-7xl mx-auto'>
    { loading && <Loading /> }
    { schoolAdmins.length === 0 && <div className='mt-2 bg-white dark:bg-gray-900 rounded-lg p-2'>
      <div className="mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8">
        <div className="text-center">
          <h2 className="text-base font-semibold text-clcpurple tracking-wide uppercase">No Schools</h2>
          <p className="max-w-xl mt-5 mx-auto text-xl text-gray-500 dark:text-gray-300">
            You are currently not managing any schools.
          </p>
          <p className="mt-5 mx-auto text-xl">
            <a href='/teach_with_us'>Apply to teach with us!</a>
          </p>
        </div>
      </div>
    </div> }

    { schoolAdmins.length > 0 && <div className='bg-white dark:bg-gray-900 rounded-lg p-2'>
      <div className="relative">
        <div className="relative flex justify-start">
          <h2 className="text-gray-900 dark:text-gray-100 text-xl font-extrabold">Events from Schools I Manage</h2>
        </div>
      </div>

      <section className="mt-6 p-2 space-y-12 sm:grid sm:grid-cols-3 sm:gap-x-2 sm:gap-y-6 sm:space-y-0 lg:gap-x-4">
        { schoolAdmins.map((school, idx) => (
          <>
            <a href={`/teachers/schools/${school.id}`} key={`dassch${school.id}`} className={`p-2 rounded-lg bg-gray-200`}>
              <div className="flex flex-col sm:flex-row gap-x-5 items-center" data-cy="cy-calendar-event">

                <div className='w-full sm:w-36'>
                  <div className="aspect-w-16 aspect-h-9 w-full">
                    <img className="object-cover shadow-lg rounded-lg" src={school.imageUrl} alt="" />
                  </div>
                </div>

                <div className='flex-1 flex-col'>
                  <div className="flex flex-col">
                    <span className="text-lg font-bold">{school.name}</span>
                    <button className="justify-center flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm font-medium text-gray-700 bg-white dark:bg-gray-900 dark:text-white dark:hover:bg-gray-800 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cccpurple" type="button">
                      View School
                      <ArrowRightIcon className="h-5 w-5 mr-2" aria-hidden="true" />
                    </button>
                  </div>
                </div>
              </div>
            </a>
            <br /><br /><br />
            <Events school={school} currentUser={currentUser} />
          </>
        ))}
      </section>
    </div> }
  </div>
}

export default ManageEvents

import React, { useEffect, useRef, useState } from 'react'
import useQuery from '@/hooks/useQuery'
import { Link, useParams } from 'react-router-dom'
import Loading from '@/shared/Loading'
import { formatInTimezone } from '@/utils/date'
import { LinkIcon, DuplicateIcon, ClockIcon, ArrowDownIcon, ArrowUpIcon, ExclamationIcon } from '@heroicons/react/solid'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { Modal, useModal } from '@/shared/Modal'
import { useGlobalState } from '@/state'
import Tippy from '@tippyjs/react'
import ToolTip from '@/shared/ToolTip'
import EventRow from '@/pages/teachers/EventRow'

const TeacherCohortRow = ({cohort, currentUser}) => {
  const [events, setEvents] = useState([])
  const [show, setShow] = useState(cohort.status === 'started')
  const { getRequest, putpostRequest } = useQuery()
  const monthYear = useRef(null)

  let background = 'bg-green-700'
  if (cohort.status === 'enrolling') { background = 'bg-yellow-600' }
  if (cohort.status === 'completed') { background = 'bg-gray-800' }
  if (cohort.status === 'draft') { background = 'bg-red-500' }

  useEffect(() => {
    if (!show) { return }

    fetchData()
  }, [show])

  const toggleShow = () => { setShow(!show) }

  const fetchData = () => {
    if (events.length > 0) { return }
    const data = { cohort_id: cohort.id }
    getRequest(`/api/v1/teachers/events/`, data, (err, jsonData) => {
      if (err) { /* handled in hook */ return }
      setEvents(jsonData.events)
    })
  }

  return <div className={show ? 'border border-3 border-black' : ''}>
    <div className="group flex justify-between items-center cursor-pointer w-full p-3 font-graphik  bg-white">
      <div className="flex items-center" data-cy="cy-calendar-event">
        <div className='flex flex-col'>
          <a href={`/teachers/cohorts/${cohort.id}/edit`} className="flex flex-col hover:bg-gray-100">
            <span className="prose-product-lg uppercase tracking-wider text-gray-700">
              <div className="l2 flex items-center gap-x-5">
                <span className={background.replace('bg-','text-')}>{cohort.status}</span>
              </div>
            </span>
            <span className="text-lg font-bold">{cohort.moniker}</span>
            <div className="flex">
              <svg className="ml-0 mt-0.5 mr-0.5 text-gray-700" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8 14C11.3137 14 14 11.3137 14 8C14 4.68629 11.3137 2 8 2C4.68629 2 2 4.68629 2 8C2 11.3137 4.68629 14 8 14Z" stroke="currentColor" strokeWidth="1.2" strokeMiterlimit="10"></path>
                <path d="M8 4.5V8H11.5" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
              </svg>
              <div className="flex text-sm text-gray-700">
                <div className="font-semibold">{formatInTimezone(cohort.startsAtUtc, currentUser.timezone, 'EEE MMMM d, Y')} <span className='font-normal'>to</span> {formatInTimezone(cohort.endsAtUtc, currentUser.timezone, 'EEE MMMM d, Y')}</div>
              </div>
            </div>
          </a>
        </div>
      </div>
      <div className='flex space-x-3 items-center'>
        <div className="px-4 py-5 sm:p-6">
          <dt className="text-base font-normal text-gray-900">Students</dt>
          <dd className="mt-1 flex justify-between items-baseline md:block lg:flex">
            <div className="flex items-baseline text-2xl font-semibold text-indigo-600">
              {cohort.userCohortsCount}
              <span className="ml-2 text-sm font-medium text-gray-500">of {cohort.capacity}</span>
            </div>
          </dd>
        </div>
        <button onClick={toggleShow} className="justify-center flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm font-medium text-gray-700 bg-white dark:bg-gray-900 dark:text-white dark:hover:bg-gray-800 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cccpurple" type="button">
          { !show  && <ArrowDownIcon className="h-5 w-5 mr-2" aria-hidden="true" /> }
          { show  && <ArrowUpIcon className="h-5 w-5 mr-2" aria-hidden="true" /> }
          { !show ? 'Expand' : 'Hide' } Details
        </button>
      </div>
    </div>
    { show && <div className="border-1 p-2 w-full overflow-hidden rounded-2xl">
      { events.length === 0 && <>
        <div className="rounded-md bg-red-50 p-4">
          <div className="flex">
            <div className="flex-shrink-0">
              <ExclamationIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
            </div>
            <div className="ml-3">
              <h3 className="text-sm font-medium text-red-800">No Events Scheduled</h3>
              <div className="mt-2 text-sm text-red-700"> There are no events attached to the cohort.</div>
              <a href={`/teachers/courses/${cohort.courseId}/events`} className='cursor-pointer'>Schedule Events</a>
            </div>
          </div>
        </div>
      </> }
      { events.map((event, idx) => {
        let showMonth = idx === 0 || monthYear.current !== formatInTimezone(event.startsAtUtc, currentUser.timezone, 'MMM yyyy')
        monthYear.current = formatInTimezone(event.startsAtUtc, currentUser.timezone, 'MMM yyyy') 
        return <div key={`evnt${event.id}`}>
          { showMonth && <div className="w-full">
            <div className="h-10 bg-black/[.03] px-4 py-2">
              <h3>{formatInTimezone(event.startsAtUtc, currentUser.timezone, 'MMM yyyy')}</h3>
            </div>
          </div> }
          <EventRow event={event} currentUser={currentUser} />
        </div>
      })}
    </div> }
  </div>
}

const CohortIndex = ({course, currentUser}) => {
  const isComponentMounted = useRef(false)
  const [cohorts, setCohorts] = useState([])
  const { openModal, closeModal, isOpen } = useModal()
  const [meta, setMeta] = useState({})
  const [loading, setLoading] = useState(false)
  const { getRequest, putpostRequest } = useQuery()
  const [page, setPage] = useState(1)
  const [contains, setContains] = useState('')
  const scrollRef = useRef(null)
  const status = useRef(null)

  useEffect(() => { fetchData() }, [page])

  const fetchData = () => {
    const data = {
      contains: contains,
      page: contains.length > 0 ? 1 : page
    }
    getRequest(`/api/v1/teachers/courses/${course.id}/cohorts`, {}, (err, jsonData) => {
      if (err) { /* handled in hook */ return }
      setCohorts(jsonData.cohorts)
      setMeta(jsonData.meta)
    })
  }

  return <>
    <div className="border-1 mt-6 w-full overflow-hidden rounded-2xl border border-black/[0.03] last:h-full">
      { cohorts.map((cohort, idx) => {
        let showStatus = idx === 0 || status.current !== cohort.status
        status.current = cohort.status
        return <div key={`teaccoh${cohort.id}`}>
          { showStatus && <div className="w-full">
            <div className="h-10 bg-black/[.03] px-4 py-2">
              <h3 className='uppercase'>{status.current}</h3>
            </div>
          </div> }
          <TeacherCohortRow key={`teaccoh${cohort.id}`} cohort={cohort} currentUser={currentUser} />
        </div>
      })}
    </div>
  </>
}

export default CohortIndex

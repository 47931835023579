import React, { useEffect, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import useQuery from '@/hooks/useQuery'
import { debounce } from 'throttle-debounce'
import { ExclamationCircleIcon } from '@heroicons/react/solid'
import { useGlobalState } from '@/state'
import { Toast } from '@/shared/Toast'
import { randomToastSuccess } from '@/shared/Toast'
import { Modal, useModal } from '@/shared/Modal'
import Loading from '@/shared/Loading'

import Card from '@/shared/Card'

const RemindMeButton = ({currentUserId, courseId}) => {
  const [saved, setSaved] = useState(false)
  const { getRequest, putpostRequest } = useQuery()

  useEffect(() => {
    getRequest(`/api/v1/courses/${courseId}/follow`, {}, (err, jsonData) => {
      if (err) { return }
      setSaved(jsonData.isFollowing)
    })
  },[currentUserId])

  const toggleSave = () => {

    putpostRequest(`/api/v1/courses/${courseId}/follow`, 'POST', {}, (err, jsonData) => {
      if (err) { return }
      setSaved(jsonData.isFollowing)
    })
  }

  return <>
    { saved && <button onClick={toggleSave} className="w-full flex items-center justify-center px-4 py-2 text-green-500 bg-white dark:bg-gray-900 font-medium rounded-md shadow-sm border border-gray-300 hover:text-clcnavy hover:bg-clcnavy">Saved!</button> }
    { !saved && <button onClick={toggleSave} className="w-full flex items-center justify-center px-4 py-2 text-gray-700 bg-white dark:bg-gray-900 font-medium rounded-md shadow-sm border border-gray-300 hover:text-clcnavy hover:bg-clcnavy">Remind me</button> }
  </>
}

export default RemindMeButton

import React, { useEffect, useRef } from 'react'
import { Switch, Route, useLocation, Link, useRouteMatch } from 'react-router-dom'

import { useGlobalState } from '@/state'
import useQuery from '@/hooks/useQuery'
import PageTitle from '@/Layout/PageTitle'
import Breadcrumbs from '@/Layout/Breadcrumbs'
import Dashboard from '@/pages/Dashboard'
import Loading from '@/shared/Loading'

function breadCrumbs() {
  const location = useLocation()
  const activePath = location.pathname
  const crumbs = [ ]
  return crumbs
}

function pageTitle() {
  const location = useLocation()
  const activePath = location.pathname
  const titleInfo = {
     title: '',
    buttons: [ ]
  }
  if (activePath === '/dashboard') { titleInfo.title = 'Dashboard' }
  return titleInfo
}


export default function DashboardContainer(props) {
  const titleInfo = pageTitle()
  const crumbs = breadCrumbs()
  const [dashboardContainer, setDashboardContainer] = useGlobalState('dashboardContainer')

  const isComponentMounted = useRef(false)
  const { getRequest } = useQuery()

  useEffect(() => {
    getRequest(`/api/v1/events?this_week=true`, {}, (err, jsonData) => {
      if (err) { /* handled in hook */ return }
      setDashboardContainer({
        ...dashboardContainer,
        events: jsonData.events,
        classSessions: jsonData.classSessions,
        attendingEventIds: jsonData.attendingEventIds
      })
    })

    isComponentMounted.current = true
  }, [])

  if (!isComponentMounted.current) {
    return <Loading />
  }

  // api call to grab all the data here, see EventsContainer

  return <>
    <Breadcrumbs>
      { crumbs.map((crumb, index) => (
        <Link key={crumb.name} to={crumb.to} className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700 dark:text-gray-100">
          {crumb.name}
        </Link>
      ))}
    </Breadcrumbs>
    <PageTitle pagetitle={titleInfo.title}>
      {titleInfo.buttons}
    </PageTitle>
    <div id='scroll-anchor' className='pt-5'>
      <Switch>
        <Route {...props} exact path='/dashboard'>
          <Dashboard />
        </Route>
      </Switch>
    </div>
  </>
}

import React, {useState, useEffect} from 'react'
import useQuery from '@/hooks/useQuery'
import { Switch } from '@headlessui/react'
import { useForm } from 'react-hook-form'
import FroalaMinimalWrapper from '@/shared/FroalaMinimalWrapper'
import { ExclamationCircleIcon, EmojiHappyIcon, LinkIcon, DuplicateIcon, ClipboardCopyIcon } from '@heroicons/react/solid'

import { PrimaryButton, DefaultButton } from '@/shared/Buttons'
import { Modal, useModal } from '@/shared/Modal'
import classNames from '@/utils/classNamesLocal'

import { showNonSpaToast } from '@/utils/nonSpaToast'
import { BulletItem, CaretItem } from '@/pages/teachers/Launch/Items'
import TestimonialForm from '@/pages/teachers/LandingPage/TestimonialForm'

const TestimonialHeaderForm = ({course}) => {
  const {landing_page} = course
  const [landingPage, setLandingPage] = useState(landing_page)
  const [editing, setEditing] = useState(false)
  const [testimonials, setTestimonials] = useState([])
  const { putpostRequest, getRequest } = useQuery()
  const { formState, reset, register, unregister, handleSubmit, setValue, watch, getValues, setError } = useForm({
   defaultValues: {
      testimonial_header: landing_page.testimonial_header,
      testimonials_section: landing_page.testimonials_section === 'visible',
    }
  })
  const { isDirty, errors } = formState
  const [loading, setLoading] = useState(false)

  useEffect(() => fetchTestimonials(), [])

  const handleUpdateSwitch = (key) => {
    setValue(key, !getValues()[key], { shouldDirty: true, shouldValidate: true })
  }

  const fetchTestimonials = () => {
    getRequest(`/api/v1/teachers/courses/${course.id}/testimonials`, {}, (err, jsonData) => {
      if (err) { /* handled in hook */ return }
      setTestimonials(jsonData.testimonials)
    })
  }

  const addTestimonial = () => {
    setLoading(true)
    const data = {course_id: course.id}
    putpostRequest(`/api/v1/teachers/testimonials`, 'POST', data, (err, jsonData) => {
      setLoading(false)
      if (err) {
        showNonSpaToast(err, false)
        return
      }
      setTestimonials([...testimonials, jsonData.testimonial ])
    })
  }


  const onSubmit = (data) => {
    const formData = {
      landing_page: {
        benefits_header: data.benefits_header,
        testimonials_section: testimonials_section ? 'visible' : 'hidden'
      }
    }
    setLoading(true)
    putpostRequest(`/api/v1/teachers/courses/${course.id}/landing_page`, 'POST', formData, (err, jsonData) => {
      setLoading(false)
      if (err) {
        showNonSpaToast("Error", false)
        if (err.slug) {
          if (err.slug[0] === 'has already been taken') { setError('slug', { type: 'custom', message: 'unique' }) }
        }
        return
      }

      showNonSpaToast("Saved", true)
      setLandingPage({ ...landingPage, testimonial_header: jsonData.landing_page.testimonial_header })
      setEditing(false)
    })
  }

  const testimonials_section = watch('testimonials_section')

  return <>
    { !editing && <div id="testimonials" className="hover:shadow-xl py-2 cursor-pointer hover:opacity-50" onClick={() => setEditing(true)}>
      <div className="text-center mb-4">
        <h1 className="mt-1 text-2xl font-extrabold text-gray-900 sm:text-4xl sm:tracking-tight">{landingPage.testimonial_header}</h1>
        { !testimonials_section && <div>
          <div className="mx-auto flex justify-center">
            <div className="flex items-end self-start rounded-full border py-2 px-5 sm:self-center sm:py-3 bg-opacity-5 bg-red-100 border-red-200">
               <h6 className="b1 ml-2 text-red-800 sm:ml-3">This section is hidden</h6>
            </div>
          </div>
        </div> }
        <div className="flex text-clcnavy justify-end">edit</div>
      </div>
      <div className="grid sm:grid-cols-2">
        { testimonials.map((test, idx) => (
          <div key={`testifor${test.id}`} className='flex flex-col space-y-2'>
            <div className="mb-4 md:mb-6">
              <div className="flex space-x-4 items-start">
                <img src='https://clc-content.s3.amazonaws.com/static/icons/icon-quote.svg' />
                <span dangerouslySetInnerHTML={{ __html: test.quote }}></span>
              </div>
            </div>
            <div className="flex">
              <div className="shrink-0">
                <div className="relative overflow-hidden rounded-2xl  w-14 h-14 md:w-20 md:h-20">
                  <div className="aspect-w-1 aspect-h-1 sm:aspect-w-1 sm:aspect-h-1">
                    <img className="object-cover shadow-lg rounded-lg" src={test.imageUrl} alt="" />
                  </div>
                </div>
              </div>
              <div className="pl-3 md:pl-6">
                <p className="p0 mb-1 font-semibold">{test.authorName}</p>
                <div className="p2 text-gray-700">{test.authorTitle}</div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div> }
    { editing && <form className='p-8 border-4 border-clcpurple rounded-lg'>
      <div className="p-8 pt-5">
        <div className="space-y-6">
          <div className='mt-3 grid grid-cols-3'>
            <Switch.Group as="div" className="col-span-1 flex items-center justify-between mb-2">
              <Switch.Label as="span" className="flex-grow flex flex-col" >
                <div className="prose-product-lg">
                  <h5 className="flex justify-between text-gray-800"><label className="mb-1">Show Section</label></h5>
                </div>
              </Switch.Label>
              <Switch checked={testimonials_section} onChange={() => handleUpdateSwitch('testimonials_section')}
                className={classNames(testimonials_section ? 'bg-clcpurple' : 'bg-gray-300 dark:bg-gray-500',
                  'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-clcpurple')} >
                <span className="sr-only" hidden>Section is shown</span>
                <span aria-hidden="true" className={classNames(testimonials_section ? 'translate-x-5' : 'translate-x-0', 'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200')}
                />
              </Switch>
            </Switch.Group>
          </div>
          <div>
            <div className="prose-product-lg">
              <h5 className="flex justify-between text-gray-800"><label className="mb-1">Testimonial Section Header</label></h5>
            </div>
            <div className="prose-product-sm flex items-center sm:prose-product-lg">
              <input type="text" className={errors.testimonial_header ? 'errors placeholder-shown:italic placeholder-gray-600 p2 py-3.5 px-4 w-full border rounded-lg' : 'placeholder-shown:italic placeholder-gray-600 p2 py-3.5 px-4 w-full border rounded-lg'} {...register('testimonial_header', { required: true }) } defaultValue={landingPage.testimonial_header} placeholder="What People Are Saying" />
            </div>

            { errors.testimonial_header && <div className='mt-2 text-sm text-red-600'>This field is required.</div> }
          </div>
          <div className='flex gap-x-5'>
            <PrimaryButton onClick={handleSubmit(onSubmit)} loading={loading} text="Save" />
            <DefaultButton onClick={() => setEditing(false)} text="Cancel" />
          </div>
          <div>
            <div className="prose-product-sm flex justify-between sm:prose-product-lg">
              <div className="prose-product-lg">
                <h5 className="flex justify-between text-gray-800"><label className="mb-1">Testimonials List</label></h5>
              </div>
            </div>
          </div>
          <ul className="list-disc space-y-2 flex flex-col">
            { testimonials.map((test, index) => (
              <TestimonialForm key={`outcf${test.id}`} testimonial={test} refreshData={fetchTestimonials} />
            ))}
          </ul>
        </div>
      </div>
      <div className='flex gap-x-5'>
        <PrimaryButton onClick={addTestimonial} loading={loading} text="Add Testimonial" />
        <DefaultButton onClick={() => setEditing(false)} text="Done Editing" />
      </div>

    </form> }
  </>
}

export default TestimonialHeaderForm

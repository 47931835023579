import React, { useEffect, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import useQuery from '@/hooks/useQuery'
import { debounce } from 'throttle-debounce'
import { ExclamationCircleIcon } from '@heroicons/react/solid'
import { useGlobalState } from '@/state'
import { showNonSpaToast } from '@/utils/nonSpaToast'
import PrimaryButton from '@/shared/Buttons/Primary'
import TicketTypes from '@/shared/TicketTypes'
import { Modal, useModal } from '@/shared/Modal'
import Loading from '@/shared/Loading'

import Card from '@/shared/Card'

const PublicAttendButton = ({initCurrentUserId, eventId}) => {
  const { getRequest, putpostRequest } = useQuery()
  const { openModal, closeModal, isOpen } = useModal()
  const [ attending, setAttending ] = useState(false)
  const [ email, setEmail ] = useState(null)
  const [ error, setError ] = useState(null)
  const [ ticketTypes, setTicketTypes ] = useState([])
  const [ currentUserId, setCurrentUserId ] = useState(initCurrentUserId)
  const [ loading, setLoading ] = useState(false)
  const event = {id : eventId}

  useEffect(() => {
    getRequest(`/api/v1/events/${eventId}/ticket_types`, {}, (err, jsonData) => {
      if (err) { /* handled in hook */ return }
      setTicketTypes(jsonData.ticketTypes)
    })
  }, [eventId])

  const findOrCreateUser = () => {
    setLoading(true)
    setError(null)

    let data = { email: document.querySelector('input[type=email]').value }
    setEmail(data.email)
    putpostRequest(`/api/v1/users/from_public_event`, 'POST', data, (err, jsonData) => {
      setLoading(false)
      if (err) { setError(err); return }
      setCurrentUserId(jsonData.userId)
    })
  }

          

  const toggleAttend = (ticketTypeId) => {
    setLoading(true)
    if (!email) { showNonSpaToast("there is a problem. Reload and try, try again.", true) }
    let data = {
      ticket_type_id: ticketTypeId,
      email: email
    }
    putpostRequest(`/api/v1/events/${event.id}/public_attend`, 'POST', data, (err, jsonData) => {
      setLoading(false)
      if (err) { console.log('toast errors'); return }

      if (ticketTypeId) {
        setAttending(attending === ticketTypeId ? null : ticketTypeId)
      } else {
        setAttending(!attending)
      }
      if (!attending === true) {
        showNonSpaToast("Attending! You'll get an email.", true)
      } else { 
        showNonSpaToast("You're not attending anymore.", true)
      }
    })
  }

  return <>
    <button onClick={openModal} className="w-full flex items-center justify-center px-4 py-2 text-gray-700 bg-white dark:bg-gray-900 font-medium rounded-md shadow-sm border border-gray-300 hover:text-white hover:bg-clcnavy">Attend</button>
    <Modal isOpen={isOpen} closeModal={closeModal}>
      { currentUserId === null && <div className="text-center flex flex-col gap-y-2">
        <label htmlFor="location" className="block text-sm font-medium text-gray-700 dark:text-gray-300 flex justify-between">
          <span>Email</span>
        </label>
        <div className="mt-1 flex rounded-md shadow-sm flex flex-col">
          <div className='relative'>
            <input type='email' className='placeholder-shown:italic placeholder-gray-600 p2 py-3.5 px-4 w-full border rounded-lg' placeholder="sally@sample.com" />
          </div>
          { error && <div className='mt-2 text-sm text-red-600'>{error}</div> }
        </div>
        <PrimaryButton onClick={findOrCreateUser} loading={loading} text="Next" />
      </div> }
      { currentUserId !== null && <>
        <TicketTypes
          event={event}
          attending={attending}
          toggleAttend={toggleAttend}
          loading={loading}
          userId={currentUserId}
          />
        <button className='inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white dark:bg-gray-900 dark:text-white dark:hover:bg-gray-800 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2' onClick={closeModal}>Okay</button>
      </>
        }
    </Modal>
  </>
}

export default PublicAttendButton

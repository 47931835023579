import React from 'react'
import { Switch, Route, useLocation, Link } from 'react-router-dom'
import { ExclamationIcon } from '@heroicons/react/solid'

import PageTitle from '@/Layout/PageTitle'
import Breadcrumbs from '@/Layout/Breadcrumbs'
import { useGlobalState } from '@/state'
import LibraryIndex from '@/pages/LibraryIndex'

function breadCrumbs() {
  const location = useLocation()
  const activePath = location.pathname
  const crumbs = [
    { to: '/concepts', name: 'Material' }
  ]
  // if (activePath === '/profile/options') { crumbs.push({ to: activePath, name: 'Options' }) }
  return crumbs
}

function pageTitle() {
  const location = useLocation()
  const activePath = location.pathname
  const titleInfo = {
     title: '',
    buttons: [ ]
  }
  if (activePath === '/concepts') { titleInfo.title = 'Material' }
  return titleInfo
}

export default function ConceptsContainer(props) {
  const titleInfo = pageTitle()
  const crumbs = breadCrumbs()
  const [ currentUser ] = useGlobalState('currentUser')

  // api call to grab all the data here, see EventsContainer

  return <>
    <Breadcrumbs>
      { crumbs.map((crumb, index) => (
        <Link key={crumb.name} to={crumb.to} className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700 dark:text-gray-100">
          {crumb.name}
        </Link>
      ))}
    </Breadcrumbs>
    <PageTitle pagetitle={titleInfo.title}>
      {titleInfo.buttons}
    </PageTitle>
    <Switch>
      <Route {...props} exact path='/concepts'>
        <LibraryIndex />
      </Route>
    </Switch>
  </>
}

import React, {useState, useEffect} from 'react'
import useQuery from '@/hooks/useQuery'
import { Switch } from '@headlessui/react'
import { useForm } from 'react-hook-form'
import FroalaWrapper from '@/shared/FroalaWrapper'
import { ExclamationCircleIcon, EmojiHappyIcon, LinkIcon, DuplicateIcon, ClipboardCopyIcon } from '@heroicons/react/solid'

import { PrimaryButton, DefaultButton } from '@/shared/Buttons'
import { Modal, useModal } from '@/shared/Modal'
import classNames from '@/utils/classNamesLocal'

import { showNonSpaToast } from '@/utils/nonSpaToast'
import { BulletItem, CaretItem } from '@/pages/teachers/Launch/Items'

const OverviewForm = ({course}) => {
  const {landing_page} = course
  const [landingPage, setLandingPage] = useState(landing_page)
  const [editing, setEditing] = useState(false)
  const { putpostRequest } = useQuery()
  const { formState, reset, register, unregister, handleSubmit, setValue, watch, getValues, setError } = useForm({
   defaultValues: {
      overview_header: landing_page.overview_header,
      overview: landing_page.overview
    }
  })
  const { isDirty, errors } = formState
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    register('overview', { required: true })
    return () => {
      unregister('overview')
    }
  }, [register])

  const handleUpdateFroala = (key, val) => {
    setValue(key, val, { shouldDirty: true, shouldValidate: true })
  }

  const onSubmit = (data) => {
    const formData = {
      landing_page: data
    }
    setLoading(true)
    putpostRequest(`/api/v1/teachers/courses/${course.id}/landing_page`, 'POST', formData, (err, jsonData) => {
      setLoading(false)
      console.log(err)
      if (err) {
        showNonSpaToast("Error", false)
        if (err.slug) {
          if (err.slug[0] === 'has already been taken') { setError('slug', { type: 'custom', message: 'unique' }) }
          if (err.slug[0] !== 'has already been taken') { setError('slug', { type: 'custom', message: 'format' }) }
        }
        return
      }

      showNonSpaToast("Saved", true)
      setLandingPage({ ...landingPage,
        overview_header: jsonData.landing_page.overview_header,
        overview: jsonData.landing_page.overview
      })
      setEditing(false)
    })
  }

  const overview = getValues().overview

  return <>
    { !editing && <div id="hero" className="hover:shadow-xl hover:opacity-50 py-2 cursor-pointer" onClick={() => setEditing(true)}>
      <div className="flex text-clcnavy justify-end">edit</div>
      <p className="text-sm uppercase text-gray-700">Course Overview</p>
      <div className="prose prose-purple text-gray-500 mx-auto lg:max-w-none">
        <h3>{landingPage.overview_header}</h3>
        <p><span dangerouslySetInnerHTML={{__html: landingPage.overview}}></span></p>
      </div>
    </div> }
    { editing && <form className='p-8 border-4 border-clcpurple rounded-lg'>
      <div className="p-8 pt-5">
        <div className="space-y-6">
          <div>
            <div className="prose-product-lg">
              <h5 className="flex justify-between text-gray-800"><label className="mb-1">Course Overview</label></h5>
            </div>
            <div className="prose-product-sm flex items-center sm:prose-product-lg">
              <input type="text" className={errors.overview_header ? 'errors placeholder-shown:italic placeholder-gray-600 p2 py-3.5 px-4 w-full border rounded-lg' : 'placeholder-shown:italic placeholder-gray-600 p2 py-3.5 px-4 w-full border rounded-lg'} {...register('overview_header', { required: true }) } defaultValue={landingPage.overview_header} placeholder="A deep dive into ..." />
            </div>

            { errors.overview_header && <div className='mt-2 text-sm text-red-600'>This field is required.</div> }
          </div>
          <div>
            <div className="prose-product-sm flex justify-between sm:prose-product-lg">
              <div className="prose-product-lg">
                <h5 className="flex justify-between text-gray-800"><label className="mb-1">Course Overview</label></h5>
              </div>
            </div>
            <div className="prose-product-sm flex items-center sm:prose-product-lg">
              { errors.overview && <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
              </div> }
              <FroalaWrapper
                hideButtons={false}
                showCharacterCounter={false}
                model={overview}
                updateModel={(val) => handleUpdateFroala('overview', val)}
              />
            </div>
            { errors.overview && <div className='mt-2 text-sm text-red-600'>This field is required.</div> }
          </div>
          <div className='flex gap-x-5'>
            <PrimaryButton onClick={handleSubmit(onSubmit)} loading={loading} text="Save" />
            <DefaultButton onClick={() => setEditing(false)} text="Cancel" />
          </div>
        </div>
      </div>

    </form> }
  </>

}

export default OverviewForm

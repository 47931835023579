import React, {useState, useEffect} from 'react'
import useQuery from '@/hooks/useQuery'
import { useForm } from 'react-hook-form'
import { ExclamationCircleIcon, EmojiHappyIcon, LinkIcon, DuplicateIcon } from '@heroicons/react/solid'
import {PrimaryButton} from '@/shared/Buttons'
import { BulletItem, CaretItem } from '@/pages/teachers/Launch/Items'

import { showNonSpaToast } from '@/utils/nonSpaToast'

const FindYourNiche = ({course}) => {
  const [show, setShow] = useState(false)
  const [done, setDone] = useState(course?.launch?.findniche === 1)
  const { putpostRequest } = useQuery()

  const markAsDone = () => {
    putpostRequest(`/api/v1/teachers/courses/${course.id}/launch_step`, 'POST', {findniche: 'done'}, (err, jsonData) => {
      if (err) { /* handled in hook */ return }
      setDone(true)
    })
  }

  return <div className={`group ${show? 'rounded-xl border border-solid border-gray-900 shadow-lg' : ''}`}>
    <div onClick={() => setShow(!show)} className={done ? 'prose-product-sm relative flex min-h-16 items-center overflow-hidden p-3 sm:prose-product-lg border-b rounded-t-xl border-gray-200 hover:bg-gray-100 bg-gray-100 dark:bg-gray-800 cursor-pointer' : 'prose-product-sm relative flex min-h-16 items-center overflow-hidden p-3 sm:prose-product-lg  border-gray-200 hover:bg-gray-100  cursor-pointer'}>
      <div className="flex grow items-center justify-between">
        <div className="flex dark:text-gray-300">
          <BulletItem done={done} />
          <div className="ml-4">
            <div className="dark:text-gray-300 flex items-center space-x-3">
              <h4>Find the market niche</h4>
            </div>
          </div>
        </div>
        <div className="mr-4 text-gray-400">
          <CaretItem show={show} />
        </div>
      </div>
    </div>
    { show && <div className="p-8 pt-5">
      <div className="flex space-x-6">
        <div className="min-w-80">
          <div className="group aspect-w-16 aspect-h-9 relative overflow-hidden rounded-lg hover:cursor-pointer">
            <div className="flex h-10 w-10 items-center justify-center md:hidden absolute z-10 m-auto bg-black opacity-80 border-2 border-clcnavy group-hover:bg-clcnavy group-hover:opacity-70 transition ease-in duration-100 rounded-full overflow-hidden text-white">
              <svg className="ml-[6px]" width="16" height="16" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M9.58396 7.49904C10.1777 7.10322 10.1777 6.23076 9.58396 5.83494L1.88673 0.703457C1.22217 0.260421 0.332031 0.736814 0.332031 1.53551V11.7985C0.332031 12.5972 1.22218 13.0736 1.88673 12.6305L9.58396 7.49904Z" fill="currentColor"></path>
              </svg>
            </div>
            <div className="hidden h-18 w-18 md:flex md:items-center md:justify-center absolute z-10 m-auto bg-black opacity-80 border-2 border-clcnavy group-hover:bg-clcnavy group-hover:opacity-70 transition ease-in duration-100 rounded-full overflow-hidden text-white">
              <svg className="ml-2" width="28" height="28" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M9.58396 7.49904C10.1777 7.10322 10.1777 6.23076 9.58396 5.83494L1.88673 0.703457C1.22217 0.260421 0.332031 0.736814 0.332031 1.53551V11.7985C0.332031 12.5972 1.22218 13.0736 1.88673 12.6305L9.58396 7.49904Z" fill="currentColor"></path>
              </svg>
            </div>
            <span><img src="" className="duration-300 ease-in-out" /></span>
          </div>
        </div>
        <div className="space-y-3">
          <div className="p2 space-y-1 text-gray-900">
            <p></p>
            <ul className="ml-6 list-outside list-disc">
              <li className="pl-1">You may assume that the course in your head matches exactly what people want, but it's <b>best to test</b> for demand.</li>
              <li className="pl-1">What if the demand isn't something you can or want to teach? Use the <a href="https://closingcredits.feather.blog/identifying-market-niche" className="text-clcnavy cursor-pointer font-medium underline text-clcnavy" target="_blank" rel="noreferrer">"how-you-look/how-you-feel"</a> framework to find your topic.</li>
              <li className="pl-1">Avoid broad surface-level topics. Instead try to <a href="https://www.loom.com/share/5fc3932ff7cf4cb1957b8bd1c69114b2" className="text-clcnavy cursor-pointer font-medium underline text-clcnavy" target="_blank" rel="noreferrer">deepen your topic</a>. This creates value that cannot easily be copied by someone else and you will experience less competition, creating a <b>consistent revenue stream</b> for you.</li>
            </ul>
            <p className="pt-2"></p>
          </div>
        </div>
      </div>
      <PrimaryButton onClick={markAsDone} text="Mark as Done" />
    </div> }
  </div>
}

const IdentifyPOV = ({course}) => {
  const [show, setShow] = useState(false)
  const [done, setDone] = useState(course?.launch?.identifypov === 1)
  const { putpostRequest } = useQuery()

  const markAsDone = () => {
    putpostRequest(`/api/v1/teachers/courses/${course.id}/launch_step`, 'POST', {identifypov: 'done'}, (err, jsonData) => {
      if (err) { /* handled in hook */ return }
      setDone(true)
    })
  }

  return <div className={`group ${show? 'rounded-xl border border-solid border-gray-900 shadow-lg' : ''}`}>
    <div onClick={() => setShow(!show)} className={done ? 'prose-product-sm relative flex min-h-16 items-center overflow-hidden p-3 sm:prose-product-lg border-b rounded-t-xl border-gray-200 hover:bg-gray-100 bg-gray-100 dark:bg-gray-800 cursor-pointer' : 'prose-product-sm relative flex min-h-16 items-center overflow-hidden p-3 sm:prose-product-lg  border-gray-200 hover:bg-gray-100  cursor-pointer'}>
      <div className="flex grow items-center justify-between">
        <div className="flex dark:text-gray-300">
          <BulletItem done={done} />
          <div className="ml-4">
            <div className="flex items-center space-x-3">
              <h4>Identify your point-of-view</h4>
            </div>
          </div>
        </div>
        <div className="mr-4 text-gray-400">
          <CaretItem show={show} />
        </div>
      </div>
    </div>
      { show && <div className="p-8 pt-5">
        <div className="flex space-x-6">
          <div className="min-w-80">
            <div className="group aspect-w-16 aspect-h-9 relative overflow-hidden rounded-lg hover:cursor-pointer">
              <div className="flex h-10 w-10 items-center justify-center md:hidden absolute z-10 m-auto bg-black opacity-80 border-2 border-primary group-hover:bg-primary group-hover:opacity-70 transition ease-in duration-100 rounded-full overflow-hidden text-white">
                <svg className="ml-[6px]" width="16" height="16" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M9.58396 7.49904C10.1777 7.10322 10.1777 6.23076 9.58396 5.83494L1.88673 0.703457C1.22217 0.260421 0.332031 0.736814 0.332031 1.53551V11.7985C0.332031 12.5972 1.22218 13.0736 1.88673 12.6305L9.58396 7.49904Z" fill="currentColor"></path>
                </svg>
              </div>
              <div className="h-18 w-18 md:flex md:items-center md:justify-center absolute z-10 m-auto bg-black opacity-80 border-2 border-primary group-hover:bg-primary group-hover:opacity-70 transition ease-in duration-100 rounded-full overflow-hidden text-white">
                <svg className="ml-2" width="28" height="28" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M9.58396 7.49904C10.1777 7.10322 10.1777 6.23076 9.58396 5.83494L1.88673 0.703457C1.22217 0.260421 0.332031 0.736814 0.332031 1.53551V11.7985C0.332031 12.5972 1.22218 13.0736 1.88673 12.6305L9.58396 7.49904Z" fill="currentColor"></path>
                </svg>
              </div>
              <span>video</span>
            </div>
          </div>
          <div className="space-y-3">
            <div className="p2 space-y-1 text-gray-900">
              <p></p>
              <ul className="ml-6 list-outside list-disc">
                <li className="pl-1">Your point-of-view is something that makes you stand out.</li>
                <li className="pl-1">What are old beliefs that are simply not true anymore? What's something you wish everyone knew? Even known topics when spun with the right point-of-view can feel new again.</li>
                <li className="pl-1"> <a href="https://closingcredits.feather.blog/identifying-pov" className="text-primary-dark cursor-pointer font-medium underline undefined" target="_blank" rel="noreferrer">Check out these ideas</a> for articulating your points-of-view.</li>
              </ul>
              <p className="pt-2"></p>
            </div>
          </div>
        </div>
      <PrimaryButton onClick={markAsDone} text="Mark as Done" />
    </div> }
  </div>
}

const ShowNiche = ({course}) => {
  return <ul className="overflow-hidden rounded-md border border-gray-200">
    <li className="border-b first:rounded-t-md last:rounded-b-md last:border-b-0">
      <FindYourNiche course={course} />
    </li>
    <li className="border-b first:rounded-t-md last:rounded-b-md last:border-b-0">
      <IdentifyPOV course={course} />
    </li>
  </ul>
}

export default ShowNiche

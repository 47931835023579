import React, {useState, useEffect} from 'react'
import useQuery from '@/hooks/useQuery'
import { useForm } from 'react-hook-form'
import { ExclamationCircleIcon, EmojiHappyIcon, LinkIcon, DuplicateIcon } from '@heroicons/react/solid'
import {PrimaryButton} from '@/shared/Buttons'
import { BulletItem, CaretItem } from '@/pages/teachers/Launch/Items'

import { showNonSpaToast } from '@/utils/nonSpaToast'

const Events = ({course}) => {
  const [show, setShow] = useState(false)
  const [done, setDone] = useState(course?.launch?.events === 1)
  const { putpostRequest } = useQuery()

  const markAsDone = () => {
    putpostRequest(`/api/v1/teachers/courses/${course.id}/launch_step`, 'POST', {events: 'done'}, (err, jsonData) => {
      if (err) { /* handled in hook */ return }
      setDone(true)
    })
  }

  return <div className={`group ${show? 'rounded-xl border border-solid border-gray-900 shadow-lg' : ''}`}>
    <div onClick={() => setShow(!show)} className={done ? 'prose-product-sm relative flex min-h-16 items-center overflow-hidden p-3 sm:prose-product-lg border-b rounded-t-xl border-gray-200 hover:bg-gray-100 bg-gray-100 dark:bg-gray-800 cursor-pointer' : 'prose-product-sm relative flex min-h-16 items-center overflow-hidden p-3 sm:prose-product-lg  border-gray-200 hover:bg-gray-100  cursor-pointer'}>
      <div className="flex grow items-center justify-between">
        <div className="flex dark:text-gray-300">
          <BulletItem done={done} />
          <div className="ml-4">
            <div className="dark:text-gray-300 flex items-center space-x-3">
              <h4>Create your events</h4>
            </div>
          </div>
        </div>
        <div className="mr-4 text-gray-400">
          <CaretItem show={show} />
        </div>
      </div>
    </div>
    { show && <>
      <div className="p-8 pt-5">
        <div className="flex space-x-6">
          <div>
            <h4 className="mb-2"></h4>
            <ul className="ml-6 list-outside list-disc">
              <li className="pl-1">Automatically send calendar invites to students with one click.</li>
              <li className="pl-1">Integrate with Zoom for automatic recordings and uploads, so you won’t ever forget to record.</li>
              <li className="pl-1">Automatically map lessons to your weekly course schedule to stay organized.</li>
            </ul>
            <a href={`/teachers/courses/${course.id}/events`}>
              <div className="prose-product-sm sm:prose-product-lg">
                <button className="mt-2 justify-center items-center flex shadow-sm hover:shadow-md disabled:shadow-none ease-out duration-200 font-medium disabled:cursor-default h-10 px-4 b2 rounded-lg bg-clcpurple text-white hover:bg-clcnavy disabled:bg-gray-500" type="button">
                  Go to events editor
                  <svg className="ml-2" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M2.5 8H13.5" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                    <path d="M9 3.5L13.5 8L9 12.5" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                  </svg>
                </button>
              </div>
            </a>
          </div>
        </div>
        <div className='mt-6'>
          <PrimaryButton onClick={markAsDone} text="Mark as Done" />
        </div>
      </div>
    </> }
  </div>
}
const EngagingContent = ({course}) => {
  const [show, setShow] = useState(false)
  const [done, setDone] = useState(course?.launch?.engaging === 1)
  const { putpostRequest } = useQuery()

  const markAsDone = () => {
    putpostRequest(`/api/v1/teachers/courses/${course.id}/launch_step`, 'POST', {engaging: 'done'}, (err, jsonData) => {
      if (err) { /* handled in hook */ return }
      setDone(true)
    })
  }

  return <div className={`group ${show? 'rounded-xl border border-solid border-gray-900 shadow-lg' : ''}`}>
    <div onClick={() => setShow(!show)} className={done ? 'prose-product-sm relative flex min-h-16 items-center overflow-hidden p-3 sm:prose-product-lg border-b rounded-t-xl border-gray-200 hover:bg-gray-100 bg-gray-100 dark:bg-gray-800 cursor-pointer' : 'prose-product-sm relative flex min-h-16 items-center overflow-hidden p-3 sm:prose-product-lg  border-gray-200 hover:bg-gray-100  cursor-pointer'}>
      <div className="flex grow items-center justify-between">
        <div className="flex dark:text-gray-300">
          <BulletItem done={done} />
          <div className="ml-4">
            <div className="dark:text-gray-300 flex items-center space-x-3">
              <h4>Maximize Engagement</h4>
            </div>
          </div>
        </div>
        <div className="mr-4 text-gray-400">
          <CaretItem show={show} />
        </div>
      </div>
    </div>
    { show && <>
      <div className="p-8 pt-5">
        <div className="flex space-x-6">
          <div className="space-y-3">
            <div className="p2 space-y-1 text-gray-900">
              <p>Great courses are interactive, tactical, and hands-on. Here are a few ways to develop engaging content:</p>
              <ul className="ml-6 list-outside list-disc">
                <li className="pl-1">Stop lecturing. Opt for live AMAs, small group exercises, or peer projects.</li>
                <li className="pl-1">Cold calls. Pick students to answer questions or speak in front of the class.</li>
                <li className="pl-1">Invite guest lecturers so students can hear a different perspective.</li>
                <li className="pl-1">No fluff. Keep things tight, specific.</li>
              </ul>
              <p className="pt-2"></p>
            </div>
          </div>
        </div>
        <div className='mt-6'>
          <PrimaryButton onClick={markAsDone} text="Mark as Done" />
        </div>
      </div>
    </> }
  </div>
}

const Homework = ({course}) => {
  const [show, setShow] = useState(false)
  const [done, setDone] = useState(course?.launch?.homework === 1)
  const { putpostRequest } = useQuery()

  const markAsDone = () => {
    putpostRequest(`/api/v1/teachers/courses/${course.id}/launch_step`, 'POST', {homework: 'done'}, (err, jsonData) => {
      if (err) { /* handled in hook */ return }
      setDone(true)
    })
  }

  return <div className={`group ${show? 'rounded-xl border border-solid border-gray-900 shadow-lg' : ''}`}>
    <div onClick={() => setShow(!show)} className={done ? 'prose-product-sm relative flex min-h-16 items-center overflow-hidden p-3 sm:prose-product-lg border-b rounded-t-xl border-gray-200 hover:bg-gray-100 bg-gray-100 dark:bg-gray-800 cursor-pointer' : 'prose-product-sm relative flex min-h-16 items-center overflow-hidden p-3 sm:prose-product-lg  border-gray-200 hover:bg-gray-100  cursor-pointer'}>
      <div className="flex grow items-center justify-between">
        <div className="flex dark:text-gray-300">
          <BulletItem done={done} />
          <div className="ml-4">
            <div className="dark:text-gray-300 flex items-center space-x-3">
              <h4>Design Your Homework</h4>
            </div>
          </div>
        </div>
        <div className="mr-4 text-gray-400">
          <CaretItem show={show} />
        </div>
      </div>
    </div>
    { show && <>
      <div className="p-8 pt-5">
        <div className="flex space-x-6">
          <div className="space-y-3">
            <div className="p2 space-y-1 text-gray-900">
              <p>Interactivity is a huge part of learning. Purely lecture or video courses cannot provide applicable hands-on learning that live courses can. Some ideas for interactive projects are:</p>
              <ul className="ml-6 list-outside list-disc">
                <li className="pl-1">Record themselves performing</li>
                <li className="pl-1">Live reads or presentations</li>
                <li className="pl-1">Take an unfinished piece of work you've created and ask them to finish it</li>
                <li className="pl-1">Share their work and get peer feedback</li>
              </ul>
            </div>
          </div>
        </div>
        <div className='mt-6'>
          <PrimaryButton onClick={markAsDone} text="Mark as Done" />
        </div>
      </div>
    </> }
  </div>
}

const Schedule = ({course}) => {
  const [show, setShow] = useState(false)
  const [done, setDone] = useState(course?.launch?.schedule === 1)
  const { putpostRequest } = useQuery()

  const markAsDone = () => {
    putpostRequest(`/api/v1/teachers/courses/${course.id}/launch_step`, 'POST', {schedule: 'done'}, (err, jsonData) => {
      if (err) { /* handled in hook */ return }
      setDone(true)
    })
  }

  return <div className={`group ${show? 'rounded-xl border border-solid border-gray-900 shadow-lg' : ''}`}>
    <div onClick={() => setShow(!show)} className={done ? 'prose-product-sm relative flex min-h-16 items-center overflow-hidden p-3 sm:prose-product-lg border-b rounded-t-xl border-gray-200 hover:bg-gray-100 bg-gray-100 dark:bg-gray-800 cursor-pointer' : 'prose-product-sm relative flex min-h-16 items-center overflow-hidden p-3 sm:prose-product-lg  border-gray-200 hover:bg-gray-100  cursor-pointer'}>
      <div className="flex grow items-center justify-between">
        <div className="flex dark:text-gray-300">
          <BulletItem done={done} />
          <div className="ml-4">
            <div className="dark:text-gray-300 flex items-center space-x-3">
              <h4>Syllabus</h4>
            </div>
          </div>
        </div>
        <div className="mr-4 text-gray-400">
          <CaretItem show={show} />
        </div>
      </div>
    </div>
    { show && <div className="p-8 pt-5">
      <div>
        <h4 className="mb-2">Organize your lessons.</h4>
        <ul className="ml-6 list-outside list-disc">
          <li className="pl-1">Start with <b>real outcomes</b> that students will achieve. Title or subtitle your lessons around them.</li>
          <li className="pl-1">Supplement your live sessions with resources like videos, articles, or homework.</li>
        </ul>
      </div>
      <PrimaryButton onClick={markAsDone} text="Mark as Done" />
    </div> }
  </div>
}

const ShowSchedule = ({course}) => {
  return <ul className="overflow-hidden rounded-md border border-gray-200">
    <li className="border-b first:rounded-t-md last:rounded-b-md last:border-b-0">
      <Schedule course={course} />
    </li>
    <li className="border-b first:rounded-t-md last:rounded-b-md last:border-b-0">
      <Homework course={course} />
    </li>
    <li className="border-b first:rounded-t-md last:rounded-b-md last:border-b-0">
      <EngagingContent course={course} />
    </li>
    <li className="border-b first:rounded-t-md last:rounded-b-md last:border-b-0">
      <Events course={course} />
    </li>
  </ul>
}

export default ShowSchedule

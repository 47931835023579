import React, {useState, useEffect} from 'react'
import useQuery from '@/hooks/useQuery'
import { Switch } from '@headlessui/react'
import { useForm } from 'react-hook-form'
import FroalaMinimalWrapper from '@/shared/FroalaMinimalWrapper'
import { ExclamationCircleIcon, EmojiHappyIcon, LinkIcon, DuplicateIcon, ClipboardCopyIcon } from '@heroicons/react/solid'

import { PrimaryButton, DefaultButton } from '@/shared/Buttons'
import { Modal, useModal } from '@/shared/Modal'
import classNames from '@/utils/classNamesLocal'

import { showNonSpaToast } from '@/utils/nonSpaToast'
import { BulletItem, CaretItem } from '@/pages/teachers/Launch/Items'
import InstructorbioForm from '@/pages/teachers/LandingPage/InstructorbioForm'

const InstructorHeaderForm = ({course}) => {
  const {landing_page} = course
  const [landingPage, setLandingPage] = useState(landing_page)
  const [editing, setEditing] = useState(false)
  const [instructors, setInstructors] = useState([])
  const { putpostRequest, getRequest } = useQuery()
  const { formState, reset, register, unregister, handleSubmit, setValue, watch, getValues, setError } = useForm({
   defaultValues: {
      instructor_header: landing_page.instructor_header
    }
  })
  const { isDirty, errors } = formState
  const [loading, setLoading] = useState(false)

  useEffect(() => fetchdata(), [])

  const fetchdata = () => {
    getRequest(`/api/v1/teachers/courses/${course.id}/instructors`, {}, (err, jsonData) => {
      if (err) { /* handled in hook */ return }
      setInstructors(jsonData.instructors)
    })
  }

  const add = () => {
    setLoading(true)
    const data = {course_id: course.id}
    putpostRequest(`/api/v1/teachers/instructors`, 'POST', data, (err, jsonData) => {
      setLoading(false)
      if (err) {
        showNonSpaToast(err, false)
        return
      }
      setInstructors([...instructors, jsonData.instructor ])
    })
  }


  const onSubmit = (data) => {
    const formData = {
      landing_page: data
    }
    setLoading(true)
    putpostRequest(`/api/v1/teachers/courses/${course.id}/landing_page`, 'POST', formData, (err, jsonData) => {
      setLoading(false)
      if (err) {
        showNonSpaToast("Error", false)
        if (err.slug) {
          if (err.slug[0] === 'has already been taken') { setError('slug', { type: 'custom', message: 'unique' }) }
        }
        return
      }

      showNonSpaToast("Saved", true)
      setLandingPage({ ...landingPage, instructor_header: jsonData.landing_page.instructor_header })
      setEditing(false)
    })
  }

  return <>
    { !editing && <div id="instructor_bios" className="hover:shadow-xl hover:opacity-50 py-2 cursor-pointer" onClick={() => setEditing(true)}>
      <div className="text-center mb-4">
        <h1 className="mt-1 text-2xl font-extrabold text-gray-900 sm:text-4xl sm:tracking-tight">{landingPage.instructor_header}</h1>
        <div className="flex text-clcnavy justify-end">edit</div>
      </div>
      <ul role="list" className="mt-2 space-y-12 sm:divide-y sm:divide-gray-200 sm:space-y-0 sm:-mt-8 lg:gap-x-8 lg:space-y-0">
        { instructors.map((inst, idx) => (
        <li key={`isntfor${inst.id}`} className="sm:py-8">
          <div className="space-y-4 sm:grid sm:grid-cols-3 sm:items-start sm:gap-6 sm:space-y-0">
            <div className="aspect-w-1 aspect-h-1 sm:aspect-w-1 sm:aspect-h-1">
              <img className="object-cover shadow-lg rounded-lg" src={inst.imageUrl} alt="" />
            </div>
            <div className="flex flex-col space-y-2">
              <div className='flex justify-between items-center'>
                <p className="text-lg mb-1 font-semibold">{inst.name}</p>
              </div>
              <div className="font-semibold text-clcnavy">{inst.title}</div>
              <div className="prose sm:prose-product-lg"><span dangerouslySetInnerHTML={{ __html: inst.bio }}></span></div>
            </div>
          </div>
        </li>
        ))}
      </ul>
    </div> }
    { editing && <form className='p-8 border-4 border-clcpurple rounded-lg'>
      <div className="p-8 pt-5">
        <div className="space-y-6">
          <div>
            <div className="prose-product-lg">
              <h5 className="flex justify-between text-gray-800"><label className="mb-1">Instructor Section Header</label></h5>
            </div>
            <div className="prose-product-sm flex items-center sm:prose-product-lg">
              <input type="text" className={errors.instructor_header ? 'errors placeholder-shown:italic placeholder-gray-600 p2 py-3.5 px-4 w-full border rounded-lg' : 'placeholder-shown:italic placeholder-gray-600 p2 py-3.5 px-4 w-full border rounded-lg'} {...register('instructor_header', { required: true }) } defaultValue={landingPage.instructor_header} placeholder="What People Are Saying" />
            </div>

            { errors.instructor_header && <div className='mt-2 text-sm text-red-600'>This field is required.</div> }
          </div>
          <div className='flex gap-x-5'>
            <PrimaryButton onClick={handleSubmit(onSubmit)} loading={loading} text="Save" />
            <DefaultButton onClick={() => setEditing(false)} text="Cancel" />
          </div>
          <div>
            <div className="prose-product-sm flex justify-between sm:prose-product-lg">
              <div className="prose-product-lg">
                <h5 className="flex justify-between text-gray-800"><label className="mb-1">Instructors List</label></h5>
              </div>
            </div>
          </div>
          <ul className="list-disc space-y-2 flex flex-col">
            { instructors.map((inst, index) => (
              <InstructorbioForm key={`instf${inst.id}`} instructor={inst} refreshData={fetchdata} />
            ))}
          </ul>
        </div>
      </div>
      <div className='flex gap-x-5'>
        <PrimaryButton onClick={add} loading={loading} text="Add Instructor" />
        <DefaultButton onClick={() => setEditing(false)} text="Done Editing" />
      </div>

    </form> }
  </>

}

export default InstructorHeaderForm

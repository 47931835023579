import React, { useEffect, useState } from 'react'
import { useGlobalState } from '@/state'
import { Link } from 'react-router-dom'
import { formatInTimezone } from '@/utils/date'
import { CheckCircleIcon, CalendarIcon, ClockIcon, ArrowRightIcon } from '@heroicons/react/solid'

import Loading from '@/shared/Loading'
import useQuery from '@/hooks/useQuery'

const MyCourses = (props) => {
  const [currentUser] = useGlobalState('currentUser')
  const { putpostRequest, getRequest } = useQuery()
  const [ enrolledCohorts, setEnrolledCohorts ] = useState([])
  const [ pastCohorts, setPastCohorts ] = useState([])
  const [ loading, setLoading ] = useState(false)

  useEffect(() => {
    setLoading(true)
    getRequest(`/api/v1/users/dashboard`, {}, (err, jsonData) => {
      if (err) { /* handled in hook */ return }
      setEnrolledCohorts(jsonData.enrolledCohorts)
      setPastCohorts(jsonData.pastCohorts)
      setLoading(false)
    })
  }, [])

  return <div className='max-w-7xl mx-auto'>
    { loading && <Loading /> }

    { enrolledCohorts.length > 0 && <div className='mt-2 bg-white dark:bg-gray-900 rounded-lg p-2'>
      <div className="relative flex justify-start">
        <h2 className="mt-6 text-gray-900 dark:text-gray-100 text-xl font-extrabold">Courses I'm Currently Taking</h2>
      </div>

      <section className="mt-6 p-2 space-y-12 sm:grid sm:grid-cols-3 sm:gap-x-2 sm:gap-y-6 sm:space-y-0 lg:gap-x-4">
        { enrolledCohorts.map((cohort, idx) => (
          <a href={`/c/${cohort.courseSlug}`} key={`dasco${cohort.id}`} className={`p-2 rounded-lg bg-gray-100 dark:bg-gray-800`}>
            <div>
              <div className="aspect-w-16 aspect-h-9">
                <img className="object-cover w-full rounded-lg" src={cohort.imageUrl} alt="" />
              </div>
              <div className='flex flex-col gap-y-3'>
                <div className="text-lg text-center leading-6 font-medium mt-2">{cohort.name}</div>
                <span className='text-gray-500 dark:text-gray-300 text-sm flex'><CalendarIcon className='w-5 h-5 mr-2' /><strong>{formatInTimezone(cohort.startsAtUtc, currentUser.timezone, 'EEE, MMM d, Y')} - {formatInTimezone(cohort.endsAtUtc, currentUser.timezone, 'EEE, MMM d, Y')}</strong></span>

                <button className="justify-center flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm font-medium text-gray-700 bg-white dark:bg-gray-900 dark:text-white dark:hover:bg-gray-800 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cccpurple" type="button">
                  View Course Dashboard
                  <ArrowRightIcon className="h-5 w-5 mr-2" aria-hidden="true" />
                </button>
              </div>
            </div>
          </a>
        ))}
      </section>
    </div> }

    { pastCohorts.length > 0 && <div className='mt-2 bg-white dark:bg-gray-900 rounded-lg p-2'>
      <div className="relative flex justify-start">
        <h2 className="text-gray-900 dark:text-gray-100 text-xl font-extrabold">Courses I've Taken In The Past</h2>
      </div>

      <section className="mt-6 p-2 space-y-12 sm:grid sm:grid-cols-3 sm:gap-x-2 sm:gap-y-6 sm:space-y-0 lg:gap-x-4">
        { pastCohorts.map((cohort, idx) => (
          <a href={`/c/${cohort.courseSlug}`} key={`dasco${cohort.id}`} className={`p-2 rounded-lg bg-gray-100 dark:bg-gray-800`}>
            <div>
              <div className="aspect-w-16 aspect-h-9">
                <img className="object-cover w-full rounded-lg" src={cohort.imageUrl} alt={cohort.name} />
              </div>
              <div className='flex flex-col gap-y-3'>
                <div className="text-lg text-center leading-6 font-medium mt-2">{cohort.name}</div>
                <span className='text-gray-500 dark:text-gray-300 text-sm flex'><CalendarIcon className='w-5 h-5 mr-2' /><strong>{formatInTimezone(cohort.startsAtUtc, currentUser.timezone, 'EEE, MMM d, Y')} - {formatInTimezone(cohort.endsAtUtc, currentUser.timezone, 'EEE, MMM d, Y')}</strong></span>

                <button className="justify-center flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm font-medium text-gray-700 bg-white dark:bg-gray-900 dark:text-white dark:hover:bg-gray-800 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cccpurple" type="button">
                  View Course Dashboard
                  <ArrowRightIcon className="h-5 w-5 mr-2" aria-hidden="true" />
                </button>
              </div>
            </div>
          </a>
        ))}
      </section>
    </div> }

  </div>
}

export default MyCourses

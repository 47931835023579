import React, {useState, useEffect} from 'react'
import useQuery from '@/hooks/useQuery'
import { Switch } from '@headlessui/react'
import { useForm } from 'react-hook-form'
import FroalaMinimalWrapper from '@/shared/FroalaMinimalWrapper'
import { ExclamationCircleIcon, EmojiHappyIcon, LinkIcon, DuplicateIcon, ClipboardCopyIcon } from '@heroicons/react/solid'

import { PrimaryButton, DefaultButton } from '@/shared/Buttons'
import { Modal, useModal } from '@/shared/Modal'
import classNames from '@/utils/classNamesLocal'

import { showNonSpaToast } from '@/utils/nonSpaToast'
import { BulletItem, CaretItem } from '@/pages/teachers/Launch/Items'

const OutlineForm = ({outline, refreshData}) => {
  const [editing, setEditing] = useState(false)
  const [confirmDelete, setConfirmDelete] = useState(false)
  const { putpostRequest } = useQuery()
  const { formState, reset, register, unregister, handleSubmit, setValue, watch, getValues, setError } = useForm({
   defaultValues: {
      title: outline.title,
      desc: outline.desc,
    }
  })
  const { isDirty, errors } = formState
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    register('desc', { required: true })
    return () => {
      unregister('desc')
    }
  }, [register])

  const handleUpdateFroala = (key, val) => {
    setValue(key, val, { shouldDirty: true, shouldValidate: true })
  }

  const destroy = () => {
    setLoading(true)
    putpostRequest(`/api/v1/teachers/landing_page_outlines/${outline.id}`, 'DELETE', {}, (err, jsonData) => {
      setLoading(false)
      if (err) { // 422 code
        if (typeof err !== 'string') { // 500 code on API
          Object.entries(err).forEach(([key, value]) => {
            setError(key, value)
          })
        }
        return
      }

      refreshData()
      showNonSpaToast("Section Deleted", true)
    })
  }

  const onSubmit = (data) => {
    const formData = {
      outline: data
    }
    setLoading(true)
    putpostRequest(`/api/v1/teachers/landing_page_outlines/${outline.id}`, 'PATCH', formData, (err, jsonData) => {
      setLoading(false)
      console.log(err)
      if (err) {
        showNonSpaToast("Error", false)
        if (err.slug) {
          if (err.slug[0] === 'has already been taken') { setError('slug', { type: 'custom', message: 'unique' }) }
          if (err.slug[0] !== 'has already been taken') { setError('slug', { type: 'custom', message: 'format' }) }
        }
        return
      }

      showNonSpaToast("Saved", true)
      setEditing(false)
      refreshData()
    })
  }

  const desc = getValues().desc

  return <>
    { !editing && <div className="hover:shadow-xl hover:opacity-50 cursor-pointer flex-col space-y-3" onClick={() => setEditing(true)}>
      <div className="flex flex-row items-start border-b-2 border-dotted py-8 border-gray-500 last:border-b-0">
        <p className="prose-website-lg mr-4 text-gray-600">00</p>
        <div className='w-full'>
          <div className='flex justify-between'>
            <p className="font-semibold text-gray-800">{outline.title}</p>
            <div className="flex text-clcnavy justify-end">edit</div>
          </div>
          <div className="py-2 text-gray-700"><span dangerouslySetInnerHTML={{ __html: outline.desc }}></span>
          </div>
        </div>
      </div>
    </div> }
    { editing && <li className='p-8 border-4 border-clcpurple rounded-lg'>
      <form>
        <div className="p-8 pt-5">
          <div className="space-y-6">
            <div>
              <div className="prose-product-lg">
                <h5 className="flex justify-between text-gray-800"><label className="mb-1">Section Title</label></h5>
              </div>
              <div className="prose-product-sm flex items-center sm:prose-product-lg">
                <input type="text" className={errors.title ? 'errors placeholder-shown:italic placeholder-gray-600 p2 py-3.5 px-4 w-full border rounded-lg' : 'placeholder-shown:italic placeholder-gray-600 p2 py-3.5 px-4 w-full border rounded-lg'} {...register('title', { required: true }) } defaultValue={outline.title} placeholder="First Module" />
              </div>

              { errors.title && <div className='mt-2 text-sm text-red-600'>This field is required.</div> }
            </div>
            <div>
              <div className="prose-product-sm flex justify-between sm:prose-product-lg">
                <div className="prose-product-lg">
                  <h5 className="flex justify-between text-gray-800"><label className="mb-1">Desc</label></h5>
                </div>
              </div>
              <div className="prose-product-sm flex items-center sm:prose-product-lg">
                { errors.desc && <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                  <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
                </div> }
                <FroalaMinimalWrapper
                  hideButtons={false}
                  showCharacterCounter={false}
                  model={desc}
                  updateModel={(val) => handleUpdateFroala('desc', val)}
                />
              </div>
              { errors.desc && <div className='mt-2 text-sm text-red-600'>This field is required.</div> }
            </div>
            <div className='flex gap-x-5'>
              <PrimaryButton onClick={handleSubmit(onSubmit)} loading={loading} text="Save" />
              <DefaultButton onClick={() => setEditing(false)} text="Cancel" />
              {!confirmDelete && <div onClick={() => setConfirmDelete(true)} className='text-red-500 text-sm cursor-pointer'>Delete</div> }
              {confirmDelete && <div onClick={destroy} className='text-red-500 text-sm cursor-pointer'>Really delete?</div> }

            </div>
          </div>
        </div>

      </form>
    </li> }
  </>

}

export default OutlineForm

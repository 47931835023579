import React, {useState, useEffect} from 'react'
import useQuery from '@/hooks/useQuery'
import { useForm } from 'react-hook-form'
import { ExclamationCircleIcon, EmojiHappyIcon, LinkIcon, DuplicateIcon } from '@heroicons/react/solid'
import PrimaryButton from '@/shared/Buttons/Primary'

import { showNonSpaToast } from '@/utils/nonSpaToast'
import { BulletItem, CaretItem } from '@/pages/teachers/Launch/Items'

import InstructorForm from '@/pages/teachers/InstructorForm'
import ShowNiche from '@/pages/teachers/Launch/Niche'
import CourseAdmins from '@/pages/teachers/CourseAdmins'
import SchoolAdmins from '@/pages/teachers/SchoolAdmins'
import ShowGrowWaitlist from '@/pages/teachers/Launch/Waitlist'

const InstructorInformation = ({course}) => {
  const [showList, setShowList] = useState(false)
  const [done, setDone] = useState(course.launch.instructor == 1)
  const [instructors, setInstructors] = useState([])
  const [loading, setLoading] = useState(false)
  const { getRequest, putpostRequest } = useQuery()

  useEffect(() => {
    if (!showList || instructors.length > 0) { return }

    fetchInstructors()
  }, [showList])

  const fetchInstructors = () => {
    getRequest(`/api/v1/teachers/courses/${course.id}/instructors`, {}, (err, jsonData) => {
      if (err) { /* handled in hook */ return }
      setInstructors(jsonData.instructors)
    })
  }
  const addInstructor = () => {
    setLoading(true)
    const data = {course_id: course.id}
    putpostRequest(`/api/v1/teachers/instructors`, 'POST', data, (err, jsonData) => {
      setLoading(false)
      console.log(err)
      if (err) {
        showNonSpaToast(err, false)
        return
      }
      setDone(true)
      setInstructors([...instructors, jsonData.instructor ])
    })
  }

  return <>
    <div className={`group ${showList ? 'rounded-xl border border-solid border-gray-900 shadow-lg' : ''}`}>
      <div onClick={() => setShowList(!showList)} className={done ? 'prose-product-sm relative flex min-h-16 items-center overflow-hidden p-3 sm:prose-product-lg border-b rounded-t-xl border-gray-200 hover:bg-gray-100 bg-gray-100 dark:bg-gray-800 cursor-pointer' : 'prose-product-sm relative flex min-h-16 items-center overflow-hidden p-3 sm:prose-product-lg  border-gray-200 hover:bg-gray-100  cursor-pointer'}>
      <div className="flex grow items-center justify-between">
        <div className="flex dark:text-gray-300">
          <BulletItem done={done} />
          <div className="ml-4">
            <div className="dark:text-gray-300  flex items-center space-x-3">
              <h4>Complete your instructor information</h4>
            </div>
          </div>
        </div>
        <div className="mr-4 text-gray-400">
          <CaretItem show={showList} />
        </div>
      </div>
    </div>
    { showList && <div className="p-8 py-5">
      <ul role="list" className="space-y-12 sm:grid sm:grid-cols-2 sm:gap-x-2 sm:gap-y-6 sm:space-y-0 lg:gap-x-4">
        { instructors.map((inst, index) => (
          <InstructorForm key={`inst${inst.id}`} initialInst={inst} refreshData={fetchInstructors} />
        ))}
      </ul>
      <div className='mt-4'>
        <PrimaryButton onClick={addInstructor} loading={loading} text="Add Instructor" />
      </div>
    </div> }
  </div>
  </>
}

const SetupYourCourse = ({course}) => {
  const [showForm, setShowForm] = useState(true)
  const { putpostRequest } = useQuery()
  const { formState, reset, register, unregister, handleSubmit, setValue, watch, getValues, setError } = useForm({
   defaultValues: {
      name: course.name,
      email: course.email,
      slug: course.slug
    }
  })
  const { isDirty, errors } = formState
  const [loading, setLoading] = useState(false)

  const onSubmit = (data) => {
    setLoading(true)
    putpostRequest(`/api/v1/teachers/courses/${course.id}`, 'PATCH', data, (err, jsonData) => {
      setLoading(false)
      console.log(err)
      if (err) {
        showNonSpaToast("Error", false)
        if (err.slug) {
          if (err.slug[0] === 'has already been taken') { setError('slug', { type: 'custom', message: 'unique' }) }
          if (err.slug[0] !== 'has already been taken') { setError('slug', { type: 'custom', message: 'format' }) }
        }
        return
      }

      showNonSpaToast("Basic Info Saved", true)
    })
  }

  return <form>
    <div className={`group ${showForm ? ' rounded-xl border border-solid border-gray-900 shadow-lg' : ''}`}>
      <div onClick={() => setShowForm(!showForm)} className="prose-product-sm relative flex min-h-16 items-center overflow-hidden p-3 sm:prose-product-lg border-b rounded-t-xl border-gray-200 hover:bg-gray-100 bg-gray-100 dark:bg-gray-800 cursor-pointer">
        <div className="flex grow items-center justify-between">
          <div className="flex">
            <svg className="m-auto" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fillRule="evenodd" clipRule="evenodd" d="M3 10C3 6.13401 6.13401 3 10 3C13.866 3 17 6.13401 17 10C17 13.866 13.866 17 10 17C6.13401 17 3 13.866 3 10ZM10 2C5.58172 2 2 5.58172 2 10C2 14.4183 5.58172 18 10 18C14.4183 18 18 14.4183 18 10C18 5.58172 14.4183 2 10 2ZM13.7827 8.48668C13.9825 8.29601 13.9898 7.97951 13.7992 7.77976C13.6085 7.58001 13.292 7.57265 13.0923 7.76332L8.85414 11.8088L6.90774 9.95083C6.70799 9.76015 6.3915 9.76751 6.20083 9.96726C6.01015 10.167 6.01751 10.4835 6.21726 10.6742L8.5089 12.8617C8.70211 13.0461 9.00617 13.0461 9.19938 12.8617L13.7827 8.48668Z" fill="#009E3D"></path>
            </svg>
            <div className="ml-4">
              <div className="text-gray-600 dark:text-gray-300  flex items-center space-x-3">
                <h4>Set up your course</h4>
              </div>
            </div>
          </div>
          <div className="mr-4 text-gray-400">
            <CaretItem show={showForm} />
          </div>
        </div>
      </div>
      { showForm && <div className="p-8 pt-5">
        <div className="space-y-6">
          <div>
            <div className="prose-product-lg">
              <h5 className="flex justify-between text-gray-800"><label className="mb-1">Course name</label></h5>
            </div>
            <div className="prose-product-sm flex items-center sm:prose-product-lg">
              <input type="text" className={errors.name ? 'errors placeholder-shown:italic placeholder-gray-600 p2 py-3.5 px-4 w-full border rounded-lg' : 'placeholder-shown:italic placeholder-gray-600 p2 py-3.5 px-4 w-full border rounded-lg'} {...register('name', { required: true }) } defaultValue={course.name} placeholder="Public Speaking 101" />
            </div>

            { errors.name && <div className='mt-2 text-sm text-red-600'>This field is required.</div> }
          </div>
          <div>
            <div>
              <div className="prose-product-sm flex justify-between sm:prose-product-lg">
                <div className="prose-product-lg">
                  <h5 className="flex justify-between text-gray-800"><label className="mb-1">Course URL</label></h5>
                </div>
              </div>
              <div className="prose-product-sm flex items-center sm:prose-product-lg">
                <div className="rounded-l-lg border border-r-0 border-gray-300 bg-gray-100 py-3.5 px-3 text-gray-600 shadow-sm shrink-0">closingcredits.com/courses/</div>
                <input type="text" className={errors.slug ? 'errors placeholder-shown:italic placeholder-gray-600 p2 py-3.5 px-4 w-full border rounded-lg rounded-r-lg' : 'placeholder-shown:italic placeholder-gray-600 p2 py-3.5 px-4 w-full border rounded-none'} {...register('slug', { required: true }) } defaultValue={course.slug} placeholder="public-speaking-101" />
              </div>
              { errors.slug?.type === 'required' && <div className='mt-2 text-sm text-red-600'>This field is required.</div> }
              { errors.slug?.message === 'unique' && <div className='mt-2 text-sm text-red-600'>Already in use by someone</div> }
              { errors.slug?.message === 'format' && <div className='mt-2 text-sm text-red-600'>Numbers 0-9, Letters a-z, hyphens -, or underlines _ only</div> }
              <div className="mt-1 flex justify-between ">
                <div className="prose-product-sm sm:prose-product-lg">
                  <p className="text-xs text-gray-600 dark:text-gray-400">Updating this URL will break any links that you have shared.</p>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className="prose-product-lg">
              <h5 className="flex justify-between text-gray-800"><label className="mb-1">Course reply-to email</label></h5>
            </div>
            <div className="prose-product-sm flex items-center sm:prose-product-lg">
              <input type="text" className={errors.email ? 'errors placeholder-shown:italic placeholder-gray-600 p2 py-3.5 px-4 w-full border rounded-lg' : 'placeholder-shown:italic placeholder-gray-600 p2 py-3.5 px-4 w-full border rounded-lg'} {...register('email', { required: true }) } defaultValue={course.name} placeholder="sally@sample.com" />
            </div>
            <div className='text-xs text-gray-600 dark:text-gray-400'>This is the email that students will reply to when they receive an automatic email about your course.</div>

            { errors.email && <div className='mt-2 text-sm text-red-600'>This field is required.</div> }
          </div>
          <PrimaryButton onClick={handleSubmit(onSubmit)} loading={loading} text="Save" />
        </div>
      </div> }
    </div>
  </form>
}

const CourseFaculty = ({course, school, currentUser}) => {
  const [showList, setShowList] = useState(false)
  const [done, setDone] = useState(course.launch.faculty == 1)
  const { putpostRequest } = useQuery()

  const markAsDone = () => {
    putpostRequest(`/api/v1/teachers/courses/${course.id}/launch_step`, 'POST', {faculty: 'done'}, (err, jsonData) => {
      if (err) { /* handled in hook */ return }
      setDone(true)
    })
  }


  return <>
    <div className={`group ${showList ? 'rounded-xl border border-solid border-gray-900 shadow-lg' : ''}`}>
      <div onClick={() => setShowList(!showList)} className={done ? 'prose-product-sm relative flex min-h-16 items-center overflow-hidden p-3 sm:prose-product-lg border-b rounded-t-xl border-gray-200 hover:bg-gray-100 bg-gray-100 dark:bg-gray-800 cursor-pointer' : 'prose-product-sm relative flex min-h-16 items-center overflow-hidden p-3 sm:prose-product-lg  border-gray-200 hover:bg-gray-100  cursor-pointer'}>
      <div className="flex grow items-center justify-between">
        <div className="flex dark:text-gray-300">
          <BulletItem done={done} />
          <div className="ml-4">
            <div className="dark:text-gray-300  flex items-center space-x-3">
              <h4>Add Faculty</h4>
            </div>
          </div>
        </div>
        <div className="mr-4 text-gray-400">
          <CaretItem show={showList} />
        </div>
      </div>
    </div>
    { showList && <div className="p-8 py-5">
      <SchoolAdmins course={course} school={school} currentUser={currentUser} />
      <CourseAdmins course={course} school={school} currentUser={currentUser} />
      <div className='mt-6'>
        <PrimaryButton onClick={markAsDone} text="Mark as Done" />
      </div>
    </div> }
  </div>
  </>
}

const TheBasics = ({course}) => {
  return <ul className="overflow-hidden rounded-md border border-gray-200">
    <li className="border-b first:rounded-t-md last:rounded-b-md last:border-b-0">
      <SetupYourCourse course={course} />
    </li>
    <li className="border-b first:rounded-t-md last:rounded-b-md last:border-b-0">
      <InstructorInformation course={course} />
    </li>
    <li className="border-b first:rounded-t-md last:rounded-b-md last:border-b-0">
      <CourseFaculty course={course} />
    </li>
  </ul>
}

const LaunchStep1 = (props) => {
  const { course, school } = props
  const [showBasics, setShowBasics] = useState(true)
  const [showNiche, setShowNiche] = useState(true)
  const [showGrowWaitlist, setShowGrowWaitlist] = useState(true)
  return ( <>
    <ul className="space-y-12">
      <li>
        <div onClick={() => setShowBasics(!showBasics)} className="mb-3 flex cursor-pointer items-center space-x-2 text-clcnavy">
          { !showBasics && <div className="rounded-full bg-clcnavy bg-opacity-5 p-3">
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M6 3L11 8L6 13" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
            </svg>
          </div> }
          { showBasics && <div className="rounded-full bg-clcnavy bg-opacity-5 p-3">
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M13 6L8 11L3 6" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
            </svg>
          </div> }
          <p className="text-large">The Basics</p>
        </div>
        { showBasics && <TheBasics course={course} school={school} /> }
      </li>
      <li>
        <div onClick={() => setShowNiche(!showNiche)} className="mb-3 flex cursor-pointer items-center space-x-2 text-clcnavy">
          { !showNiche && <div className="rounded-full bg-clcnavy bg-opacity-5 p-3">
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M6 3L11 8L6 13" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
            </svg>
          </div> }
          { showNiche && <div className="rounded-full bg-clcnavy bg-opacity-5 p-3">
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M13 6L8 11L3 6" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
            </svg>
          </div> }
          <p className="text-large">Find Your Niche</p>
        </div>
        { showNiche && <ShowNiche course={course} /> }
      </li>
      <li>
        <div onClick={() => setShowGrowWaitlist(!showGrowWaitlist)} className="mb-3 flex cursor-pointer items-center space-x-2 text-clcnavy">
          { !showGrowWaitlist && <div className="rounded-full bg-clcnavy bg-opacity-5 p-3">
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M6 3L11 8L6 13" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
            </svg>
          </div> }
          { showGrowWaitlist && <div className="rounded-full bg-clcnavy bg-opacity-5 p-3">
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M13 6L8 11L3 6" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
            </svg>
          </div> }
          <p className="text-large">Grow Your Waitlist</p>
        </div>
        { showGrowWaitlist && <ShowGrowWaitlist course={course} /> }
      </li>
    </ul>
    <a className='mt-6' href={`/teachers/courses/${course.id}/launch_step2`}>
      <div className="prose-product-sm sm:prose-product-lg">
        <button className="mt-2 justify-center items-center flex shadow-sm hover:shadow-md disabled:shadow-none ease-out duration-200 font-medium disabled:cursor-default h-10 px-4 b2 rounded-lg bg-clcpurple text-white hover:bg-clcnavy disabled:bg-gray-500" type="button">
          Go to step 2: Enroll Students
          <svg className="ml-2" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2.5 8H13.5" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
            <path d="M9 3.5L13.5 8L9 12.5" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
          </svg>
        </button>
      </div>
    </a>
  </>
  )
}

export default LaunchStep1

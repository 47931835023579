import React, {useState, useEffect} from 'react'
import useQuery from '@/hooks/useQuery'
import { useForm } from 'react-hook-form'
import { ExclamationCircleIcon, EmojiHappyIcon, LinkIcon, DuplicateIcon } from '@heroicons/react/solid'
import {PrimaryButton} from '@/shared/Buttons'
import { BulletItem, CaretItem } from '@/pages/teachers/Launch/Items'

import { showNonSpaToast } from '@/utils/nonSpaToast'

const Zoom = ({course}) => {
  const [show, setShow] = useState(false)
  const [done, setDone] = useState(course?.launch?.zoom === 1)
  const { putpostRequest } = useQuery()

  const markAsDone = () => {
    putpostRequest(`/api/v1/teachers/courses/${course.id}/launch_step`, 'POST', {zoom: 'done'}, (err, jsonData) => {
      if (err) { /* handled in hook */ return }
      setDone(true)
    })
  }

  return <div className={`group ${show? 'rounded-xl border border-solid border-gray-900 shadow-lg' : ''}`}>
    <div onClick={() => setShow(!show)} className={done ? 'prose-product-sm relative flex min-h-16 items-center overflow-hidden p-3 sm:prose-product-lg border-b rounded-t-xl border-gray-200 hover:bg-gray-100 bg-gray-100 dark:bg-gray-800 cursor-pointer' : 'prose-product-sm relative flex min-h-16 items-center overflow-hidden p-3 sm:prose-product-lg  border-gray-200 hover:bg-gray-100  cursor-pointer'}>
      <div className="flex grow items-center justify-between">
        <div className="flex dark:text-gray-300">
          <BulletItem done={done} />
          <div className="ml-4">
            <div className="dark:text-gray-300 flex items-center space-x-3">
              <h4>Connect Your Zoom</h4>
            </div>
          </div>
        </div>
        <div className="mr-4 text-gray-400">
          <CaretItem show={show} />
        </div>
      </div>
    </div>
    {show && <>
      <div className="p-8 pt-5">
        <div className="flex space-x-6">
          <div>
            <h4 className="mb-2"></h4>
            <div>
              <ul className="ml-6 list-outside list-disc">
                <li className="pl-1">Automatically record and share your Zoom meetings.</li>
                <li className="pl-1">Automatically create meeting rooms for your events.</li>
                <li className="pl-1">Don't forget to do a trial run: test screensharing, breakout rooms, muting students, etc.</li>
              </ul>
            </div>
            <div className="prose-product-sm sm:prose-product-lg"><button data-cy="" className="mt-2 justify-center items-center flex shadow-sm hover:shadow-md disabled:shadow-none ease-out duration-200 font-medium disabled:cursor-default h-10 px-4 b2 rounded-lg bg-clcnavy text-white" type="button">Connect Zoom</button></div>
          </div>
        </div>
        <div className='mt-6'>
          <PrimaryButton onClick={markAsDone} text="Mark as Done" />
        </div>
      </div>
    </>}
  </div>
}

const Surveys = ({course}) => {
  const [show, setShow] = useState(false)
  const [done, setDone] = useState(course?.launch?.nps === 1)
  const { putpostRequest } = useQuery()

  const markAsDone = () => {
    putpostRequest(`/api/v1/teachers/courses/${course.id}/launch_step`, 'POST', {nps: 'done'}, (err, jsonData) => {
      if (err) { /* handled in hook */ return }
      setDone(true)
    })
  }

  return <div className={`group ${show? 'rounded-xl border border-solid border-gray-900 shadow-lg' : ''}`}>
    <div onClick={() => setShow(!show)} className={done ? 'prose-product-sm relative flex min-h-16 items-center overflow-hidden p-3 sm:prose-product-lg border-b rounded-t-xl border-gray-200 hover:bg-gray-100 bg-gray-100 dark:bg-gray-800 cursor-pointer' : 'prose-product-sm relative flex min-h-16 items-center overflow-hidden p-3 sm:prose-product-lg  border-gray-200 hover:bg-gray-100  cursor-pointer'}>
      <div className="flex grow items-center justify-between">
        <div className="flex dark:text-gray-300">
          <BulletItem done={done} />
          <div className="ml-4">
            <div className="dark:text-gray-300 flex items-center space-x-3">
              <h4>Create Post-cohort Feedback Surveys</h4>
            </div>
          </div>
        </div>
        <div className="mr-4 text-gray-400">
          <CaretItem show={show} />
        </div>
      </div>
    </div>
    {show && <>
      <div className="p-8 pt-5">
        <h4 className="mb-2">What happens after the cohort?</h4>
        <ul className="ml-6 list-outside list-disc">
          <li className="pl-1">Students automatically receive a post-cohort survey so you can level-up your course by collecting feedback.</li>
          <li className="pl-1">Testimonials and Ratings: Closing Credits automatically collects this info so your happy students can spread the word.</li>
        </ul>
        <a href={`/teachers/courses/${course.id}/surveys`}>
          <div className="prose-product-sm sm:prose-product-lg">
            <button className="mt-2 justify-center items-center flex shadow-sm hover:shadow-md disabled:shadow-none ease-out duration-200 font-medium disabled:cursor-default h-10 px-4 b2 rounded-lg bg-clcpurple text-white hover:bg-clcnavy disabled:bg-gray-500" type="button">
              Go to surveys editor
              <svg className="ml-2" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M2.5 8H13.5" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                <path d="M9 3.5L13.5 8L9 12.5" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
              </svg>
            </button>
          </div>
        </a>
        <div className='mt-6'>
          <PrimaryButton onClick={markAsDone} text="Mark as Done" />
        </div>
      </div>
    </>}
  </div>
}

const TrialRun = ({course}) => {
  const [show, setShow] = useState(false)
  const [done, setDone] = useState(course?.launch?.schedule === 1)
  const { putpostRequest } = useQuery()

  const markAsDone = () => {
    putpostRequest(`/api/v1/teachers/courses/${course.id}/launch_step`, 'POST', {listed: 'done'}, (err, jsonData) => {
      if (err) { /* handled in hook */ return }
      setDone(true)
    })
  }

  return <div className={`group ${show? 'rounded-xl border border-solid border-gray-900 shadow-lg' : ''}`}>
    <div onClick={() => setShow(!show)} className={done ? 'prose-product-sm relative flex min-h-16 items-center overflow-hidden p-3 sm:prose-product-lg border-b rounded-t-xl border-gray-200 hover:bg-gray-100 bg-gray-100 dark:bg-gray-800 cursor-pointer' : 'prose-product-sm relative flex min-h-16 items-center overflow-hidden p-3 sm:prose-product-lg  border-gray-200 hover:bg-gray-100  cursor-pointer'}>
      <div className="flex grow items-center justify-between">
        <div className="flex dark:text-gray-300">
          <BulletItem done={done} />
          <div className="ml-4">
            <div className="dark:text-gray-300 flex items-center space-x-3">
              <h4>Positive Feedback</h4>
            </div>
          </div>
        </div>
        <div className="mr-4 text-gray-400">
          <CaretItem show={show} />
        </div>
      </div>
    </div>
    { show && <>
      <div className="p-8 pt-5">
        <div className="flex space-x-6">
          <div className="space-y-3">
            <div className="p2 space-y-1 text-gray-900">
              <p>We recommend doing a trial run for your first live session. Here are a few Zoom techniques to test:</p>
              <ul className="ml-6 list-outside list-disc">
                <li className="pl-1">Screenshare a window in Zoom</li>
                <li className="pl-1">Play music</li>
                <li className="pl-1">Mute students</li>
                <li className="pl-1">Open and close breakout rooms</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <PrimaryButton onClick={markAsDone} text="Mark as Done" />
    </> }
  </div>
}

const WelcomeEmails = ({course}) => {
  const [show, setShow] = useState(false)
  const [done, setDone] = useState(course?.launch?.orientation === 1)
  const { putpostRequest } = useQuery()

  const markAsDone = () => {
    putpostRequest(`/api/v1/teachers/courses/${course.id}/launch_step`, 'POST', {orientation: 'done'}, (err, jsonData) => {
      if (err) { /* handled in hook */ return }
      setDone(true)
    })
  }

  return <div className={`group ${show? 'rounded-xl border border-solid border-gray-900 shadow-lg' : ''}`}>
    <div onClick={() => setShow(!show)} className={done ? 'prose-product-sm relative flex min-h-16 items-center overflow-hidden p-3 sm:prose-product-lg border-b rounded-t-xl border-gray-200 hover:bg-gray-100 bg-gray-100 dark:bg-gray-800 cursor-pointer' : 'prose-product-sm relative flex min-h-16 items-center overflow-hidden p-3 sm:prose-product-lg  border-gray-200 hover:bg-gray-100  cursor-pointer'}>
      <div className="flex grow items-center justify-between">
        <div className="flex dark:text-gray-300">
          <BulletItem done={done} />
          <div className="ml-4">
            <div className="dark:text-gray-300 flex items-center space-x-3">
              <h4>Prepare Your Orientation Email</h4>
            </div>
          </div>
        </div>
        <div className="mr-4 text-gray-400">
          <CaretItem show={show} />
        </div>
      </div>
    </div>
    { show && <>
      <div className="p-8 pt-5">
        <div className="flex space-x-6">
          <div className="space-y-3">
            <div className="p2 space-y-1 text-gray-900">
              <p>One week before your cohort kicks off, send a warm welcome email to your students using the broadcast email feature. Get them excited, and make sure to inform them on what to expect.</p>
              <p className="pt-2"></p>
            </div>
            <a href={`/teachers/courses/${course.id}/emails`}>
              <div className="prose-product-sm sm:prose-product-lg">
                <button className="mt-2 justify-center items-center flex shadow-sm hover:shadow-md disabled:shadow-none ease-out duration-200 font-medium disabled:cursor-default h-10 px-4 b2 rounded-lg bg-clcpurple text-white hover:bg-clcnavy disabled:bg-gray-500" type="button">
                  Go to emails editor
                  <svg className="ml-2" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M2.5 8H13.5" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                    <path d="M9 3.5L13.5 8L9 12.5" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                  </svg>
                </button>
              </div>
            </a>
          </div>
        </div>
        <div className='mt-6'>
          <PrimaryButton onClick={markAsDone} text="Mark as Done" />
        </div>
      </div>
    </> }
  </div>
}

const ShowCommunity = ({course}) => {
  return <ul className="overflow-hidden rounded-md border border-gray-200">
    <li className="border-b first:rounded-t-md last:rounded-b-md last:border-b-0">
      <Surveys course={course} />
    </li>
    <li className="border-b first:rounded-t-md last:rounded-b-md last:border-b-0">
      <WelcomeEmails course={course} />
    </li>
  </ul>
}

export default ShowCommunity

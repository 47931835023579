import React, {useState, useEffect} from 'react'
import { ExclamationCircleIcon, EmojiHappyIcon, LinkIcon, DuplicateIcon } from '@heroicons/react/solid'
import { BulletItem, CaretItem } from '@/pages/teachers/Launch/Items'
import { Switch } from '@headlessui/react'
import { Modal, useModal } from '@/shared/Modal'
import DatePicker from 'react-datepicker'
import classNames from '@/utils/classNamesLocal'

import { format } from 'date-fns-tz'
import useQuery from '@/hooks/useQuery'
import {PrimaryButton, DefaultButton} from '@/shared/Buttons'
import { useForm } from 'react-hook-form'
import { showNonSpaToast } from '@/utils/nonSpaToast'

import CohortEdit from '@/pages/teachers/CohortEdit'

const times = ['00:00', '00:30', '01:00', '01:30', '02:00', '02:30', '03:00', '03:30', '04:00', '04:30', '05:00', '05:30', '06:00', '06:30', '07:00', '07:30', '08:00', '08:30', '09:00', '09:30', '10:00', '10:30', '11:00', '11:30', '12:00', '12:30']

const LandingPage = ({course}) => {
  const [show, setShow] = useState(false)
  const [done, setDone] = useState(course?.launch?.sharesurvey === 1)

  return <div className={`group ${show? 'rounded-xl border border-solid border-gray-900 shadow-lg' : ''}`}>
    <div onClick={() => setShow(!show)} className={done ? 'prose-product-sm relative flex min-h-16 items-center p-3 sm:prose-product-lg border-b rounded-t-xl border-gray-200 hover:bg-gray-100 bg-gray-100 dark:bg-gray-800 cursor-pointer' : 'prose-product-sm relative flex min-h-16 items-center p-3 sm:prose-product-lg  border-gray-200 hover:bg-gray-100  cursor-pointer'}>
      <div className="flex grow items-center justify-between">
        <div className="flex dark:text-gray-300">
          <BulletItem done={done} />
          <div className="ml-4">
            <div className="flex items-center space-x-3">
              <h4>Setup your Landing Page</h4>
            </div>
          </div>
        </div>
        <div className="mr-4 text-gray-400">
          <CaretItem show={show} />
        </div>
      </div>
    </div>
    { show && <div className="p-8 pt-5">
      <div className="flex space-x-6">
        <div>
          <h4 className="mb-2">What Potential Students See First</h4>

          <ul className="ml-6 list-outside list-disc">
            <li className="pl-1">Outline exactly what the course will entail.</li>
            <li className="pl-1">Give good info on why the instructor is the best person to teach this topic.</li>
          </ul>
          <a href={`/teachers/courses/${course.id}/landing_page`}>
            <div className="prose-product-sm sm:prose-product-lg">
              <button className="mt-2 justify-center items-center flex shadow-sm hover:shadow-md disabled:shadow-none ease-out duration-200 font-medium disabled:cursor-default h-10 px-4 b2 rounded-lg bg-clcpurple text-white hover:bg-clcnavy disabled:bg-gray-500" type="button">
                Go to landing page editor
                <svg className="ml-2" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M2.5 8H13.5" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                  <path d="M9 3.5L13.5 8L9 12.5" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                </svg>
              </button>
            </div>
          </a>
        </div>
      </div>
    </div> }
  </div>
}

const Pricing = ({course}) => {
  const [show, setShow] = useState(false)
  const [done, setDone] = useState(course?.launch?.organicgrowthmaterial === 1)

  return <div className={`group ${show? 'rounded-xl border border-solid border-gray-900 shadow-lg' : ''}`}>
    <div onClick={() => setShow(!show)} className={done ? 'prose-product-sm relative flex min-h-16 items-center p-3 sm:prose-product-lg border-b rounded-t-xl border-gray-200 hover:bg-gray-100 bg-gray-100 dark:bg-gray-800 cursor-pointer' : 'prose-product-sm relative flex min-h-16 items-center p-3 sm:prose-product-lg  border-gray-200 hover:bg-gray-100  cursor-pointer'}>
      <div className="flex grow items-center justify-between">
        <div className="flex dark:text-gray-300">
          <BulletItem done={done} />
          <div className="ml-4">
            <div className="flex items-center space-x-3">
              <h4>Finalize Your Pricing</h4>
            </div>
          </div>
        </div>
        <div className="mr-4 text-gray-400">
          <CaretItem show={show} />
        </div>
      </div>
    </div>
    { show && <div className="p-8 pt-5">
      <div className="flex space-x-6">
        <b>Requirements:</b> A Stripe account — create an account with <a href='https://dashboard.stripe.com/register'>Stripe</a>
        Pricing form goes here w/ stripe
      </div>
    </div> }
  </div>
}

const Dates = ({course, cohort}) => {
  const [show, setShow] = useState(!(course?.launch?.dates === 1))
  const [done, setDone] = useState(course?.launch?.dates === 1)

  return <div className={`group ${show? 'rounded-xl border border-solid border-gray-900 shadow-lg' : ''}`}>
    <div onClick={() => setShow(!show)} className={done ? 'prose-product-sm relative flex min-h-16 items-center p-3 sm:prose-product-lg border-b rounded-t-xl border-gray-200 hover:bg-gray-100 bg-gray-100 dark:bg-gray-800 cursor-pointer' : 'prose-product-sm relative flex min-h-16 items-center p-3 sm:prose-product-lg  border-gray-200 hover:bg-gray-100  cursor-pointer'}>
      <div className="flex grow items-center justify-between">
        <div className="flex dark:text-gray-300">
          <BulletItem done={done} />
          <div className="ml-4">
            <div className="flex items-center space-x-3">
              <h4>Set dates for your first cohort</h4>
            </div>
          </div>
        </div>
        <div className="mr-4 text-gray-400">
          <CaretItem show={show} />
        </div>
      </div>
    </div>
    { show && <div className="p-8 pt-5">
      <CohortEdit cohort={cohort} />
    </div> }
  </div>
}

const ShowPrep = ({course, cohort}) => {
  return <ul className="rounded-md border border-gray-200">
    <li className="border-b first:rounded-t-md last:rounded-b-md last:border-b-0">
      <Dates course={course} cohort={cohort} />
    </li>
    <li className="border-b first:rounded-t-md last:rounded-b-md last:border-b-0">
      <LandingPage course={course} />
    </li>
  </ul>
}

export default ShowPrep

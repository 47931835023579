import React, {useState, useEffect, useRef} from 'react'
import useQuery from '@/hooks/useQuery'
import { Switch } from '@headlessui/react'
import { useForm } from 'react-hook-form'
import FroalaMinimalWrapper from '@/shared/FroalaMinimalWrapper'
import { TrashIcon, EmojiHappyIcon, LinkIcon, DuplicateIcon, ClipboardCopyIcon } from '@heroicons/react/solid'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { Modal, useModal } from '@/shared/Modal'

import { PrimaryButton, DefaultButton } from '@/shared/Buttons'
import classNames from '@/utils/classNamesLocal'

import { showNonSpaToast } from '@/utils/nonSpaToast'
import { BulletItem, CaretItem } from '@/pages/teachers/Launch/Items'

const QuestionEdit = ({question, idx, updateQuestions, removeQuestion}) => {
  const [ options, setOptions ] = useState(question.options || [])
  const [ title, setTitle ] = useState(question.title)
  const isComponentMounted = useRef(false)
  const remove = () => { }
  const removeOption = (oidx) => { 
    options.splice(oidx,1)
    const newOptions = options.slice()
    setOptions(newOptions)
  }
  const addOption = () => { setOptions([...options, '']) }
  const updateTitle = (e) => { setTitle(e.target.value) }
  const updateOption = (e, oidx) => {
    const newOptions = options.slice()
    newOptions[oidx] = e.target.value
    setOptions(newOptions)
  }

  useEffect(() => {
    if (!isComponentMounted.current) {
      isComponentMounted.current = true
      return
    }

    updateQuestions(idx, {
      kind: question.kind,
      title: title,
      options: options
    })

  }, [title, options])

  return <div className='group rounded-xl border border-solid border-gray-900 shadow-lg mt-1'>
    <div className='flex justify-between items-center space-x-4 prose-product-sm p-3 sm:prose-product-lg border-gray-200'>
      <div className='flex flex-grow items-center space-x-2'>
        <div>{idx < 10 && <>0</>}{idx + 1}.</div>
        <div className='w-full flex-1'>
          <input type="text" className='placeholder-shown:italic placeholder-gray-600 p2 py-3.5 px-4 w-full border rounded-lg font-semibold' defaultValue={title} onBlur={(e) => updateTitle(e)} placeholder="Type your question" />
        </div>
      </div>
      <TrashIcon onClick={() => removeQuestion(idx)} className="h-6 w-6 text-red-500 cursor-pointer" />
    </div>
    <div className='p-3 pt-0'>
      { question.kind === 'textarea' && <div className='text-sm text-gray-500'>Respondents will answer in a short text area.</div> }
      { question.kind === 'checkbox' && <div className='text-sm text-gray-500'>Respondents will select multiple.</div> }
      { question.kind === 'radio' && <div className='text-sm text-gray-500'>Respondents will select one.</div> }
      { question.kind === 'rating' && <div className='text-sm text-gray-500'>Respondents will choose a number between 1 and 10.</div> }
    </div>
    { (question.kind === 'checkbox' || question.kind === 'radio') && <>
      <div className='p-3 pt-0'>
        { options.map((option, oidx) => (
          <div key={`${option[0]}q${question.id}${option[2]}op${oidx}`}>
            <div className='group p-1 rounded-sm mt-1 hover:bg-gray-100 cursor-pointer flex items-center'>
              <input type="text" className='placeholder-shown:italic placeholder-gray-600 p2 py-3.5 px-4 w-full border rounded-lg' defaultValue={option} autoFocus={option === ""} onBlur={(e) => updateOption(e, oidx)} placeholder="type your option" />
              <TrashIcon onClick={() => removeOption(oidx)} className="h-6 w-6 text-red-500 cursor-pointer" />
            </div>
          </div>
        ))}
        <div className='group p-1 rounded-sm mt-1 hover:bg-gray-100 cursor-pointer flex items-center'>
          <div className='placeholder-shown:italic placeholder-gray-600 p2 py-3.5 px-4 w-full border rounded-lg text-clcnavy' onClick={addOption}>Add Option</div>
        </div>
      </div>
    </> }
  </div>
}

const SurveyEdit = ({survey}) => {
  const [ questions, setQuestions] = useState(survey.questions)
  const { openModal, closeModal, isOpen } = useModal()
  const isComponentMounted = useRef(false)
  const { putpostRequest, getRequest } = useQuery()

  const addQuestion = (kind) => {
    setQuestions([...questions, {title: 'type your question', kind: kind}])
    closeModal()
  }

  const removeQuestion = (idx) => { 
    console.log(questions)
    questions.splice(idx,1)
    const newQuestions = JSON.parse(JSON.stringify(questions))
    console.log(newQuestions)
    //const newQuestions = Object.assign([], questions)
    setQuestions(newQuestions)
  }

  const updateQuestions = (idx, question) => {
    const newQuestions = questions.slice()
    newQuestions[idx] = question
    setQuestions(newQuestions)
  }

  useEffect(() => {
    if (!isComponentMounted.current) {
      isComponentMounted.current = true
      return
    }

    putpostRequest(`/api/v1/teachers/surveys/${survey.id}`, 'PATCH', {survey: { questions: questions}}, (err, jsonData) => {
      if (err) {
        showNonSpaToast(err, false)
        return
      }
      showNonSpaToast("Survey Saved", true)
    })

  }, [questions])

  return <>
    { survey.kind === 'interest' && <h2 className="text-xl text-bold relative h-8 w-auto align-middle">Course Interest Survey</h2> }
    { survey.kind === 'post-cohort' && <h2 className="text-xl text-bold relative h-8 w-auto align-middle">Post-Cohort Survey</h2> }
    { survey.kind === 'interest' && <div>This survey is an excellent way to gather key insights from your audience before building out your curriculum. Survey respondents will provide their <b>email</b> and they will be automatically added to your course <b>waitlist</b>.</div> }
    <div className='mt-2'>
      { questions.map((question, idx) => (
        <QuestionEdit key={`q${question.title}${idx}${question.kind}`} question={question} updateQuestions={updateQuestions} idx={idx} removeQuestion={removeQuestion} />
      ))}
      <div className='group p-1 rounded-sm mt-1 hover:bg-gray-100 cursor-pointer flex items-center'>
        <div className='placeholder-shown:italic placeholder-gray-600 p2 py-3.5 px-4 w-full border rounded-lg text-clcnavy' onClick={openModal}>Add Question</div>
      </div>
      <Modal isOpen={isOpen} closeModal={closeModal} full>
        <div className="">
           <h3 className="mb-4 self-start text-gray-900">Select a question type</h3>
           <div className="grid grid-cols-2 gap-x-1">
              <div className="rounded-xl border border-transparent p-2 hover:cursor-pointer hover:border hover:border-gray-300 hover:shadow-lg cursor-pointer" onClick={() => addQuestion('textarea')}>
                 <div className="rounded-xl bg-opacity-5 bg-green-500 flex justify-center"><img className="p-2" src="https://d3fe4umbwqdrlj.cloudfront.net/course-applications/short_answer.svg" /></div>
                 <div className="font-semibold">Short answer</div>
                 <p className="mt-1 text-sm text-gray-700">Students will have an open-ended text field to respond</p>
              </div>
              <div className="rounded-xl border border-transparent p-2 hover:cursor-pointer hover:border hover:border-gray-300 hover:shadow-lg cursor-pointer" onClick={() => addQuestion('radio')}>
                 <div className="rounded-xl bg-opacity-5 bg-clcnavy flex justify-center"><img className="p-2" src="https://d3fe4umbwqdrlj.cloudfront.net/course-applications/multiple_choice.svg" /></div>
                 <h4 className="font-semibold">One choice</h4>
                 <p className="mt-1 text-sm text-gray-700">Students will choose one from a pre-selected list of options</p>
              </div>
           </div>
           <div className="grid grid-cols-2">
              <div className="rounded-xl border border-transparent p-2 hover:cursor-pointer hover:border hover:border-gray-300 hover:shadow-lg cursor-pointer" onClick={() => addQuestion('checkbox')}>
                 <div className="rounded-xl bg-opacity-5 bg-clcpurple flex justify-center"><img className="p-2" src="https://d3fe4umbwqdrlj.cloudfront.net/course-applications/multiple_select.svg" /></div>
                 <h4 className="font-semibold">Multiple select</h4>
                 <p className="mt-1 text-sm text-gray-700">Students will choose multiple from a pre-selected list of options</p>
              </div>
             <div className="rounded-xl border border-transparent p-2 hover:cursor-pointer hover:border hover:border-gray-300 hover:shadow-lg cursor-pointer" onClick={() => addQuestion('rating')}>
                 <div className="rounded-xl bg-opacity-5 bg-red-500 flex justify-center"><img className="p-2" src="https://d3fe4umbwqdrlj.cloudfront.net/course-applications/rating.svg" /></div>
                 <h4 className="font-semibold">Rating</h4>
                 <p className="mt-1 text-sm text-gray-700">Students will select a number on a scale from 1 to 10</p>
              </div>
           </div>
        </div>
        <button className='inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white dark:bg-gray-900 dark:text-white dark:hover:bg-gray-800 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2' onClick={closeModal}>Cancel</button>
      </Modal>
    </div>
  </>
}

export default SurveyEdit

import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { Switch, Route, useLocation, useHistory, Link } from 'react-router-dom'
import { useGlobalState } from '@/state'

import Sidebar from '@/Layout/Sidebar'
import Header from '@/Layout/Header'
import Banners from '@/Layout/Banners'
import ErrorHandler from '@/Layout/ErrorHander'
import ErrorNotification from '@/shared/ErrorNotification'
import { Toast } from '@/shared/Toast'
import Loading from '@/shared/Loading'

import ChatsContainer from '@/containers/ChatsContainer'
import SelectCourseContainer from '@/containers/SelectCourseContainer'
import ConceptsContainer from '@/containers/ConceptsContainer'
import ContentContainer from '@/containers/ContentContainer'
import DashboardContainer from '@/containers/DashboardContainer'
import EventsContainer from '@/containers/EventsContainer'
import OnboardingContainer from '@/containers/OnboardingContainer'
import PromoCodeContainer from '@/containers/PromoCodeContainer'
import SettingsContainer from '@/containers/SettingsContainer'
import SubscriptionContainer from '@/containers/SubscriptionContainer'

const NotFound = () => (
  <div className="bg-white dark:bg-gray-800 shadow sm:rounded-lg mt-8">
    <div className="px-4 py-5 sm:p-6">
      <div className="sm:flex sm:items-start sm:justify-between">
        <div>
          <h3 className="text-lg leading-6 font-medium text-gray-900 dark:text-white">Not Found</h3>
          <div className="mt-2 max-w-xl text-sm text-gray-500 dark:text-gray-200">
            <p> I think that link was broken. Please report this to hello@closingcredits.com if you don't mind and we'll get it fixed  </p>
          </div>
        </div>
      </div>
    </div>
  </div>
)


const NO_SIDEBAR = ['/onboarding', '/select_course', '/subscriptions', '/select_enrollment_type', '/promo_code']
const NO_HEADER = ['/onboarding', '/select_course', '/select_enrollment_type']

export default function App(props) {
  // eslint-disable-next-line no-unused-vars
  const [currentUser, setCurrentUser] = useGlobalState('currentUser')
  let history = useHistory()
  const isComponentMounted = useRef(false)
  const location = useLocation()
  const activePath = location.pathname

  useEffect(() => {
    if (activePath === '/onboarding') { return }

    // redirect in reverse order
    const leadPaths = ['/subscriptions', '/select_enrollment_type', '/change_card', '/promo_code']
    if (currentUser.needsOnboarding) {
      history.push('/onboarding')
    }
  }, [activePath, currentUser])

  useEffect(() => {
    setCurrentUser(props.currentUser)

    isComponentMounted.current = true
  }, [])

  if (!isComponentMounted.current) {
    return <Loading full={true} />
  }

  return (
    <div className="h-screen flex overflow-hidden dark:bg-gray-700 bg-gray-100">
      <div className="flex flex-col w-0 flex-1">
        <main className='overflow-y-auto'>
          <Banners />
          <div className="container mx-auto px-0 sm:px-6 lg:px-8 pb-10">
            <ErrorHandler>
              <Switch>
                <Route {...props} exact path='/dashboard'>
                  <DashboardContainer />
                </Route>
                <Route {...props} exact path='/onboarding'>
                  <OnboardingContainer />
                </Route>
                <Route {...props} exact path={['/select_course','/reserve_seat', '/select_enrollment_type', '/finalize_enrollment']}>
                  <SelectCourseContainer />
                </Route>
                <Route {...props} exact path='/settings'>
                  <SettingsContainer />
                </Route>
                <Route {...props} exact path={['/events', '/events/:id', '/past-events', '/myevents']}>
                  <EventsContainer />
                </Route>
                <Route {...props} exact path='/concepts'>
                  <ConceptsContainer />
                </Route>
                <Route {...props} exact path='/chat'>
                  <ChatsContainer />
                </Route>
                <Route {...props} path={['/subscriptions', '/buy_credits', '/change_card']}>
                  <SubscriptionContainer />
                </Route>
                <Route {...props} path={['/c/:courseId', '/l/:lessonId', '/library/:contentId', '/course-material/:courseId']}>
                  <ContentContainer />
                </Route>
                <Route {...props} exact path='/promo_code'>
                  <PromoCodeContainer />
                </Route>
                <Route exact path='/' component={() => {
                  // not a SPA path, used for redirects only until converted to SPA
                  window.location.href = '/'
                  return null
                }}/>
                <Route component={NotFound} />
              </Switch>
            </ErrorHandler>
          </div>
        </main>
      </div>
      <ErrorNotification />
      <Toast />
    </div>
  )
}

App.propTypes = {
  currentUser: PropTypes.object.isRequired
}
